import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import modalStyle from "assets/jss/material-kit-react/modalStylePro.js";
import Typography from '@material-ui/core/Typography';
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import styles from "assets/jss/material-kit-react/views/components.js";



const style = theme => ({
  ...modalStyle(theme),
  modalRootExample: {
    "& > div:first-child": {
      display: "none"
    },
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(style);
const useStyles2 = makeStyles(styles);

export default function ExampleScrolling() {
  const [scrollingModal, setScrollingModal] = React.useState(false);
  const classes = useStyles();
  const classes2 = useStyles2(styles);
  return (
    <div style={{color:"grey", display:"contents"}}>
      <Button color="transparent" onClick={() => setScrollingModal(true)} className={classes2.footerPoliticiestext3}>
      Política de privacidade
      </Button>
      <Dialog 
        onClick={() => setScrollingModal(false)}
        classes={{
          root: `${classes.modalRoot} ${classes.modalRootExample}`,
          paper: classes.modal
        }}
        open={scrollingModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setScrollingModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}>
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => setScrollingModal(false)}>
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <Typography>
              <h2 className={classes.modalTitle}>POLÍTICA DE PRIVACIDADE</h2>
          </Typography>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}>
                  <Typography style={{fontSize:"12px", textAlign:"justify", color:"#848494", textIndent:"20px"}}>
                      O principal objetivo da nossa empresa é laborar com o mínimo de complexidade possível e por isso tentamos deixar essa Política de Privacidade ("Política de Privacidade") o mais simples possível – mas, caso ainda possua quaisquer tipos de dúvidas, nossos canais de atendimento indicados estão à sua disposição.
                  </Typography>
                  <Typography style={{fontSize:"12px", textAlign:"justify", marginTop:"20px", color:"#848494", textIndent:"20px"}}>  
                      A Política de privacidade do Fitti Pagamentos - Eireli e demais empresas do Grupo, têm como foco proporcionar experiências incríveis para garantir que você desfrute ao máximo dos nossos serviços e se sinta bastante satisfeito. E para isso, a sua privacidade e a segurança dos seus dados pessoais são e sempre serão muito importantes para nós.
                  </Typography>
                  <Typography>
                      <h4 className={classes.modalTitle} style={{marginTop:"35px", letterSpacing: "0px",}} >1. INFORMAÇÕES COLETADAS E TRATADAS PELO FITTI</h4>
                  </Typography>
                      <CustomLinearProgress
                          variant="determinate"
                          color="success"
                          value={100}
                          style={{ width: "90%", display: "inline-block"}}>
                    </CustomLinearProgress>
                  <Typography style={{fontSize:"12px", textAlign:"justify", marginTop:"5px", color:"#848494", textIndent:"20px"}}>  
                    O Fitti coleta e trata dados pessoais para fins como identificação e autenticação, viabilização de ofertas e serviços do próprio Fitti, proteção ao crédito, operacionalização de novos produtos, prevenção e combate de crimes financeiros, problemas técnicos ou de segurança nos processos de identificação e autenticação, e até mesmo a melhoria de serviços e da sua experiência. <p>Dentre os dados coletados, o Fitti poderá tratar dados sensíveis, como biometria, para fins de prevenção de fraude e garantia de segurança dos serviços contratados. Também podemos coletar e tratar dados para cumprir com a legislação vigente aplicável.</p>
                  </Typography>
                  <Typography>
                      <h4 className={classes.modalTitle} style={{marginTop:"35px", letterSpacing: "0px",}} >2. COOKIES</h4>
                  </Typography>
                      <CustomLinearProgress
                          variant="determinate"
                          color="success"
                          value={100}
                          style={{ width: "90%", display: "inline-block"}}>
                    </CustomLinearProgress>
                    <Typography style={{fontSize:"12px", textAlign:"justify", marginTop:"5px", color:"#848494", textIndent:"20px"}}>  
                        Usamos Cookies para que você tenha a melhor experiência ao utilizar as nossas plataformas web e mobile.
                        Eles ajudam a lembrar suas preferências e personalizar seu acesso. Para entender melhor como funcionam e como usamos os Cookies, você pode acessar nossa Política de Cookies.
                    </Typography>
                  <Typography>
                      <h4 className={classes.modalTitle} style={{marginTop:"35px", letterSpacing: "0px",}} >3. DURAÇÃO DO TRATAMENTO DOS DADOS PESSOAIS</h4>
                  </Typography>
                    <CustomLinearProgress
                            variant="determinate"
                            color="success"
                            value={100}
                            style={{ width: "90%", display: "inline-block"}}>
                    </CustomLinearProgress>
                  <Typography style={{fontSize:"12px", textAlign:"justify", marginTop:"5px", color:"#848494", textIndent:"20px"}}>  
                      O prazo de tratamento dos seus dados pessoais pelo Fitti variará de acordo com os tipos de produtos e serviços contratados, prestados e fornecidos, de acordo com as finalidades do tratamento dos dados pessoais, ou de acordo com as disposições contratuais e legais em questão.
                  </Typography>
                  <Typography>
                      <h4 className={classes.modalTitle} style={{marginTop:"35px", letterSpacing: "0px",}} >4. SEUS DIREITOS COMO TITULAR DOS DADOS PESSOAIS</h4>
                  </Typography>
                        <CustomLinearProgress
                            variant="determinate"
                            color="success"
                            value={100}
                            style={{ width: "90%", display: "inline-block"}}>
                      </CustomLinearProgress>
                  <Typography style={{fontSize:"12px", textAlign:"justify", marginTop:"5px", color:"#848494", textIndent:"20px"}}>  
                      A LGPD confere uma série de direitos aos titulares dos dados. Sendo assim, você, como titular de dados, pode fazer as seguintes solicitações ao Fitti conforme direitos previstos na Lei: confirmação da existência de tratamento, acesso aos dados pessoais, correção dos dados incompletos, inexatos ou desatualizados, anonimização, bloqueio ou eliminação dos dados, portabilidade dos dados pessoais, informações sobre o compartilhamento dos dados pessoais, informação sobre a possibilidade de não fornecer consentimento, bem como de ser informado sobre as consequências, em caso de negativa, revogação do consentimento, oposição ao tratamento dos dados pessoais, revisão de decisões tomadas unicamente com base em tratamento automatizado de dados pessoais que afetem os interesses dos titulares de dados.                  
                  </Typography>
                  <Typography>
                      <h4 className={classes.modalTitle} style={{marginTop:"35px", letterSpacing: "0px",}}>5. COMPARTILHAMENTO DOS DADOS PESSOAIS </h4>
                  </Typography>
                      <CustomLinearProgress
                            variant="determinate"
                            color="success"
                            value={100}
                            style={{ width: "90%", display: "inline-block"}}>
                      </CustomLinearProgress>
                  <Typography style={{fontSize:"12px", textAlign:"justify", marginTop:"5px", color:"#848494", textIndent:"20px"}}>  
                    O Fitti zela pela privacidade dos seus titulares de dados e, em conformidade com as normas de proteção de dados e sigilo bancário, somente compartilha suas informações para as finalidades previstas nesse Termo e na Política de Privacidade completa.                  </Typography>
                  <Typography>
                      <h4 className={classes.modalTitle} style={{marginTop:"35px", letterSpacing: "0px",}} >6. CANAIS DE ATENDIMENTO PARA ASSUNTOS DE PRIVACIDADE </h4>
                  </Typography>
                      <CustomLinearProgress
                            variant="determinate"
                            color="success"
                            value={100}
                            style={{ width: "90%", display: "inline-block"}}>
                      </CustomLinearProgress>
                  <Typography style={{fontSize:"12px", textAlign:"justify", marginTop:"5px", color:"#848494", textIndent:"20px"}}>  
                      Para que você possa exercer seus direitos como titular de dados, bem como determinar suas preferências no tratamento e utilização pelo Fitti de alguns de seus dados pessoais você pode usar os canais de atendimento oficiais disponíveis na página inicial do Fitti.
                      <p>Quer saber mais ou obter informações relevantes? Entre em contato com sac@fittibank.com.br</p>                  
                  </Typography>

          
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => setScrollingModal(false)} color="success">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}