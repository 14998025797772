import React from "react";
import {Bar} from 'react-chartjs-2';
import  'chartjs-plugin-datalabels';
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/components.js";
import Typography from '@material-ui/core/Typography';
import styled, { keyframes } from 'styled-components';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
//import taxasconsorcio from 'images/taxasconsorcio.png';
//import SmoothImage from 'react-smooth-image';
import Check from '@material-ui/icons/Check';
//import Close from '@material-ui/icons/Close';
//import Success from "components/Typography/Success.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import pricingStyle from "assets/jss/material-kit-react/views/sectionsSections/pricingStyle.js";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";


//import CardFooter from "components/Card/CardFooter.js";
//import Button from "components/CustomButtons/Button.js";
//import NavPills from "components/NavPills/NavPills.js";
//import Danger from "components/Typography/Danger.js";



// @material-ui/icons
//import Favorite from "@material-ui/icons/Favorite";
// core components



// sections for this page






Chart.register(ChartDataLabels);

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(pricingStyle);

//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;


export default function ChartConsorcios(){

     const classes = useStyles(styles)
     const classes2 = useStyles2(pricingStyle)
     let chartfinal = {
            chartData: {
                labels: [ ['Fitti', 'Empréstimos'], 
                          ['Empréstimos', 'Tradicionais'], 
                          ['Outros', 'Empéstimos'] 
                        ],
                labels1: [ 'Fitti Empréstimos', 'Empréstimos Tradicionais', 'Outros Empréstimos'],
                datasets: [
                            {   label: 'Fiti x Concorrentes',
                                data: [ 0.89, 0.99, 1.05 ],
                                backgroundColor: [  'rgba(76, 175, 80, 0.6)',
                                                    'rgba(153, 153, 153, 0.6)',
                                                    'rgba(210, 210, 210, 0.6)' ],

                                    borderColor: [  'rgba(76, 175, 80, 1)',
                                                    'rgba(153, 153, 153, 1)',
                                                    'rgba(210, 210, 210, 1)' ], 
                                    borderWidth: 2,
                                    borderRadius: 50,
                                    barPercentage:0.8,
                                    
                            }
                        
                        
                        ]
            }   
    }
    //const plugins = { pluginsFinal: [ChartDataLabels] }
    const options = {
            
            config: {
                plugins: {legend: {display: false}, datalabels:{font:{size:'20px'}, formatter: function(value, context){ return value + '%'}},
                tooltip: {
                    backgroundColor:'rgba(153, 153, 153, 1)',
                    borderColor:'rgba(153, 153, 153, 1)',
                    borderWidth:'2',
                    callbacks: {
                        label: function (context, data) {
                            var label = chartfinal.chartData.labels1[context.dataIndex];
                            var value = chartfinal.chartData.datasets[context.datasetIndex].data[context.dataIndex]; 
                            
                            return label +': '+ value + '%' 
                        },

                        title: function(context) {
                            console.log(context[0].label);
                            return context[0].label.replaceAll(',',' ');
                        }
                        },
                        labelColor: function(context) {
                            return (context.dataset.data.backgroundColor)
                        },
                       
                },

            },
                responsive: true,
                maintainAspectRatio: false,
                animation: { animateScroll: true, duration:2000, easing:'easeInQuart', delay:50},
                animationDelay: true,
               
                scales: {  x: { grid: {display: false,} },
                           y: { ticks: { beginAtZero: true, display: true, callback: (label) => `${label}%` , gridLines: { display: true, drawBorder: true }  }} }
                
            }
    }


    return (   
       
<GridContainer className={classes.container} style={{display: "flex", justifyContent: "center"}}>
                <div className={classes.container2} style={{marginBottom:"35px", marginTop:"10px"}}>
                    <Animation1>
                        <Typography className={classes.titleanimatedbuttons2}>
                            Compare as taxas
                        </Typography>
                    </Animation1>
                </div> 

        <GridItem xs={12} sm={6} md={6} >
        <Typography className={classes.titleConsorcioTaxas}>
                  Vantagens na escolha do Fitti
        </Typography>
        <GridContainer>
            <GridItem xs={4} sm={4} md={4} lg={4}>
              <Card pricing color="success" style={{ backgroundImage: "linear-gradient(34deg, rgba(1,100,125,1) 0%, rgba(149,200,63,1) 100% )" }}>
                <CardBody pricing style={{display: "contents", justifyContent: "center"}}>
                   <Typography className={classes.cardNumberTitle}> Fitti </Typography>
                   <Typography style={{color:"white", marginBottom:"-20px", fontVariantCaps: "small-caps", display:"flex", justifyContent: "center"}} className={classes.typographTaxa}> Á partir de: </Typography>
                   <Typography className={classes.cardNumberTitleNumero3}> 0,89<small>%</small>  </Typography>
                   <Typography style={{color:"white", marginTop:"-15px", fontVariantCaps: "small-caps", display:"flex", justifyContent: "center"}} className={classes.typographTaxa}> a.m </Typography>
                  <List style={{color:"#fff"}}>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} />
                      <Typography className={classes.typographTaxa}> Menores taxas </Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}> Reajuste IPCA </Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Com carência na 1º parcela</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Com depósito imediato </Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Diversas <br/> linhas de empréstimos </Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}> Prazo: <br/> Até 240 meses </Typography>
                    </ListItem>
                  </List>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={4} sm={4} md={4} lg={4}>
            <Card pricing  plain >
                <CardBody pricing style={{display: "contents", justifyContent: "center"}}>
                 <Typography className={classes.cardNumberTitle2}> Tradicionais </Typography>
                 <Typography style={{color:"rgb(63, 63, 63)", marginBottom:"-20px", fontVariantCaps: "small-caps", display:"flex", justifyContent: "center"}} className={classes.typographTaxa}> Á partir de: </Typography>
                 <Typography className={classes.cardNumberTitleNumero4}> 0,99<small>%</small>  </Typography>
                 <Typography style={{color:"rgb(63, 63, 63)", marginTop:"-15px", fontVariantCaps: "small-caps", display:"flex", justifyContent: "center"}} className={classes.typographTaxa}> a.m </Typography>
                  <List >
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} />
                      <Typography className={classes.typographTaxa}> Taxas Médias </Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}> Reajuste IPCA </Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}> Sem carência na 1º parcela </Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}> Sem depósito imediato </Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}> Poucas <br/> linhas de empréstimos</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}> Prazo: <br/> Até 180 meses </Typography>
                    </ListItem>
                  </List>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={4} sm={4} md={4} lg={4}>
            <Card pricing  plain >
                <CardBody pricing style={{display: "contents", justifyContent: "center"}}>
                <Typography className={classes.cardNumberTitle2}> Outros </Typography>   
                <Typography style={{color:"rgb(63, 63, 63)", marginBottom:"-20px", fontVariantCaps: "small-caps", display:"flex", justifyContent: "center"}} className={classes.typographTaxa}> Á partir de: </Typography>
                <Typography className={classes.cardNumberTitleNumero4}> 1,05<small>%</small>  </Typography>
                <Typography style={{color:"rgb(63, 63, 63)", marginTop:"-15px", fontVariantCaps: "small-caps", display:"flex", justifyContent: "center"}} className={classes.typographTaxa}> a.m </Typography>
                  <List >
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} />
                      <Typography className={classes.typographTaxa}> Maiores Taxas </Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}> Reajuste IPCA </Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}> Sem carência na 1º parcela </Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}> Sem depósito imediato </Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Apenas 1 <br/> linha de <br/>  empréstimo</Typography>
                    </ListItem>
                   <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}> Prazo: <br/> Até 180 meses </Typography>
                    </ListItem>
                  </List>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
      {/* Pricing 4 END */}
        </GridItem>
       
        <GridItem xs={12} sm={6} md={6} >
                        <Typography className={classes.titleConsorcioTaxas2}>
                               Taxa ao mês á partir de:
                         </Typography>
                         <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <div className={classes.chartConsorcios} style={{boxShadow: "0px 2px 23px -16px", borderRadius: "20px", marginBottom:"50px", marginTop:"20px", paddingTop: "20px", paddingBottom: "20px", paddingLeft:"25px", paddingRight:"25px"}}>
                                <Bar data={chartfinal.chartData} options={options.config} ></Bar>
                            </div>
                        </div>
        </GridItem>
        </GridContainer> 
      

    );
}

