

import React, { useEffect } from 'react';


const TradingViewTickerTape = () => {
    useEffect(() => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
      script.async = true;
      script.innerHTML = JSON.stringify({
        "symbols": [
          {
            "description": "Petrobras ON N2",
            "proName": "BMFBOVESPA:PETR3"
          },
          {
            "description": "Petrobras PN N2",
            "proName": "BMFBOVESPA:PETR4"
          },
          {
            "description": "VALE ON",
            "proName": "BMFBOVESPA:VALE3"
          },
          {
            "description": "Índice Bovespa",
            "proName": "BMFBOVESPA:IBOV"
          },
          {
            "proName": "FOREXCOM:SPXUSD",
            "title": "S&P 500 Index"
          },
          {
            "description": "APPLE INC.",
            "proName": "NASDAQ:AAPL"
          },
          {
            "description": "MICROSOFT CORP.",
            "proName": "NASDAQ:MSFT"
          },
          {
            "description": "Amazon.com, INC.",
            "proName": "NASDAQ:AMZN"
          },
          {
            "description": "Tesla, INC.",
            "proName": "NASDAQ:TSLA"
          },
          {
            "description": "Meta Platforms, INC.",
            "proName": "NASDAQ:META"
          },
          {
            "description": "NVIDIA CORP.",
            "proName": "NASDAQ:NVDA"
          },
          {
            "description": "Advanced Micro Devices, INC.",
            "proName": "NASDAQ:AMD"
          },
          {
            "description": "USIMINAS PNA N1",
            "proName": "BMFBOVESPA:USIM5"
          }
        ],
        "showSymbolLogo": true,
        "isTransparent": true,
        "displayMode": "adaptive",
        "colorTheme": "light",
        "locale": "br"
      });
  
      const container = document.getElementsByClassName('tradingview-widget-container__widget')[0];
      if (container) {
        container.appendChild(script);
      }
  
      return () => {
        if (container) {
          container.innerHTML = '';
        }
      };
    }, []);
  
    return (
      <div className="tradingview-widget-container">
        <div className="tradingview-widget-container__widget"></div>
        <div className="tradingview-widget-copyright">
  
        </div>
      </div>
    );
  };
  
  export default TradingViewTickerTape;