import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import modalStyle from "assets/jss/material-kit-react/modalStylePro.js";
import Typography from '@material-ui/core/Typography';
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import styles from "assets/jss/material-kit-react/views/components.js";



const style = theme => ({
  ...modalStyle(theme),
  modalRootExample: {
    "& > div:first-child": {
      display: "none"
    },
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(style);
const useStyles2 = makeStyles(styles);

export default function ExampleScrolling() {
  const [scrollingModal, setScrollingModal] = React.useState(false);
  const classes = useStyles();
  const classes2 = useStyles2(styles);
  return (
    <div>
      <Button color="transparent" onClick={() => setScrollingModal(true)} className={classes2.footerPoliticiestext2}>
      Política de segurança
      </Button>
      <Dialog 
        onClick={() => setScrollingModal(false)}
        classes={{
          root: `${classes.modalRoot} ${classes.modalRootExample}`,
          paper: classes.modal
        }}
        open={scrollingModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setScrollingModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}>
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => setScrollingModal(false)}>
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <Typography>
              <h2 className={classes.modalTitle}>POLÍTICA DE SEGURANÇA</h2>
          </Typography>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}>
                  <Typography style={{fontSize:"12px", textAlign:"justify", color:"#848494", textIndent:"20px"}}>
                  Os pilares da política de Segurança da Informação e Cibernética do <strong>Fitti Pagamentos - Eireli</strong> e demais empresas do Grupo estão aderentes aos valores da Instituição e presentes no cumprimento da função de todos os colaboradores. As suas premissas são:
                  </Typography>
                  <Typography style={{fontSize:"12px", textAlign:"justify", marginTop:"20px", color:"#848494", textIndent:"20px"}}>  
                      <ul style={{listStyleType:"disc"}}>                
                          <li>Proteger as informações e ativos de tecnologia da informação contra acesso, modificação, destruição ou divulgação não autorizados;</li>

                          <li>Garantir a continuidade do processamento das informações críticas ao negócio;</li>

                          <li>Cumprir a legislação que regulamenta os aspectos de propriedade intelectual e atender a legislação que regulamenta as atividades do <strong> Fitti</strong> e seu mercado de atuação;</li>

                          <li>Determinar os mecanismos de gestão de riscos cibernéticos.</li>
                      </ul>  
                  </Typography>
                  <Typography style={{fontSize:"12px", textAlign:"justify", marginTop:"5px", color:"#848494", textIndent:"20px"}}> 
                      A área de SEGURANÇA DA INFORMAÇÃO E GOVERNANÇA DE DADOS é responsável por manter, atualizar e divulgar a Política de Segurança da Informação e Cibernética, as normas e procedimentos que dela derivam. Esta Política aplica-se a todos os administradores, colaboradores, terceiros e demais envolvidos nas atividades do <strong> Fitti </strong>.
                  </Typography>
                  <Typography>
                      <h4 className={classes.modalTitle} style={{marginTop:"35px", letterSpacing: "0px",}} >PRINCÍPIOS</h4>
                  </Typography>
                      <CustomLinearProgress
                          variant="determinate"
                          color="success"
                          value={100}
                          style={{ width: "90%", display: "inline-block"}}>
                  </CustomLinearProgress>
                  <Typography style={{fontSize:"12px", textAlign:"justify", color:"#848494", textIndent:"20px"}}>
                        A nossa visão sobre Segurança da Informação e Cibernética está baseada nos seguintes princípios:                  </Typography>
                  <Typography style={{fontSize:"12px", textAlign:"justify", marginTop:"20px", color:"#848494", textIndent:"20px"}}>  
                      <ul>                
                          <li><strong>Confidencialidade</strong> - Somente o Usuário da Informação, que esteja devidamente autorizado pelo Gestor da Informação, deve ter acesso às Informações respeitando os critérios de segregação de funções pré definidos;</li>

                          <li><strong>Integridade</strong>  - Garantir que informações não sejam alteradas desde a sua criação até seu uso. Eventuais alterações, supressões e/ou adições devem ser autorizadas pelo Gestor da Informação;</li>

                          <li><strong>Disponibilidade</strong> - Deve garantir que as Informações estejam sempre disponíveis para o Usuário da Informação;</li>

                          <li><strong>Autenticidade</strong> - Garante a identidade de quem está enviando a Informação, ou seja, gera o não-repúdio que se dá quando há garantia de que o emissor não pode se esquivar da autoria da mensagem (irretratabilidade).</li>
                      </ul>  
                  </Typography>
                  <Typography>
                      <h4 className={classes.modalTitle} style={{marginTop:"35px", letterSpacing: "0px",}}>ABRANGÊNCIA EM SISTEMAS E ATIVOS COMPUTACIONAIS</h4>
                  </Typography>
                      <CustomLinearProgress
                          variant="determinate"
                          color="success"
                          value={100}
                          style={{ width: "90%", display: "inline-block"}}>
                    </CustomLinearProgress>
                  <Typography style={{fontSize:"12px", textAlign:"justify", marginTop:"5px", color:"#848494", textIndent:"20px"}}>  
                        As premissas definidas em política são aplicáveis a todos os ambientes computacionais de processamento de dados do <strong> Fitti </strong>, estendendo, mas não se limitando a todos os servidores, bases de dados, sistemas operacionais, hardware, software, dispositivos de redes, telefonia, dispositivos móveis, além de ambientes de terceiros que de forma física ou lógica estejam integrados ou conectados nos ambientes do <strong> Fitti </strong> e seu parque tecnológico.
                  </Typography>
                  <Typography style={{fontSize:"12px", textAlign:"justify", marginTop:"5px", color:"#848494", textIndent:"20px"}}>  
                  O <strong> Fitti </strong> pauta suas ações em boas práticas do mercado nacional e internacional, são elas:
                  </Typography>
                  <Typography style={{fontSize:"12px", textAlign:"justify", marginTop:"20px", color:"#848494", textIndent:"20px"}}>  
                      <ul>                
                          <li><strong>ISO 27002</strong> – Políticas para segurança da informação;</li>

                          <li><strong>ISO 27701</strong> – Gestão da Privacidade da Informação;</li>

                          <li><strong>CIS</strong> – Center for Internet Security.</li>
                      </ul>  
                  </Typography>
                  <Typography>
                      <h4 className={classes.modalTitle} style={{marginTop:"35px", letterSpacing: "0px",}} >RESPONSABILIDADES</h4>
                  </Typography>
                    <CustomLinearProgress
                            variant="determinate"
                            color="success"
                            value={100}
                            style={{ width: "90%", display: "inline-block"}}>
                    </CustomLinearProgress>
                  <Typography style={{fontSize:"12px", textAlign:"justify", marginTop:"5px", color:"#848494", textIndent:"20px"}}>  
                  Nossos controles internos de segurança da informação são atribuídos nas seguintes dimensões:
                  </Typography>
                  <Typography style={{fontSize:"12px", textAlign:"justify", marginTop:"20px", color:"#848494", textIndent:"20px"}}>  
                      <ul style={{listStyleType:"decimal"}}>                
                          <li>Inventário e Controle de Ativos de Hardware;</li>

                          <li>Inventário e Controle de Ativos de Software;</li>

                          <li>Gerenciamento contínuo de vulnerabilidades;</li>

                          <li>Uso controlado de privilégios administrativos;</li>

                          <li>Configurações seguras para hardware e software;</li>

                          <li>Manutenção, monitoramento e análise de Logs de Auditoria;</li>

                          <li>Proteções de e-mail e navegador da web;</li>

                          <li>Defesas contra malware;</li>

                          <li>Limitação e controle de portas de rede, protocolos e serviços;</li>

                          <li>Recursos de Recuperação de Dados;</li>

                          <li>Configuração segura para dispositivos de rede, como firewalls, roteadores e switches;</li>

                          <li>Defesa de Fronteira;</li>

                          <li>Proteção de dados;</li>

                          <li>Acesso controlado com base no conceito “need -to-know“;</li>

                          <li>Controle de acesso sem fio;</li>
                          
                          <li>Monitoramento e Controle de Contas;</li>

                          <li>Implementar um programa de conscientização e treinamento em segurança;</li>

                          <li>Segurança de Software de Aplicação;</li>

                          <li>Gerenciamento e Resposta a Incidentes;</li>
                          
                          <li>Testes de penetração e exercícios do time de resposta a incidentes;</li>

                          <li>Descarte de informações;</li>

                          <li>Gestão de continuidade de negócios.</li>
                      </ul>  
                  </Typography>
                  <Typography>
                      <h4 className={classes.modalTitle} style={{marginTop:"35px", letterSpacing: "0px",}} >CONFORMIDADE</h4>
                  </Typography>
                        <CustomLinearProgress
                            variant="determinate"
                            color="success"
                            value={100}
                            style={{ width: "90%", display: "inline-block"}}>
                      </CustomLinearProgress>
                  <Typography style={{fontSize:"12px", textAlign:"justify", marginTop:"5px", color:"#848494", textIndent:"20px"}}>  
                  Caso o <strong> Fitti </strong> identifique uma conduta não aderente ou algum descumprimento das diretrizes estabelecidas, medidas administrativas e ou legais cabíveis serão tomadas.                  
                  </Typography>
          
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => setScrollingModal(false)} color="success">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}