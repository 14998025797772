import { container, container2, container3 } from "assets/jss/material-kit-react.js";
import backgroundcss from "images/fundo1.svg";
import tooltip from "assets/jss/material-kit-react/tooltipsStyle.js";



const componentsStyle = {
  container,
  container2,
  container3,

  brand: {
    color: "#FFFFFF",
    textAlign: "left"
  },
  title: {
    color:"#3f3f3f",
    fontSize: "4.0rem",
    fontWeight: "5",
     letterSpacing: "-4px",
     lineHeight: "1.1",
    '@media (max-width:780px)': {  
      fontSize: "2.5rem",
      textAlign: "center",
      letterSpacing: "0px",
      lineHeight: "1.0",
    }
  
  },

  titleEmprestimosPrincipal: {
    color:"#3f3f3f",
    fontWeight: "5",
     letterSpacing: "-4px",
     lineHeight: "1.1",
     fontSize:"50px", 
     marginLeft: "45px",
    '@media (max-width:780px)': {  
      fontSize: "2.5rem",
      textAlign: "center",
      letterSpacing: "0px",
      lineHeight: "1.0",
      marginLeft: "0px"
    }
  
  },

  titleEmprestimosPrincipal2BR: {
      display: "none",
    '@media (max-width:780px)': {  
      display: "block",
      marginTop: "-45px"
    },
  },

  titleEmprestimosPrincipal2: {
    color:"#3f3f3f",
    fontWeight: "5",
     letterSpacing: "-4px",
     lineHeight: "1.1",
     fontSize:"50px", 
    
    '@media (max-width:780px)': {  
      fontSize: "2.5rem",
      textAlign: "center",
      letterSpacing: "0px",
      lineHeight: "1.0",
    },
    
    },

  

empreParamsInput: {
    display:"flex", 
    marginTop: "-11px",
    '@media (max-width:500px)': {  
      display:"block", 
      marginTop: "-10px",
    },
    
  },


empreParamsInputTotal: {
    overflow:"hidden",
     
    '@media (max-width:780px)': {  
    overflow:"hidden",
   
    },
    '@media (max-width:370px)': {  
    display:"inline-grid",
    },
    
  },


  title2sub: {
    color:"#3f3f3f",
    fontSize: "2.0rem",
    fontWeight: "5",
     letterSpacing: "-2px",
     lineHeight: "1.1",
     '@media (max-width:991px)': {  
      fontSize: "1.7rem",
    },
    '@media (max-width:780px)': {  
      fontSize: "1.3rem",
      textAlign: "center",
      letterSpacing: "0px",
      lineHeight: "1.0",
    }
  
  },

   title2subTaxas: {
    color:"#3f3f3f",
    fontSize: "55px",
    fontWeight: "bold",
     letterSpacing: "-2px",
     lineHeight: "1.1",
    '@media (max-width:780px)': {  
      fontSize: "40px",
      textAlign: "center",
      letterSpacing: "0px",
      lineHeight: "1.0",
    }
  },

  title2subEmpreParams: {
    color: "#3f3f3f",
    marginTop:"10px",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    marginBottom: "40px",
    '@media (max-width:780px)': {  
      lineHeight: "1.0",
    }
  },

  title2subEmpreParamsInput: {
    color: "#4caf50",
    marginTop:"10px",
    fontSize: "1rem",
    fontWeight: "500",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    marginBottom: "10px",
    textAlign: "center",
    '@media (max-width:780px)': {  
      lineHeight: "1.0",
      textAlign: "center",
    }
  },

  title2subEmpreParamsInput2: {
    color: "#4caf50",
    marginTop:"10px",
    fontSize: "1rem",
    fontWeight: "500",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    marginBottom: "36px",
    marginLeft: "-30px",
    '@media (max-width:780px)': {  
      lineHeight: "1.0",
    }
  },

  title2subEmpreParamsInput3: {
    color: "#4caf50",
    //marginTop:"10px",
    fontSize: "1rem",
    fontWeight: "500",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    //marginBottom: "36px",
    '@media (max-width:780px)': {  
      lineHeight: "1.0",
    }
  },

  title2subEmpreParamsInput4: {
    color: "#4caf50",
    marginTop:"10px",
    fontSize: "1rem",
    fontWeight: "500",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    marginBottom: "10px",
    '@media (max-width:780px)': {  
      lineHeight: "1.0",
      textAlign: "center",
      },
   },

  subTableWords: {
    color:"#3f3f3f",
    fontSize: "30px",
    fontWeight: "bold",
     lineHeight: "1.1",
    '@media (max-width:780px)': {  
      fontSize: "20px",
      textAlign: "center",
      lineHeight: "1.0",
    }
  
  },
  
  subTableWordsCred: {
    color:"#3f3f3f",
    fontSize: "20px",
    fontWeight: "bold",
     lineHeight: "1.1",
    '@media (max-width:780px)': {  
      fontSize: "20px",
      textAlign: "center",
      lineHeight: "1.0",
    }
  
  },

  subTableWordsParcelas: {
    color:"#3f3f3f",
    fontSize: "20px",
    fontWeight: "100",
     lineHeight: "1.1",
    '@media (max-width:780px)': {  
      fontSize: "20px",
      textAlign: "center",
      lineHeight: "1.0",
    }
  
  },

displayTitlesub: {
  display:"Flex", 
  justifyContent:"flex-start", 
  paddingRight: "90px",
  marginTop: "20px",
  marginLeft: "1px",
  '@media (max-width:1200px)': {  
    paddingRight: "0px",
    marginRight: "0px",
    },
     '@media (max-width:959px)': {  
    paddingRight: "0px",
    marginRight: "270px",
    },
    '@media (max-width:780px)': {  
    justifyContent:"center", 
    paddingRight: "0px",
    marginLeft: "0px",
    marginRight: "0px",
    }
  
  },

  displayTitlesub2: {
    display:"Flex", 
    justifyContent:"flex-start", 
    paddingRight: "0px",
    marginTop: "20px",
    marginLeft: "1px",
    '@media (max-width:1200px)': {  
      paddingRight: "0px",
      marginRight: "0px",
      },
       '@media (max-width:959px)': {  
      paddingRight: "0px",
      marginRight: "270px",
      },
      '@media (max-width:780px)': {  
      justifyContent:"center", 
      paddingRight: "0px",
      marginLeft: "0px",
      marginRight: "0px",
      }
    
    },
  
displayTitlesub3: {
    display:"Grid", 
    justifyContent:"flex-start", 
    paddingRight: "0px",
    marginTop: "20px",
    marginLeft: "1px",
    '@media (max-width:1200px)': {  
      paddingRight: "0px",
      marginRight: "0px",
      },
       '@media (max-width:959px)': {  
      paddingRight: "0px",
      marginRight: "270px",
      },
      '@media (max-width:780px)': {  
      justifyContent:"center", 
      paddingRight: "0px",
      marginLeft: "0px",
      marginRight: "0px",
      }
    
    },
  
   


displayFundoCinzaEmprestimos:{
    boxShadow: "0px 2px 23px -16px", 
    borderRadius: "20px",  
    padding: "14px",  
    height: "324px", 
    backgroundColor:"#f7f7f9",
'@media (max-width:1290px)': {  
    height: "350px",
    },
  '@media (max-width:780px)': {  
    height: "455px",
    },
'@media (max-width:770px)': {  
    height: "350px",
    },
    '@media (max-width:750px)': {  
    height: "455px",
    },
     '@media (max-width:660px)': {  
    height: "350px",
    },
      '@media (max-width:530px)': {  
    height: "475px",
    },
  },


  displayFundoCinzaEmprestimos2:{
    boxShadow: "0px 2px 23px -16px", 
    borderRadius: "20px",  
    padding: "14px",  
    //height: "324px", 
    backgroundColor:"#f7f7f9",

  },

  displayFundoCinzaEmprestimos3:{
    boxShadow: "0px 2px 23px -16px", 
    borderRadius: "20px",  
    padding: "14px",  
    height: "300px", 
    backgroundColor:"#f7f7f9",
'@media (max-width:1290px)': {  
    height: "320px",
    },
  '@media (max-width:780px)': {  
    height: "455px",
    },
'@media (max-width:770px)': {  
    height: "300px",
    },
    '@media (max-width:750px)': {  
    height: "455px",
    },
     '@media (max-width:660px)': {  
    height: "300px",
    },
      '@media (max-width:530px)': {  
    height: "390px",
    },
  },

  calcInvesRend:{
    display:"flex", 
    justifyContent:"center",
    '@media (max-width:540px)': {  
      display:"grid",
      justifyContent: "center",
      textAlign: "center"
    }
  },

  modalFooterEmpre: {
    display:"flex",
    justifyContent: "inherit",
    '@media (max-width:780px)': {  
      display:"grid",
    },
  },

buttonEmpreParamsInput: {
    display: "flex",
    justifyContent: "center",
    marginTop: "-31px",
    '@media (max-width:780px)': {  
        marginTop: "0px"
    },
}, 


  subtitulo: {
    color:"#3f3f3f",
    fontWeight: "6",
    fontSize: "0.913rem",
    margin: "10px 0 0",
    marginBottom: "20px",
    marginTop: "50px",
    lineHeight: "1.8",
    letterSpacing: "0px",
    '@media (max-width:780px)': {  
      marginTop: "25px",
      marginBottom: "20px",
      textAlign: "center",
      alignItem: 'center',
      paddingRight:"30px",
      paddingLeft:"30px",
      fontWeight: "3",
    },
  },

    letteraccordion: {
      color:"#3f3f3f",
      fontWeight: "6",
      fontSize: "0.913rem",
      lineHeight: "1.8",
      letterSpacing: "0px",
      '@media (max-width:780px)': {  
        textAlign: "justify",
        alignItem: 'justify',
        fontWeight: "3",
      },
  },

  letteraccordion3: {
    color:"#fff",
    fontWeight: "6",
    fontSize: "0.913rem",
    lineHeight: "1.8",
    letterSpacing: "0px",
    '@media (max-width:780px)': {  
      textAlign: "justify",
      alignItem: 'justify',
      fontWeight: "3",
    },
},

  letteraccordion2: {
    color:"#fff",
    fontWeight: "6",
    fontSize: "0.913rem",
    lineHeight: "1.8",
    letterSpacing: "0px",
    '@media (max-width:780px)': {  
      textAlign: "justify",
      alignItem: 'justify',
      fontWeight: "3",
    },
},

  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  link: {
    textDecoration: "none"
  },
  textCenter: {
    textAlign: "center"
  },

  sectionTop: {
    marginTop:"120px",
    backgroundImage: `url(${backgroundcss})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    backgroundSize: "1300px",
    height: "100%",
    minHeight: "100%",
    minWidth: "100%",
    overflowX: "hidden",
    paddingBottom: "40px",
   
    '@media (max-width:780px)': {  
      marginTop: "100px",
      alignItem: 'center',
      textAlign: 'center',
      overflowX: "hidden",
      backgroundSize: "900px",
    }

  },

  sectionTop2: {
    marginTop:"20px",
  
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    backgroundSize: "1300px",
    height: "100%",
    minHeight: "100%",
    minWidth: "100%",
    paddingBottom: "40px",
   
    '@media (max-width:780px)': {  
      backgroundImage: `url(${backgroundcss})`,
      marginTop: "20px",
      alignItem: 'center',
      textAlign: 'center',
      overflowX: "hidden",
      overflowY: "hidden",
      backgroundSize: "900px",
      backgroundPosition: "initial",
    }

  },
  
  sectionTopConsorcio: {
    marginTop:"120px",
    backgroundColor:"#fff",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    backgroundSize: "1300px",
    height: "100%",
    minHeight: "100%",
    minWidth: "100%",
    overflowX: "hidden",
    paddingBottom: "40px",
   
    '@media (max-width:780px)': {  
      marginTop: "100px",
      alignItem: 'center',
      textAlign: 'center',
      overflowX: "hidden",
      backgroundSize: "900px",
    }

  },

  appcss: {
    minHeight: "100%",
    minWidth: "100%",
    position: "absolute",
    left: "0",
    right: "0",
    margin: "0 !important",
    overflowX: "hidden",
   
  },

  //Footer Config

  footerMiniText: {
    textAlign: "justify",
    color:"#FFFFFF",
    fontWeight: "2",
    fontSize: "0.8rem",
   
  },

  footerTitleText: {
    textAlign: "center",
    color:"#FFFFFF",
    fontWeight: "10!Importante",
    fontSize: "1.2rem",
    '@media (max-width:780px)': {  
      textAlign: 'left',
    }
  },

  imgAnimation: {
   // Width:'200%', 
    '@media (max-width:780px)': {  
          width:'100%',
          },      
  },
  

  gridfooter: {
    textAlign: "justify !Important",
    color:"#FFFFFF",
    fontWeight: "2",
    fontSize: "0.875rem",
    
  },

  footerIcon: {
      color: "#fff!Important",
      position: "relative",
      padding: "0.9375rem",
      fontWeight: "400",
      fontSize: "12px",
      textTransform: "uppercase",
      borderRadius: "3px",
      lineHeight: "20px",
      textDecoration: "none",
      margin: "0px",
      display: "inline-flex",
      "&:hover,&:focus": {
        color: "#00bf8f!Important",
        background: "rgba(200, 200, 200, 0.2)"
      },
  },

  IconApps: {
    fill:"#fff",
    transition: "all .25s ease",
    "&:hover,&:focus": {
      color: "#00bf8f",
      background: "transparent",
      fill: "#00bf8f",
  },
},

  footerLinks: {
    color: "#fff!Important",
    position: "relative",
    padding: "0",
    fontWeight: "400",
    fontSize: "14px",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "block",
    "&:hover,&:focus": {
      color: "#00bf8f!Important",
      transition: "all 150ms ease 0s",
    },
},

  socialIcons: {
    position: "relative",
    fontSize: "22px !important",
    marginRight: "4px",
  },

  socialIcons2: {
    position: "relative",
    fontSize: "4rem!important",
    marginLeft: "15px",
  },

  ...tooltip,

  listIconFoot: {
    margin: 0,
    padding: "0",
    marginLeft: "-15px",
    listStyle: "none",
    color: "inherit",
    display: "inline-block",
    align:"left", 
    float:"left",
  },

  listIconFoot2: {
    margin: 0,
    padding: "0",
    marginLeft: "-50px",
    listStyle: "none",
    color: "inherit",
    display: "inline-flex",
    align:"left", 
    float:"left",
  },

  listItemIconFoot: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
  },
  listItemText2: {
    padding: "0 !important"
  },

  listLinksFoot: {
    listStyle: "none",
    color: "inherit",
    align:"left", 
    float:"left",
    display: "block",
    alignItem: "left",
    marginLeft: "80px",
    '@media (max-width:805px)': {  
      marginLeft: "0px",
     },
     
  },

  listItemLinksFoot: {
    marginTop: "5px",
    color: "inherit",
    margin: "0",
    padding: "0",
    textAlign: "left",
    listStyle: "none",
    alignItem: "left",
    '@media (max-width:780px)': {  
      textAlign: 'left',
     },
  },

  titleFonesMin: {
    color: "#fff!Important",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    textAlign: "left",
    alignItem: "left",
    display: "block",
    margin: "0",
    padding: "0",
    
  },

  titleFonesMax: {
    color: "#fff!Important",
    fontWeight: "400",
    fontSize: "14px",
    textTransform: "uppercase",
    padding: "0",
    margin: "0",
    textAlign: "left",
    alignItem: "left",
    display: "block",
    "&:hover,&:focus": {
      color: "#00bf8f!Important",
      transition: "all 150ms ease 0s",
    },
  },

  titleFonesMax2: {
    color: "#fff!Important",
    position: "relative",
    fontWeight: "400",
    fontSize: "14px",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0",
    display: "block",
    align:"left", 
    float:"left",
    "&:hover,&:focus": {
      color: "#00bf8f!Important",
      transition: "all 150ms ease 0s",
    },
  },

  listItemFonesFoot: {
    position: "relative",
    marginTop: "5px",
    color: "inherit",
    margin: "0",
    padding: "0",
    textAlign: "left",
    listStyle: "none",
    alignItem: "left",
    display: "block",
    '@media (max-width:780px)': {  
      textAlign: 'left',
     },
 },
     listItemFonesFoot2: {
      position: "relative",
      marginTop: "20px",
      color: "inherit",
      margin: "0",
      padding: "0",
      textAlign: "left",
      listStyle: "none",
      alignItem: "left",
      display: "block",
      '@media (max-width:780px)': {  
        textAlign: 'left',
       },
    },
 
    footerMiniTextrodape: {
      textAlign: "justify",
      color:"#FFFFFF",
      fontWeight: "2",
      fontSize: "0.8rem",
      '@media (max-width:780px)': {  
        textAlign: 'center',
       },

    },

    footerPoliticiestext: {
      color:"#fff!Important",
      fontWeight: "2",
      fontSize: "0.8rem",
      marginRight: "5px",
      marginLeft: "0",
      marginTop: "0",
      marginBottom: "0",
      padding: "0",
      textDecoration: "none",
      "&:hover,&:focus": {
        color: "#00bf8f!Important",
        transition: "all 150ms ease 0s",
      },
      '@media (max-width:780px)': {  
        textAlign: 'center',
        alignItem: "center",
       },
    },

    footerPoliticiestext2: {
      color:"#fff!Important",
      fontWeight: "2",
      fontSize: "0.8rem",
      marginRight: "5px",
      marginLeft: "5px",
      marginTop: "0",
      marginBottom: "0",
      padding: "0",
      textDecoration: "none",
      textTransform:"capitalize",
      "&:hover,&:focus": {
        color: "#00bf8f!Important",
        transition: "all 150ms ease 0s",
      },
      '@media (max-width:780px)': {  
        textAlign: 'center',
        alignItem: "center",
       },
    },

    footerPoliticiestext3: {
      color:"#4caf50!Important",
      fontWeight: "2",
      fontSize: "0.8rem",
      marginRight: "5px",
      marginLeft: "5px",
      marginTop: "0",
      marginBottom: "0",
      padding: "0",
      textDecoration: "none",
      textTransform:"capitalize",
      "&:hover,&:focus": {
        color: "#00bf8f!Important",
        transition: "all 150ms ease 0s",
      },
      '@media (max-width:780px)': {  
        textAlign: 'center',
        alignItem: "center",
       },
    },

    GridPolices: {
      margin: "0 0 50px 0", 
      padding: "0", 
      display:"flex-end", 
      textAlign: "center", 
      alignItems:"baseline", 
      '@media (max-width:959px)': {  
        textAlign: 'center',
        alignItem: "center",
        margin: "20px 0 30px 0"
      }, 
    },

    //Botões cards//

    /* {
      margin: 0px;
      padding: 0px;
  } */
  titlecard: {
    color:"#fff",
    fontSize: "1.5rem",
    fontWeight: "Bold",
  },

  titlecard2: {
    color:"#fff",
    fontSize: "1.4rem",
    fontWeight: "Bold",
    margin:"0",
    padding:"0",
  },
  infocards: {
      fontSize: ".8rem",
      marginTop: "10px",
      color:"#fff",
      lineHeight: "18px",
    
  },
  content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgb(242, 243, 248)",
      width: "100vw",
      height: "600px",
      overflow: "hidden",
      marginTop:"-80px",
      '@media (max-width:780px)': {  
        overflowX: "hidden",
       },

  },
  btn: {
      minWidth: "60px",
      height: "60px",
      borderRadius: "20px",
      background: "#fff",
      border: "0px",
      outline: "none",
      cursor: "pointer",
      zIndex: "200",
      margin: "10px",
      boxShadow: "0px 0px 0px 0px rgba(0,0,0,.08)",
      transition: "all .25s ease",
      '@media (max-width:780px)': {  
        minWidth: "0.5vw",
        borderRadius: "8px",
         margin: "1vw",
       },
  '&:hover': {
      transform: "translate(0, -10px)",
      boxShadow: "0px 17px 35px 0px rgba(0,0,0,.07)",
    },
  },
  //.btn .i
  btnI: {
      fontSize: "1.6rem",
  },
  conCards: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "100%",
      overflowY: "hidden",
      paddingTop: "100px",
      //paddingLeft:"60px",
      paddingRight: "50px",
      scrollBehavior: "smooth",
  '&::-webkit-scrollbar': {
      height: "0px",
    },
  '&::after': {
      content: '',
      display: "block",
      minWidth: "20px",
      height: "100px",
      position: "relative",
    },
  },
  card: {
      width: "300px",
      minWidth: "300px",
      height: "380px",
      background: "#fff",
      borderRadius: "30px",
      position: "relative",
      Zindex: "10",
      margin: "25px",
      minHeight: "356px",
      transition: "all .25s ease",
      boxShadow: "0px 0px 0px 0px rgba(0,0,0,.08)",
      cursor: "pointer",
  '&:hover': {
      transform: "translate(0, -10px)",
      boxShadow: "0px 17px 35px 0px rgba(0,0,0,.09)",
  },
},

cardCosorcio: {
      width: "300px",
      minWidth: "300px",
      height: "305px",
      background: "#fff",
      borderRadius: "30px",
      position: "relative",
      Zindex: "10",
      margin: "25px",
      minHeight: "305px",
      transition: "all .25s ease",
      boxShadow: "0px 0px 0px 0px rgba(0,0,0,.08)",
      cursor: "pointer",
  '&:hover': {
      transform: "translate(0, -10px)",
      boxShadow: "0px 17px 35px 0px rgba(0,0,0,.09)",
  },
},
  // .card:hover .con-img
   cardHoverConImg: {
    '&:hover': {
      transform: "translate(0,-15px)",
    },
  },

  // .card:hover .con-img img.blur
  cardHoverConImgImgBlur: {
      opacity: ".45",
      transform: "translate(-10px, 26px) scale(.85)",
  },
  //.card .H3
  cardH3: {
      position: "absolute",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      left: "0px",
      top: "0px",
      padding: "15px",
  },
   //.card i.bx 
  cardIBx: {
      position: "absolute",
      right: "0px",
      top: "0px",
      padding: "15px",
      fontSize: "1.3rem",
  },
   //.card .con-text
  cardConText: {
      padding: "20px", 
  },
  cardfundodegrade: {
    padding: "", 
 
},
   //.card .con-img 
  cardConImg: {
      /* transform: translate(0,-90px); */
      width: "calc(100% - 60px)",
      margin: "10px 30px",
      marginTop: "-5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "all .35s ease-out",
      '&:hover': {
        transform: "translate(0, -15px)",
             
  },
},

cardConImgConsorcio: {
  /* transform: translate(0,-90px); */
  width: "calc(100% - 60px)",
  margin: "10px 30px",
  marginTop: "-5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "all .35s ease-out",
  '&:hover': {
    transform: "translate(0, -15px)",   
  },
},

   //.card img 
  cardImg: {
      height: "280px",
      zIndex: "4",
      marginTop: "-90px",
  },
  //.card img.blur 
  cardImgBlur: {
     height: "280px",
      position: "absolute",
      filter: "blur(15px)",
      zIndex: "1",
      opacity: ".45",
      transform: "translate(-10px, 26px) scale(.85)",
      transition: "all .35s ease-out",
      marginTop: "-90px",

      '&:hover': {
        opacity: ".55",
        transform: "translate(-10px, 50px) scale(.85)",      
     },    
  },

    //.card img 
    cardImgConsorcios: {
      height: "180px",
      zIndex: "4",
      marginTop: "-90px",
  },
  //.card img.blur 
  cardImgBlurConsorcios: {
     height: "180px",
      position: "absolute",
      filter: "blur(15px)",
      zIndex: "1",
      opacity: ".45",
      transform: "translate(-10px, 26px) scale(.85)",
      transition: "all .35s ease-out",
      marginTop: "-90px",

      '&:hover': {
        opacity: ".55",
        transform: "translate(-10px, 50px) scale(.85)",      
     },    
  },

  badgeConsórcios: {
    marginTop:"5px",
    marginBottom:"10px",
    display:"flex",
  },

  badgeConsorcioSpan: {
    fontSize: "17.5px !Important",
    fontWeight: "bold",
    display: "flex",
    marginLeft: "8px",
  },

  badgeConsórciosMini: {
    marginTop:"5px",
    display: "flex",
    justifyContent: "space-between",
    "&, & span": {
      display:"flex",
    },
  },

  badgeInvestimentosMini: {
    marginTop:"5px",
    display: "flex",
    justifyContent: "flex-start",
    "&, & span": {
      display:"flex",
    },
  },

  titleanimatedbuttons: {
    display:"flex", 
    justifyContent: "center", 
    alignItems: "center",
    fontSize: "2.5rem",
    fontWeight: "4",
    lineHeight: "1.1",
    color:"#3f3f3f",
    letterSpacing: "-1px",
    marginBottom:"-10px",
    marginTop:"0px",
    paddingTop:"30px",
    '@media (max-width:780px)': {  
      marginBottom:"-10px",
      marginTop:"-50px",
      fontSize: "1.6rem",
      letterSpacing: "0.2px",
     },
  
  },

  titleanimatedbuttons2: {
    display:"flex", 
    justifyContent: "center", 
    alignItems: "center",
    lineHeight: "1.1",
    fontSize: "2.5rem",
    fontWeight: "4",
    color:"#3f3f3f",
    letterSpacing: "-1px",
    marginBottom:"-10px",
    marginTop:"20px",
    textAlign: "center",
    '@media (max-width:780px)': {  
      marginBottom:"-10px",
      marginTop:"20px",
      fontSize: "1.6rem",
      letterSpacing: "0.2px",
      justifyContent: "center",
      textAlign: "center", 
     },
  
  },

  titleConsorcioTaxas: {
    display:"flex", 
    justifyContent: "center", 
    alignItems: "center",
    lineHeight: "1.1",
    fontSize: "2.0rem",
    fontWeight: "4",
    color:"#3f3f3f",
    letterSpacing: "-1px",
    marginBottom:"-10px",
    marginTop:"20px",
    textAlign: "center",
    '@media (max-width:780px)': {  
      marginBottom:"-10px",
      marginTop:"20px",
      fontSize: "1.6rem",
      letterSpacing: "0.2px",
      justifyContent: "center",
      textAlign: "center", 
     },
  
  },

  titleConsorcioTaxas2: {
    display:"flex", 
    justifyContent: "center", 
    alignItems: "center",
    lineHeight: "1.1",
    fontSize: "2.0rem",
    fontWeight: "4",
    color:"#3f3f3f",
    letterSpacing: "-1px",
    marginBottom:"-10px",
    marginTop:"20px",
    textAlign: "center",
    marginLeft:"101px",
    '@media (max-width:1166px)': {  
      marginLeft:"81px",
      display:"flex", 
    justifyContent: "center", 
    alignItems: "center",
    lineHeight: "1.1",
    fontSize: "2.0rem",
    fontWeight: "4",
    color:"#3f3f3f",
    letterSpacing: "-1px",
    marginBottom:"-10px",
    marginTop:"20px",
    textAlign: "center",
     },
    '@media (max-width:1091px)': {  
      marginLeft:"49px",
      display:"flex", 
    justifyContent: "center", 
    alignItems: "center",
    lineHeight: "1.1",
    fontSize: "2.0rem",
    fontWeight: "4",
    color:"#3f3f3f",
    letterSpacing: "-1px",
    marginBottom:"-10px",
    marginTop:"20px",
    textAlign: "center",
     },
     '@media (max-width:1024px)': {  
      marginLeft:"91px",
      display:"flex", 
    justifyContent: "center", 
    alignItems: "center",
    lineHeight: "1.1",
    fontSize: "2.0rem",
    fontWeight: "4",
    color:"#3f3f3f",
    letterSpacing: "-1px",
    marginBottom:"-10px",
    marginTop:"20px",
    textAlign: "center",
     },
    '@media (max-width:800px)': {  
    marginLeft:"52px",
    display:"flex", 
    justifyContent: "center", 
    alignItems: "center",
    lineHeight: "1.1",
    fontSize: "2.0rem",
    fontWeight: "4",
    color:"#3f3f3f",
    letterSpacing: "-1px",
    marginBottom:"-10px",
    marginTop:"20px",
    textAlign: "center",
     },
    '@media (max-width:780px)': {  
    marginLeft:"46px",
    display:"flex", 
    justifyContent: "center", 
    alignItems: "center",
    lineHeight: "1.1",
    fontSize: "2.0rem",
    fontWeight: "4",
    color:"#3f3f3f",
    letterSpacing: "-1px",
    marginBottom:"-10px",
    marginTop:"20px",
    textAlign: "center",
     },
    '@media (max-width:760px)': {  
      marginBottom:"-10px",
      marginTop:"20px",
      fontSize: "1.6rem",
      letterSpacing: "0.2px",
      justifyContent: "center",
      textAlign: "center", 
      marginLeft:"0px",
     },
  
  },

  titleanimatedbuttonsSlide: {
    display:"flex", 
    justifyContent: "center", 
    alignItems: "center",
    fontSize: "2.5rem",
    fontWeight: "4",
    lineHeight: "1.1",
    color:"#fff",
    letterSpacing: "0.2px",
    marginBottom:"-10px",
    marginTop:"0px",
    paddingTop:"30px",
    '@media (max-width:780px)': {  
      marginBottom:"-10px",
      fontSize: "1.6rem",
      letterSpacing: "0.2px",
     },
  
  },

  titleInvestimentosChart: {
    display:"flex", 
    justifyContent: "center", 
    lineHeight: "1.1",
    fontSize: "2.0rem",
    fontWeight: "4",
    color:"#3f3f3f",
    letterSpacing: "-1px",
    marginBottom:"-10px",
    marginTop:"20px",
   },

  titleanimatedbuttons21: {
    display:"flex", 
    justifyContent: "center", 
    alignItems: "center",
    lineHeight: "1.1",
    fontSize: "2.5rem",
    fontWeight: "4",
    color:"#3f3f3f",
    letterSpacing: "-1px",
    marginBottom:"-10px",
    marginTop:"10px",
    '@media (max-width:780px)': {  
      marginBottom:"-10px",
      fontSize: "1.6rem",
      letterSpacing: "0.2px",
     },
  
  },

cartoesProdutosInvest: { 
  marginBottom:"50px", 
  marginTop:"90px", 
  outline: "none",
  '@media (max-width:920px)': {  
    marginBottom:"480px", 
   },
  },

gridservicos: { 
  width:"100%",
  '@media (max-width:780px)': {  
  width:"90%"
   },
  },

  
  titleanimatedbuttons3: {
    display:"flex", 
    justifyContent: "left", 
    alignItems: "left",
    lineHeight: "1.1",
    fontSize: "1.6rem",
    fontWeight: "initial",
    color:"#3f3f3f",
    letterSpacing: "0px",
    marginLeft: "25px",
    marginBottom:"-10px",
    marginTop:"180px",
    '@media (max-width:805px)': { 
      marginLeft: "10px",
      justifyContent: "right",
      textAlign: "end", 
  
     },
    '@media (max-width:760px)': { 
      display:"none",
     },
  
  },

  titleanimatedbuttons4: {
    display:"flex", 
    justifyContent: "left", 
    alignItems: "left",
    lineHeight: "1.1",
    fontSize: "1.1rem",
    fontWeight: "bold",
    color:"#3f3f3f",
    marginLeft: "25px",
    letterSpacing: "0px",
    marginBottom:"-10px",
    marginTop:"20px",
    '@media (max-width:805px)': { 
      marginLeft: "10px",  
      justifyContent: "right",
      textAlign: "end",
      float: "right"
     },

    '@media (max-width:760px)': { 
      display:"none",
     },
  
  },


  titleanimatedbuttons5: {
    display:"flex", 
    justifyContent: "left", 
    alignItems: "left",
    lineHeight: "1.1",
    fontSize: "1.6rem",
    fontWeight: "initial",
    color:"#3f3f3f",
    letterSpacing: "0px",
    marginLeft: "25px",
    marginBottom:"-10px",
    marginTop:"5px",
    '@media (max-width:805px)': { 
      marginLeft: "10px",
      justifyContent: "right",
      textAlign: "end", 
  
     },
    '@media (max-width:760px)': { 
      display:"none",
     },
  
  },

  //Arrows Animated

arrowanimatedbuttons: {
  display:"flex", 
  justifyContent: "center",
  marginBottom: "auto",
  marginTop: "10px",
  '@media (max-width:956px)': {  
    width:'100%',
    marginTop: "200px",
    marginBottom: "auto",
    },
  '@media (max-width:810px)': {  
    width:'100%',
    marginTop: "30px",
    marginBottom: "auto",
    },
  '@media (max-width:800px)': {  
    width:'100%',
    marginTop: "260px",
    marginBottom: "auto",
    },
  '@media (max-width:770px)': {  
    width:'100%',
    marginTop: "30px",
    marginBottom: "auto",
    },
  '@media (max-width:769px)': {  
    width:'100%',
    marginTop: "270px",
    marginBottom: "auto",
    },
  '@media (max-width:750px)': {  
    width:'100%',
    marginTop: "30px",
    marginBottom: "auto",
  },
    
  '@media (max-width:640px)': {  
    width:'100%',
    marginTop: "130px",
    marginBottom: "auto",
    },
  '@media (max-width:630px)': {  
      width:'100%',
      marginTop: "30px",
      marginBottom: "auto",
    },
   '@media (max-width:600px)': {  
      width:'100%',
      marginTop: "130px",
      marginBottom: "auto",
    },
    '@media (max-width:535px)': {  
      width:'100%',
      marginTop: "30px",
      marginBottom: "auto",
    },
    '@media (max-width:490px)': {  
      width:'100%',
      marginTop: "80px",
      marginBottom: "auto",
    },
    '@media (max-width:450px)': {  
      width:'100%',
      marginTop: "30px",
      marginBottom: "auto",
    },
},



arrowanimatedbuttonsConsorcio: {
  display:"flex", 
  justifyContent: "center",
  marginBottom: "-30px",
  marginTop: "10px",
  '@media (max-width:956px)': {  
    width:'100%',
    marginTop: "200px",
    marginBottom: "auto",
    },
  '@media (max-width:810px)': {  
    width:'100%',
    marginTop: "30px",
    marginBottom: "auto",
    },
  '@media (max-width:800px)': {  
    width:'100%',
    marginTop: "200px",
    marginBottom: "auto",
    },
  '@media (max-width:770px)': {  
    width:'100%',
    marginTop: "30px",
    marginBottom: "auto",
    },
  '@media (max-width:769px)': {  
    width:'100%',
    marginTop: "180px",
    marginBottom: "auto",
    },
  '@media (max-width:750px)': {  
    width:'100%',
    marginTop: "30px",
    marginBottom: "auto",
    },
  '@media (max-width:640px)': {  
    width:'100%',
    marginTop: "100px",
    marginBottom: "auto",
    },
  '@media (max-width:630px)': {  
      width:'100%',
      marginTop: "30px",
      marginBottom: "auto",
    },
   '@media (max-width:600px)': {  
      width:'100%',
      marginTop: "110px",
      marginBottom: "auto",
    },
    '@media (max-width:535px)': {  
      width:'100%',
      marginTop: "30px",
      marginBottom: "auto",
    },
    '@media (max-width:520px)': {  
      width:'100%',
      marginTop: "150px",
      marginBottom: "auto", 
    },
    '@media (max-width:490px)': {  
      width:'100%',
      marginTop: "120px",
      marginBottom: "auto",
    },
    '@media (max-width:390px)': {  
      width:'100%',
      marginTop: "120px",
      marginBottom: "auto",
    },
    '@media (max-width:340px)': {  
      width:'100%',
      marginTop: "20px",
      marginBottom: "auto",
    },
    '@media (max-width:325px)': {  
      width:'100%',
      marginTop: "40px",
      marginBottom: "auto",
    },
},

arrowanimatedbuttonsHomeCarEquity: {
  display:"flex", 
  justifyContent: "center",
  marginBottom: "-30px",
  marginTop: "10px",
  '@media (max-width:956px)': {  
    width:'100%',
    marginTop: "200px",
    marginBottom: "auto",
    },
  '@media (max-width:810px)': {  
    width:'100%',
    marginTop: "30px",
    marginBottom: "auto",
    },
  '@media (max-width:800px)': {  
    width:'100%',
    marginTop: "180px",
    marginBottom: "auto",
    },
  '@media (max-width:770px)': {  
    width:'100%',
    marginTop: "30px",
    marginBottom: "auto",
    },
  '@media (max-width:769px)': {  
    width:'100%',
    marginTop: "180px",
    marginBottom: "auto",
    },
  '@media (max-width:750px)': {  
    width:'100%',
    marginTop: "30px",
    marginBottom: "auto",
    },
     '@media (max-width:712px)': {  
    width:'100%',
    marginTop: "180px",
    marginBottom: "auto",
    },
  '@media (max-width:640px)': {  
    width:'100%',
    marginTop: "155px",
    marginBottom: "auto",
    },
  '@media (max-width:630px)': {  
      width:'100%',
      marginTop: "30px",
      marginBottom: "auto",
    },
   '@media (max-width:600px)': {  
      width:'100%',
      marginTop: "165px",
      marginBottom: "auto",
    },
    '@media (max-width:535px)': {  
      width:'100%',
      marginTop: "30px",
      marginBottom: "auto",
    },
    '@media (max-width:520px)': {  
      width:'100%',
      marginTop: "150px",
      marginBottom: "auto", 
    },
    '@media (max-width:490px)': {  
      width:'100%',
      marginTop: "60px",
      marginBottom: "auto",
    },
     '@media (max-width:480px)': {  
      width:'100%',
      marginTop: "130px",
      marginBottom: "auto",
    },
    '@media (max-width:414px)': {  
      width:'100%',
      marginTop: "66px",
      marginBottom: "auto",
    },
      '@media (max-width:393px)': {  
      width:'100%',
      marginTop: "56px",
      marginBottom: "auto",
    },
    '@media (max-width:390px)': {  
      width:'100%',
      marginTop: "30px",
      marginBottom: "auto",
    },
    '@media (max-width:340px)': {  
      width:'100%',
      marginTop: "20px",
      marginBottom: "auto",
    },
    '@media (max-width:325px)': {  
      width:'100%',
      marginTop: "1px",
      marginBottom: "auto",
    },
},


botaoSimularConsorcio: { 
  width:"360px", 
  height:"50px", 
  fontSize:"14px",
  '@media (max-width:550px)': {  
    width:'100%',
    height:"50px", 
    fontSize:"14px",
  },
},

alinhaBotoesInvestimentos: {
  display:"flex",
  justifyContent: "center",
  '@media (max-width:550px)': {  
    display:"contents",
  },
},

cardsInvestmentMediaQuery: {
  '@media (max-width:550px)': {  
    maxWidth:"100%"
  },
},

botaoAbraSuaContaInvestimentos: { 
  width:"210px", 
  height:"50px", 
  fontSize:"14px",
  marginRight:"10px",
  '@media (max-width:550px)': {  
    width:'100%',
    height:"50px", 
    fontSize:"14px",
  },
},

botaoFaleComInvestidorInvestimentos: { 
  width:"210px", 
  height:"50px", 
  fontSize:"14px",
  '@media (max-width:550px)': {  
    width:'100%',
    height:"50px", 
    fontSize:"14px",
  },
},

arrowsprincipal: {
  width: "60px",
  bottom: "-20px",
  height: "90px",
  /* margin-right: 1px; */
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  '@media (max-width:780px)': {  
    bottom: "0px",
   },
},

path: {
	stroke: "#2994D1",
	fill: "transparent",
	strokeWidth: "1px",	
	animation: "arrow 2s infinite",
"-webkit-animation": "arrow 2s infinite", 
},


a1: { 
	animationDelay:"-1s",
	"-webkit-animation-delay": "-1s", /* Safari 和 Chrome */
}, 

a2: {  
	animationDelay:"-0.5s",
	"-webkit-animation-delay":"-0.5s", /* Safari 和 Chrome */
},

a3: {  
	animationDelay:"0s",
	"-webkit-animation-delay":"0s", /* Safari 和 Chrome */
},

butaoanimetext: {
 color: "#3f3f3f",
 textDecoration: "none",
 display:"block",
 alignItems: "center",
 fontSize: "0.913rem",
 fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
 "&:hover,&:focus": {
  color: "#00bf8f!Important",
  transition: "all 150ms ease 0s",
},
},
outline1: {
  outline:"none",
},

customPagingDots: {
  background: "rgb(242, 243, 248)", 
  position:"relative", 
  height:"50px", 
  margin:"0", 
  marginRight:"10px", 
  display:"flex", 
  justifyContent: "center", 
  alignContent:"center", 
  listStyle: "none", 
  outline: "none", 
  decoration:"none",
  '@media (max-width:780px)':  {
    marginRight:"40px",
  },
  "&:hover,&:focus": {
    cursor:"pointer!Important",
  },
},

customPagingDots2: {
  position:"absolute", 
  top: "38%",
  left: "2%",
  margin:"0", 
  display:"flex",
  flexDirection: "column", 
  justifyContent: "center", 
  alignContent:"center", 
  listStyle: "none", 
  outline: "none", 
  decoration:"none",
  zIndex: "200",
  '@media (max-width:780px)':  {
      position:"absolute", 
      top: "38.5rem",
      left: "-8%",
      display:"flex", 
      flexDirection: "row", 
      flexWrap: "wrap", 
      justifyContent: "center", 
      alignContent:"center", 
      listStyle: "none", 
      outline: "none", 
      decoration:"none",
    },
    '@media (max-width:770px)':{ 
      position:"absolute", 
      top: "38.5rem",
      left: "8%",
      display:"flex", 
      flexDirection: "row", 
      flexWrap: "wrap", 
      justifyContent: "center", 
      alignContent:"center", 
      listStyle: "none", 
      outline: "none", 
      decoration:"none",
    },
    '@media (max-width:700px)':  {
      position:"absolute", 
      top: "38.5rem",
      left: "-8%",
      display:"flex", 
      flexDirection: "row", 
      flexWrap: "wrap", 
      justifyContent: "center", 
      alignContent:"center", 
      listStyle: "none", 
      outline: "none", 
      decoration:"none",
    },
},


customPagingDotsInvest: {
  position:"absolute", 
  top: "38%",
  left: "2%",
  margin:"0", 
  display:"flex",
  flexDirection: "column", 
  justifyContent: "center", 
  alignContent:"center", 
  listStyle: "none", 
  outline: "none", 
  decoration:"none",
  zIndex: "200",
  '@media (max-width:780px)':  {
      position:"absolute", 
      top: "38.5rem",
      left: "-8%",
      display:"flex", 
      flexDirection: "row", 
      flexWrap: "wrap", 
      justifyContent: "center", 
      alignContent:"center", 
      listStyle: "none", 
      outline: "none", 
      decoration:"none",
    },
    '@media (max-width:770px)':{ 
      position:"absolute", 
      top: "38.5rem",
      left: "17%",
      display:"flex", 
      flexDirection: "row", 
      flexWrap: "wrap", 
      justifyContent: "center", 
      alignContent:"center", 
      listStyle: "none", 
      outline: "none", 
      decoration:"none",
    },
    '@media (max-width:700px)':  {
      position:"absolute", 
      top: "38.5rem",
      left: "-8%",
      display:"flex", 
      flexDirection: "row", 
      flexWrap: "wrap", 
      justifyContent: "center", 
      alignContent:"center", 
      listStyle: "none", 
      outline: "none", 
      decoration:"none",
    },

    '@media (max-width:550px)':  {
      position:"absolute", 
      top: "38.5rem",
      left: "4%",
      display:"flex", 
      flexDirection: "row", 
      flexWrap: "wrap", 
      justifyContent: "center", 
      alignContent:"center", 
      listStyle: "none", 
      outline: "none", 
      decoration:"none",
    },

    '@media (max-width:525px)':  {
      position:"absolute", 
      top: "38.5rem",
      left: "-8%",
      display:"flex", 
      flexDirection: "row", 
      flexWrap: "wrap", 
      justifyContent: "center", 
      alignContent:"center", 
      listStyle: "none", 
      outline: "none", 
      decoration:"none",
    },
},

cardz: {
  width:"250px", 
  height:"250px", 
  '@media (max-width:1024px)':  {
    width:"auto",
    height:"281px",  
  },
  '@media (max-width:1010px)':  {
    width:"250",
    height:"250px",  
  },
  '@media (max-width:780px)':  {
    width:"auto",
    height:"250px",  
  },

   '@media (max-width:280px)':  {
    width:"auto",
    height:"280px",  
  },
},

griditemcard: {
  paddingLeft: "15px!Important",
  paddingRight: "15px!Important",   
  width:"auto",
  '@media (max-width:1024px)':  {
    paddingLeft: "15px!Important",
    paddingRight: "unset!Important", 
  },
    '@media (max-width:760px)':  {
      paddingLeft: "15px!Important",
      paddingRight: "15px!Important",     
  },



},

ipadSimulationCenter: {
'@media (max-width:1024px)': {
  display:"flex",
  justifyContent:"center", 
  },
  '@media (max-width:930px)': {
  display:"initial",
  justifyContent:"center", 
  },
},

containerquery: {
  width: "100%",
  marginRight: "auto",
  marginLeft: "auto",
  paddingLeft: "15px",
  paddingRight: "15px",
  '@media (min-width: 1200px)': {
    maxWidth: "1140px",
  },
'@media (min-width: 992px)': {
    maxWidth: "960px",
},
  '@media (max-width:600px)':  {
    marginLeft: "-72px",
    paddingLeft: "70px",
  },
  '@media (max-width:550px)':  {
      marginLeft: "auto",
      paddingLeft: "15px",
  },
  
},

//NavPills

sectionNavPills: {
  backgroundImage: `url(${backgroundcss})`,
  backgroundRepeat: "no-repeat",
  height: "100%",
  minHeight: "100%",
  minWidth: "100%",
  overflowX: "hidden",
  paddingBottom: "40px",
   marginTop:"20px",

   '@media (max-width:805px)': { 
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "900px",
   },
   
},

movieplayer1: {
  borderRadius: "20px",
},

tittleCellPhone: {
    display:"flex", 
    justifyContent: "left", 
    alignItems: "left",
    lineHeight: "1.1",
    fontSize: "1.6rem",
    fontWeight: "initial",
    color:"#3f3f3f",
    letterSpacing: "0px",
    marginBottom:"10px",
    textAlign: "center",
    '@media (max-width:805px)': { 
      justifyContent: "center",
      alignItems: "center",
     },
  },

  tittleCellPhoneWhite: {
    display:"flex", 
    justifyContent: "left", 
    alignItems: "left",
    lineHeight: "1.1",
    fontSize: "1.6rem",
    fontWeight: "initial",
    color:"#fff",
    letterSpacing: "0px",
    marginBottom:"10px",
    textAlign: "left",
    '@media (max-width:805px)': { 
      justifyContent: "center",
      alignItems: "center",
     },
  },

  tittleCellPhoneWhite2: {
    display:"flex", 
    justifyContent: "left", 
    alignItems: "left",
    lineHeight: "1.3",
    fontSize: "1.6rem",
    fontWeight: "initial",
    color:"#fff",
    letterSpacing: "0px",
    marginBottom:"10px",
    textAlign: "left",
    '@media (max-width:805px)': { 
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
     },
  },
 
  celphonealignSm: {
    display:"flex", 
    justifyContent:"flex-end",
    zIndex:"2",
    paddingRight: "0px",
    '@media (max-width:790px)': {
      display:"flex", 
      zIndex:"2",
      justifyContent:"center",
      marginBottom: "-50px",
      
  },

  '@media (max-width:850px)': {
    display:"flex", 
    zIndex:"2",
    justifyContent:"center",
},
  },

  celphonealignSm3: {
    display:"flex", 
    justifyContent:"flex-end",
    zIndex:"2",
    paddingRight: "0px",
    top: "-150px",
    '@media (max-width:790px)': {
      display:"flex", 
      zIndex:"2",
      justifyContent:"center",
  
      
  },

  '@media (max-width:850px)': {
    display:"flex", 
    zIndex:"2",
    justifyContent:"center",
},
  },


  celphonealignSm2: {
    display:"none", 
    justifyContent:"flex-end",
    zIndex:"2",
    paddingRight: "0px",
     top: "-150px",
    
    '@media (max-width:900px)': {
      display:"flex", 
      zIndex:"2",
      justifyContent:"center",
      marginBottom: "-600px",
  },
  
  },

  celphoneContainer1: {
    marginTop:"20px",
    display:"flex",
      marginLeft: "0px",
      marginRight: "0px",
      width: "100%",
      flexWrap: "wrap",
      boxSizing: "border-box", 
    '@media (max-width:805px)': {
      marginTop:"20px",
      display:"block", 
    },
  },
  celphoneContainer: {
    marginTop:"20px",
    display:"flex",
      marginLeft: "-15px",
      marginRight: "15px",
      width: "100%",
      flexWrap: "wrap",
      boxSizing: "border-box", 
      paddingLeft:"20px",
    '@media (max-width:805px)': {
      marginTop:"20px",
      display:"block", 
    },
  },

  pcImage: {
    position:"relative", 
    flexDirection:"column", 
    alignSelf:"center", 
    justifyContent:"center", 
    verticalAlign:"center", 
    height:"270px",
 
  },

  pcImage2: {
    position:"relative", 
    flexDirection:"column", 
    alignSelf:"center", 
    justifyContent:"center", 
    verticalAlign:"center", 
    height:"270px",
  },

listacelfone: {
justifyContent: "flex-start",
marginBottom:"10px",
'@media (max-width:805px)': {
  justifyContent: "center",
  },
'@media (max-width:700px)': {
  justifyContent: "flex-start",
  },
},


//VIDEO

video: {
  width: "100%",
  border: "1px solid black",
},
wrapper: {
  display: "table",
  width: "auto",
  position: "relative",

},
playpause: {
  //backgroundImage: `url(${})`,
  backgroundRepeat:"no-repeat",
  width: "50%",
  height: "50%",
  position: "absolute",
  left: "0%",
  right: "0%",
  top: "0%",
  bottom: "0%",
  margin:"auto",
  backgroundSize:"contain",
  backgroundPosition: "center",
},

titulovideo: {
  color:"#3f3f3f", 
  fontSize:"1.4rem", 
  fontWeight:"initial", 
  marginTop:"8px",
  justifyContent: "center",
  textAlign:"center",
  '@media (max-width:805px)': {
    justifyContent: "center",
    textAlign:"center",
    },
},

textovideo: {
  color:"#3f3f3f", 
  fontSize:"0.8rem", 
  fontWeight:"1", 
  marginTop:"8px",
  marginBottom: "25px",
  justifyContent: "center",
  textAlign:"center",
  '@media (max-width:805px)': {
    justifyContent: "center",
    textAlign:"center",
    },
},

//.card img.blur 
cardImgBlur2: {
  height: "180px",
   position: "absolute",
   filter: "blur(15px)",
   zIndex: "1",
   opacity: ".45",
   transition: "all .35s ease-out",
   marginTop: "220px",
   marginLeft: "-180px",
   animation: "animation1 6s ease-in-out alternate infinite",
   animationDelay: "0s",
   boxShadow: "-86px 0 17px rgba(0,0,0,0.7)",

},

badgesome: {
  '@media (max-width:805px)': {
   display:"none",
   visibility: "hidden"
    },
},

linksmenu: {
  textDecoration: "none"
},

menuLinks: {
  display: "flex",
  flexBasis: "250px",
  backgroundColor: "#1e90ff"
},

menuConteudo: {
  display: "flex",
  width: "400px",
 
},

cardconsorcioPasso: {
  display:"flex", 
  flexWrap:"wrap", 
  justifyContent:"flex-end", 
  marginTop: "60px", 
  '@media (max-width:760px)': { 
     marginTop: "65px"
  }
},

chartConsorcios: {
  width:'70%',
  height:'400px',
  '@media (max-width:760px)': { 
     width:'-webkit-fill-available',
     height:'470px',
     display: "flex",
     justifyContent: "center",
     marginBottom: "35px",
  }
},

chartSimulacaoRendaFixa: {
  width:'70%',
  height:'400px',
  boxShadow: "0px 2px 23px -16px",
  borderRadius: "20px", 
  marginBottom:"50px", 
  marginTop:"20px", 
  paddingTop: "20px", 
  paddingBottom: "20px", 
  paddingLeft:"25px", 
  paddingRight:"25px", 
  maxHeight:"240px", 
  minWidth:"100%",
  '@media (max-width:760px)': { 
     width:'-webkit-fill-available',
     height:'470px',
     display: "flex",
     justifyContent: "center",
     marginBottom: "35px",
     maxHeight:"320px", 
  }
},

colorButtonCardAnimesOpen: {
  color:"rgba(1, 100, 125, 1)"
},

verticalTimeline: {
  "&:before": {
    background:"#F5F5F5!Important"
  },
},

compareTaxas: {
  color:"#FFF",
  fontWeight: "6",
  fontSize: "0.913rem",
  margin: "0",
  marginBottom: "20px",
  marginTop: "50px",
  lineHeight: "1.8",
  letterSpacing: "0px",
  '@media (max-width:780px)': {  
    marginTop: "25px",
    marginBottom: "20px",
    textAlign: "center",
    alignItem: 'center',
    paddingRight:"30px",
    paddingLeft:"30px",
    fontWeight: "3",
  },
},
typographTaxa: { 
    fontSize: "0.8rem",
    fontFamily: "Roboto",
    fontWeight: "400",
    lineHeight: "1.5",
    marginLeft: "3px",
    marginRight: "16px",
    letterSpacing: "0.00938em",
    '@media (max-width:780px)': { 

    },

},

svgTaxa: {
  marginLeft: "none",
  '@media (max-width:1300px)': { 
    marginLeft: "-11px",
  },
},

cardNumberTitle: {
  display:"Flex",
  justifyContent:"center",
  fontSize: "27px",
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    marginBottom: "0px",
    marginTop: "10px",
    color:"#FFF",
    '@media (max-width:750px)': { 
    fontSize: "22px",
  },
},

cardNumberTitleNumero: {
  display:"Flex",
  justifyContent:"center",
  fontSize: "40px",
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    marginBottom: "0px",
    marginTop: "10px",
    color:"#FFF",
     '@media (max-width:330px)': { 
    fontSize: "30px",
  },
},
cardNumberTitleNumero3: {
  display:"Flex",
  justifyContent:"center",
  fontSize: "35px",
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    marginBottom: "0px",
    marginTop: "10px",
    color:"#FFF",
     '@media (max-width:330px)': { 
    fontSize: "30px",
  },
},

cardNumberTitleNumero2: {
  display:"Flex",
  justifyContent:"center",
  fontSize: "40px",
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    marginBottom: "0px",
    marginTop: "10px",
  color: "rgb(63, 63, 63)",
  '@media (max-width:330px)': { 
    fontSize: "39px",
  },
},

cardNumberTitleNumero4: {
  display:"Flex",
  justifyContent:"center",
  fontSize: "35px",
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    marginBottom: "0px",
    marginTop: "10px",
  color: "rgb(63, 63, 63)",
  '@media (max-width:330px)': { 
    fontSize: "39px",
  },
},
cardNumberTitle2: {
  display:"Flex",
  justifyContent:"center",
  fontSize: "27px",
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    marginBottom: "0px",
    marginTop: "10px",
    color: "rgb(63, 63, 63)",
    '@media (max-width:750px)': { 
    fontSize: "22px",
    },

    '@media (max-width:350px)': { 
    fontSize: "18px",
  },
   
},

cardNumbersubTitle: {
display:"Flex",
  justifyContent:"center",
  fontSize: "13px",
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    marginBottom: "10px",
    marginTop: "-15px",
    color:"#FFF",

},

cardCarHomeEquity1: {
  display: "flex", 
  justifyContent: "flex-end",
  '@media (max-width:585px)': { 
    marginTop: "25px",
    marginBottom: "-80px",
  },
},

listasHomeCarEquity: {
marginTop:"150px",
'@media (max-width:585px)': { 
  marginTop: "0px",
  },
},

cardNumbersubTitle2: {
display:"Flex",
  justifyContent:"center",
  fontSize: "13px",
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    marginBottom: "10px",
    marginTop: "-15px",
},

tableHomeCarEequity1: {
  '@media (max-width:920px)': { 
    display: "flex", 
    flexDirection: "column",
    order:"1",
    marginTop: "-20px!Important",
    marginBottom: "-35px",
  },
 
  '@media (max-width:585px)': { 
    display: "flex", 
    flexDirection: "column",
    order:"1",
    marginTop: "-20px!Important",
    marginBottom: "-35px",
  },
},

imgCarEquitySolo: {
  display: "flex", 
  justifyContent: "center", 
  marginTop:"-55px",
  '@media (max-width:920px)': { 
    marginTop: "-75px",
    marginBottom: "-30px",
  },
  
  '@media (max-width:585px)': { 
    marginTop: "-66px",
    marginBottom: "-30px",
  },
},

tableHomeCarEequity2: {
    '@media (max-width:920px)': { 
    order:"2",
    marginBottom:"40px",
  },
    '@media (max-width:585px)': { 
    order:"2",
    marginBottom:"40px",
  },

},

espacoslistas: {
  marginBottom:"35px", 
  marginTop:"30px",  
  '@media (max-width:585px)': { marginBottom:"-40px"}
},

espacoslistas2: {
  marginBottom:"35px", 
  marginTop:"30px",  
  '@media (max-width:585px)': { marginBottom:"0px"}
},

animationHomecarEquity: {
  marginBottom: "80px",
'@media (max-width:1024px)': { marginBottom: "250px"},  
'@media (max-width:920px)': { marginBottom: "80px"},
'@media (max-width:585px)': { marginTop: "-10px" },
},


tableHomeCarEequityTitle1: {
  marginTop:"20px", 
  fontSize:"30px", 
  lineHeight:"1.3", 
  marginBottom:"20px",
  '@media (max-width:980px)': { 
    marginTop: "40px", 
    order: "unset!Important",
    },
 '@media (max-width:585px)': { 
  marginTop: "10px",
  order: "2!Important", 
  },
},

tableHomeCarEequityTitle12: {
  marginTop:"0px", 
  fontSize:"30px", 
  lineHeight:"1.3", 
  marginBottom:"20px",
  '@media (max-width:980px)': { 
    marginTop: "40px", 
    order: "unset!Important",
    },
    '@media (max-width:830px)': { 
      marginTop: "0px", 
      order: "unset!Important",
      },
 '@media (max-width:585px)': { 
  marginTop: "10px",
  order: "2!Important", 
  },
},

tableHomeCarEequityTitle2: {
 order:"3",
  '@media (max-width:980px)': { 
    order: "unset!Important",
    },
  '@media (max-width:585px)': { 
    order: "3!Important", 
    },
},

chathomeequityAlign: { 
marginTop:"100px", 
backgroundColor:"#fff", 
display:"flex", 
justifyContent:"center", 
overflow:"hidden",
'@media (max-width:585px)': { 
  display:"contents", 
  },
},

chathomeequityAlign1: {
  marginTop:"30px",
  '@media (max-width:585px)': { 
    order:"1",
    marginTop:"110px",
    },
},

chathomeequityAlign2: {
  marginTop:"30px",
  '@media (max-width:830px)': { 
    marginBottom: "120px",
    },
  '@media (max-width:585px)': { 
    order:"2",
    marginBottom: "100px",
    },
  
},


chatHomeCarEequityTitleObs1: {
  marginTop:"23px"
 },

chatHomeCarEequityTitleObs: {
  fontSize: "12px",
    marginTop: "10px",
    fontStyle: "italic",
    color: "grey",
    '@media (max-width:830px)': { 
      marginBottom: "30px",
      },
    '@media (max-width:585px)': { 
      marginBottom: "0px",
      },
     
 },

tableHomeCarEequityTitleSolo1: {
  marginTop:"20px", 
  fontSize:"30px", 
  lineHeight:"1.3", 
  marginBottom:"20px",
  '@media (max-width:980px)': { 
    marginTop: "250px", 
    order: "unset!Important",
    },
 '@media (max-width:585px)': { 
  marginTop: "150px",
  order: "2!Important", 
  },
},

titlePriceRendaFixa: {

display:"flex",
justifyContent:"center", 
marginTop:"-10px", 
fontSize: "3rem", 
ineHeight: "1.25", 
fontWeight: "700", 
opacity: "1", 
textTransform: "none", 
color: "rgb(76, 175, 80)", 
verticalAlign: "unset", 
fontFamily: "Roboto Slab, sans-serif", 
letterSpacing: "-0.125px",
  '@media (max-width:585px)': { 
   fontSize: "1.5rem", 
    },
  '@media (max-width:380px)': { 
   fontSize: "1.5rem", 
    },
},

displayCardsInvest: {
  display: "flex", 
  justifyContent: "center",
  '@media (max-width:585px)': { 
    display: "block", 
    justifyContent: "center",
    },
},

label: {
  color: "rgba(0, 0, 0, 0.26)",
  cursor: "pointer",
  display: "inline-flex",
  fontSize: "14px",
  transition: "0.3s ease all",
  lineHeight: "1.428571429",
  fontWeight: "400",
  paddingLeft: "0"
},

inputDataChat: {
  color: "#fff!Important",
  textAlign: "center",
},

marksSlider:{
  position: "absolute", 
  left: "-5px", 
  top: "8px", 
  fontSize: "0.55rem", 
  fontFamily: "Roboto", 
  lineHeight: "1.43", 
  letterSpacing: "0.01071em", 
  fontWeight: "400", 
  color: "rgba(0, 0, 0, 0.6)"  },

}



export default componentsStyle;
