import React from "react";
import Accordion from "components/Accordion/Accordion.js";
import styled, {keyframes} from 'styled-components';
import Typography from '@material-ui/core/Typography';
import styles from "assets/jss/material-kit-react/views/components.js";
import { makeStyles } from "@material-ui/core/styles";
//import { useScrollTrigger } from '@material-ui/core';
//import Grow from '@material-ui/core/Grow';
import Success from "components/Typography/Success.js";
import Check from "@material-ui/icons/Check";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";




const useStyles = makeStyles(styles);
//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
  animation-delay: 1.5s;
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
  transform: translatey(0px);
  animation: ${float} 6s ease-in-out infinite;
  `;

export default _=>{

  const classes = useStyles(styles);


  return (
<div Id="accordion">
    <div className={classes.container2}>
        <Animation1>
                  <Typography className={classes.titleanimatedbuttons2}>
                      Dúvidas sobre o Fitti Empréstimos?
                  </Typography> 
      </Animation1>
    </div>
   
   <div style={{boxShadow: "0px 2px 23px -16px", borderRadius: "20px", marginBottom:"50px", marginTop:"50px", paddingTop: "20px", paddingBottom: "20px", paddingLeft:"25px", paddingRight:"25px"}}>
    <div className={classes.accordion1}>
      <Accordion
      //active={0}
      activeColor={"success" }
      collapses={[
        {
          title:  <Typography>"O que é o Fitti Investimentos?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                    O Fitti Investimentos tem como objetivo principal oferecer uma ampla seleção de produtos financeiros e serviços de investimentos, através de plataformas digitais (Web e Mobile), bem como através do serviço de Assessoria e consultoria especializada.   
              </Typography>
        },
        {
          title: <Typography>"O Fitti Investimentos é regulado pelo Bacen, CVM e entidades afins?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                      O Fitti Investimentos atua em regime de Assessoria de Investimentos devidamente registrada na CVM - Comissão de Valores Mobiliários, na forma da Resolução CVM 178, atuando no mercado financeiro como preposto da GENIAL INVESTIMENTOS CORRETORA DE VALORES MOBILIARIOS S.A. o que pode ser verificado através do site da ANCORD: <a href='https://www.ancord.org.br/certificacao-e-credenciamento/'>https://www.ancord.org.br/certificacao-e-credenciamento/</a>, e Correspondente Bancário, juntamente á Instituições Financeiras parceiras fiscalizadas e autorizadas pelo Banco Central do Brasil. Cada uma das verticais está alocada em empresas e verticais de negócios que são parte do Fitti Investimentos - LTDA - CNPJ: 52.915.262/0001-80 e Fitti Pagamentos - Eireli - CNPJ: 24.341.342/0001-33.`
              </Typography>
        },
        {
          title: <Typography>"Quais são as categorias (Linhas)  de Investimentos que o Fitti oferece?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>   
                      O Fitti oferece as seguintes modalidades de Investimentos: 
                      <Success >
                     <List>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Digital Retail Investor:</span>Para Pessoas Físicas e Jurídicas - "De R$1,00 a R$100 mil de Patrimônio."</ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Private (Assessoria):</span> Para Pessoas Físicas e Pessoas Jurídicas - "A partir de R$200 mil de Patrimônio."</ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Corporate/Middle:</span> Para Pessoas Jurídicas - "A partir de R$200 mil de Patrimônio."</ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Wealth Management:</span> Para Pessoas Jurídicas - "Acima de R$2 milhões de Patrimônio."</ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Investment Banking:</span> Para Pessoas Jurídicas - Acima de R$2 milhões de Patrimônio." </ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Research:</span>Para Pessoas Físicas e Pessoas Jurídicas - "A partir de R$200 mil". </ListItem> 
                    </List>
                    </Success>{" "}
              
              
              </Typography>
        },
        {
          title: <Typography>"Tenho entre R$1,00 e R$100 mil para investir, o que tenho direito?"</Typography>,
          content:
          <Typography  className={classes.letteraccordion}>   
                      Digital Retail Investor: 
                      <Success >
                     <List>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Acesso a plataforma de investimentos (Web e Digital).</span></ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Atendimento por Whatsapp.</span></ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Conta corrente com taxa zero.</span></ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Taxa Zero e Corretagem Zero.</span></ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Cashback em diversos produtos de investimentos.</span></ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Live Diária com Especialistas.</span> </ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Cursos grátis para começar a investir do jeito certo.</span></ListItem> 
                    </List>
                    </Success>{" "}  
            </Typography>
        },
        {
          title: <Typography>"Tenho mais de R$200 mil para investir, o que tenho direito?"</Typography>,
          content:
          <Typography  className={classes.letteraccordion}>   
                      Private (Assessoria): 
                      <Success >
                     <List>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Acesso a plataforma de investimentos (Web e Digital).</span></ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Alocação de investimentos direto com o Assessor Exclusivo.</span></ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Análise de portfólio com recomendações.</span></ListItem>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Planejamento financeiro junto com o Assessor.</span></ListItem>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Conta corrente com taxa zero.</span></ListItem>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Melhores taxas e Corretagens para alocações realizadas na mesa.</span></ListItem>  
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Cashback em diversos produtos de investimentos.</span></ListItem>   
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Atendimento presencial ou por vídeochamada, telefone e chat.</span></ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Reuniões períodicas.</span></ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Conteúdos exclusivos e lives semanais com o time de Análise</span> </ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Produtos exclusivos para a sua carteira.</span></ListItem> 
                    </List>
                    </Success>{" "}  
            </Typography>
        },
        {
          title: <Typography>"Sou sócio ou gestor de uma empresa com mais de R$200 mil na tesouraria para investir, o que tenho direito?"</Typography>,
          content:
          <Typography  className={classes.letteraccordion}>   
                      Private (Assessoria): 
                      <Success >
                     <List>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Acesso a plataforma de investimentos (Web e Digital).</span></ListItem>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Acesso direto a mesa de renda fixa.</span></ListItem>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Acesso direto a mesa de renda variável.</span></ListItem>    
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Alocação de investimentos direto com o Assessor Exclusivo.</span></ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Análise de portfólio com recomendações.</span></ListItem>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Planejamento financeiro junto com o Assessor.</span></ListItem>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Conta corrente com taxa zero.</span></ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Melhores taxas e Corretagens para alocações realizadas na mesa.</span></ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Cashback em diversos produtos de investimentos.</span></ListItem>   
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Atendimento presencial ou por vídeochamada, telefone e chat.</span></ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Reuniões períodicas.</span></ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Conteúdos exclusivos e lives semanais com o time de Análise</span> </ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Produtos exclusivos para a sua carteira.</span></ListItem> 
                    </List>
                    </Success>{" "}  
            </Typography>
        },

        {
          title: <Typography>"Quais são os tipos de produtos e serviços financeiros disponíveis para investir pelo Fitti Investimentos?"</Typography>,
          content:
          <Typography  className={classes.letteraccordion}>   
                      Renda Fixa: 
                      <Success>
                     <List>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Tesouro Direto.</span></ListItem>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> CDB.</span></ListItem>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> LCI e LCA.</span></ListItem>    
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> LC´s.</span></ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> CRI e CRA.</span></ListItem>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Debêntures.</span></ListItem>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> LF.</span></ListItem> 
                     </List>
                     </Success>{" "}
                     Renda Variável: 
                     <Success> 
                     <List>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Ações Nacionais e Internacionais.</span></ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> BDR´s.</span></ListItem>   
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> ETF´s</span></ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Índices.</span></ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Commodities.</span> </ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Criptomoedas.</span></ListItem>
                     </List>
                     </Success>{" "}
                     Derivativos:
                     <Success> 
                     <List>
                              <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Opções.</span></ListItem>   
                              <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Constratos à Termo e Futuros.</span></ListItem> 
                              <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Swaps.</span></ListItem> 
                              <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Mini Índice.</span> </ListItem> 
                              <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Op. Estruturadas e COE´s</span></ListItem>
                     </List>
                     </Success>{" "}
                     Fundos:
                     <Success> 
                     <List>
                              <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Fundos de investimentos em renda fixa.</span></ListItem> 
                              <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Fundos de investimentos em ações.</span></ListItem> 
                              <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Fundos de investimentos multimercados.</span> </ListItem> 
                              <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Fundos de investimentos imobiliários.</span></ListItem>   
                              <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Fundos de investimentos no exterior e cambial.</span></ListItem> 
                              <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Fundos de direitos creditórios - FIDC´s.</span> </ListItem> 
                              <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Fundos de previdência - VGBL e PGBL.</span></ListItem>   
                        </List>
                    </Success>{" "}  
            </Typography>
        },
    
 
        {
          title: <Typography>"Quais são os custos para começar a investir pelo Fitti Investimentos?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                   No Fitti você não possui custo algum para começar a investir, pois a corretagem é zero para quem investe pela plataforma web ou pelo aplicativo, porém há uma taxa de serviço para quem utiliza as mesas para realizar aplicações.
              </Typography>
        },

      ]}
      ></Accordion>
    </div> 
  </div>
  </div>
  )
}

