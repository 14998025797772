import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
//import LocationOn from "@material-ui/icons/LocationOn";
// core components
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
//import Card from "components/Card/Card.js";
import styles from "assets/jss/material-kit-react/views/components.js";
//Badges dos cards
import Badge from "components/Badge/Badge.js";
//Typography
import Typography from '@material-ui/core/Typography';
//Styled
import styled from 'styled-components';
import Button from "components/CustomButtons/Button.js";

import Emprestimos from 'images/emprestimos.png';
//import Seguros from 'images/seguros.png';


const useStyles = makeStyles(styles);

const FooterScreen = styled.div`
  height: 100%;
  width: 100%;
`;
const Fittideg = styled.div`
  background: linear-gradient(
    60deg,
    rgba(1, 100, 125, 1) 0%,
    rgba(149, 200, 63,1) 100%
  );
 
  height: 207px;
  width: 100%;
  border-top-left-radius:  -50px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;

`;



export default function CardsButtons(props) { 
  const classes = useStyles(styles);

  

  return(

  <div style={{marginBottom:"70px", marginTop:"140px", outline: "none"}}>
    <div style={{outline: "none"}} className={classes.card}>
                      <div className={classes.cardConImg} >
                          <img className={classes.cardImg} src={Emprestimos} alt=""></img>
                          <img className={classes.cardImgBlur}  src={Emprestimos} alt=""></img>
                      </div>
                      <div className={classes.cardfundodegrade}>
                      <FooterScreen>
                          <Fittideg>
                          <div className={classes.cardConText}>
                              <Typography className={classes.titlecard}>
                                      EMPRÉSTIMOS
                              </Typography> 
                              <Typography className={classes.infocards}>
                                      <div>Saiba mais sobre as nossas linhas de crédito para Pessoas Físicas e PJ´s.</div> 
                                      <div style={{lineHeight: "2.0", marginTop:"10px"}}><Badge color="white">Home Equity</Badge><Badge color="white">Car Equity</Badge>
                                      <Badge color="white">Crediário</Badge><Badge color="white">Crédito Pessoal</Badge><Badge color="white">Consignado</Badge></div>
                              </Typography>
                              <Button size="sm" type="button" color="white" style={{width:"250px"}} round>
                                  Acessar
                              </Button>
                          </div>
                          </Fittideg> 
                      </FooterScreen> 
                      </div>
                  </div>
             </div>
  );
}

