import { container, title } from "assets/jss/material-kit-react.js";

const pillsStyle = {
  section: {
    padding: "70px 0"
  },
  container,
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },

  MenuConteudoPills: {
    backgroundColor: "#fff",
    color:"black",
    marginTop: "-80px",
    width:"70rem",
  },

  ButtonPillsMenuSize: { 
    width:"100%", 
    height:"25px", 
    display:"inline-flex", 
    justifyContent:"space-between" ,
}, 

}

export default pillsStyle;
