/*eslint-disable*/
import React  from "react";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
//Material Icons
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import AttachMoney from "@material-ui/icons/AttachMoney";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import BarChartIcon from '@material-ui/icons/BarChart';
import PaymentIcon from '@material-ui/icons/Payment';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import FastfoodIcon from '@material-ui/icons/Fastfood';

import Login from '../Login/Login';
import SignUp from '../Login/SignUp';
// react components for routing our app without refresh
//import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
//Badges dos cards
import Badge from "components/Badge/Badge.js";

import { Link } from 'react-router-dom';

// @material-ui/icons
//import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdownPro.js";
import CustomDropdown2 from "components/CustomDropdown/CustomDropdownPro2.js";
import CustomDropdown3 from "components/CustomDropdown/CustomDropdownPro3.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStylePro.js";
//Typography
import Typography from '@material-ui/core/Typography';

//import {BrowserRouter as Router} from 'react-router-dom';
//import Menu from '../MenuViews/Menu';
//import Content from '../MenuViews/Content';
import MenuNavPillsPravc from  '../MenuViews/MenuNavPillsPravc';
import MenuNavPillsPraEmp from  '../MenuViews/MenuNavPillsPraEmp';
import Ofitti from  '../MenuViews/Ofitti';

//const [isDropDownOnen, setDropDownOnen] = useState(false);
//const handleDrawerClick = () => {
//   setDropDownOnen(!isDropDownOnen);
 //};

 



const useStyles = makeStyles(styles);
CustomDropdown.defaultProps = {
  caret: true,
  hoverColor: "success"
};


export default function HeaderLinks(props) {

 const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  var onClickSections = {};

  const { dropdownHoverColor } = props;

  const classes = useStyles();
  return (
    <List  className={classes.list + " " + classes.mlAuto}>
       <ListItem className={classes.listItem} style={{order:"3"}}>
         <Ofitti></Ofitti>
      </ListItem>  
      
      <ListItem className={classes.listItemWeb} style={{order:"4"}}>
      <CustomDropdown2 
          noLiPadding
          navDropdown
          buttonText="Para Você"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
  
          }}
          dropdownList={[
              <div className={classes.tamanhoMenu}> 
                  <MenuNavPillsPravc></MenuNavPillsPravc>
              </div>
          ]}>
          </CustomDropdown2>
      </ListItem>

      <ListItem className={classes.listItemWeb} style={{order:"4"}}>
      <CustomDropdown3 
          noLiPadding
          navDropdown
          buttonText="Para Empresas"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
  
          }}
          dropdownList={[

          <div className={classes.tamanhoMenu}> 
              <MenuNavPillsPraEmp></MenuNavPillsPraEmp>
          </div>
          ]}>
          </CustomDropdown3>
      </ListItem>
    
      <ListItem className={classes.listItemMobile} style={{order:"4"}}>
      <CustomDropdown
          noLiPadding
          navDropdown
          buttonText="Para Você"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          dropdownList={[
            
            <Link  style={{textDecoration: "none", display:"flex", justifyContent:"space-between" }}/* to ="/consorcios" onClick={() => handleCloseMenu(prop)} */ className={classes.dropdownLink}><span style={{marginRight:"10px", }}><BarChartIcon  style={{marginRight:"10px", verticalAlign:"middle",  }}></BarChartIcon> Investimentos</span><span  className={classes.badgesome}><Badge color="gray">Em breve</Badge></span></Link>,
            {divider: true},
            <Link  style={{textDecoration: "none", display:"flex", justifyContent:"space-between" }}/* to ="/consorcios" onClick={() => handleCloseMenu(prop)} */ className={classes.dropdownLink}><span style={{marginRight:"10px", }}><MonetizationOnIcon style={{marginRight:"10px", verticalAlign:"middle",  }}></MonetizationOnIcon>Empréstimos</span></Link>,
            <Link  style={{textDecoration: "none", display:"flex", justifyContent:"space-between" }}/* to ="/consorcios" onClick={() => handleCloseMenu(prop)} */ className={classes.dropdownLink}><span style={{marginRight:"10px", }}><BeachAccessIcon style={{marginRight:"10px", verticalAlign:"middle",  }}></BeachAccessIcon>Seguros</span></Link>,
            <Link  style={{textDecoration: "none", display:"flex", justifyContent:"space-between" }}/* to ="/consorcios" onClick={() => handleCloseMenu(prop)} */ className={classes.dropdownLink}><span style={{marginRight:"10px", }}><AttachMoney  style={{marginRight:"10px", verticalAlign:"middle",  }}></AttachMoney> Câmbio</span><span className={classes.badgesome}><Badge color="gray">Em breve</Badge></span></Link>,
            <Link  style={{textDecoration: "none", display:"flex", justifyContent:"space-between" }}  to ="/consorcios"  className={classes.dropdownLink}><span style={{marginRight:"10px", }}><BeenhereIcon  style={{marginRight:"10px", verticalAlign:"middle",  }}></BeenhereIcon> Consórcios</span><span  className={classes.badgesome}><Badge color="gray">Em breve</Badge></span></Link>,
            <Link  style={{textDecoration: "none", display:"flex", justifyContent:"space-between" }}/* to ="/consorcios" onClick={() => handleCloseMenu(prop)} */ className={classes.dropdownLink}><span style={{marginRight:"10px", }}><ShoppingCart  style={{marginRight:"10px", verticalAlign:"middle",  }}></ShoppingCart> Shopping</span><span  className={classes.badgesome}><Badge color="gray">Em breve</Badge></span></Link>,


            
          ]}>
          </CustomDropdown>
      </ListItem>
      <ListItem className={classes.listItem2Mobile} style={{order:"5"}}>
        <CustomDropdown
          noLiPadding
          navDropdown
          buttonText="Para Empresas"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
         
          dropdownList={[
           
     
            <a href="#" style={{display:"flex", justifyContent:"space-between" }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><BarChartIcon  style={{marginRight:"10px", verticalAlign:"middle",  }}></BarChartIcon> Investimentos</span></a>,
            {divider: true},
            <a href="#" style={{display:"flex", justifyContent:"space-between", }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><MonetizationOnIcon style={{marginRight:"10px", verticalAlign:"middle",  }}></MonetizationOnIcon>Empréstimos</span></a>,
            <a href="#" style={{display:"flex", justifyContent:"space-between" }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><BeachAccessIcon style={{marginRight:"10px", verticalAlign:"middle",  }}></BeachAccessIcon>Seguros</span></a>,
            <a href="#" style={{display:"flex", justifyContent:"space-between" }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><AttachMoney  style={{marginRight:"10px", verticalAlign:"middle",  }}></AttachMoney> Câmbio</span><span  className={classes.badgesome}><Badge color="gray">Em breve</Badge></span></a>,
            <Link style={{textDecoration: "none"}} to ="/consorcios" style={{display:"flex", justifyContent:"space-between" }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><BeenhereIcon  style={{marginRight:"10px", verticalAlign:"middle",  }}></BeenhereIcon> Consórcios</span><span  className={classes.badgesome}><Badge color="gray">Em breve</Badge></span></Link>,
 

            
          
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem} style={{order:"6"}}>
        <Button
          href="https://fitticomvoce.com.br"
          color="transparent"
          target="_blank" 
          className={classes.navLink}
        >Blog
        </Button>
      </ListItem>
      <ListItem className={classes.listItem} style={{order:"7"}}>
        {/*<Tooltip title="Delete">
          <IconButton aria-label="Delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>*/}
        <Tooltip
          id="linkedin-tooltip"
          title="Siga-nos no LinkedIn"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}>
          <Button
            href="https://www.linkedin.com/company/fittiinvestimentos/"
            target="_blank"
            color="transparent"
            className={classes.navLink}>
            <i className={classes.socialIcons + " fab fa-linkedin"} />
            <Typography className={classes.socialnames1} >
                /company/contafitti
            </Typography>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem} style={{order:"8"}}>
        <Tooltip
          id="facebook-tooltip"
          title="Siga-nos no Facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}>
          <Button
            color="transparent"
            href="https://www.facebook.com/fittiinvestimentos"
            target="_blank"
            className={classes.navLink}>
            <i className={classes.socialIcons + " fab fa-facebook"} />
            <Typography className={classes.socialnames1} >
                /contafitti
            </Typography>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem} style={{order:"9"}}>
        <Tooltip
          id="instagram-tooltip"
          title="Siga-nos no Instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}>
          <Button
            color="transparent"
            href="https://www.instagram.com/fittiinvestimentos/"
            target="_blank"
            className={classes.navLink}> 
            <i className={classes.socialIcons + " fab fa-instagram"} />
            <Typography className={classes.socialnames1} >
                @contafitti
            </Typography>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem} style={{order:"1"}} >
      <SignUp></SignUp>
      </ListItem>
      <ListItem className={classes.listItem} style={{order:"2"}}>
        <Login></Login>
      </ListItem>
    </List>
  );
}
