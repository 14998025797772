import React, {useState, useRef} from 'react';
// core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";
//Animations e imagens
//import AnimationUpDown from '../../views/Components/Sections/AnimationUpDown.js';
import SmoothImage from 'react-smooth-image';

import Tabblet from 'images/tabblet.png';
import capaPcsite from 'images/capaPcsite.png';

import videoImac from 'videos/clipeImac.mp4';
//import ReactPlayer from 'react-player/lazy';
//import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
//import Button from "components/CustomButtons/Button.js";

//JQuery
//import $ from "jquery"
import Button from "components/CustomButtons/Button.js";
import { useEffect } from 'react';


const useStyles = makeStyles(styles);

function usePlayerState3($videoPlayer3) {  
  const [playerState3, setPlayerState3] = useState({
      playing: true,
  });
//O useEffect é utilizado para mudar a função do video de play para pouse 
  useEffect(() => {
      playerState3.playing ? $videoPlayer3.current.play() : $videoPlayer3.current.pause(); 
  }, [ $videoPlayer3, playerState3.playing, ]);
  

  function toggleVideoPlay3() {
      setPlayerState3({
          ...playerState3,
          playing: !playerState3.playing,
      })
  }
  return {
      playerState3,
      toggleVideoPlay3
  }
}


function Example(){
  const classes = useStyles(styles)
  //video
  const $videoPlayer3 = useRef(null)
  const {
    playerState3,
    toggleVideoPlay3
    } = usePlayerState3($videoPlayer3);



  return (
                
    <div  className={classes.pcImage3}>
          <div style={{position:"relative", boxSizing:"border-box", padding:"0", margin:"0", zIndex:"2", width:"none", height: "auto",  top:"10rem", display:"flex", justifyContent: "center", }}>
            <div>
                <Button style={{width:"14.7rem", height:"9rem", borderRadius:"10px"}}  onClick={toggleVideoPlay3}
                        color="transparent"
                        className={classes.footerIcon}>{ playerState3.playing ? "" : <i className={classes.socialIcons2 + " fas fa-play"}></i>}
                        
                </Button>
            </div>  
        </div>  
       <SmoothImage  imageStyles={{position:"inherit", width:"19.8em", height: "auto", overflow:"hidden"}}
           src={Tabblet} alt='Plataforma Fitti' transitionTime={1.0}  transitionTimeFunction={"ease-in"}>
       </SmoothImage> 
       <div style={{position:"relative", bottom:"32.25em", boxSizing:"border-box", padding:"0", margin:"0", zIndex:"-1", width:"none", height: "auto", marginLeft:"35px" }}>
         <video style={{width:"15.0rem", height:"auto", borderRadius: "10px"}}
             webkit-playsinline
             playsInline
             playing
             id="video" 
             autoPlay={true} 
             muted={true}   
             name="media"
             loop={true}
             preload={"auto"}
             autoBuffer={"auto"} 
             poster={capaPcsite}  
             ref={$videoPlayer3}>                 
             <source src={videoImac}  type="video/mp4"></source>
         </video>   
      </div>
   </div>
  )}
export default Example;