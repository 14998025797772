import React from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// react components for routing our app without refresh
//import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
//import Header from "components/Header/Header.js";
import Footer2 from "components/Footer/Footer2";
//import ButtonsArea from "components/ButtonsArea/ButtonsArea";
//import ButtonsAreaAnime from "components/ButtonsArea/ButtonsAreaAnime.jsx";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
//import Parallax from "components/Parallax/Parallax.js";
// sections for this page
//import HeaderLinks from "components/Header/HeaderLinks.js";
//import SectionNavbarsNOVO from "./Sections/SectionNavbarsNOVO.js";
//Scrool Smooth site
//import { SmoothProvider } from 'react-smooth-scrolling'

import styles from "assets/jss/material-kit-react/views/components.js";
import ButtonsAreaAnimeEmprestimos from "components/ButtonsAreaEmprestimos/ButtonsAreaAnimeEmprestimos";

//Efeitos
import {Typewriter} from 'react-simple-typewriter';
//import 'react-simple-typewriter/dist/index.css';

//Typography
import Typography from '@material-ui/core/Typography';

//Imagens
//import Logo from 'images/logo-fitticontadigital.svg';


//import Cardeapp from 'images/cardeapp.png';

//Container
//import Container from '@material-ui/core/Container';
//import Grid from '@material-ui/core/Grid';

//Animations
//import AnimationUpDown from '../../../views/Components/Sections/AnimationUpDown';
import AnimationUpDownEmprestimos from  '../../../views/Components/Sections/AnimationUpDownEmprestimos';


//Css degrade Fitticolor
//import Fittidegrade from './Sections/Fittidegrade.js';

//Css
//Animations
import ArrowAnimatedButton from '../../../views/Components/Sections/ArrowAnimatedButton';
//import buttonStyle from "assets/jss/material-kit-react/components/buttonStyle.js";
//import "components/Appcss/App.css";

//JQuery
//import $ from "jquery"
//scroll
//import {animateScroll as scroll} from 'react-scroll';
//import { Fade } from "@material-ui/core";
//import anime from "animejs/lib/anime.es.js";

//Animação do cartão
//import Slide from '@material-ui/core/Slide';
//import Servicos from 'components/Servicos/Servicos.jsx';
import AccordionEmprestimos from  'components/Accordion/AccordionEmprestimos';
//import NavPillsPro from  'components/NavPills/NavPillsPro.jsx';
import VideoAreaEmprestimos from  'components/VideoArea/VideoAreaEmprestimos.jsx';
//import CartoesFitti from 'components/CartoesFitti/CartoesFitti.jsx';
//import CardConsorcios from 'components/CardConsorcios/CardConsorcios.jsx';
import CardEmprestimos from 'components/CardEmprestimos/CardEmprestimos.jsx';
import ServicosEmprestimos from 'components/Servicos/ServicosEmprestimos.jsx';
import ChartEmprestimos from 'components/Charts/ChartEmprestimos.jsx';
//import { useLocation } from 'react-router-dom';




//import {BrowserRouter as Router} from 'react-router-dom';
//import Menu from '../../components/MenuViews/Menu';
//import Content from '../../components/MenuViews/Content';

const useStyles = makeStyles(styles);

export default function MenuEmprestimos(props) {
  const classes = useStyles();
  //const { ...rest } = props;
  
  

  return (     
       <section  className={classes.appcss}>
            <div className={classes.sectionTopConsorcio} >
                    <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={6}>
                        <div>
                            <Typography  className={classes.title}  color="initial">
                                Os Empréstimos
                            </Typography>
                            <Typography className={classes.title}  color="initial">
                                    que dão Fitti com 
                                    <br></br><span style={{ color: "#3f3f3f", fontWeight: 'bold' }}> <Typewriter
                                        loop
                                        cursor
                                        cursorStyle='|'
                                        typeSpeed={70}
                                        deleteSpeed={50}
                                        delaySpeed={1000}
                                        words={['você!', 'sua empresa!', 'seus sonhos!']}>
                                    </Typewriter></span>
                            </Typography> 
                            <Typography className={classes.subtitulo}>
                                    Aqui você encontra empréstimos e financiamentos para você ou seu negócio, com garantia e sem garantia, com as menores taxas do mercado e contratação totalmente digital.
                            </Typography>
                            <div style={{marginTop:"15px"}}>
                                <Button  className={classes.botaoSimularConsorcio} color="success" round>
                                SIMULE SEU EMPRÉSTIMO
                                </Button>
                            </div>
                        </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <AnimationUpDownEmprestimos>
                            </AnimationUpDownEmprestimos>
                        </GridItem>
                    </GridContainer>
                    <div className={classes.arrowanimatedbuttonsConsorcio}>
                        <ArrowAnimatedButton>
                        </ArrowAnimatedButton>
                    </div>
            </div>
            <div name="section2" id="section2" style={{background: "rgb(242, 243, 248)", minHeight:"620px"}}>
                <ButtonsAreaAnimeEmprestimos>
                </ButtonsAreaAnimeEmprestimos>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"5px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                            <Button  className={classes.botaoSimularConsorcio} color="success" round>
                            SIMULE SEU CRÉDITO
                            </Button>
                    </GridItem>
                </GridContainer>
            </div>
             <div name="" id="section2" style={{background: "#fff", minHeight:"700px"}}>
                <CardEmprestimos>
                </CardEmprestimos>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"-5px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                            <Button  className={classes.botaoSimularConsorcio} color="success" round>
                            SIMULE SEU CRÉDITO
                            </Button>
                    </GridItem>
                </GridContainer>
            </div>
            <div name="" id="section2" style={{background: "rgb(242, 243, 248)", minHeight:"700px"}}>
                <ServicosEmprestimos>
                </ServicosEmprestimos>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"5px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                            <Button  className={classes.botaoSimularConsorcio} color="success" round>
                            SIMULE SEU CRÉDITO
                            </Button>
                    </GridItem>
                </GridContainer>
            </div>
            <div name="" id="section2" style={{background: "#fff", minHeight:"550px"}}>
                <ChartEmprestimos>
                </ChartEmprestimos>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"-18px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                            <Button  className={classes.botaoSimularConsorcio} color="success" round>
                            SIMULE SEU CRÉDITO
                            </Button>
                    </GridItem>
                </GridContainer>
            </div>
            
            <div name="ViddeoArea" id="VideoArea" style={{background: "rgb(242, 243, 248)"}}>
                <VideoAreaEmprestimos>
                </VideoAreaEmprestimos>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"-49px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                            <Button  className={classes.botaoSimularConsorcio} color="success" round>
                            SIMULE SEU CRÉDITO
                            </Button>
                    </GridItem>
                </GridContainer>
            </div>
            <div>
                <GridContainer className={classes.container} style={{minHeight:"517px"}}>
                    <AccordionEmprestimos>
                    </AccordionEmprestimos>
                </GridContainer>
            </div>
            <div name="rodape" id="rodape" >
                <Footer2>
                </Footer2>
            </div>
        </section>

  );
}