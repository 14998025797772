import React, {useState, useRef} from 'react';
// core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";
//Animations e imagens
//import AnimationUpDown from '../../views/Components/Sections/AnimationUpDown.js';
import SmoothImage from 'react-smooth-image';

import Imacfundo from 'images/IMACfundo.png';
import capaPcsite from 'images/capaPcsite.png';
import videoImac from 'videos/clipeImac.mp4';
//import ReactPlayer from 'react-player/lazy';
//import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
//import Button from "components/CustomButtons/Button.js";

//JQuery
//import $ from "jquery"
import Button from "components/CustomButtons/Button.js";
import { useEffect } from 'react';



const useStyles = makeStyles(styles);

function usePlayerState2($videoPlayer2) {  
  const [playerState2, setPlayerState2] = useState({
      playing: true,
  });
//O useEffect é utilizado para mudar a função do video de play para pouse 
  useEffect(() => {
      playerState2.playing ? $videoPlayer2.current.play() : $videoPlayer2.current.pause(); 
  }, [ $videoPlayer2, playerState2.playing, ]);
  

  function toggleVideoPlay2() {
      setPlayerState2({
          ...playerState2,
          playing: !playerState2.playing,
      })
  }
  return {
      playerState2,
      toggleVideoPlay2
  }
}


function Example(){
  const classes = useStyles(styles)
  //video
  const $videoPlayer2 = useRef(null)
  const {
    playerState2,
    toggleVideoPlay2
    } = usePlayerState2($videoPlayer2);



  return (
                
                    <div  className={classes.pcImage}>
                        <div style={{position:"relative", boxSizing:"border-box", padding:"0", margin:"0", zIndex:"2", width:"none", height: "auto",  top:"10rem", display:"flex", justifyContent:"center" }}>
                                <div>
                                    <Button style={{width:"14.7rem", height:"9rem", borderRadius:"10px"}}  onClick={toggleVideoPlay2}
                                            color="transparent"
                                            className={classes.footerIcon}>{ playerState2.playing ? "" : <i className={classes.socialIcons2 + " fas fa-play"}></i>}
                                            
                                    </Button>
                                </div>  
                        </div>  
                        <SmoothImage  imageStyles={{position:"inherit",width:"19.8em", height: "auto", overflow:"hidden"}}
                          src={Imacfundo} alt='Plataforma Fitti' transitionTime={1.0}  transitionTimeFunction={"ease-in"}>
                      </SmoothImage> 
                    <div style={{position:"relative", bottom:"32.25em", boxSizing:"border-box", padding:"0", margin:"0", zIndex:"-1", width:"none", height: "auto", marginLeft:"35px" }}>
                        <video style={{width:"15.0rem", height:"auto", borderRadius: "10px"}}
                            webkit-playsinline
                            playsInline
                            playing
                            id="video" 
                            autoPlay={true} 
                            muted={true}   
                            name="media"
                            loop={true}
                            preload={"auto"}
                            autoBuffer={"auto"}
                            poster={capaPcsite}   
                            ref={$videoPlayer2}>                 
                            <source src={videoImac}  type="video/mp4"></source>
                        </video>   
                     </div>
                 </div>
  )}
export default Example;