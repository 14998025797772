import React from 'react';
//import styled, { keyframes } from 'styled-components'
import styles from "assets/jss/material-kit-react/views/components.js";
import { makeStyles } from "@material-ui/core/styles";
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-scroll';



//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
  animation-delay: 3.0s;
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
  transform: translatey(0px);
  animation: ${float} 4s ease-in-out infinite;
  `;

const arrow1 = keyframes`
0% {
  opacity:0;
}
40% {
  opacity:1;
}
80% {
  opacity:0;
}
100% {
  opacity:0;
}
  `;

const Animation = styled.path`
	stroke: #2994D1;
	fill: transparent;
	stroke-width: 2px;
  animation: ${arrow1} 2s infinite;
`;

  const useStyles = makeStyles(styles);

export default _ => {

const classes = useStyles();

  
    return (
        <Link  to="section2" smooth={true} duration={1000} style={{cursor:"pointer"} } className={classes.butaoanimetext} > 
            <Animation1 style={{marginRight:"0", padding:"0"} }>Saiba mais!</Animation1>
            <svg className={classes.arrowsprincipal}>
              <Animation style={{animationDelay:"-1s", }}  d="M0 0 L30 32 L60 0"> </Animation>
              <Animation style={{animationDelay:"-0.5s", }}  d="M0 20 L30 52 L60 20"></Animation>
              <Animation style={{animationDelay:"0s", }} d="M0 40 L30 72 L60 40"></Animation>
            </svg>
        </Link>
    );
   }
   
