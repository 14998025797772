import React from "react";
import Accordion from "components/Accordion/Accordion.js";
import styled, {keyframes} from 'styled-components';
import Typography from '@material-ui/core/Typography';
import styles from "assets/jss/material-kit-react/views/components.js";
import { makeStyles } from "@material-ui/core/styles";
//import { useScrollTrigger } from '@material-ui/core';
//import Grow from '@material-ui/core/Grow';




const useStyles = makeStyles(styles);
//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
  animation-delay: 1.5s;
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
  transform: translatey(0px);
  animation: ${float} 6s ease-in-out infinite;
  `;

export default _=>{

  const classes = useStyles(styles);


  return (
<div Id="accordion">
    <div className={classes.container2}>
        <Animation1>
                  <Typography className={classes.titleanimatedbuttons2}>
                      Por que Fitti?
                  </Typography> 
      </Animation1>
    </div>
   
   <div style={{boxShadow: "0px 2px 23px -16px", borderRadius: "20px", marginBottom:"50px", marginTop:"50px", paddingTop: "20px", paddingBottom: "20px", paddingLeft:"25px", paddingRight:"25px"}}>
    <div className={classes.accordion1}>
      <Accordion
      //active={0}
      activeColor={"success" }
      collapses={[
        {
          title:  <Typography>"O que é o Fitti?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                    O Fitti é uma Fintech que tem como objetivo principal oferecer produtos financeiros e não financeiros através de plataformas digitais (Web e Mobile).   
              </Typography>
        },
        {
          title: <Typography>"O Fitti é regulado pelo Bacen e entidades afins?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                      O Fitti labora com diversas verticais de produtos e serviços financeiros e não financeiros, atuando como correspondente bancário, cambial, corretora de seguros e agente autônomo de investimentos. Como correspondente bancário, seguimos as diretrizes da resolução nº 3.954 do Banco Central do Brasil. Como correspondente cambial seguimos as diretrizes da resolução nº 4.811 do Banco Central do Brasil. Como corretora de seguros seguimos as diretrizes da Lei 4.594 e demais regras definidas pela SUSEP - Superintendência de Seguros Privados. E como agente autônomo de investimentos seguimos as diretrizes da Instrução CVM Nº16 da Comissão de Valores Mobiliários. Cada uma das verticais está alocada em empresas e verticais de negócios que são parte do Fitti Pagamentos - Eireli - CNPJ: 24.341.342/0001-33.
              </Typography>
        },
        {
          title: <Typography>"Quando será o lançamento da conta digital?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                  Em breve faremos a divulgação do cronograma de lançamento. Por enquanto estamos na fase de preparativos. Estamos quase lá! ;)
              </Typography>
        },
        {
          title: <Typography>"Quais produtos e serviços posso contratar através da plataforma do Fitti?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                  Através das plataformas digitais do Fitti (Web e Mobile), você pode contratar serviços como empréstimos, seguros, câmbio (+ de 20 moedas), fazer compras no nosso shopping, contratar consórcios, utilizar meios de pagamentos e realizar investimentos.
              </Typography>
        }
      ]}
      ></Accordion>
    </div> 
  </div>
  </div>
  )
}

