
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import HorizontalScroll from "react-scroll-horizontal";
//import Button from "components/CustomButtons/Button.js";
//import Checkbox from "@material-ui/core/Checkbox";
// material-ui icons
//import Check from "@material-ui/icons/Check";
// core components
//import Table from "components/Tables/Table";

//import CardFooter from "components/Card/CardFooterPro.js";


import style from "assets/jss/material-kit-react/views/componentsSections/contentAreas.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import style1 from "assets/jss/material-kit-react/views/components.js";
import Typography from '@material-ui/core/Typography';
import styled, { keyframes } from 'styled-components';
//import Car from "@material-ui/icons/DirectionsCarOutlined";
//import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
//import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
//import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';

//import styles from "assets/jss/material-kit-react/views/components.js";
//import { makeStyles } from "@material-ui/core/styles";
//import Slide from '@material-ui/core/Slide';
//import EmprestimoCarHomeEquity from 'images/emprestimohomeequity.png'
import EmprestimoCarHomeEquity2 from 'images/emprestimohomeequity2.png'

import { Fade } from '@material-ui/core';
import SmoothImage from 'react-smooth-image'


//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;

const useStyles = makeStyles(style);
const useStyles1 = makeStyles(style1);


export default function Tables() {



  const classes = useStyles();
  const classes1 = useStyles1(style1);



  return (
    <GridContainer id="container-tabs" className={classes1.container} style={{display: "flex", justifyContent: "center"}}>
    <div className={classes1.container2} style={{marginBottom:"35px", marginTop:"10px"}}>
        
                    <Animation1>
                        <Typography className={classes1.titleanimatedbuttons2}>
                        Use o seu carro ou a sua casa para obter o seu empréstimo
                        </Typography>
                    </Animation1>
                  
                <GridContainer className={classes.container} style={{display: "flex", justifyContent: "center", marginTop:"40px"}}>
                    <GridItem xs={12} sm={6} md={6}  className={classes1.tableHomeCarEequity1}>
                            <Typography style={{  order:"2"}}  className={classes1.tableHomeCarEequityTitle1}>
                                Como utilizar o seu veículo ou imóvel para conseguir um empréstimo?
                            </Typography>
                            <Typography className={classes1.tableHomeCarEequityTitle2} style={{order:"3"}}>
                                O empréstimo com garantia é uma modalidade ideal para quem tem carro ou imóvel e precisa de crédito de R$ 5 mil a R$ 5 milhões, com juros menores e parcelas alongadas. Compare a diferença:
                            </Typography>
                            <Fade style={{order:"1"}} direction="up" in={true} timeout={{ appear: 3000, enter: 3000, exit: 3000,}} mountOnEnter unmountOnExit>
                                <div className={classes.imgAnimation}>
                                        <SmoothImage containerStyles={{height:"180px", marginBottom:"210px", overflow:"visible", paddingBottom:"0", display: "grid", justifyContent: "center"}} imageStyles={{position:"relative", height: "auto", overflow:"visible",  marginTop:"5%", marginBottom:"10%",  paddingBottom:"0" }}
                                            src={EmprestimoCarHomeEquity2} alt='Home Equity Fitti' transitionTime={1.0}  transitionTimeFunction={"ease-in"}>
                                        </SmoothImage>
                                </div>
                            </Fade>
                    </GridItem>
                    
                    <GridItem xs={12} sm={6} md={6} style={{order:"2"}} >
            
                          <div className={classes1.cardCarHomeEquity1}>
                                <div style={{boxShadow: "0px 2px 23px -16px", borderRadius: "20px", marginRight:"20px", backgroundColor:"#fff", marginBottom:"50px", marginTop:"20px", paddingTop: "20px", paddingBottom: "20px", paddingLeft:"25px", paddingRight:"25px"}}>
                                    <MapsHomeWorkIcon style={{ color:"#4caf50", fontSize:"5.0rem", alignSelf: "center", marginRight:"20px"}}></MapsHomeWorkIcon >
                                <div> 
                                    <Typography style={{fontWeight:"200", lineHeight: "1.2", fontSize: "1.2rem" }}>
                                        Empréstimo com <br/><strong>garantia de imóvel</strong>
                                    </Typography>
                                </div>
                                <div>
                                    <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"25px"}}>
                                        Utilize sua casa, apartamento, galpão, terreno ou imóvel comercial.
                                    </Typography>
                                </div>
                                <List>
                                  <ListItem> 
                                      <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"25px", display:"content"}}>
                                          <CalculateOutlinedIcon style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></CalculateOutlinedIcon> Juros a partir de 0,89% por mês.
                                      </Typography>
                                  </ListItem>
                                  <ListItem> 
                                      <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"20px",  display:"content"}}>
                                          <PaidOutlinedIcon style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></PaidOutlinedIcon>De R$40 Mil a R$5 Milhões.
                                      </Typography>
                                  </ListItem>
                                  <ListItem> 
                                      <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"20px", display:"content"}}>
                                            <EventOutlinedIcon style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></EventOutlinedIcon>Até 240 meses para pagar.
                                      </Typography>
                                  </ListItem>
                                  <ListItem> 
                                      <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"20px",  display:"content"}}>
                                         <RequestQuoteOutlinedIcon style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></RequestQuoteOutlinedIcon> Parcelas a partir de R$725,10.
                                      </Typography>
                                  </ListItem>
                                </List>
                                
                                </div>
                                <div  style={{boxShadow: "0px 2px 23px -16px", borderRadius: "20px", marginBottom:"50px", marginTop:"20px", backgroundColor:"#fff", paddingTop: "20px", paddingBottom: "20px", paddingLeft:"25px", paddingRight:"25px"}}>
                                    <DirectionsCarFilledIcon   style={{color:"#4caf50", fontSize:"5.0rem", alignSelf: "center", marginRight:"20px"}}></DirectionsCarFilledIcon>
                                <div> 
                                    <Typography style={{fontWeight:"200", lineHeight: "1.2", fontSize: "1.2rem" }}>
                                        Empréstimo com <br/><strong>garantia de auto</strong>
                                    </Typography>
                                </div>
                                <div>
                                <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"25px"}}>  
                                    Utilize o seu automóvel quitado e regularizado (Sem multas).
                                </Typography>
                                </div>
                                <List>
                                  <ListItem> 
                                      <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"25px"}}>
                                            <CalculateOutlinedIcon style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></CalculateOutlinedIcon>Juros a partir de 1,82% por mês.
                                      </Typography>
                                  </ListItem>
                                  <ListItem> 
                                      <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"20px"}}>
                                            <PaidOutlinedIcon style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></PaidOutlinedIcon>De R$5 Mil a R$150 Mil.
                                      </Typography>
                                  </ListItem>
                                  <ListItem> 
                                      <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"20px"}}>
                                            <EventOutlinedIcon style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></EventOutlinedIcon>Até 60 meses para pagar.
                                      </Typography>
                                  </ListItem>
                                  <ListItem> 
                                      <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"20px"}}>
                                            <RequestQuoteOutlinedIcon style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></RequestQuoteOutlinedIcon>Parcelas a partir de R$151,00.
                                      </Typography>
                                  </ListItem>
                                </List>
                                
                                
                                </div>
                          </div>
                    </GridItem>
                </GridContainer>
        </div>
  </GridContainer >
  );
}