import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
//import LocationOn from "@material-ui/icons/LocationOn";
// core components
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
//import Card from "components/Card/Card.js";
import styles from "assets/jss/material-kit-react/views/components.js";
//Badges dos cards
import Badge from "components/Badge/Badge.js";
//Typography
import Typography from '@material-ui/core/Typography';
//Styled
import styled from 'styled-components';
import Button from "components/CustomButtons/Button.js";

import investimosPrivateAssessoria from 'images/investimosPrivateAssessoria.png';
//import Seguros from 'images/seguros.png';


const useStyles = makeStyles(styles);

const FooterScreen = styled.div`
  height: 100%;
  width: 100%;
`;
const Fittideg = styled.div`
  background: linear-gradient(
    60deg,
    rgba(60, 52, 50, 1) 0%,
    rgba(1, 100, 125, 1) 100%
  );

  
  height: 222px;
  width: 100%;
  border-top-left-radius:  -50px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;

`;



export default function CardsButtons(props) { 
  const classes = useStyles(styles);

  

  return(

  <div style={{marginBottom:"70px", marginTop:"140px", outline: "none"}}>
    <div style={{outline: "none"}} className={classes.cardCosorcio}>
                      <div className={classes.cardConImg} >
                          <img className={classes.cardImgConsorcios} src={investimosPrivateAssessoria} alt=""></img>
                          <img className={classes.cardImgBlurConsorcios}  src={investimosPrivateAssessoria} alt=""></img>
                      </div>
                      <div className={classes.cardfundodegrade}>
                      <FooterScreen>
                          <Fittideg>
                          <div className={classes.cardConText}>
                              <Typography className={classes.titlecard}>
                                PRIVATE<br/>
                                (Assessoria)
                              </Typography> 
                              <Typography className={classes.infocards}>
                                      <div className={classes.badgeConsórcios}>Patrimônio: <div className={classes.badgeConsorcioSpan}><div style={{margin:"0 5px"}}>A partir de</div> <div style={{fontSize:"11px"}}>R$</div>200 mil</div></div>
                                      <div className={classes.badgeInvestimentosMini}>Investidor: <Badge color="white"><div  style={{textTransform:"lowercase", margin:"0 4px"}}>Pessoa Física</div></Badge><Badge color="white"><div  style={{textTransform:"lowercase", margin:"0 4px"}}>Pessoa Jurídica</div></Badge></div>
                                      <div className={classes.badgeInvestimentosMini}>Perfil: <Badge color="white"><div style={{textTransform:"lowercase", marginRight:"5px"}}>Individual</div></Badge><Badge color="white"><div style={{textTransform:"lowercase", marginRight:"5px"}}>Qualificado</div></Badge><Badge color="white"><div style={{textTransform:"lowercase", marginRight:"5px"}}>Profissional</div></Badge></div>  
                              </Typography>
                              <Button size="sm" type="button" color="white" style={{width:"250px"}} round>
                                  Acessar
                              </Button>
                          </div>
                          </Fittideg> 
                      </FooterScreen> 
                      </div>
                  </div>
             </div>
  );
}

