import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import Slide from '@material-ui/core/Slide';
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
// @material-ui/icons
//import LocationOn from "@material-ui/icons/LocationOn";
// core components
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
//import Card from "components/Card/Card.js";
import styles from "assets/jss/material-kit-react/views/components.js";
//CSS Slick Carousel
import "slick-carousel/slick/slick.css"; 
//import "slick-carousel/slick/slick-theme.css"; 
import styled from 'styled-components';

import Button from "components/CustomButtons/Button.js";

//JQuery
//import $ from "jquery"

//Typography
//import Typography from '@material-ui/core/Typography';
//Styled
//import styled, {keyframes} from 'styled-components';

//import CartoesEstudante from 'components/CartoesFitti/CartoesEstudante';
//import CartoesPF from 'components/CartoesFitti/CartoesPF';
//import CarrosselFundo from 'components/CartoesFitti/CarrosselFundo';

import FundoCartoesEstudante from 'components/CartoesFitti/FundoCartoesEstudante';
import FundoCartoesPF from 'components/CartoesFitti/FundoCartoesPF';
import FundoCartoesMei from 'components/CartoesFitti/FundoCartoesMei';
import FundoCartoesPJ from 'components/CartoesFitti/FundoCartoesPJ';
//import backgroundcss from "images/fundo1.svg";


//import GridItem from "components/Grid/GridItem";


//import carouselStyle from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";
//import image1 from "assets/img/bg.jpg";
//import image2 from "assets/img/bg2.jpg";
//import image3 from "assets/img/bg3.jpg";
//JQuery
//import $ from "jquery"





const useStyles = makeStyles(styles);


//functions da arrow

const Prev = styled.button`
    color: "black";
    min-width: 60px;
    height: 60px;
    border-radius: 20px;
    background: #fff;
    border: 0px;
    outline: none;
    cursor: pointer;
    z-index: 200;
    margin: 10px;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,.08);
    transition: all .25s ease;
    font-Size: 1.6rem;  
    position: absolute;
    top: 50%;
    display: none;
    visibility: hidden;
    left:  50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    @media (max-width:780px) {  
        min-Width: 0.5vw;
        border-radius: 8px;
        margin: 1vw;
        left: -1.0vw;
        };

    &:hover {
  
    transform: translate(0, -10px);
    box-shadow: 0px 17px 35px 0px rgba(0,0,0,.07);
    }

 `;
 const Next = styled.button`
    color: "black";
    min-width: 60px;
    height: 60px;
    border-radius: 20px;
    background: #fff;
    border: 0px;
    outline: none;
    cursor: pointer;
    z-index: 200;
    margin: 10px;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,.08);
    transition: all .25s ease;
    font-Size: 1.6rem;  
    display: none; 
    visibility: hidden;
    position: absolute;
    top: 50%;
    right: 10%;
    display: block;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    @media (max-width:780px) {  
        min-Width: 0.5vw;
        border-radius: 8px;
        margin: 1vw;
        };
    @media (max-width:375px) {  
    right: -5.5vw;
    };

    &:hover {
  
    transform: translate(0, -10px);
    box-shadow: 0px 17px 35px 0px rgba(0,0,0,.07);
    }
 `;


export default function SectionCarousel() {
  const classes = useStyles();
  //const texto1 = "Cartão Pessoa Física";
  //const texto2 = "Cartão Pessoa Jurídica";


  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    cssEase:'ease-in-out',
    nextArrow: <Next><i className="material-icons">keyboard_arrow_right</i></Next>,
    prevArrow: <Prev><i className="material-icons">keyboard_arrow_left</i></Prev>,
 

 
    responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 765,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },

        {
          breakpoint: 360,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },

        {
          breakpoint: 300,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ],
     appendDots: dots => (
        <div className={{display:"flex", justifyContent:"center"}}>
          <ul className={classes.customPagingDots2}> {dots} </ul>
        </div> 
      ),
   
      customPaging: i => { 
        var letters = ['Cartão Estudante','Cartão Pessoa Física','Cartão Mei','Cartão Pessoa Jurídica','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
      
        return ( 
            <Button size="sm" type="button" color="white" round>
                {`${letters[i]}`}
            </Button>
        )
}

    };

  return ( 
      <div>
        <Carousel  {...settings}>
              <div className={classes.outline1}>   
                <FundoCartoesEstudante>
                </FundoCartoesEstudante>
              </div>
              <div className={classes.outline1}>
                <FundoCartoesPF>
                </FundoCartoesPF>
              </div>
              <div className={classes.outline1}>   
                <FundoCartoesMei>
                </FundoCartoesMei>
              </div>
              <div className={classes.outline1}>   
                <FundoCartoesPJ>
                </FundoCartoesPJ>
              </div>
          </Carousel>
      </div>
  );
}