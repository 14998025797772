import React, {useState, useRef} from 'react';
// core components
import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styled, { keyframes } from 'styled-components'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";
//Animations e imagens
//import AnimationUpDown from '../../views/Components/Sections/AnimationUpDown.js';
import SmoothImage from 'react-smooth-image';
import IphoneBranco from 'images/iphone.png';
//import Imacfundo from 'images/IMACfundo.png';
//import Tabblet from 'images/tabblet.png';
import appfittiprodutos from 'images/appfittiprodutos.png';


import Typography from '@material-ui/core/Typography';
import videoPhone from 'videos/appFitti.mp4';
//import videoImac from 'videos/clipeImac.mp4';
//import ReactPlayer from 'react-player/lazy';

import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Check from '@material-ui/icons/Check';
import Badge from "components/Badge/Badge.js";

//import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
//import Button from "components/CustomButtons/Button.js";

//JQuery
//import $ from "jquery"

import Button from "components/CustomButtons/Button.js";
import { useEffect } from 'react';
import VideoPc2 from "./VideoPC2";
import VideoTablet2 from "./VideoTablet2";

const useStyles = makeStyles(styles);

//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;







function usePlayerState($videoPlayer) {  
    const [playerState, setPlayerState] = useState({
        playing: true,
    });
//O useEffect é utilizado para mudar a função do video de play para pause 
    useEffect(() => {
        playerState.playing ? $videoPlayer.current.play() : $videoPlayer.current.pause(); 
    }, [ $videoPlayer, playerState.playing, ]);
    

    function toggleVideoPlay() {
        setPlayerState({
            ...playerState,
            playing: !playerState.playing,
        })
    }
    return {
        playerState,
        toggleVideoPlay
    }
}




function Example(){
  const classes = useStyles(styles)
  //video
  const $videoPlayer = useRef(null)
  const {
    playerState,
    toggleVideoPlay
    } = usePlayerState($videoPlayer);

  return (
    <GridContainer className={classes.celphoneContainer1} > 
      <div className={classes.sectionNavPills}>
      <div className={classes.container2} style={{marginBottom:"35px", marginTop:"20px"}}>
            <Animation1>
                  <Typography className={classes.titleanimatedbuttons2}>
                    Contrate da forma que desejar
                  </Typography>
            </Animation1>
      </div>
    <NavPills style={{overflow:"hidden"}}
      color="success"
      tabs={[
        {
          tabButton: "Contrate pelo App",
          tabContent: (
            <div style={{margin:"10px", overflow:"hidden"}}>
              <GridContainer className={classes.celphoneContainer}> 
              <GridItem xs={12} sm={12} md={6} className={classes.celphonealignSm}>
                <div style={{position:"relative", flexDirection:"column", overflow:"none", alignSelf:"center", justifyContent:"center", verticalAlign:"center", height:"600px", top:"-32.5rem"}}>
                        <div style={{position:"relative", boxSizing:"border-box", padding:"0", margin:"0", zIndex:"2", width:"none", height: "auto",  top:"32rem", display:"flex", justifyContent: "center", }}>
                                <div>
                                    <Button style={{width:"14rem", height:"31rem", borderRadius:"20px"}}  onClick={toggleVideoPlay}
                                            color="transparent"
                                            className={classes.footerIcon}>{ playerState.playing ? "" : <i className={classes.socialIcons2 + " fas fa-play"}></i>}
                                            
                                    </Button>
                                </div>  
                        </div>  
                      <SmoothImage style={{height:"auto"}} imageStyles={{width:"18.3em", height:"auto", overflow:"none", position: "none"}}
                          src={IphoneBranco} alt='Aplicativo Fitti' transitionTime={1.0}  transitionTimeFunction={"ease-in"}>
                      </SmoothImage> 
                    <div style={{position:"relative", bottom:"52.2em", boxSizing:"border-box", padding:"0", margin:"0", zIndex:"-1", width:"none", height: "auto", marginLeft:"35px" }}>
                        <video style={{width:"14.35em", height:"auto", borderRadius: "20px"}}
                            className="video1"
                            id="video" 
                            webkit-playsinline
                            playsInline
                            playing
                            autoPlay={true} 
                            muted={true}   
                            name="media"
                            loop={true}
                            preload={"yes"}
                            autoBuffer={"auto"}
                            ref={$videoPlayer}
                            poster={appfittiprodutos}>                 
                            <source src={videoPhone}  type="video/mp4"></source>
                        </video>   
                     </div>
                 </div>
              </GridItem>


              
                
                  <GridItem xs={12} sm={12} md={6} style={{order:"2", display:"block", justifyContent:"left"}}>
                          <Typography style={{marginBottom:"20px"}} className={classes.tittleCellPhone}>
                              Tudinho na sua Conta Digital Fitti.
                          </Typography> 
                          <List>
                            <Typography  className={classes.letteraccordion}>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Conta Digital gratuita.</span><span><Badge color="gray">Em breve</Badge></span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50" }}></Check><span style={{marginRight:"5px"}}> Serviços e produtos financeiros disponíveis no App. </span><span><Badge color="gray">Em breve</Badge></span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Emissões de boletos ilimitados.</span><span><Badge color="gray">Em breve</Badge></span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Saques na rede 24 horas e redes de varejo.</span><span><Badge color="gray">Em breve</Badge></span>   
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Pix e Teds ilimitados e gratuitos.</span><span><Badge color="gray">Em breve</Badge></span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Pagamentos de contas e boletos.</span><span><Badge color="gray">Em breve</Badge></span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}> 
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Cartão físico bandeira Visa.</span><span><Badge color="gray">Em breve</Badge></span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Cartão virtual.</span><span><Badge color="gray">Em breve</Badge></span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Catálogos e ferramentas de pagamentos.</span><span><Badge color="gray">Em breve</Badge></span>
                              </ListItem>
                            </Typography>
                          </List>
                   </GridItem>
                 
                </GridContainer>
             </div>
          )
        },


        {
          tabButton: "Contrate pela Web",
          tabContent: (
            <div style={{overflow:"hidden"}}>
              <GridContainer className={classes.celphoneContainer}>
              
                <GridItem xs={12} sm={12} md={6} className={classes.celphonealignSm3}>
                   <VideoPc2></VideoPc2>
               </GridItem>
                <GridItem xs={12} sm={12} md={6} className={classes.celphonealignSm2}>
                   <VideoTablet2></VideoTablet2>
                </GridItem>

                  <GridItem xs={12} sm={12} md={6} style={{order:"2", display:"block", justifyContent:"left"}}>
                          <Typography style={{marginBottom:"20px"}} className={classes.tittleCellPhone}>
                              Tudinho na sua Plataforma Fitti.
                          </Typography> 
                          <List style={{display:"block", justifyContent:"center", alignItems:"center", textAlign:"center"}}>
                            <Typography  className={classes.letteraccordion}>
                              <ListItem className={classes.listacelfone }>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Simulações e cotações gratuitas.</span><span><Badge color="gray">Em breve</Badge></span>
                              </ListItem>
                              <ListItem  className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50" }}></Check><span style={{marginRight:"5px"}}>Serviços e produtos financeiros disponíveis pelo site. </span><span><Badge color="gray">Em breve</Badge></span>
                              </ListItem>
                              <ListItem  className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Contratações e compras rápidas.</span><span><Badge color="gray">Em breve</Badge></span>
                              </ListItem>
                              <ListItem  className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Avaliações de crédito em poucos minutos. </span><span><Badge color="gray">Em breve</Badge></span>   
                              </ListItem>
                              <ListItem  className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Emissões de apólices em poucos minutos.</span><span><Badge color="gray">Em breve</Badge></span>
                              </ListItem>
                              <ListItem  className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Compra de moedas em poucos minutos.</span><span><Badge color="gray">Em breve</Badge></span>
                              </ListItem>
                              <ListItem  className={classes.listacelfone}> 
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Shopping com o melhor cashback.</span><span><Badge color="gray">Em breve</Badge></span>
                              </ListItem>
                              <ListItem  className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Atendimento e suporte online.</span><span><Badge color="gray">Em breve</Badge></span>
                              </ListItem>
                              <ListItem  className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Pagamentos por boletos, cartões e PIX.</span><span><Badge color="gray">Em breve</Badge></span>
                              </ListItem>
                              <ListItem  className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Ferramentas práticas para suas vendas.</span><span><Badge color="gray">Em breve</Badge></span>
                              </ListItem>
                            </Typography>
                          </List>
                   </GridItem>
                </GridContainer>
             </div>
          )
        },

      ]}>
    </NavPills>
  </div>
    </GridContainer>
  );
}



export default Example;

