import React, {useState, useEffect} from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
//import Header from "components/Header/Header.js";
import Footer2 from "components/Footer/Footer2";
//import ButtonsArea from "components/ButtonsArea/ButtonsArea";
//import ButtonsAreaAnime from "components/ButtonsArea/ButtonsAreaAnime.jsx";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
//import Parallax from "components/Parallax/Parallax.js";
// sections for this page
//import HeaderLinks from "components/Header/HeaderLinks.js";
//import SectionNavbarsNOVO from "./Sections/SectionNavbarsNOVO.js";
//Scrool Smooth site
//import { SmoothProvider } from 'react-smooth-scrolling'

import styles from "assets/jss/material-kit-react/views/components.js";
//import ButtonsAreaAnimeConsorcio from "components/ButtonsAreaConsorcios/ButtonsAreaAnimeConsorcio";

//Efeitos
import {Typewriter} from 'react-simple-typewriter';
//import 'react-simple-typewriter/dist/index.css';

//Typography
import Typography from '@material-ui/core/Typography';

//Imagens
//import Logo from 'images/logo-fitticontadigital.svg';


//import Cardeapp from 'images/cardeapp.png';

//Container
//import Container from '@material-ui/core/Container';
//import Grid from '@material-ui/core/Grid';

//Animations
//import AnimationUpDown from '../../../views/Components/Sections/AnimationUpDown';
import AnimationUpDownHomeCarEquity from  '../../../../../views/Components/Sections/AnimationUpDownHomeCarEquity.js';


//Css degrade Fitticolor
//import Fittidegrade from './Sections/Fittidegrade.js';

//Css
//Animations
import ArrowAnimatedButton from '../../../../../views/Components/Sections/ArrowAnimatedButton';
//import buttonStyle from "assets/jss/material-kit-react/components/buttonStyle.js";
//import "components/Appcss/App.css";

//JQuery
//import $ from "jquery"
//scroll
//import {animateScroll as scroll} from 'react-scroll';
//import { Fade } from "@material-ui/core";
//import anime from "animejs/lib/anime.es.js";

//Animação do cartão
//import Slide from '@material-ui/core/Slide';
//import Servicos from 'components/Servicos/Servicos.jsx';
import AccordionEmprestimosHomeCarEquity from  'components/Accordion/AccordionEmprestimosHomeCarEquity.jsx';
//import NavPillsPro from  'components/NavPills/NavPillsPro.jsx';
import VideoAreaEmprestimosHomeEquity from  'components/VideoArea/VideoAreaEmprestimosHomeEquity.jsx';
//import CartoesFitti from 'components/CartoesFitti/CartoesFitti.jsx';
//import CardConsorcios from 'components/CardConsorcios/CardConsorcios.jsx';
import CardEmprestimosHomeCarRquity from 'components/CardEmprestimos/CardEmprestimosCarHomeEquity.jsx';
import ServicosEmprestimosHomeCarEquity from 'components/Servicos/ServicosEmprestimosHomeCarEquity.jsx';
import ChartEmprestimosHomeEquity from 'components/Charts/ChartEmprestimosHomeEquity.jsx';
import TableHomeEquity from 'components/Tables/TableHomeEquity.jsx';
import TableHomeEquitySolo from 'components/Tables/TableHomeEquitySolo.jsx';
import TableCarEquitySolo from 'components/Tables/TableCarEquitySolo.jsx';
//import NavPillsProEmpreCarHomeEquity from 'components/NavPills/NavPillsProEmpreCarHomeEquity.jsx';
//import { useLocation } from 'react-router-dom';


//import {BrowserRouter as Router} from 'react-router-dom';
//import Menu from '../../components/MenuViews/Menu';
//import Content from '../../components/MenuViews/Content';
// plugin that creates slider
//import Slider from "nouislider";
//import Nouislider from "nouislider-react";
//import noUiSlider from 'nouislider';
//import "nouislider/distribute/nouislider1.css";
//import wNumb from "wnumb";

// @material-ui/core components
//import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui icons
//import People from '@material-ui/icons/People';
// core components
//import CustomInput from 'components/CustomInput/CustomInput.js';
//import MKInput from "components/Inputs/MKInput.js";
//import InputAdornment from '@mui/material/InputAdornment';


import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import NumberFormat from 'react-number-format';
//import Input from '@mui/material/Input';
//import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
//import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
//import FormControl from '@mui/material/FormControl';
//import FormLabel from '@mui/material/FormLabel';
import NavPills from "components/NavPills/NavPillsEmpreCarHomeEquity1.js";
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import Slider2 from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
//import SliderUnstyled from '@mui/base/SliderUnstyled';
import PaidIcon from '@mui/icons-material/Paid';
//import ModalButtonEmprestimos from './ModalButtonEmprestimos.jsx';
//import SliderHomeEquity from './SliderHomeEquity.jsx';
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components

import modalStyle from "assets/jss/material-kit-react/modalStylePro.js";
//import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
//import AnimatedNumber from 'react-animated-number';

//import Card from "components/Card/Card.js";
//import CardBody from "components/Card/CardBody.js";
//import SliderUnstyled from '@mui/base/SliderUnstyled';
import styled1, { keyframes } from 'styled-components';
import RepeatIcon from '@mui/icons-material/Repeat';
import DoneIcon from '@mui/icons-material/Done';
import CountUp from 'react-countup';
//import Tables from './../../../../Tables/TableConsorcioServicos';
import {animateScroll as scroll} from 'react-scroll';
import * as Scroll from 'react-scroll'




const style = theme => ({
  ...modalStyle(theme),
  modalRootExample: {
    "& > div:first-child": {
      display: "none"
    },
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  }
});

const useStyles = makeStyles(styles); //tudo que tiver 2 no modal tirar
const useStyles3 = makeStyles(style); //itens do modal..ligadas ao const classes sem numeração


//Itens do modal
//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled1.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});



//const ariaLabel = { 'aria-label': 'description' };


const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });
  
  TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  
  const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        /* onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }} */
        type="tel"
        allowNegative={false}
        autoComplete="off"
        thousandSeparator="."
        isNumericString
        prefix="R$"
        suffix=",00"
        decimalSeparator=","
       
        onValueChange={values => {
          onChange({  target: {
            value: values.value
          },
        });
      } 
    }
        
      />
    );
  });
  
  NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  


export default function MenuConsorcios(props) {
  const classes = useStyles();
  //const { ...rest } = props;
  let LinkScroll = Scroll.Link;

//INPUT do Valor do emprestimo Home Equity
  var timeout20;
  const [value20, setValue20] = useState(0)
  const handleChange20 = (event20) => {
    timeout20 && clearTimeout(timeout20);
    timeout20 = setTimeout(()=>{
      console.log('change');
      setValue20(event20.target.value);
    }, 1000); 
  };

//HyperText do Input do Crédito
const [valorMinimoEmprestimo, setValorMinimoEmprestimo] = useState(<span>Valor mínimo de R$40.000</span>)
useEffect( () => {
  var timeMinimoEmprestimo
  timeMinimoEmprestimo && clearTimeout(timeMinimoEmprestimo);
  timeMinimoEmprestimo = setTimeout(()=>{
    console.log('change');
    setValorMinimoEmprestimo(value20 > 0 && value20 < 40000 ?  <span style={{color:"red"}}>Valor mínimo de R$40.000</span> : <span>Valor mínimo de R$40.000</span>)
  },500)

}, [value20])


  //INPUT do Valor do imovel Home Equity
  var timeout22;
  const [value22, setValue22] = useState(0)
  const handleChange22 = (event22) => {
    timeout22 && clearTimeout(timeout22);
    timeout22 = setTimeout(()=>{
      console.log('change');
      setValue22(event22.target.value);
    }, 1000); 
  };


  //HyperText do Input do Imovel

  const valorCondicao = value20 * 2.5
  const [valorMinimoImovel, setValorMinimoImovel] = useState(<span>Valor mínimo de R$40.000</span>)
useEffect( () => {
  function minimoimovel(){ 
    if(value22 > 0 && value22 < valorCondicao){ 
      return(
      <span style={{color:"red"}}>Valor mínimo de R$ {new Intl.NumberFormat('pt-BR').format(valorCondicao)} </span>
      ) 
    } else { 
      return(
      <span>Valor mínimo de R$100.000</span>
      ) 
    }
  };
  var timeMinimoImovel
  timeMinimoImovel && clearTimeout(timeMinimoImovel);
  timeMinimoImovel = setTimeout(()=>{
    console.log('change');
    setValorMinimoImovel(minimoimovel())
  },500)

}, [value22, valorCondicao])


  //Itens do radio
  const [selectedValue, setSelectedValue] = React.useState('price');

  const handleChange3 = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange3,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

//INPUT do Radio Price ou sac Home Equity
var timeoutRadioHomEquity;
const [valueRadioHomeEquity1, setValueRadioHomeEquity1] = useState('price')
const handleChangeRadioHomeEquity1 = (eventRadioHomeEquity1) => {
  timeoutRadioHomEquity && clearTimeout(timeoutRadioHomEquity);
  timeoutRadioHomEquity = setTimeout(()=>{
    console.log('change');
    setValueRadioHomeEquity1(eventRadioHomeEquity1.target.value);
  }, 1000); 
};

//Item sobre tipo de capitalização que aparece no modal
const [tiposParcelas, setTipoParcelas] = useState("Parcelas iguais")
useEffect( () => {
  var timeoutRadioHomEquity2
  timeoutRadioHomEquity2 && clearTimeout(timeoutRadioHomEquity2);
  timeoutRadioHomEquity2 = setTimeout(()=>{
    console.log('change');
    setTipoParcelas(valueRadioHomeEquity1 === 'price' ? " (Parcelas iguais)" : "(Parcelas regressivas)")
  },1000)

}, [valueRadioHomeEquity1])

//Item sobre o taxa de juros que aparece no modal
const [tipoTxjuros, setTipoTxJuros] = useState(0.0124)
useEffect( () => {
  var timeoutTipoTxJuros
  timeoutTipoTxJuros && clearTimeout(timeoutTipoTxJuros);
  timeoutTipoTxJuros = setTimeout(()=>{
    console.log('change');
    const proporcaoInputCredito1 = value20 * 3.3334
    setTipoTxJuros(value22 >= proporcaoInputCredito1 ? 0.0124 : 0.0129 )
  },200)

}, [value22, value20])

        //Função Titop label
        //Função do Slide
        function valueLabelFormat(value5) {
          const units = ['Parcelas'];

          return `${value5} ${units[0]}`;
        }
        var timeout;
        const [value5, setValue5] = useState(36)
        const handleChange10 = (event, newValue) => {
          timeout && clearTimeout(timeout);
          timeout = setTimeout(()=>{
            console.log('change');
            setValue5(newValue);
          }, 1000); 
        };
        

        const PrettoSlider = styled(Slider2)({
          color: '#52af77',
          height: 8,
          '& .MuiSlider-track': {
            border: 'none',
          },
          '& .MuiSlider-thumb': {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: 'inherit',
            },
            '&:before': {
              display: 'none',
            },
          },
          '& .MuiSlider-valueLabel': {
            lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 90,
            height: 30,
            //borderRadius: '50% 50% 50% 0',
            backgroundColor: '#52af77',
          // transformOrigin: 'bottom left',
            //transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            //'&:before': { display: 'none' },
            //'&.MuiSlider-valueLabelOpen': {
              //transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
            //},
            //'& > *': {
              //transform: 'rotate(45deg)',
            //},
          },
          '& .MuiSlider-markLabel': {
            fontSize: "0.55rem",
            },
        });


        const marks = [
          {
            value: 36,
            label: '36',
          },
          {
            value: 48,
            label: '48',
          },
          {
            value: 60,
            label: '60',
          },
          {
            value: 72,
            label: '72',
          },
            {
            value: 84,
            label: '84',
          },
            {
            value: 96,
            label: '96',
          },
          {
            value: 108,
            label: '108',
          },
            {
            value: 120,
            label: '120',
          },
          {
            value: 132,
            label: '132',
          },
          {
            value: 144,
            label: '144',
          },
          {
            value: 156,
            label: '156',
          },
            {
            value: 168,
            label: '168',
          },
            {
            value: 180,
            label: '180',
          },
            {
            value: 192,
            label: '192',
          },
            {
            value: 204,
            label: '204',
          },
              {
            value: 216,
            label: '216',
          },
                {
            value: 228,
            label: '228',
          },
                  {
            value: 240,
            label: '240',
          },
        ];
        //Itens do modal de simulação

        const [scrollingModal, setScrollingModal] = React.useState(false);
        const [scrollingModal2, setScrollingModal2] = React.useState(false);
        //const classes = useStyles();
        const classes3 = useStyles3();

        const proporcaoRendaMin = 3.333334464

        const [price, setPrice] = useState(0)
        const simulacaoHomeEquity = () => {
          //setPrice(price + ((10000)/((((1+0.125)^5)-1)/(0.125*((1+0.125)^5)))) )
          const MIP = 0.001304706 
          const DFI = 0.0001363
          const ADM = 25
          //const variacaoTxCreditoBruto = ((((value20 - 40000)/1000)*(-0.0005))+0.1560235)
          //const txCreditoBruto = value20 ===  40000  ?  0.1560235 : variacaoTxCreditoBruto
          const txCreditoBruto = calculo()
          
          function calculo(){  
              if(value20 <= 40000 ) {
              return( 0.1560235 )
            } else if(value20 >= 40000 && value20 <= 60000) {
              return(  ((((value20 - 40000)/1000)*(-0.0024))+0.1560235) )
            } else if(value20 >= 60001 && value20 <= 80000) {
              return(  ((((value20 - 40000)/1000)*(-0.00165))+0.1560235) )
            } else if(value20 >= 80001 && value20 <= 100000) {
              return(  ((((value20 - 40000)/1000)*(-0.00117))+0.1560235) )
            } else if(value20 >= 100001 && value20 <= 150000){
              return( ((((value20 - 40000)/1000)*(-0.000780))+0.1560235) )
            } else if(value20 >= 150001 && value20 <= 290000){
              return( ((((value20 - 40000)/1000)*(-0.0004677))+0.1560235) )
            } else if(value20 >= 290001 && value20 <= 390000){ 
              return(  ((((value20 - 40000)/1000)*(-0.00039))+0.1560235) )
            } else if(value20 >= 390001 && value20 <= 500000  ){ 
              return( ((((value20 - 40000)/1000)*(-0.0002165))+0.1560235) ) 
            } else if(value20 >= 500001 && value20 <= 680000  ){ 
              return( ((((value20 - 40000)/1000)*(-0.00019472))+0.1560235) ) 
            }  else if(value20 >= 680001 && value20 <=790000  ){ 
              return( ((((value20 - 40000)/1000)*(-0.000167))+0.1560235) ) 
            } else if(value20 >= 790001 && value20 <=980000  ){ 
              return( ((((value20 - 40000)/1000)*(-0.0001462))+0.1560235) ) 
            } else if(value20 >= 980001){ 
              return( ((((value20 - 40000)/1000)*(-0.0001169))+0.1560235) ) 
            }
            
        }

          const creditoBruto = (value20 * (1+txCreditoBruto))
          const proporcaoInputCredito = value20 * 3.3334
          const txJuros = value22 >= proporcaoInputCredito ? 0.0124 : 0.0129 
          const saldoDevedor = creditoBruto
         
          if ( valueRadioHomeEquity1 === 'price' )  { 
              const propoporcao2inputs = value20*2.5
              if(value20 < 40000 && value22 < 100000) {
                setScrollingModal(false) 
                setScrollingModal2(true) 
              } else if(value22 < propoporcao2inputs) {
                setScrollingModal(false) 
                setScrollingModal2(true)
              } else if(value20 < 40000 && value22 >= 100000 ) {
                setScrollingModal(false) 
                setScrollingModal2(true)
              } else { 
                setPrice(price + (((saldoDevedor))/(((Math.pow((1 + txJuros), value5))-1)/(txJuros*(Math.pow((1 + txJuros), value5))))) + ADM + ((creditoBruto*(1+txJuros)) * MIP) + ((value22)*DFI))
                setScrollingModal(true)
              }
          } else {
              const propoporcao2inputs2 = value20*2.5  
              if(value20 < 40000 && value22 < 100000){
                setScrollingModal(false) 
                setScrollingModal2(true)
              } else if(value22 < propoporcao2inputs2) {
                setScrollingModal(false) 
                setScrollingModal2(true)
              } else if(value20 < 40000 && value22 >= 100000 ) {
                setScrollingModal(false) 
                setScrollingModal2(true)
              } else { 
                setPrice(price + (((saldoDevedor)/value5) + ((creditoBruto*(1+txJuros))*txJuros)) + ADM + ((creditoBruto*(1+txJuros)) * MIP) + ((value22)*DFI))
                setScrollingModal(true)
              }
          }
        
        }

        //Para desligar o modal e zerar o calculo
          const simulacaoHomeEquityRestart = () => {
          //setPrice(price + ((10000)/((((1+0.125)^5)-1)/(0.125*((1+0.125)^5)))) )
          setPrice(price - price)
          setScrollingModal(false)
        }

            const simulacaoHomeEquityClose = () => {
          //setPrice(price + ((10000)/((((1+0.125)^5)-1)/(0.125*((1+0.125)^5)))) )
          setPrice(price - price)
          setScrollingModal2(false)
        }


  return (     
       <section  className={classes.appcss}>
            <div className={classes.sectionTopConsorcio} >
                <GridContainer className={classes.container3}>
                    <GridItem xs={12} sm={12} md={12} lg={6} className={classes.ipadSimulationCenter}>
                        <div>
                            <Typography  className={classes.titleEmprestimosPrincipal}  color="initial">
                               <span>Empréstimo </span><span style={{ color: "#3f3f3f", fontWeight: 'bold'}}> c/ Garantia</span>
                            </Typography>
                            <Typography className={classes.titleEmprestimosPrincipal}  color="initial">
                                    que dá Fitti com <br className={classes.titleEmprestimosPrincipal2BR}/>
                                    <span className={classes.titleEmprestimosPrincipal2} style={{ color: "#3f3f3f", fontWeight: 'bold'}}> <Typewriter
                                        loop
                                        cursor
                                        cursorStyle='|'
                                        typeSpeed={70}
                                        deleteSpeed={50}
                                        delaySpeed={1000}
                                        words={['você!', 'seu jeito!']}>
                                    </Typewriter></span>
                            </Typography> 
                            <div className={classes.displayTitlesub3}>
                                <GridItem xs={12} sm={12} md={12}>
                                    <div className={classes.displayFundoCinzaEmprestimos}>
                                                                                  
 <GridContainer style={{marginTop: "-25px"}}> 
      <div style={{maxWidth: "500px"}}>
          <NavPills style={{overflow:"hidden", height:"40px"}}
            color="success"
            
            tabs={[
              {
                tabButton: (<span><MapsHomeWorkIcon style={{marginRight: "5px", verticalAlign: "middle"}}></MapsHomeWorkIcon>Home Equity</span> ),
                tabContent: (
                  <div className={classes.empreParamsInputTotal}>
                
                                <div className={classes.empreParamsInput}>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Typography className={classes.title2subEmpreParamsInput}>
                                          <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Valor do empréstimo</span>
                                        </Typography>
                                        <TextField 
                                            id="outlined-basic" 
                                            //label="Valor do empréstimo" 
                                            variant="outlined"
                                            //required
                                            placeholder="R$100.000,00"
                                            helperText={valorMinimoEmprestimo}
                                            color="success"
                                            size="small"
                                            value={value20.numberformat}
                                            onChange={handleChange20}
                                            name="numberformat"
                                            //id="formatted-numberformat-input"
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                              
                                            
                                            //InputProps={{startAdornment: <InputAdornment position="start">R$</InputAdornment>, ariaLabel}}
                                            >
                                        </TextField>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Typography className={classes.title2subEmpreParamsInput}>
                                            <span><MapsHomeWorkIcon style={{marginRight: "5px", verticalAlign: "middle"}}></MapsHomeWorkIcon>Valor do imóvel</span>
                                        </Typography>
                                        <TextField 
                                            id="outlined-basic" 
                                            //label="Valor do imóvel" 
                                            variant="outlined"
                                            //required
                                            placeholder="R$100.000,00"
                                            helperText={valorMinimoImovel}
                                            color="success"
                                            size="small"
                                            value={value22.numberformat}
                                            onChange={handleChange22}
                                            name="numberformat"
                                            //id="formatted-numberformat-input"
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                    
                                            //InputProps={{startAdornment: <InputAdornment position="start">R$</InputAdornment>, ariaLabel}}
                                            >
                                        </TextField>
                                    </GridItem>
                                </div>
                                <GridItem style={{paddingLeft: "46px", paddingRight: "46px"}}> 
                                        <Typography className={classes.title2subEmpreParamsInput2}>
                                            Em quantas parcelas? 
                                        </Typography>
                                        <PrettoSlider
                                        aria-label="Temperature"
                                        //defaultValue={30}
                                        //getAriaValueText={valuetext}
                                      
                                        valueLabelDisplay="on"
                                        valueLabelColor="success"
                                        getAriaValueText={valueLabelFormat}
                                        valueLabelFormat={valueLabelFormat}
                                        step={12}
                                        marks={marks}
                                        min={36}
                                        max={240}
                                        color="success"
                                        defaultValue={value5} 
                                        onChange={handleChange10}
                                              >
                                        </PrettoSlider>
                                </GridItem>  
                                    <GridItem style={{display:"block", justifyContent:"center", marginTop:"-5px"}}>
                                        <Typography className={classes.title2subEmpreParamsInput3}>
                                            Amortização:
                                        </Typography>
                                    <RadioGroup value={valueRadioHomeEquity1} onChange={handleChangeRadioHomeEquity1} defaultValue='price' className={classes.buttonEmpreParamsInput} row aria-label="Capitalização" name="row-radio-buttons-group">
                                        <FormControlLabel  value="price" {...controlProps('price')} control={<Radio color="success" size="small" />}  label="Price"></FormControlLabel>
                                        <FormControlLabel value="sac"   {...controlProps('sac')} control={<Radio color="success" size="small" />}  label="SAC"></FormControlLabel>
                                    </RadioGroup>
                                </GridItem>



                                 
                  </div>
                )
              },


              {
                tabButton: (<span><DirectionsCarFilledIcon  style={{marginRight: "5px", verticalAlign: "middle"}}></DirectionsCarFilledIcon>Car Equity</span> ),
                tabContent: (
                   <div className={classes.empreParamsInputTotal}>
                
                                <div className={classes.empreParamsInput}>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Typography className={classes.title2subEmpreParamsInput}>
                                            <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Valor do empréstimo</span>
                                        </Typography>
                                        <TextField 
                                            id="outlined-basic" 
                                            //label="Valor do empréstimo" 
                                            variant="outlined"
                                            //required
                                            placeholder="R$100.000,00"
                                            helperText="Valor mínimo de R$10.000,00"
                                            color="success"
                                            size="small"
                                            //value={values.numberformat}
                                            //onChange={handleChange}
                                            name="numberformat"
                                            //id="formatted-numberformat-input"
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            
                                            //InputProps={{startAdornment: <InputAdornment position="start">R$</InputAdornment>, ariaLabel}}
                                            >
                                        </TextField>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Typography className={classes.title2subEmpreParamsInput}>
                                             <span><DirectionsCarFilledIcon  style={{marginRight: "5px", verticalAlign: "middle"}}></DirectionsCarFilledIcon>Valor do Veículo</span>
                                        </Typography>
                                        <TextField 
                                            id="outlined-basic" 
                                            //label="Valor do imóvel" 
                                            variant="outlined"
                                            //required
                                            placeholder="R$100.000,00"
                                            helperText="Valor mínimo de R$40.000,00"
                                            color="success"
                                            size="small"
                                            //value={values.numberformat}
                                            //onChange={handleChange1}
                                            name="numberformat"
                                            //id="formatted-numberformat-input"
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                    
                                            //InputProps={{startAdornment: <InputAdornment position="start">R$</InputAdornment>, ariaLabel}}
                                            >
                                        </TextField>
                                    </GridItem>
                                </div>
                                <GridItem style={{paddingLeft: "46px", paddingRight: "46px"}}> 
                                      <Typography className={classes.title2subEmpreParamsInput2}>
                                          Em quantas parcelas? {value5}
                                      </Typography>
                                      <PrettoSlider
                                      aria-label="Temperature"
                                      //defaultValue={30}
                                      //getAriaValueText={valuetext}
                                    
                                      valueLabelDisplay="on"
                                      valueLabelColor="success"
                                      getAriaValueText={valueLabelFormat}
                                      valueLabelFormat={valueLabelFormat}
                                      step={12}
                                      marks={marks}
                                      min={36}
                                      max={240}
                                      color="success"
                                      defaultValue={value5} 
                                      onChange={handleChange10}
                                            >
                                      </PrettoSlider>
                                </GridItem>  
                                <GridItem style={{display:"block", justifyContent:"center", marginTop:"-5px"}}>
                                        <Typography className={classes.title2subEmpreParamsInput3}>
                                            Amortização:
                                        </Typography>
                                    <RadioGroup className={classes.buttonEmpreParamsInput} defaultValue="Price" row aria-label="Capitalização" name="row-radio-buttons-group">
                                        <FormControlLabel  value="Price" {...controlProps('Price (Parcelas iguais)')} control={<Radio color="success" size="small" />}  label="Price"></FormControlLabel>
                                        <FormControlLabel value="SAC"   {...controlProps('SAC (Parcelas regressivas)')} control={<Radio color="success" size="small" disabled />}  label="SAC"></FormControlLabel>
                                    </RadioGroup>
                                </GridItem>
                              </div>
                            )
                          },

                        ]}>
                      </NavPills>
                    </div>
                </GridContainer>
                        
                                    </div>
                                </GridItem>
                            </div>

                           <div style={{display: "flex", justifyContent: "center", marginTop: "-30px"}}>
                           
                             <div>
                               <LinkScroll to="topo"  smooth={true} duration={1000} offset={-100} onClick={() => scroll.scrollToTop()}>
                                  <Button color="success" round onClick={simulacaoHomeEquity}  className={classes.botaoSimularConsorcio}>
                                      Simule seu crédito
                                  </Button>
                               </LinkScroll>
                                  <Dialog 
                                    //onClick={() => setScrollingModal(false)}
                                    classes={{
                                      root: `${classes3.modalRoot} ${classes3.modalRootExample}`,
                                      paper: classes3.modal
                                    }}
                                    open={scrollingModal}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={simulacaoHomeEquityRestart}
                                    aria-labelledby="classic-modal-slide-title"
                                    aria-describedby="classic-modal-slide-description">
                                    <DialogTitle
                                      id="classic-modal-slide-title"
                                      disableTypography
                                      className={classes3.modalHeader}>
                                      <Button
                                        simple
                                        className={classes3.modalCloseButton}
                                        key="close"
                                        aria-label="Close"
                                        onClick={simulacaoHomeEquityRestart}>
                                        {" "}
                                        <Close className={classes3.modalClose} />
                                      </Button>
                                      <div className={classes3.container2}>
                                            <Animation1>
                                                  <Typography className={classes.titleanimatedbuttons2}>
                                                    Resultado da simulação
                                                  </Typography>
                                            </Animation1>
                                        </div>

                                    </DialogTitle>
                                    <DialogContent
                                      id="classic-modal-slide-description"
                                      className={classes3.modalBody}>

                                    
                                  <GridItem xs={12} sm={12} md={12} lg={12}>
                                      <div className={classes.displayFundoCinzaEmprestimos2}>
                                        <div>
                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                              <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Valor da primeira parcela</span>
                                          </Typography>
                                        
                                          <CountUp end={price}
                                          style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "3rem", lineHeight: "1.25", fontWeight: "700", opacity: "1", textTransform: "none", color: "rgb(76, 175, 80)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                          formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                          frameStyle={perc => ( {opacity: perc/100} )}
                                          decimals={2}
                                          decimal=","
                                          ></CountUp>
                                      </div>
                                      <div>
                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                              <span>Nº de Parcelas</span>
                                          </Typography>
                                        
                                          <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>{value5}x</div>
                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                        </div>
                                        <div>
                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                              <span>Renda Necessária</span>
                                          </Typography>
                                        
                                
                                          <CountUp end={price * proporcaoRendaMin}
                                              style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                              formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                              frameStyle={perc => ( {opacity: perc/100} )}
                                              decimals={2}
                                              decimal=","
                                          ></CountUp>
                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                        </div>
                                        <div>
                                            <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                <span>Taxa de juros</span>
                                            </Typography>
                                          
                                            <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}> {100 * tipoTxjuros}% a.m. + IPCA</div>
                                            <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                        </div>
                                          <div>
                                              <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                  <span>Sistema de Amortização</span>
                                              </Typography>
                                            
                                              <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>Tabela {valueRadioHomeEquity1} {tiposParcelas}</div>
                                            
                                            
                                            </div>
                                
                                        </div>
                                    </GridItem>
                                    </DialogContent>
                                    <DialogActions style={{display: "flex", justifyContent: "space-evenly"}}>
                                        <GridItem className={classes.modalFooterEmpre}>
                                          <Link style={{textDecoration:"none"}} to="/emprestimos/homecarequity/chathomeequity">
                                              <Button onClick={simulacaoHomeEquityRestart}  color="success" round>
                                                  <span><DoneIcon fontSize="large"  style={{marginRight: "5px", verticalAlign: "middle"}}></DoneIcon> Gostei! Quero solicitar!</span>
                                              </Button>
                                          </Link>
                                          <Button onClick={simulacaoHomeEquityRestart} color="secondary" round>
                                              <span><RepeatIcon fontSize="large" style={{marginRight: "5px", verticalAlign: "middle"}}></RepeatIcon> Simular novamente</span>
                                          </Button>
                                        
                                        </GridItem>
                                    </DialogActions>
                                  </Dialog>


                                  <Dialog 
                                    //onClick={() => setScrollingModal(false)}
                                    classes={{
                                      root: `${classes3.modalRoot} ${classes3.modalRootExample}`,
                                      paper: classes3.modal
                                    }}
                                    open={scrollingModal2}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={simulacaoHomeEquityClose}
                                    aria-labelledby="classic-modal-slide-title"
                                    aria-describedby="classic-modal-slide-description">
                                    <DialogTitle
                                      id="classic-modal-slide-title"
                                      disableTypography
                                      className={classes3.modalHeader}>
                                      <Button
                                        simple
                                        className={classes3.modalCloseButton}
                                        key="close"
                                        aria-label="Close"
                                        onClick={simulacaoHomeEquityClose}>
                                        {" "}
                                        <Close className={classes3.modalClose} />
                                      </Button>
                                      <div className={classes3.container2}>
                                            <Animation1>
                                                  <Typography className={classes.titleanimatedbuttons2}>
                                                    Resultado da simulação
                                                  </Typography>
                                            </Animation1>
                                        </div>

                                    </DialogTitle>
                                    <DialogContent
                                      id="classic-modal-slide-description"
                                      className={classes3.modalBody}>

                                    
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div className={classes.displayFundoCinzaEmprestimos2}>
                                          <div>
                                            <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Preencha todos os campos!</span>
                                            </Typography>
                                          </div>
                                        </div>
                                      </GridItem>
                                    </DialogContent>
                              
                                    <DialogActions style={{display: "flex", justifyContent: "space-evenly"}}>
                                        <GridItem className={classes.modalFooterEmpre}>
                                      
                                          <Button onClick={simulacaoHomeEquityClose} color="success" round>
                                              <span><DoneIcon style={{marginRight: "5px", verticalAlign: "middle"}}></DoneIcon>OK!</span>
                                          </Button>
                                        </GridItem>
                                    </DialogActions>
                                  </Dialog>
                      

                                </div>
                            </div>

                        </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={6} className={classes.animationHomecarEquity}>
                            <AnimationUpDownHomeCarEquity>
                            </AnimationUpDownHomeCarEquity>
                        </GridItem>
                    </GridContainer>
                    <div className={classes.arrowanimatedbuttonsHomeCarEquity}>
                        <ArrowAnimatedButton>
                        </ArrowAnimatedButton>
                    </div>
            </div>
             <div name="" id="section2" style={{background: "rgb(242, 243, 248)", minHeight:"700px", marginTop:"-70px"}}>
                <TableHomeEquity>
                </TableHomeEquity>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"30px", marginBottom:"80px", display: "flex", justifyContent: "center"}}>
                    <LinkScroll to="topo"  smooth={true} duration={1000} offset={-100} onClick={() => scroll.scrollToTop()}>
                            <Button  className={classes.botaoSimularConsorcio} color="success" round>
                            SIMULE SEU CRÉDITO
                            </Button>
                    </LinkScroll>
                    </GridItem>
                </GridContainer>
            </div>
            <div name="" id="section2" style={{background: "#fff", minHeight:"700px", marginTop:"-50px"}}>
                <TableHomeEquitySolo>
                </TableHomeEquitySolo>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"30px", marginBottom:"80px", display: "flex", justifyContent: "center"}}>
                    <LinkScroll to="topo"  smooth={true} duration={1000} offset={-100} onClick={() => scroll.scrollToTop()}>
                            <Button  className={classes.botaoSimularConsorcio} color="success" round>
                            SIMULE SEU CRÉDITO
                            </Button>
                    </LinkScroll>
                    </GridItem>
                </GridContainer>
            </div>
            <div name="" id="section2" style={{background: "rgb(242, 243, 248)", minHeight:"700px", marginTop:"-50px", marginBotton:"50px"}}>
            <TableCarEquitySolo>
            </TableCarEquitySolo>
            <GridContainer className={classes.container}>
                <GridItem xs={12} sm={12} md={12} style={{marginTop:"30px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                  <LinkScroll to="topo"  smooth={true} duration={1000} offset={-100} onClick={() => scroll.scrollToTop()}>
                          <Button  className={classes.botaoSimularConsorcio} color="success" round>
                          SIMULE SEU CRÉDITO
                          </Button>
                  </LinkScroll>
                </GridItem>
            </GridContainer>
        </div>

           
           
             <div name="" id="section2" style={{background: "#fff", minHeight:"700px"}}>
                <CardEmprestimosHomeCarRquity>
                </CardEmprestimosHomeCarRquity>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"-5px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                    <LinkScroll to="topo"  smooth={true} duration={1000} offset={-100} onClick={() => scroll.scrollToTop()}>
                            <Button  className={classes.botaoSimularConsorcio} color="success" round>
                            SIMULE SEU CRÉDITO
                            </Button>
                      </LinkScroll>
                    </GridItem>
                </GridContainer>
            </div>
           
            <div name="" id="section2" style={{background: "rgb(242, 243, 248)", minHeight:"700px"}}>
                <ServicosEmprestimosHomeCarEquity>
                </ServicosEmprestimosHomeCarEquity>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"5px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                    <LinkScroll to="topo"  smooth={true} duration={1000} offset={-100} onClick={() => scroll.scrollToTop()}>
                            <Button  className={classes.botaoSimularConsorcio} color="success" round>
                            SIMULE SEU CRÉDITO
                            </Button>
                      </LinkScroll>
                    </GridItem>
                </GridContainer>
            </div>
            <div name="" id="section2" style={{background: "#fff", minHeight:"550px"}}>
                <ChartEmprestimosHomeEquity>
                </ChartEmprestimosHomeEquity>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"-18px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                    <LinkScroll to="topo"  smooth={true} duration={1000} offset={-100} onClick={() => scroll.scrollToTop()}>
                            <Button  className={classes.botaoSimularConsorcio} color="success" round>
                            SIMULE SEU CRÉDITO
                            </Button>
                     </LinkScroll>
                    </GridItem>
                </GridContainer>
            </div>
            <div name="ViddeoArea" id="VideoArea" style={{background: "rgb(242, 243, 248)"}}>
                <VideoAreaEmprestimosHomeEquity>
                </VideoAreaEmprestimosHomeEquity>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"-49px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                    <LinkScroll to="topo"  smooth={true} duration={1000} offset={-100} onClick={() => scroll.scrollToTop()}>
                            <Button  className={classes.botaoSimularConsorcio} color="success" round>
                            SIMULE SEU CRÉDITO
                            </Button>
                        </LinkScroll>
                    </GridItem>
                </GridContainer>
            </div>
            <div>
                <GridContainer className={classes.container} style={{minHeight:"517px"}}>
                    <AccordionEmprestimosHomeCarEquity>
                    </AccordionEmprestimosHomeCarEquity>
                </GridContainer>
            </div>
            <div name="rodape" id="rodape" >
                <Footer2>
                </Footer2>
            </div>
        </section>

  );
}