import React from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// react components for routing our app without refresh
//import { Link } from "react-router-dom";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
//import Footer2 from "components/Footer/Footer2";
//import ButtonsArea from "components/ButtonsArea/ButtonsArea";
//import ButtonsAreaAnime from "components/ButtonsArea/ButtonsAreaAnime.jsx";
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
//import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
//Link
import { Link } from 'react-router-dom'
//import SectionNavbarsNOVO from "./Sections/SectionNavbarsNOVO.js";
//Scrool Smooth site
//import { SmoothProvider } from 'react-smooth-scrolling'

//import styles from "assets/jss/material-kit-react/views/components.js";
//import ButtonsAreaAnime from "components/ButtonsArea/ButtonsAreaAnime";

//Efeitos
//import Typewriter from 'react-simple-typewriter';
//import 'react-simple-typewriter/dist/index.css';

//Typography
//import Typography from '@material-ui/core/Typography';

//Imagens
//import  LogoContaDigital { ReactComponent as Image } from 'images/logo-fitticontadigital.svg';
//import  LogoFittiConsorcios { ReactComponent as Image } from 'images/logo-fitticonsorcios.svg';
//import  LogoFittiEmprestimos { ReactComponent as Image } from 'images/logo-fittiemprestimos.svg';

//import  LogoFittiSeguros { ReactComponent as Image } from 'images/logo-fittiseguros.svg';
//import  LogoFittiCambio { ReactComponent as Image } from 'images/logo-fitticambio.svg';
//import  LogoFittiShopping { ReactComponent as Image } from 'images/logo-fittishopping.svg';

//import  LogoFittiPagamentos { ReactComponent as Image } from 'images/logo-fittipagamentos.svg';
//import  LogoFittiInvestimentos { ReactComponent as Image } from 'images/logo-fittiinvestimentos.svg';
//import  LogoFittiSaude { ReactComponent as Image } from 'images/logo-fittisaude.svg';
//import  LogoFittiValeBeneficios { ReactComponent as Image } from 'images/logo-fittivalebeneficios.svg';

import LogoContaDigital from 'images/logofitticontadigital.svg';
import LogoFittiConsorcios from 'images/logofitticonsorcios.svg';
import LogoFittiEmprestimos from 'images/logofittiemprestimos.svg';

import LogoFittiSeguros from 'images/logofittiseguros.svg';
import LogoFittiCambio from 'images/logofitticambio.svg';
import LogoFittiShopping from 'images/logofittishopping.svg';

import LogoFittiPagamentos from 'images/logofittipagamentos.svg';
import LogoFittiInvestimentos from 'images/logofittiinvestimentos.svg';
import LogoFittiSaude from 'images/logofittisaude.svg';
import LogoFittiValeBeneficios from 'images/logofittivalebeneficios.svg';


//import Cardeapp from 'images/cardeapp.png';

//Container
//import Container from '@material-ui/core/Container';
//import Grid from '@material-ui/core/Grid';

//Animations
//import AnimationUpDown from './Sections/AnimationUpDown.js';


//Css degrade Fitticolor
//import Fittidegrade from './Sections/Fittidegrade.js';

//Css
//Animations
//import ArrowAnimatedButton from "./Sections/ArrowAnimatedButton.js";
//import buttonStyle from "assets/jss/material-kit-react/components/buttonStyle.js";
//import "components/Appcss/App.css";

//JQuery
//import $ from "jquery"
//scroll
import {animateScroll as scroll} from 'react-scroll';
import * as Scroll from 'react-scroll'
//import { Fade } from "@material-ui/core";
//import anime from "animejs/lib/anime.es.js";

//Animação do cartão
//import Slide from '@material-ui/core/Slide';
//import Servicos from 'components/Servicos/Servicos.jsx';
//import Accordion from  'components/Accordion/Accordion.jsx';
//import NavPillsPro from  'components/NavPills/NavPillsPro.jsx';
//import VideoArea from  'components/VideoArea/VideoArea.jsx';
//import CartoesFitti from 'components/CartoesFitti/CartoesFitti.jsx';


//import {BrowserRouter as Router} from 'react-router-dom';
//import Menu from '../../components/MenuViews/Menu';
//import Content from '../../components/MenuViews/Content';

import { Switch, Route } from 'react-router-dom'

//const useStyles = makeStyles(styles);

export default function App(props) {
  let LinkScroll = Scroll.Link;
  //const classes = useStyles();
  const { ...rest } = props;

  //const LogoContaDigital = require("images/logo-fitticontadigital.svg");
  //const LogoFittiConsorcios = require("images/logo-fitticonsorcios.svg");
  //const LogoFittiEmprestimos = require("images/logo-fittiemprestimos.svg");
  //const LogoFittiSeguros= require("images/logo-fittiseguros.svg");
  //const LogoFittiCambio = require("images/logo-fitticambio.svg");
  //const LogoFittiShopping = require("images/logo-fittishopping.svg");
  //const LogoFittiPagamentos = require("images/logo-fittipagamentos.svg");
  //const LogoFittiInvestimentos = require( "images/logo-fittiinvestimentos.svg");
  //const LogoFittiSaude = require("images/logo-fittisaude.svg");
  //const LogoFittiValeBeneficios = require("images/logo-fittivalebeneficios.svg");


  return (   
          <Header 
              brand={ 
                <Switch>
                    <Route path="/emprestimos">
                        <Link to="/emprestimos" onClick={() => scroll.scrollToTop()}>
                            <img
                                src={LogoFittiEmprestimos}
                                style={{ width:'260px', marginRight:'10px'}} alt='Logo'>
                            </img>
                            
                        </Link>
                    </Route>
                    <Route path="/seguros">
                        <Link to="/seguros" onClick={() => scroll.scrollToTop()}>
                            <img
                                src={LogoFittiSeguros}
                                style={{ width:'260px', marginRight:'10px'}} alt='Logo'>
                            </img>
                            
                        </Link>
                    </Route>
                    <Route path="/cambio">
                        <Link to="/cambio" onClick={() => scroll.scrollToTop()}>
                            <img
                                src={LogoFittiCambio}
                                style={{ width:'260px', marginRight:'10px'}} alt='Logo'>
                            </img>
                            
                        </Link> 
                    </Route> 
                    <Route path="/consorcios">
                        <Link to="/consorcios" onClick={() => scroll.scrollToTop()}>
                            <img
                                src={LogoFittiConsorcios}
                                style={{ width:'260px', marginRight:'10px'}} alt='Logo'>
                            </img>
                            
                        </Link>
                    </Route>
                    <Route path="/shopping">
                        <Link to="/shopping" onClick={() => scroll.scrollToTop()}>
                            <img
                                src={LogoFittiShopping}
                                style={{ width:'260px', marginRight:'10px'}} alt='Logo'>
                            </img>
                            
                        </Link>
                    </Route>
                    <Route path="/pagamentos">
                        <Link to="/pagamentos" onClick={() => scroll.scrollToTop()}>
                            <img
                                src={LogoFittiPagamentos}
                                style={{ width:'260px', marginRight:'10px'}} alt='Logo'>
                            </img>
                            
                        </Link>
                    </Route>
                    <Route path="/investimentos">
                        <Link to="/investimentos" onClick={() => scroll.scrollToTop()}>
                            <img
                                src={LogoFittiInvestimentos}
                                style={{ width:'260px', marginRight:'10px'}} alt='Logo'>
                            </img>
                            
                        </Link>
                    </Route>
                    <Route path="/fittisaude">
                        <Link to="/fittisaude" onClick={() => scroll.scrollToTop()}>
                            <img
                                src={LogoFittiSaude}
                                style={{ width:'260px', marginRight:'10px'}} alt='Logo'>
                            </img>
                            
                        </Link>
                    </Route>
                    <Route path="/fittivale">
                        <Link to="/fittivale" onClick={() => scroll.scrollToTop()}>
                            <img
                                src={LogoFittiValeBeneficios}
                                style={{ width:'260px', marginRight:'10px'}} alt='Logo'>
                            </img>
                            
                        </Link>
                    </Route>
                    <Route path="/contadigital">
                    <LinkScroll to="/contadigital" smooth={true} duration={1000} offset={-100}>
                            <img
                                src={LogoContaDigital}
                                style={{ width:'260px', marginRight:'10px'}} alt='Logo'>
                            </img>
                            
                        </LinkScroll>
                     </Route>
                       <Route path="/">
                    <LinkScroll to="topo" smooth={true} duration={1000} offset={-100}>
                            <img
                                src={LogoFittiInvestimentos}
                                style={{ width:'260px', marginRight:'10px'}} alt='Logo'>
                            </img>
                            
                        </LinkScroll>
                     </Route>
                </Switch>
                        }
                 
              rightLinks={<HeaderLinks />}
              fixed
              color= "transparent"
              changeColorOnScroll={{
                    height: 100,
                    color: "white"
              }}
              {...rest}>
          </Header>
  );
}