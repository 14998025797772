import React from "react";
//import {Bar} from 'react-chartjs-2';
import  'chartjs-plugin-datalabels';
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/components.js";
import Typography from '@material-ui/core/Typography';
import styled, { keyframes } from 'styled-components';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
//import taxasconsorcio from 'images/taxasconsorcio.png';
//import SmoothImage from 'react-smooth-image';
import Check from '@material-ui/icons/Check';
//import Close from '@material-ui/icons/Close';
//import Success from "components/Typography/Success.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import pricingStyle from "assets/jss/material-kit-react/views/sectionsSections/pricingStyle.js";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";


//import CardFooter from "components/Card/CardFooter.js";
//import Button from "components/CustomButtons/Button.js";
//import NavPills from "components/NavPills/NavPills.js";
//import Danger from "components/Typography/Danger.js";



// @material-ui/icons
//import Favorite from "@material-ui/icons/Favorite";
// core components



// sections for this page






Chart.register(ChartDataLabels);

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(pricingStyle);

//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;


export default function ChartConsorcios(){

     const classes = useStyles(styles)
     const classes2 = useStyles2(pricingStyle)
  


    return (   
       
<GridContainer className={classes.container} style={{display: "flex", justifyContent: "center"}}>
                <div className={classes.container2} style={{marginBottom:"35px", marginTop:"10px"}}>
                    <Animation1>
                        <Typography className={classes.titleanimatedbuttons2}>
                            Conheça nossos diferenciais
                        </Typography>
                    </Animation1>
                </div> 

      <GridItem style={{display:"content"}}>
        <Typography className={classes.titleConsorcioTaxas}>
                  Vantagens na escolha do Fitti
        </Typography>
        <GridContainer>
            <GridItem xs={4} sm={4} md={4} lg={4} className={classes.cardsInvestmentMediaQuery}>
              <Card pricing color="success" style={{minWidth:"131px", backgroundImage: "linear-gradient(34deg, rgba(1,100,125,1) 0%, rgba(149,200,63,1) 100% )" }}>
                <CardBody pricing style={{display: "contents", justifyContent: "center"}}>
                   <Typography className={classes.cardNumberTitle}> Fitti </Typography>
                   <Typography style={{color:"white", marginBottom:"-18px", fontVariantCaps: "small-caps", display:"flex", justifyContent: "center"}} className={classes.typographTaxa}> Á partir de: </Typography>
                   <Typography className={classes.cardNumberTitleNumero3}> <small>R$</small>  1,00</Typography>
                   <Typography style={{color:"white", marginTop:"-9px", fontVariantCaps: "small-caps", display:"flex", justifyContent: "center"}} className={classes.typographTaxa}> de patrimônio </Typography>
                  <List style={{color:"#fff"}}>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} />
                      <Typography className={classes.typographTaxa}> Profissionais c/ alto nível de formação e certificação.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Foco nos objetivos e performance do cliente.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Corretagem com custo zero.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Produtos exclusivos.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Atendimento digital e presencial constante.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Alta diversidade de soluções e serviços financeiros.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Mais opções de produtos domésticos e internacionais.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Cashback em produtos.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Tecnologia em simulações de carteiras.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Carteiras e estratégias vencedoras replicáveis.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Asset allocation otimizado.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Recomendações personalizadas.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Monitoramento constante.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Conteúdos exclusivos.</Typography>
                    </ListItem>




                  </List>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={4} sm={4} md={4} lg={4} className={classes.cardsInvestmentMediaQuery}>
            <Card pricing  plain >
                <CardBody pricing style={{display: "contents", justifyContent: "center"}}>
                 <Typography className={classes.cardNumberTitle2}> <s>Tradicionais</s></Typography>
                 <Typography style={{color:"rgb(63, 63, 63)", marginBottom:"-18px", fontVariantCaps: "small-caps", display:"flex", justifyContent: "center"}} className={classes.typographTaxa}> Á partir de: </Typography>
                 <Typography className={classes.cardNumberTitleNumero4}><small>R$</small><s>50</s><small>mil</small></Typography>
                 <Typography style={{color:"rgb(63, 63, 63)", marginTop:"-9px", fontVariantCaps: "small-caps", display:"flex", justifyContent: "center"}} className={classes.typographTaxa}>de patrimônio</Typography>
                  <List >
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} />
                      <Typography className={classes.typographTaxa}>Profissionais c/ médio nível de formação e certificação.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Foco em rebate, criando conflitos de interesses c/ clientes.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}> Corretagem em alguns produtos.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Produtos genéricos.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Atendimento presencial c/ média constância.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Média diversidade de soluções e serviços financeiros.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Opções moderadas de produtos.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Sem cashback em produtos.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Simulações de carteiras manuais.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Carteiras e estratégias c/ média performance.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Asset allocation genérico.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Recomendações generalizadas.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Monitoramento esporádico.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Conteúdos genéricos.</Typography>
                    </ListItem>


                  </List>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={4} sm={4} md={4} lg={4} className={classes.cardsInvestmentMediaQuery}>
            <Card pricing  plain >
                <CardBody pricing style={{display: "contents", justifyContent: "center"}}>
                <Typography className={classes.cardNumberTitle2}><s>Outros</s></Typography>   
                <Typography style={{color:"rgb(63, 63, 63)", marginBottom:"-18px", fontVariantCaps: "small-caps", display:"flex", justifyContent: "center"}} className={classes.typographTaxa}> Á partir de: </Typography>
                <Typography className={classes.cardNumberTitleNumero4}> <small>R$</small><s>100</s><small>mil</small></Typography>
                <Typography style={{color:"rgb(63, 63, 63)", marginTop:"-9px", fontVariantCaps: "small-caps", display:"flex", justifyContent: "center"}} className={classes.typographTaxa}> de patrimônio </Typography>
                  <List >
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa}/>
                      <Typography className={classes.typographTaxa}>Profissionais c/ baixo nível de formação e certificação.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Foco em rebate criando conflitos de interesses c/ clientes.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Corretagem nos produtos.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Produtos genéricos.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Atendimento somente presencial s/ constância.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Baixa diversidade de soluções e serviços financeiros ofertados.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Poucas opções de produtos na plataforma.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Sem cashback em produtos.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Sem oferta de simulações de carteiras.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Carteiras e estratégias c/ baixa performance.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Asset allocation genérico.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Recomendações generalizadas.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Monitoramento mínimo.</Typography>
                    </ListItem>
                    <ListItem className={classes2.servicosListas}>
                      <Check className ={classes.svgTaxa} /> 
                      <Typography className={classes.typographTaxa}>Conteúdos básicos.</Typography>
                    </ListItem>
                  </List>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
      {/* Pricing 4 END */}
        </GridItem>
      </GridContainer> 
      

    );
}

