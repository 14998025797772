import React from "react";
import Accordion from "components/Accordion/Accordion.js";
import styled, {keyframes} from 'styled-components';
import Typography from '@material-ui/core/Typography';
import styles from "assets/jss/material-kit-react/views/components.js";
import { makeStyles } from "@material-ui/core/styles";
//import { useScrollTrigger } from '@material-ui/core';
//import Grow from '@material-ui/core/Grow';
import Success from "components/Typography/Success.js";
import Check from "@material-ui/icons/Check";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";




const useStyles = makeStyles(styles);
//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
  animation-delay: 1.5s;
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
  transform: translatey(0px);
  animation: ${float} 6s ease-in-out infinite;
  `;

export default _=>{

  const classes = useStyles(styles);


  return (
<div Id="accordion">
    <div className={classes.container2}>
        <Animation1>
                  <Typography className={classes.titleanimatedbuttons2}>
                      Dúvidas sobre o Fitti Empréstimos?
                  </Typography> 
      </Animation1>
    </div>
   
   <div style={{boxShadow: "0px 2px 23px -16px", borderRadius: "20px", marginBottom:"50px", marginTop:"50px", paddingTop: "20px", paddingBottom: "20px", paddingLeft:"25px", paddingRight:"25px"}}>
    <div className={classes.accordion1}>
      <Accordion
      //active={0}
      activeColor={"success" }
      collapses={[
        {
          title:  <Typography>"O que é o Fitti Empréstimos?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                    O Fitti Empréstimos é um serviço provido por Fitti Pagamentos, e tem como objetivo principal oferecer produtos relacionados á Empréstimos através de plataformas digitais (Web e Mobile).   
              </Typography>
        },
        {
          title: <Typography>"O Fitti Empréstimos é regulado pelo Bacen e entidades afins?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                      O Fitti Empréstimos atua em regime de correspondente bancário, juntamente á Instituições Financeiras parceiras fiscalizadas e autorizadas pelo Banco Central. Cada uma das verticais está alocada em empresas e verticais de negócios que são parte do Fitti Pagamentos - Eireli - CNPJ: 24.341.342/0001-33.
              </Typography>
        },
        {
          title: <Typography>"Quais são as categorias (Linhas) de empréstimos que o Fitti oferece?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>   
                      O Fitti oferece as seguintes modalidades de Empréstimos: 
                      <Success >
                     <List>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Empréstimo pessoal (CDC):</span> Para Pessoas Físicas.</ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Crediário (CDC - Carnê Digital):</span> Para Pessoas Físicas e Pessoas Jurídicas.</ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Empréstimo consignado:</span> Para Pessoas Físicas.</ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Home Equity (Empréstimo com garantia de imóvel):</span> Para Pessoas Físicas e Pessoas Jurídicas.</ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Car Equity (Empréstimo com garantia de automóvel):</span> Para Pessoas Físicas e Pessoas Jurídicas. </ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Financiamento de imóveis:</span>Para Pessoas Físicas e Pessoas Jurídicas. </ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Financiamento de automóveis:</span> Para Pessoas Físicas e Pessoas Jurídicas.</ListItem> 
                    </List>
                    </Success>{" "}
              
              
              </Typography>
        },
        {
          title: <Typography>"Toda a jornada de contratação e utilização é on-line?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                  Sim. Desde a simulação, adesão do empréstimo e acompanhamento das parcelas.  Você simula online, confirma a adesão do empréstimo, tudo em poucos minutos! Assim que estiver tudo certo, é só assinar o contrato e esperar o dinheiro cair na sua conta.
              </Typography>
        },
        {
          title: <Typography>"Qual a diferença entre empréstimo sem garantia e empréstimo com garantia"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                  A diferença está no tamanho ou volume do empréstimo, na taxa de juros praticada, nos prazos e nas exigências de imóvel ou automóvel como garantia ou na modalidade com apenas a avaliação do score pessoal na linha sem garantia.
              </Typography>
        },
        {
          title: <Typography>"Quais são as modalidades de empréstimos que atendem as pessoas físicas?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                  O Fitti oferece as seguintes modalidades de Empréstimos para Pessoas Físicas: Empréstimo pessoal (CDC), Crediário (CDC - Carnê Digital), Empréstimo consignado, Home Equity (Empréstimo com garantia de imóvel), Car Equity (Empréstimo com garantia de automóvel), Financiamento de Automóveis e Financiamento de Imóveis.  
              </Typography>
        },
        {
          title: <Typography>"Quais são as modalides de empréstimos que atendem as pessoas jurídicas?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                   O Fitti oferece as seguintes modalidades de Empréstimos para pessoas jurídicas: Crediário (CDC - Carnê Digital), Home Equity (Empréstimo com garantia de imóvel), Car Equity (Empréstimo com garantia de automóvel), Financiamento de Automóveis e Financiamento de Imóveis.    
              </Typography>
        },

        {
          title: <Typography>"Qual é o valor mínimo e máximo das linhas de empréstimos?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                   As linhas de empréstimos oferecidas pelo Fitti começam no mínimo em R$1.000,00 e vão até R$5.000.000,00. Confira:
                   <Success >
                     <List>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Empréstimo pessoal (CDC):</span> R$1.000,00 a R$30.000,00 </ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Crediário (CDC - Carnê Digital):</span> R$1.000,00 a R$30.000,00 </ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Empréstimo consignado:</span> R$1.000,00 a R$50.000,00 </ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Home Equity (Empréstimo com garantia de imóvel):</span> R$100.000,00 a R$5.000.000,00 </ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Car Equity (Empréstimo com garantia de automóvel):</span> R$5.000,00 a R$100.000,00 </ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Financiamento de imóveis:</span> R$100.000,00 a R$5.000.000,00 </ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Financiamento de automóveis:</span> R$20.000,00 a R$500.000,00 </ListItem> 
                    </List>
                    </Success>{" "}
              </Typography>
        },


        {
          title: <Typography>"O que é CET (Custo Efetivo Total)?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                  CET é o Custo Efetivo Total, ou seja, o resultado da soma de todos os custos envolvidos no pagamento do empréstimo, como: a taxa de juros do empréstimo (Tarifa cobrada por quem está disponibilizando dinheiro) e o IOF - Imposto Sobre Operações de Financeiras (Taxa para operações de crédito), entre outras.
              </Typography>
        },

        {
          title: <Typography>"Em quantas parcelas posso pagar os Empréstimos oferecidos pelo Fitti?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                     As linhas de empréstimos oferecidas pelo Fitti possuem parcelas de até 240 meses. Confira:
                   <Success >
                     <List>
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Empréstimo pessoal (CDC):</span> Até 80 meses.</ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Crediário (CDC - Carnê Digital):</span> Até 24 meses.</ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Empréstimo consignado:</span> Até 240 meses. </ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Home Equity (Empréstimo com garantia de imóvel):</span> Até 240 meses.</ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Car Equity (Empréstimo com garantia de automóvel):</span> Até 240 meses.</ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Financiamento de imóveis:</span> Até 240 meses.</ListItem> 
                           <ListItem style={{display: "flex"}}> <Check style={{marginRight: "15px"}} /> <span style={{color: "#3f3f3f", marginRight:"5px"}}> Financiamento de automóveis:</span> Até 240 meses.</ListItem> 
                    </List>
                    </Success>{" "}
              </Typography>
        },

        {
          title: <Typography>"Quais as formas de pagamento dos Empréstimos Fitti?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                  As parcelas das linhas de empréstimos poderão ser pagas por boletos bancários, enviados com antecedência aos clientes ou emitidos como primeira, segunda ou mais vias, através da plataforma web e mobile do Fitti, utilizando o acesso pessoal da sua conta criada durante o processo de solicitação e adesão do empréstimo.
              </Typography>
        },

        {
          title: <Typography>"Preciso pagar algum valor antecipadamente?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                  Não há custos para analisar a sua proposta de crédito, nem para a liberação do empréstimo. Se você for abordado por alguém que peça algum pagamento antecipado, denuncie! Isso é crime!
              </Typography>
        },

        {
          title: <Typography>"Posso amortizar as parcelas antecipadamente?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                 Para realizar qualquer proposta de amortização prévia você deverá entrar em contato através dos nosso canais de atendimento solicitando a amortização antecipada.
              </Typography>
        },

      ]}
      ></Accordion>
    </div> 
  </div>
  </div>
  )
}

