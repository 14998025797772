import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";
import CartoesRendaVariavel from 'components/CartoesFitti/CartoesRendaVariavel';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import CartoesEstudante from 'components/CartoesFitti/CartoesEstudante';
//Typography
import Typography from '@material-ui/core/Typography';
import styled, {keyframes} from 'styled-components';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Check from '@material-ui/icons/Check';
import Badge from "components/Badge/Badge.js";
import CartaoRendaVariavelPng from 'images/cartaoRendaVariavel.png';

const useStyles = makeStyles(styles);
const FooterScreen = styled.div`
  height: "100%";
  width: "100%";
`;
const Fittideg = styled.div`
  background: linear-gradient(
    90deg,
    rgba(1, 100, 125, 1) 0%,
    rgba(149, 200, 63,1) 100%
  );
  min-height: 650px;
  width: 100%;
`;

export default function Footer2(props) { 
const classes = useStyles(styles);

//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
  animation-delay: 1.5s;
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
  transform: translatey(0px);
  animation: ${float} 6s ease-in-out infinite;
  `;


//Animation Enter
const float2 = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: rotate(0deg) scale(1);
      z-index:3;
  }

  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      opacity:1
      transform: rotate(3.21615deg) scale(1);
      z-index:2;
  }
 
100% {
  box-shadow: 0 0px 0px 0px rgba(0,
  0,
  0,
  0.6);
  opacity:1
  transform: rotate(28deg) scale(1);
  z-index:3;
}`;


const Animation2 = styled.div`
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
  transform:  rotate(28deg) scale(1);
  animation: ${float2} 6s ease-in-out alternate infinite;
  animation-delay: 0s;
  `;

//Animation Enter
const float3 = keyframes`
  0% {
    box-shadow: -86px 0 17px rgba(0,0,0,0.7);
   -webkit-transform: skew(10deg);
   -moz-transform: skew(10deg);
   -ms-transform: skew(10deg);
   -o-transform: skew(10deg);
   transform: skew(10deg) translate(-10px, 50px) scale(.85);
   -webkit-transform-origin: 0% 100%;
   -moz-transform-origin: 0% 100%;
   -ms-transform-origin: 0% 100%;
   -o-transform-origin: 0% 100%;
   transform-origin: 0% 100%;

  }

 
100% {
  box-shadow: -86px 0 17px rgba(0,0,0,0.7);
   -webkit-transform: skew(30deg);
   -moz-transform: skew(30deg);
   -ms-transform: skew(30deg);
   -o-transform: skew(30deg);
   transform: skew(30deg) translate(-10px, 50px) scale(.85);
   -webkit-transform-origin: 0% 100%;
   -moz-transform-origin: 0% 100%;
   -ms-transform-origin: 0% 100%;
   -o-transform-origin: 0% 100%;
   transform-origin: 0% 100%;
 
}`;


const Animation3 = styled.div`
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
  transform:  rotate(30deg) translate(-10px, 50px) scale(.85);
  animation: ${float3} 6s ease-in-out alternate infinite;
  animation-delay: 0s;
  `;

return (
    <FooterScreen>
          <Fittideg>
          <Animation1>
                  <Typography  className={classes.titleanimatedbuttonsSlide}>
                      Invista em diversos produtos
                  </Typography> 
        </Animation1>
          <GridContainer  className={classes.container}  style={{display:"flex"}}>
            <GridItem xs={12} sm={12} md={6} style={{ order:"2", display: "block", justifyContent:"center"}}>
              <div>
                <Typography style={{marginBottom:"20px", marginTop:"50px"}} className={classes.tittleCellPhoneWhite2}>
                      Para cada tipo de necessidade, um produto financeiro para dar "Fitti com você".
                </Typography> 
              </div>
                <Typography style={{marginBottom:"20px", marginTop:"30px"}} >
                <List>
                            <Typography  className={classes.letteraccordion3}>
                              <ListItem className={classes.listacelfone} >
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#fff"  }}></Check><span style={{marginRight:"5px", color:"#fff"}}>Ações ON/PN de empresas do mercado nacional.</span><span><Badge color="white">Ações nacionais</Badge></span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#fff" }}></Check><span style={{marginRight:"5px", color:"#fff"}}>Ações de empresas do mercado internacional.</span><span><Badge color="white">Ações internacionais</Badge></span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#fff" }}></Check><span style={{marginRight:"5px", color:"#fff"}}>Brazilian Depositary Receipts.</span><span><Badge color="white">BDR´s</Badge></span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#fff" }}></Check><span style={{marginRight:"5px", color:"#fff"}}>Exchange Traded Funds.</span><span><Badge color="white">ETF´s</Badge></span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#fff" }}></Check><span style={{marginRight:"5px", color:"#fff"}}>Índices de ações, de títulos e commodities.</span><span><Badge color="white">Índices</Badge></span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#fff"  }}></Check><span style={{marginRight:"5px", color:"#fff"}}>Commodities de metais, grãos e boi.</span><span><Badge color="white">Commodities</Badge></span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#fff" }}></Check><span style={{marginRight:"5px", color:"#fff"}}>Bitcoin, Ethereum, Litecoin, Dogecoin, entre outros.</span><span><Badge color="white">Criptomoedas</Badge></span>
                              </ListItem>
                            </Typography>
                          </List>
                </Typography> 
              </GridItem>
              <GridItem xs={12} sm={12} md={6} style={{order:"1", display: "flex", justifyContent:"center",}}>
                  <Animation2>
                      <CartoesRendaVariavel></CartoesRendaVariavel>
                  </Animation2>
                  <Animation3>
                    <img className={classes.cardImgBlur2}  src={CartaoRendaVariavelPng} alt=""></img>
                  </Animation3>
              </GridItem>
          </GridContainer>
          </Fittideg>
    </FooterScreen>
  );
}


