import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
//import Weekend from "@material-ui/icons/Weekend";
//import Home from "@material-ui/icons/Home";
//import Business from "@material-ui/icons/Business";
//import AccountBalance from "@material-ui/icons/AccountBalance";
import Check from "@material-ui/icons/Check";
//import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
//import CardFooter from "components/Card/CardFooter.js";
//import Button from "components/CustomButtons/Button.js";
//import NavPills from "components/NavPills/NavPills.js";
import Success from "components/Typography/Success.js";
//import Danger from "components/Typography/Danger.js";

import pricingStyle from "assets/jss/material-kit-react/views/sectionsSections/pricingStyle.js";
import styles1 from "assets/jss/material-kit-react/views/components.js";
import styles2 from "assets/jss/material-kit-react/views/componentsSections/sectionCards.js";
import Typography from '@material-ui/core/Typography';
import styled, { keyframes } from 'styled-components';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import CardHeader from "components/Card/CardHeader.js";
//import Badge from "components/Badge/Badge.js";
//import Table from "components/Table/Table.js";

//import bg11 from "assets/img/bg11.jpg";
//import city from "assets/img/examples/city.jpg";
//import cardBlog3 from "assets/img/examples/card-blog3.jpg";


import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import TimerIcon from '@material-ui/icons/Timer';


const useStyles = makeStyles(pricingStyle);
const useStyles1 = makeStyles(styles1);
const useStyles2 = makeStyles(styles2);


//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;


export default function SectionPricing({ ...rest }) {
  const classes = useStyles(pricingStyle);
  const classes1 = useStyles1(styles1)
  const classes2 = useStyles2(styles2)
  return (
          <GridContainer className={classes2.container} >
            <div className={classes1.container2} style={{marginBottom:"35px", marginTop:"20px"}}>
                <Animation1>
                      <Typography className={classes1.titleanimatedbuttons2}>
                        Porque escolher o Empréstimo Fitti?
                        
                      </Typography>
                </Animation1>
            </div>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <Card pricing >
              <CardHeader color="success" style={{display: "flex", justifyContent: "center" }} ><TimerIcon style={{verticalAlign:"middle", width: "80px", height: "80px"}}></TimerIcon></CardHeader>
                <CardBody pricing >
                 <Typography style={{fontWeight:"bold", fontSize:"1.5rem", color: "#3f3f3f", textAlign: "center"}}>Rápido e 100% digital</Typography>
                 <List>
                      <ListItem className={classes.servicosListas}> 
                          <Success>
                            <Check style={{marginRight: "15px"}} />
                          </Success>{" "}
                          <Typography className={classes.subtitulo}>
                                        <span className={classes.textoCardsServicos}>Todo o processo de análise e contratação do seu empréstimo é online. Em apenas alguns minutos você começa a simular seu empréstimo com taxas personalizadas. </span>
                          </Typography>
                       </ListItem>
                  </List>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <Card pricing color="success" >
                <CardHeader color="success" style={{display: "flex", justifyContent: "center" }} ><MonetizationOnIcon style={{verticalAlign:"middle", width: "80px", height: "80px"}}></MonetizationOnIcon></CardHeader>
                <CardBody pricing >
                <Typography style={{fontWeight:"bold", fontSize:"1.5rem", color: "#3f3f3f", textAlign: "center"}}>Taxas competitivas</Typography>
                 <List>
                      <ListItem  className={classes.servicosListas}> 
                          <Success>
                            <Check style={{marginRight: "15px"}} />
                          </Success>{" "}
                          <Typography className={classes.subtitulo}>
                                        <span className={classes.textoCardsServicos}> Para te dar um empurrãozinho na hora que você mais precisa de crédito, temos as menores taxas de juros do mercado.</span>
                          </Typography>
                      </ListItem> 
                  </List>
                </CardBody>
              </Card>
            </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
              <Card pricing >
               <CardHeader color="success" style={{display: "flex", justifyContent: "center" }} ><VerifiedUserIcon style={{verticalAlign:"middle", width: "80px", height: "80px"}}></VerifiedUserIcon></CardHeader>
                <CardBody pricing >
                 <Typography style={{fontWeight:"bold", fontSize:"1.5rem", color: "#3f3f3f", textAlign: "center"}}>Total segurança e transparência</Typography>
                 <List>
                      <ListItem className={classes.servicosListas}> 
                          <Success>
                            <Check style={{marginRight: "15px"}} />
                          </Success>{" "}
                          <Typography className={classes.subtitulo}>
                                        <span className={classes.textoCardsServicos}>  No Fitti a análise de crédito é segura e totalmente transparente. Nós consultamos e avaliamos suas informações, e com isso, oferecemos produtos com condições perfeitas para você. Tudo com solidez e tecnologia própria.</span>
                          </Typography>
                        </ListItem> 
                  </List>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
   
  );
}
