import React from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// react components for routing our app without refresh
//import { Link } from "react-router-dom";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
//import Header from "components/Header/Header.js";
//import Footer2 from "components/Footer/Footer2";
//import ButtonsArea from "components/ButtonsArea/ButtonsArea";
//import ButtonsAreaAnime from "components/ButtonsArea/ButtonsAreaAnime.jsx";
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
//import Parallax from "components/Parallax/Parallax.js";
// sections for this page
//import HeaderLinks from "components/Header/HeaderLinks.js";
//import SectionNavbarsNOVO from "./Sections/SectionNavbarsNOVO.js";
//Scrool Smooth site
//import { SmoothProvider } from 'react-smooth-scrolling'

//import styles from "assets/jss/material-kit-react/views/components.js";
//import ButtonsAreaAnime from "components/ButtonsArea/ButtonsAreaAnime";

//Efeitos
//import Typewriter from 'react-simple-typewriter';
//import 'react-simple-typewriter/dist/index.css';

//Typography
//import Typography from '@material-ui/core/Typography';

//Imagens
//import Logo from 'images/logo-fitticontadigital.svg';


//import Cardeapp from 'images/cardeapp.png';

//Container
//import Container from '@material-ui/core/Container';
//import Grid from '@material-ui/core/Grid';

//Animations
//import AnimationUpDown from './Sections/AnimationUpDown.js';


//Css degrade Fitticolor
//import Fittidegrade from './Sections/Fittidegrade.js';

//Css
//Animations
//import ArrowAnimatedButton from "./Sections/ArrowAnimatedButton.js";
//import buttonStyle from "assets/jss/material-kit-react/components/buttonStyle.js";
//import "components/Appcss/App.css";

//JQuery
//import $ from "jquery"
//scroll
//import {animateScroll as scroll} from 'react-scroll';
//import { Fade } from "@material-ui/core";
//import anime from "animejs/lib/anime.es.js";

//Animação do cartão
//import Slide from '@material-ui/core/Slide';
//import Servicos from 'components/Servicos/Servicos.jsx';
//import Accordion from  'components/Accordion/Accordion.jsx';
//import NavPillsPro from  'components/NavPills/NavPillsPro.jsx';
//import VideoArea from  'components/VideoArea/VideoArea.jsx';
//import CartoesFitti from 'components/CartoesFitti/CartoesFitti.jsx';


import {BrowserRouter as Router} from 'react-router-dom';
//import Menu from '../../components/MenuViews/Menu';
import Content from '../../components/MenuViews/Content.jsx';
import Menu from '../../components/MenuViews/Menu';
import ScrollToTop from './ScrollToTop';


//const useStyles = makeStyles(styles);

export default function App(props) {
  //const classes = useStyles();
  //const { ...rest } = props;
  
  return (

    <body style={{ scrollBehavior: "smooth"}}>
      <Router>
          <ScrollToTop></ScrollToTop>
          <Menu></Menu>
          <Content></Content>
      </Router>
    </body>
  );
}
