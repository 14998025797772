import React from "react";
import Accordion from "components/Accordion/Accordion.js";
import styled, {keyframes} from 'styled-components';
import Typography from '@material-ui/core/Typography';
import styles from "assets/jss/material-kit-react/views/components.js";
import { makeStyles } from "@material-ui/core/styles";
//import { useScrollTrigger } from '@material-ui/core';
//import Grow from '@material-ui/core/Grow';




const useStyles = makeStyles(styles);
//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
  animation-delay: 1.5s;
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
  transform: translatey(0px);
  animation: ${float} 6s ease-in-out infinite;
  `;

export default _=>{

  const classes = useStyles(styles);


  return (
<div Id="accordion">
    <div className={classes.container2}>
        <Animation1>
                  <Typography className={classes.titleanimatedbuttons2}>
                      Dúvidas sobre o Fitti Consórcios?
                  </Typography> 
      </Animation1>
    </div>
   
   <div style={{boxShadow: "0px 2px 23px -16px", borderRadius: "20px", marginBottom:"50px", marginTop:"50px", paddingTop: "20px", paddingBottom: "20px", paddingLeft:"25px", paddingRight:"25px"}}>
    <div className={classes.accordion1}>
      <Accordion
      //active={0}
      activeColor={"success" }
      collapses={[
        {
          title:  <Typography>"O que é o Fitti Consórcios?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                    O Fitti Consórcios é um serviço provido por Fitti Pagamentos, e tem como objetivo principal oferecer produtos relacionados á Consórcios através de plataformas digitais (Web e Mobile).   
              </Typography>
        },
        {
          title: <Typography>"O Fitti Consórcios é regulado pelo Bacen e entidades afins?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                      O Fitti Consórcios atua em regime de correspondente bancário, juntamente á Administradoras de Consórcios parceiras e demais Instituições Financeiras fiscalizadas e autorizadas pelo Banco Central. Cada uma das verticais está alocada em empresas e verticais de negócios que são parte do Fitti Pagamentos - Eireli - CNPJ: 24.341.342/0001-33.
              </Typography>
        },
        {
          title: <Typography>"O que é consórcio?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>   
                  Consórcio nada mais é do que uma reunião de pessoas com o mesmo objetivo, que se reúne em um grupo para adquirir um bem (Imóveis, Automóveis, Motos) ou um serviço (Construção, Reformas, Cirurgias Estética, Festas, etc.) de forma programada e sem pagar juros.
              </Typography>
        },
        {
          title: <Typography>"Toda a jornada de contratação e utilização é on-line?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                  Sim. Desde a simulação e a escolha do seu plano até o acompanhamento dos sorteios. Você compra online e paga a primeira parcela (no cartão ou boleto) seguindo o passo-a-passo direto com o Fitti Consórcios, tudo em poucos minutos!
              </Typography>
        },
        {
          title: <Typography>"Como faço para adquirir um consórcio?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                  Para comprar o seu consórcio basta acessar o botão "Simule seu Crédito", receber as informações sobre a simulação, escolher o consórcio ideal para você, realizar o pagamento da primeira parcela e gerenciar o serviço através das nossas plataformas web e mobile utilizando seu login: "nome e senha de usuário". E claro, aguardar pela contemplação ou dar um lance.
              </Typography>
        },
        {
          title: <Typography>"Como funcionam os Grupos de Consórcios?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                  O Fitti Consórcios vai incluir você em um grupo, com outras pessoas que também compraram uma cota de consórcio (não necessariamente do mesmo valor de crédito) mas do mesmo produto, seja um Imóvel, Auto, Moto ou Serviços. Assim, você terá o número do seu grupo e da sua cota de consórcio e já começa a participar dos sorteios na próxima assembleia.

                  Os grupos são formados por uma quantidade limitada de participantes. A duração de cada grupo possui prazo determinado e ele é finalizado após o pagamento da última parcela.

                  Existem 2 formas de entrada nos grupos de consórcio: você pode aderir a um grupo novo, que ainda está sendo formado, ou a um grupo que já está em andamento.

                  Grupos novos:
                  Nesse caso, o Fitti venderá as cotas, e irá informar a previsão para lançamento deste novo grupo. Para garantir a sua vaga você irá pagar a primeira parcela e a segunda somente no mês em que será realizada a assembleia de inauguração do seu grupo.

                  Você sabia que participar de um grupo novo ou em fase inicial te dá mais chances de ser contemplado através do sorteio? Isso acontece porque no início a quantidade de participantes ainda é menor, desta forma a probabilidade de você ser contemplado é maior.

                  Grupos em andamento:
                  Nesse caso, considerando que algumas assembleias já aconteceram, para participar, é preciso adquirir uma cota vaga ou comprar a cota de um participante que queira vendê-la.

                  No primeiro caso, deve-se comprar diretamente com o Fitti. No segundo, é preciso negociar o valor da compra com o atual participante. No entanto, a troca de titularidade da cota deve ter a anuência da administradora, de acordo com as regras do contrato de participação.
              </Typography>
        },
        {
          title: <Typography>"Como funcionam as parcelas?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                   As parcelas são Mensais, todas essas pessoas contribuem com uma parcela (proporcional ao crédito e prazo escolhido), que vai acumulando um saldo de investimento e também um saldo no grupo para realizar as contemplações por sorteio e por lances.              
              </Typography>
        },

        {
          title: <Typography>"Existe reajuste nas parcelas?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                   Sim, para que não ocorra a desvalorização do seu crédito, com relação ao bem que será adquirido, o valor da carta de crédito sempre será atualizado. Todo o ano, no dia do aniversário de adesão da sua cota o crédito é atualizado e, por isso, os valores das parcelas são reajustados. Essa correção é aplicada de acordo com o IPCA (Índice de preços as Consumidor) ou INCC (Índice Nacional de Custo da Construção), conforme o grupo e tipo de consórcio adquirido. Sendo assim, essa correção não pode ser considerado um "aumento", pois o seu crédito também valoriza.
                   Vale lembrar, que aqueles que já foram contemplados e receberam o crédito, também terão as suas parcelas reajustadas. Isso é necessário para manter o saldo no grupo e o poder de compra daqueles que ainda não receberam o dinheiro. Nesse caso, o reajuste não pode ser considerado um "custo", pois o mesmo será compensado pelo ganho financeiro com a valorização do bem que já foi adquirido no momento da contemplação.
                   No primeiro caso, deve-se comprar diretamente com o Fitti. No segundo, é preciso negociar o valor da compra com o atual participante. No entanto, a troca de titularidade da cota deve ter a anuência da administradora, de acordo com as regras do contrato de participação.
              </Typography>
        },

        {
          title: <Typography>"Como funcionam as contemplações?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                   Todo mês, pelo menos uma ou mais pessoas são sorteadas para pegar o dinheiro antecipadamente e comprar o seu bem, onde quiser. Todos têm as mesmas chances e só participam dos sorteios aqueles que estiverem com o pagamento das parcelas em dia.
                   Os sorteios são realizados, através da extração da Loteria Federal imediatamente anterior à data da assembleia do grupo.
                   No primeiro caso, deve-se comprar diretamente com o Fitti. No segundo, é preciso negociar o valor da compra com o atual participante. No entanto, a troca de titularidade da cota deve ter a anuência da administradora, de acordo com as regras do contrato de participação.
              </Typography>
        },

        {
          title: <Typography>"Como funcionam os lances?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                   Funciona assim:
                            Antes das assembleias, você acessa o site do Fitti com o o seu login e senha e oferece um adiantamento do valor que você tem a pagar. Ninguém vê o lance do outro, é sigiloso.
                            
                            Tem dois tipos de lance:
                            
                            O LANCE LIVRE, é para quem tem uma reserva própria e pode ofertar um valor maior à vista. Nesse caso, não existe limite e o maior percentual ofertado vence e, consequentemente, recebe o crédito. Para quem tem como objetivo comprar um imóvel, por exemplo, existe a opção de usar o saldo do FGTS na oferta de lance.
                            
                            O LANCE FIXO, consiste na oferta de um percentual fixo, estabelecido pela administradora em conjunto com o grupo. Se duas ou mais pessoas ofertarem o lance nessa mesma modalidade, o critério de desempate será a cota cuja numeração for mais próxima da sorteada no período.
                            
                            Dica: Uma forma de fazer o pagamento do lance livre e do lance fixo é utilizar também uma parte do crédito. Essa modalidade é chamada de lance facilitado (embutido), e serve para compor o valor ofertado com recursos próprios. Dessa forma, se você for o vencedor, o percentual do lance embutido utilizado será descontado do valor do crédito que você irá receber.
                            
                            As regras e percentuais de lances variam conforme o produto e o plano contratado. Após a compra é possível conferir no site do Fitti as características dos lances do grupo.
                            
                            Só paga o lance quem for o ganhador e todo valor pago como lance é abatido do saldo devedor, conforme as regras do contrato.


              </Typography>
        },

        {
          title: <Typography>"O meu consórcio valoriza?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                  A carta de crédito contemplada é dinheiro na mão, assim você pode conseguir um bom negócio na hora de realizar a compra. E para que não ocorra a desvalorização do seu crédito, com relação ao bem que será adquirido, todo o ano, no dia do aniversário de adesão da sua cota o seu crédito é atualizado e, por isso, os valores das parcelas são reajustados. Assim, quanto mais o tempo passa mais vale a sua carta de crédito.
              </Typography>
        },

        {
          title: <Typography>"Posso alterar o valor do crédito?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                 Sim, você poderá solicitar o aumento ou diminuição do valor do seu crédito a qualquer momento, desde que ainda não tenha sido contemplado. No caso de aumento do crédito ele é limitado ao maior crédito do grupo, se esse já for o seu caso você não poderá mais aumentar ou mudar de grupo.
              </Typography>
        },

        {
          title: <Typography>"Fui contemplado e agora?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                Quando você for contemplado, vai precisar passar pelo processo de aprovação de cadastro para ter o crédito liberado. Ai é só comprar qualquer bem ou serviço pertencente à categoria do seu grupo.

                A carta de crédito corresponde a dinheiro à vista e, dessa forma, você tem maior poder de negociação para fazer o melhor negócio na hora da compra.

                E depois de ser contemplado?
                Após pegar o dinheiro para comprar o bem desejado, você continua pagando as parcelas do consórcio normalmente, até o encerramento do seu plano.

              </Typography>
        },

        {
          title: <Typography>"E se demorar para ser contemplado?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                  Em algum momento isso deve acontecer. Em média você tem 68% de chances de ser contemplado antes da metade do prazo. Contra apenas 32% de chance de ser contemplado depois da metade do prazo total do seu consórcio. As contemplações são realizadas por sorteio e por lance, assim na média o grupo vai contemplar 02 ou mais pessoas mensalmente. Nessa lógica você poderá ter o seu crédito liberado antes mesmo de terminar de pagar as suas parcelas, mesmo que nunca ofereça um lance para antecipar a contemplação. E ainda mesmo quem seja contemplado somente no final, recebe o valor que pagou, corrigido monetariamente para comprar o seu bem.
              </Typography>
        },

        {
          title: <Typography>"Como funciona a Liberação do Crédito"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                  Quando você for contemplado vai precisar apresentar a documentação do bem que irá adquirir e passar por um processo de avaliação para a liberação de crédito.
                  Vale lembrar que a aprovação de cadastro e demais garantias necessárias para a liberação do crédito são exigências do Banco Central do Brasil e servem para garantir aos demais participantes do grupo, que aquela pessoa que já tendo recebido o crédito, vai continuar pagando as suas parcelas até o final do seu prazo.
              </Typography>
        },

        {
          title: <Typography>"Posso vender o meu Consórcio?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                      Consórcio em andamento:
                      Sim, você pode vender e transferir o seu consórcio em andamento (desde que esteja ativo e em dia com os pagamentos) para outra pessoa a qualquer momento, sempre respeitando as regras do contrato e com a prévia autorização da administradora.

                      Consórcio contemplado:
                      Caso você seja contemplado e não queira utilizar o crédito, poderá transferir o seu consórcio para outra pessoa com uma boa rentabilidade.

                      Funciona assim:
                      O consorciado paga as mensalidades e quando é contemplado, vende o seu consórcio para outra pessoa, que deseja obter um crédito imediato, mas não quer esperar pela contemplação. Nessa negociação, o vendedor normalmente cobra um percentual sobre o valor do crédito como ágio, além do total que já foi pago.

                      Essa transação é feita entre as partes, sem interferência da administradora. As regras para transferência de cotas estão estabelecidas no contrato e a pessoa que está adquirindo o consórcio deverá passar por uma aprovação de cadastro.
              </Typography>
        },
        
        {
          title: <Typography>"Quais outros produtos e serviços posso contratar através da plataforma do Fitti?"</Typography>,
          content:
              <Typography  className={classes.letteraccordion}>
                  Através das plataformas digitais do Fitti (Web e Mobile), você pode contratar serviços como empréstimos, seguros, câmbio (+ de 20 moedas), fazer compras no nosso shopping, contratar consórcios, utilizar meios de pagamentos e realizar investimentos.
              </Typography>
        }
      ]}
      ></Accordion>
    </div> 
  </div>
  </div>
  )
}

