import React from 'react';
import styled, { keyframes } from 'styled-components'
import InvestimentosHome from 'images/investimentosHome.png'
import styles from "assets/jss/material-kit-react/views/components.js";
import { makeStyles } from "@material-ui/core/styles";
//import Slide from '@material-ui/core/Slide';
import { Fade } from '@material-ui/core';
import SmoothImage from 'react-smooth-image'


const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
  animation-delay: 1.5s;
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
  transform: translatey(0px);
  animation: ${float} 6s ease-in-out infinite;
  `;

const useStyles = makeStyles(styles);

export default _ => {

    const classes = useStyles();
  

    return (
    <Fade direction="up" in={true} timeout={{ appear: 3000, enter: 3000, exit: 3000,}} mountOnEnter unmountOnExit>
        <div className={classes.imgAnimation}>
            <Animation1>
                <SmoothImage containerStyles={{height:"180px", marginBottom:"75px", overflow:"visible", paddingBottom:"0", display: "grid", justifyContent: "center"}} imageStyles={{position:"relative", height: "auto", overflow:"visible",  marginTop:"5%", marginBottom:"10%",  paddingBottom:"0" }}
                    src={InvestimentosHome} alt='Investimentos Fitti' transitionTime={1.0}  transitionTimeFunction={"ease-in"}>
                </SmoothImage> 
            </Animation1>
        </div>
    </Fade>
    );
   }
   