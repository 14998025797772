import React, {useEffect} from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
import styled, { keyframes } from 'styled-components'
import cardconsorcio from 'images/cardconsorcio.png'
//import styles from "assets/jss/material-kit-react/views/components.js";
import { makeStyles } from "@material-ui/core/styles";
//import Slide from '@material-ui/core/Slide';
//import { Fade } from '@material-ui/core';
import SmoothImage from 'react-smooth-image';
import Typography from '@material-ui/core/Typography';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import CardBody from "components/Card/CardBodyPro.js";
//import CardFooter from "components/Card/CardFooterPro.js";
//import CardHeader from "components/Card/CardHeaderPro.js";
//import Card from "components/Card/CardPro.js";
import styles from "assets/jss/material-kit-react/views/components.js";

// @material-ui icons
//import Favorite from "@material-ui/icons/Favorite";
//import Share from "@material-ui/icons/Share";
//import avatar from "assets/img/faces/avatar.jpg";
//import cardsStyle from "assets/jss/material-kit-react/views/componentsSections/sectionCards.js";

//import ShoppingCart from "@material-ui/icons/ShoppingCart";
import AttachMoney from "@material-ui/icons/AttachMoney";
//import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
//import BeachAccessIcon from '@material-ui/icons/BeachAccess';
//import BarChartIcon from '@material-ui/icons/BarChart';
//import PaymentIcon from '@material-ui/icons/Payment';
import BeenhereIcon from '@material-ui/icons/Beenhere';
//import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
//import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import DvrIcon from '@material-ui/icons/Dvr';
import FindInPageIcon from '@material-ui/icons/FindInPage';
//import FastfoodIcon from '@material-ui/icons/Fastfood';
//import Business from "@material-ui/icons/Business";
//import cardsStyle from "assets/jss/material-kit-react/components/cardStylePro.js";
//import Button from "components/CustomButtons/Button.js";

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Check from '@material-ui/icons/Check';



 /* const style1 = {
  ...cardsStyle
};  */ 

const useStyles = makeStyles(styles);


//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;


export default _=> {

  const classes = useStyles(styles)



  //Animação de entrada dos itens com o scroll
useEffect(() => {
    Aos.init({duration: 1500});
  }, []); window.addEventListener('load', function() {
    Aos.refresh();
  });

return (
<div className={classes.sectionTop2}>
<GridContainer className={classes.container} >
        <div className={classes.container2}>
            <Animation1>
                  <Typography className={classes.titleanimatedbuttons2}>
                    Simples e seguro para você contratar o seu Empréstimo
                     
                  </Typography>
            </Animation1>
        </div>
        <GridItem xs={12} sm={12} md={3} className={classes.griditemcard}>
         <div>
         
            <div  data-aos="fade-left">
              <SmoothImage containerStyles={{height:"200px", overflow:"visible", paddingBottom:"0" }} imageStyles={{position:"relative", width: "auto", height: "620px", overflow:"visible",  marginTop:"3%", marginBottom:"10%",  paddingBottom:"0" }}
                  src={cardconsorcio} alt='Moça mexendo no celular' transitionTime={1.0}  transitionTimeFunction={"ease-in"}>
              </SmoothImage> 
            </div>
         
         </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} className={classes.griditemcard} >
               <div style={{marginTop:"100px", marginLeft:"300px"}}>
                
                     <Typography className={classes.titleanimatedbuttons5}  data-aos="fade-left">
                        Confira o passo a passo <br/>
                        logo abaixo!
                     </Typography> 
                     <Typography className={classes.titleanimatedbuttons4}  data-aos="fade-left">
                        Empréstimos de A a Z.
                     </Typography> 
               </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ width:"-webkit-fill-available"}}>
        <div>
            <VerticalTimeline className={classes.verticalTimeline}>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ backgroundColor: "#F5F5F5", color: '#3f3f3f', borderRadius:"20px" }}
                contentArrowStyle={{ borderRight: '7px solid  #3f3f3f' }}
                date= {<Typography style={{display:"contents", verticalAlign:"middle"}}>ETAPA 1</Typography>}
                iconStyle={{ backgroundColor: "#F5F5F5", color: '#3f3f3f' }}
                icon={<DvrIcon />}>
                <div style={{ display:"flex"}}>
                    <Check style={{marginRight:"1px", verticalAlign:"middle",  color:"#4caf50" }}></Check>
                    <Typography style={{marginTop: "-1px", textAlign: "left", fontWeight: "1", fontSize: "1.2rem"}}>
                        Você simula com o Fitti e ele entende os seus objetivos.
                    </Typography>
                </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ backgroundColor: "#F5F5F5", color: '#3f3f3f', borderRadius:"20px" }}
                contentArrowStyle={{ borderRight: '7px solid  #3f3f3f' }}
                date= {<Typography style={{display:"contents", verticalAlign:"middle"}}>ETAPA 2</Typography>}
                iconStyle={{backgroundColor: "#F5F5F5", color: '#3f3f3f' }}
                icon={<FindInPageIcon  />}>
                <div style={{ display:"flex"}}>
                    <Check style={{marginRight:"1px", verticalAlign:"middle",  color:"#4caf50" }}></Check>
                    <Typography style={{marginTop: "-1px", textAlign: "left", fontWeight: "1", fontSize: "1.2rem"}}>
                        O Fitti orienta opções de parcelas e o limite de crédito ideal para você.
                    </Typography>
                </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ backgroundColor: "#F5F5F5", color: '#3f3f3f', borderRadius:"20px" }}
                contentArrowStyle={{ borderRight: '7px solid  #3f3f3f' }}
                date= {<Typography style={{display:"contents", verticalAlign:"middle"}}>ETAPA 3</Typography>}
                iconStyle={{ backgroundColor: "#F5F5F5", color: '#3f3f3f' }}
                icon={<AttachMoney />}>
                 <div style={{ display:"flex"}}>
                    <Check style={{marginRight:"1px", verticalAlign:"middle",  color:"#4caf50" }}></Check>
                    <Typography style={{marginTop: "-1px", textAlign: "left", fontWeight: "1", fontSize: "1.2rem"}}>
                        Você confirma e gerencia tudo pelo computador ou celular.
                    </Typography>
                </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ backgroundColor: "#F5F5F5", color: '#3f3f3f', borderRadius:"20px" }}
                contentArrowStyle={{ borderRight: '7px solid  #3f3f3f' }}
                date= {<Typography style={{display:"contents", verticalAlign:"middle"}}>ETAPA 4</Typography>}
                iconStyle={{ backgroundColor: "#F5F5F5", color: '#3f3f3f' }}
                icon={<BeenhereIcon  />}>
                <div style={{ display:"flex"}}>
                    <Check style={{marginRight:"1px", verticalAlign:"middle",  color:"#4caf50" }}></Check>
                    <Typography style={{marginTop: "-1px", textAlign: "left", fontWeight: "1", fontSize: "1.2rem"}}>
                        Nosso time de humanos cuida sempre de você e tira suas dúvidas.
                    </Typography>
                 </div>
            </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    </GridItem>
        
</GridContainer>
</div>
  );
}