import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Close from "@material-ui/icons/Close";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "assets/jss/material-kit-react/components/headerStyle.js";

import Login from '../Login/Login';
import SignUp from '../Login/SignUp';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from '@material-ui/core/Typography';
import Badge from "components/Badge/Badge.js";
//import FastfoodIcon from '@material-ui/icons/Fastfood';
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import AttachMoney from "@material-ui/icons/AttachMoney";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import BarChartIcon from '@material-ui/icons/BarChart';
//import PaymentIcon from '@material-ui/icons/Payment';
import BeenhereIcon from '@material-ui/icons/Beenhere';
//import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
//import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { Link } from 'react-router-dom';
import CustomDropdown from "components/CustomDropdown/CustomDropdownPro.js";
import CustomDropdown2 from "components/CustomDropdown/CustomDropdownPro2.js";
import CustomDropdown3 from "components/CustomDropdown/CustomDropdownPro3.js";
import MenuNavPillsPravc from  '../MenuViews/MenuNavPillsPravc';
import MenuNavPillsPraEmp from  '../MenuViews/MenuNavPillsPraEmp';
//import Ofitti from  '../MenuViews/Ofitti';
import * as Scroll from 'react-scroll'

import styles2 from "assets/jss/material-kit-react/components/headerLinksStylePro.js";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);


export default function Header(props) {
  let LinkScroll = Scroll.Link;
  const classes = useStyles();
  const classes2 = useStyles2(styles2);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;

    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, rightLinks, leftLinks, brand, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  const brandComponent = <Button className={classes.title}>{brand}</Button>;
  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        {leftLinks !== undefined ? brandComponent : null}
        <div className={classes.flex}>
          {leftLinks !== undefined ? (
            <Hidden smDown implementation="css">
              {leftLinks}
            </Hidden>
          ) : (
            brandComponent
          )}
        </div>
        <Hidden smDown implementation="css" className={classes.hidden}>
        <div className={classes.collapse}>{rightLinks}</div>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={handleDrawerToggle}
        >   
        <IconButton style={{float: "right"}}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.closeButtonDrawer}>
            <Close />
        </IconButton>

          <div className={classes.appResponsive}>
                    


                    
            <List  className={classes2.list + " " + classes2.mlAuto}>
                <ListItem className={classes2.listItem} style={{order:"3"}}>
                    <LinkScroll to="rodape" onClick={handleDrawerToggle}   smooth={true} duration={1000}>
                      <Button
                        href="#rodape"
                        color="transparent"
                        target="" 
                        className={classes2.navLink}>
                          O Fitti 
                      </Button>
                    </LinkScroll> 
                </ListItem>  
                
                <ListItem className={classes2.listItemWeb} style={{order:"4"}}>
                <CustomDropdown2 
                    noLiPadding
                    navDropdown
                    buttonText="Para Você"
                    buttonProps={{
                      className: classes2.navLink,
                      color: "transparent",
            
                    }}
                    dropdownList={[
                        <div className={classes2.tamanhoMenu}> 
                            <MenuNavPillsPravc></MenuNavPillsPravc>
                        </div>
                    ]}>
                    </CustomDropdown2>
                </ListItem>

                <ListItem className={classes2.listItemWeb} style={{order:"4"}}>
                <CustomDropdown3 
                    noLiPadding
                    navDropdown
                    buttonText="Para Empresas"
                    buttonProps={{
                      className: classes2.navLink,
                      color: "transparent",
            
                    }}
                    dropdownList={[

                    <div className={classes2.tamanhoMenu}> 
                        <MenuNavPillsPraEmp></MenuNavPillsPraEmp>
                    </div>
                    ]}>
                    </CustomDropdown3>
                </ListItem>
              
                <ListItem className={classes2.listItemMobile} style={{order:"4"}}>
                <CustomDropdown
                    noLiPadding
                    navDropdown
                    buttonText="Para Você"
                    buttonProps={{
                      className: classes2.navLink,
                      color: "transparent"
                    }}
                    dropdownList={[
                      
                      <Link onClick= {handleDrawerToggle}  style={{textDecoration: "none", display:"flex", justifyContent:"space-between" }}/* to ="/consorcios" onClick={() => handleCloseMenu(prop)} */ className={classes2.dropdownLink}><span style={{marginRight:"10px", }}><BarChartIcon  style={{marginRight:"10px", verticalAlign:"middle",  }}></BarChartIcon> Investimentos</span><span  className={classes2.badgesome}><Badge color="gray">Em breve</Badge></span></Link>,
                      {divider: true},
                      <Link onClick= {handleDrawerToggle}  style={{textDecoration: "none", display:"flex", justifyContent:"space-between" }} to ="/emprestimos" className={classes2.dropdownLink}><span style={{marginRight:"10px", }}><MonetizationOnIcon style={{marginRight:"10px", verticalAlign:"middle",  }}></MonetizationOnIcon>Empréstimos</span></Link>,
                      <Link onClick= {handleDrawerToggle}  style={{textDecoration: "none", display:"flex", justifyContent:"space-between" }} to ="/seguros" className={classes2.dropdownLink}><span style={{marginRight:"10px", }}><BeachAccessIcon style={{marginRight:"10px", verticalAlign:"middle",  }}></BeachAccessIcon>Seguros</span></Link>,
                      <Link onClick= {handleDrawerToggle}  style={{textDecoration: "none", display:"flex", justifyContent:"space-between" }} to ="/cambio" className={classes2.dropdownLink}><span style={{marginRight:"10px", }}><AttachMoney  style={{marginRight:"10px", verticalAlign:"middle",  }}></AttachMoney> Câmbio</span><span className={classes2.badgesome}><Badge color="gray">Em breve</Badge></span></Link>,
                      <Link onClick= {handleDrawerToggle}  style={{textDecoration: "none", display:"flex", justifyContent:"space-between" }} to ="/consorcios"  className={classes2.dropdownLink}><span style={{marginRight:"10px", }}><BeenhereIcon  style={{marginRight:"10px", verticalAlign:"middle",  }}></BeenhereIcon> Consórcios</span><span  className={classes2.badgesome}><Badge color="gray">Em breve</Badge></span></Link>,
                      <Link onClick= {handleDrawerToggle}  style={{textDecoration: "none", display:"flex", justifyContent:"space-between" }}/* to ="/consorcios" onClick={() => handleCloseMenu(prop)} */ className={classes2.dropdownLink}><span style={{marginRight:"10px", }}><ShoppingCart  style={{marginRight:"10px", verticalAlign:"middle",  }}></ShoppingCart> Shopping</span><span  className={classes2.badgesome}><Badge color="gray">Em breve</Badge></span></Link>,

       
                      
                    ]}>
                    </CustomDropdown>
                </ListItem>
                <ListItem className={classes2.listItem2Mobile} style={{order:"5"}}>
                  <CustomDropdown
                    noLiPadding
                    navDropdown
                    buttonText="Para Empresas"
                    buttonProps={{
                      className: classes2.navLink,
                      color: "transparent"
                    }}
                  
                    dropdownList={[
                    

                      <Link onClick= {handleDrawerToggle} style={{textDecoration: "none", display:"flex", justifyContent:"space-between" }} /* to ="/consorcios" onClick={() => handleCloseMenu(prop)} */ className={classes2.dropdownLink}><span style={{marginRight:"10px", }}><BarChartIcon  style={{marginRight:"10px", verticalAlign:"middle",  }}></BarChartIcon> Investimentos</span></Link>,
                      {divider: true},
                      <Link onClick= {handleDrawerToggle} style={{textDecoration: "none", display:"flex", justifyContent:"space-between" }} to ="/emprestimos" className={classes2.dropdownLink}><span style={{marginRight:"10px", }}><MonetizationOnIcon style={{marginRight:"10px", verticalAlign:"middle",  }}></MonetizationOnIcon>Empréstimos</span></Link>,
                      <Link onClick= {handleDrawerToggle} style={{textDecoration: "none", display:"flex", justifyContent:"space-between" }} to ="/seguros" className={classes2.dropdownLink}><span style={{marginRight:"10px", }}><BeachAccessIcon style={{marginRight:"10px", verticalAlign:"middle",  }}></BeachAccessIcon>Seguros</span></Link>,
                      <Link onClick= {handleDrawerToggle} style={{textDecoration: "none", display:"flex", justifyContent:"space-between" }} to ="/cambio" className={classes2.dropdownLink}><span style={{marginRight:"10px", }}><AttachMoney  style={{marginRight:"10px", verticalAlign:"middle",  }}></AttachMoney> Câmbio</span><span  className={classes2.badgesome}><Badge color="gray">Em breve</Badge></span></Link>,
                      <Link onClick= {handleDrawerToggle} style={{textDecoration: "none", display:"flex", justifyContent:"space-between"}}  to ="/consorcios"  className={classes2.dropdownLink}><span style={{marginRight:"10px", }}><BeenhereIcon  style={{marginRight:"10px", verticalAlign:"middle",  }}></BeenhereIcon> Consórcios</span><span  className={classes2.badgesome}><Badge color="gray">Em breve</Badge></span></Link>,
            
   

                    
                    ]}
                  />
                </ListItem>
                <ListItem className={classes2.listItem} style={{order:"6"}}>
                  <Button
                    href="https://fitticomvoce.com.br"
                    color="transparent"
                    target="_blank" 
                    className={classes2.navLink}
                  >Blog
                  </Button>
                </ListItem>
                <ListItem className={classes2.listItem} style={{order:"7"}}>
                  {/*<Tooltip title="Delete">
                    <IconButton aria-label="Delete">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>*/}
                  <Tooltip
                    id="linkedin-tooltip"
                    title="Siga-nos no LinkedIn"
                    placement={window.innerWidth > 959 ? "top" : "left"}
                    classes={{ tooltip: classes2.tooltip }}>
                    <Button
                      href="https://linkedin.com/company/fittiinvestimentos/"
                      target="_blank"
                      color="transparent"
                      className={classes2.navLink}>
                      <i className={classes2.socialIcons + " fab fa-linkedin"} />
                      <Typography className={classes2.socialnames1} >
                          /company/contafitti
                      </Typography>
                    </Button>
                  </Tooltip>
                </ListItem>
                <ListItem className={classes2.listItem} style={{order:"8"}}>
                  <Tooltip
                    id="facebook-tooltip"
                    title="Siga-nos no Facebook"
                    placement={window.innerWidth > 959 ? "top" : "left"}
                    classes={{ tooltip: classes2.tooltip }}>
                    <Button
                      color="transparent"
                      href="https://www.facebook.com/fittiinvestimentos"
                      target="_blank"
                      className={classes2.navLink}>
                      <i className={classes2.socialIcons + " fab fa-facebook"} />
                      <Typography className={classes2.socialnames1} >
                          /contafitti
                      </Typography>
                    </Button>
                  </Tooltip>
                </ListItem>
                <ListItem className={classes2.listItem} style={{order:"9"}}>
                  <Tooltip
                    id="instagram-tooltip"
                    title="Siga-nos no Instagram"
                    placement={window.innerWidth > 959 ? "top" : "left"}
                    classes={{ tooltip: classes2.tooltip }}>
                    <Button
                      color="transparent"
                      href="https://www.instagram.com/fittiinvestimentos/"
                      target="_blank"
                      className={classes2.navLink}> 
                      <i className={classes2.socialIcons + " fab fa-instagram"} />
                      <Typography className={classes2.socialnames1} >
                          @contafitti
                      </Typography>
                    </Button>
                  </Tooltip>
                </ListItem>
                <ListItem className={classes2.listItem} style={{order:"1"}} >
                <SignUp></SignUp>
                </ListItem>
                <ListItem className={classes2.listItem} style={{order:"2"}}>
                  <Login></Login>
                </ListItem>
              </List>
            );
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
   
  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "fitticor",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "fitticor",
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};

