import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
//import Dashboard from "@material-ui/icons/Dashboard";
//import Schedule from "@material-ui/icons/Schedule";
//import List from "@material-ui/icons/List";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPillsMenu from "components/NavPills/NavPillsMenu.js";
import pillsStyle from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";
import Badge from "components/Badge/Badge.js";

import ShoppingCart from "@material-ui/icons/ShoppingCart";
import AttachMoney from "@material-ui/icons/AttachMoney";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import BarChartIcon from '@material-ui/icons/BarChart';
import PaymentIcon from '@material-ui/icons/Payment';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
//import FastfoodIcon from '@material-ui/icons/Fastfood';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';



// @material-ui icons
//import Subject from "@material-ui/icons/Subject";
//import WatchLater from "@material-ui/icons/WatchLater";
// core components
import Card from "components/Card/CardPro.js";
//import CardBody from "components/Card/CardBodyPro.js";
import Button from "components/CustomButtons/Button.js";

import cardsStyle from "assets/jss/material-kit-react/views/componentsSections/sectionCards.js";
//Typography
import Typography from '@material-ui/core/Typography';

//Imagens
import MenuContaDigitalPF from 'images/MenuContaDigitalPF.jpg';
import MenuContaDigitalEstudante from 'images/MenuContaDigitalEstudante.jpg';
import MenuEmprestimos from 'images/MenuEmprestimos.jpg';
import MenuFinImob from 'images/MenuFinImob.jpg';
import MenuEmpPessoal from 'images/MenuEmpPessoal.jpg';
import MenuEmpConsg from 'images/MenuEmpConsg.jpg';
import MenuHomeEquity from 'images/MenuHomeEquity.jpg';
import MenuSeguros from 'images/MenuSeguros.jpg';
import MenuCambio from 'images/MenuCambio.jpg';
import MenuShopping from 'images/MenuShopping.jpg';
import MenuMeiosPagamentos from 'images/MenuMeiosPagamentos.jpg';
import MenuInvestimentos from 'images/MenuInvestimentos.jpg';
import MenuConsorcio from 'images/MenuConsorcio.jpg';
import MenuConsorcioServico from 'images/MenuConsorcioServico.jpg';
import MenuConsorcioImobiliario from 'images/MenuConsorcioImobiliario.jpg';
import MenuConsorcioAUTO from 'images/MenuConsorcioAUTO.jpg';
import MenuConsorcioMOTO from 'images/MenuConsorcioMOTO.jpg';
import MenuSaudePrincipal from 'images/MenuSaudePrincipal.jpg';
import MenuSaudeConsultasTele from 'images/MenuSaudeConsultasTele.jpg';
import MenuSaudeAgendamentoExames from 'images/MenuSaudeAgendamentoExames.jpg';
import MenuSaudePlanos from 'images/MenuSaudePlanos.jpg';
import MenuSaudeRemediosEFarm from 'images/MenuSaudeRemediosEFarm.jpg';

import { Link } from 'react-router-dom'
//import { useCounter } from "components/CustomDropdown/CustomDropdownPro2.js";

const style1 = {
  ...cardsStyle,
};

const useStyles1 = makeStyles(style1);
const useStyles = makeStyles(pillsStyle);



export default function SectionPills() {

const classes1 = useStyles1();
const classes = useStyles();

//const [ anchorEl, handleClick1, handleClose1, handleCloseMenu1 ] = useCounter()

  
  return (
    <div className={classes.MenuConteudoPills}>
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="navigation-pills">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <NavPillsMenu
                horizontal={{
                  tabsGrid: { xs: 12, sm: 4, md: 4 },
                  contentGrid: { xs: 12, sm: 8, md: 8 },
                }}
                color="success"
                tabs={[
                  {
                    tabButton: <span style={{width:"100%", height:"25px", display:"inline-flex", justifyContent:"space-between" }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><PhoneIphoneIcon  style={{marginRight:"10px", verticalAlign:"middle",  }}></PhoneIphoneIcon>Isso é um teste</span><span style={{marginInlineStart: "auto"}}  className={classes.badgesome}><Badge color="gray">Em breve</Badge></span><ArrowForwardIosIcon fontSize="small" style={{fontSize: 12, marginLeft:"10px", verticalAlign:"middle", height: "auto",}}></ArrowForwardIosIcon></span>,
                    tabContent: (
                      <div style={{height:"500px", width:"98%", overflow:"hidden", marginTop: "-30px"}}>
                            <Link style={{textDecoration: "none"}}  to ="/" /* onClick={() => handleCloseMenu1()}*/ >
                                <Card 
                                  background
                                  className={classes1.buttonCardMenu}
                                  style={{
                                  backgroundImage: `url(${MenuContaDigitalPF})`
                                  }}>
                                  <GridContainer  /*background*/  style={{width:"inherit"}}>
                                        <GridItem style={{alignText:"left", padding: "90px 40px 5px", zIndex: "2"}}>
                                            <div>
                                                <Typography style={{float:"left", fontWeight:"bold", fontSize:"18px"}} >
                                                  Conta Digital Pessoa Física
                                                </Typography>
                                            </div>
                                        </GridItem>
                                        <GridItem style={{display:"flex", justifyContent:"space-between", alignText:"left", padding: "10px 40px 20px", zIndex: "2"}}>
                                            <div>
                                                <Typography style={{fontSize:"14px", textAlign: "left", lineHeight: "1.2", color:"#fff!Important"}}>
                                                  Conta Digital para Pessoas Físicas 100% gratuita.<br/>
                                                  Em breve você poderá fazer tudo<br/>
                                                  pelo aplicativo, inclusive contratar  diversos<br/>
                                                  produtos e serviços financeiros e não financeiros.
                                                </Typography> 
                                            </div>
                                            <div>
                                                <Button className={classes1.buttonContaDigitalPF} color="success" round>
                                                  SAIBA MAIS
                                                </Button>
                                            </div>
                                        </GridItem>
                                  </GridContainer>
                                </Card>
                            </Link>
                            <Link style={{textDecoration: "none"}} to ="/">
                                <Card 
                                  background
                                  className={classes1.buttonCardMenu}
                                  style={{
                                  backgroundImage: `url(${MenuContaDigitalEstudante})`
                                  }}>
                                  <GridContainer  /*background*/  style={{width:"inherit"}}>
                                        <GridItem style={{alignText:"left", padding: "90px 40px 5px", zIndex: "2"}}>
                                            <div>
                                                <Typography style={{float:"left", fontWeight:"bold", fontSize:"18px"}} >
                                                  Conta Digital Estudante
                                                </Typography>
                                            </div>
                                        </GridItem>
                                        <GridItem style={{display:"flex", justifyContent:"space-between", alignText:"left", padding: "10px 40px 20px", zIndex: "2"}}>
                                            <div>
                                                <Typography style={{fontSize:"14px", textAlign: "left", lineHeight: "1.2"}}>
                                                  Conta Digital para Estudantes 100% gratuita.<br/>
                                                  Em breve você poderá fazer tudo pelo app, além<br/>
                                                  de obter descontos e cashback, inclusive contratar<br/>
                                                  diversos produtos e serviços financeiros e não financeiros.
                                                </Typography> 
                                            </div>
                                            <div>
                                                <Button className={classes1.buttonContaDigitalEstudante}  color="success" round>
                                                  SAIBA MAIS
                                                </Button>
                                            </div>
                                        </GridItem>
                                  </GridContainer>
                                </Card>
                            </Link>
                      </div>
                    ),
                  },
                  {
                    tabButton:  <span style={{width:"100%", display:"inline-flex", justifyContent:"space-between", }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><MonetizationOnIcon style={{marginRight:"10px", verticalAlign:"middle",  }}></MonetizationOnIcon>Empréstimos</span><ArrowForwardIosIcon fontSize="small" style={{fontSize: 12, float:"right", verticalAlign:"middle", height: "auto", }}></ArrowForwardIosIcon></span>,
                    tabContent: (
                      <div style={{display:"inline-flex"}}>
                      <div style={{height:"500px", width:"45%", overflow:"hidden", marginTop: "-30px", marginRight:"15px"}}>
                      <Card 
                        background
                        className={classes1.buttonCardMenuFittiEmprestimos}
                        style={{
                        backgroundImage: `url(${MenuEmprestimos})`
                        }}>
                        <GridContainer  /*background*/  style={{width:"inherit"}}>
                              <GridItem className={classes1.buttonCardMenuFittiEmpreQuadrinho}>
                                  <div>
                                      <Typography style={{fontWeight:"bold", fontSize:"16px"}} >
                                        Fitti<br/>
                                        Empréstimos
                                      </Typography>
                                  </div>
                              </GridItem>
                              <GridItem style={{display:"flex", justifyContent:"center", alignText:"center", padding: "5px 40px 158px", zIndex: "2", marginLeft:"15px"}}>
                    
                                  <div>
                                      <Button style={{width:"100px", height:"30px"}} color="success" round>
                                        ACESSAR
                                      </Button>
                                  </div>
                              </GridItem>
                        </GridContainer>
                      </Card>
                      </div>
                      <div style={{display:"Grid", justifyItems: "center"}}> 
                      <div style={{display:"flex", justifyContent:"flex-start", height:"280px", width:"100%", overflow:"hidden", marginTop: "-30px", marginBottom: "-14px"}}>
                          <Card 
                            background
                            className={classes1.buttonCardMenu2}
                            style={{
                              backgroundImage: `url(${MenuHomeEquity })`
                                             }}>
                            <GridContainer  /*background*/  style={{width:"inherit"}}>
                                  <GridItem style={{alignText:"left", padding: "70px 40px 5px", zIndex: "2"}}>
                                      <div>
                                          <Typography className={classes1.textoPrincipalEmprestimos}>
                                            Home Equity/Car Equity<br/>
                                          </Typography>
                                      </div>
                                  </GridItem>
                                  <GridItem style={{display:"block", justifyContent:"left",  textAlign:"left", padding: "10px 40px 20px", zIndex: "2"}}>
                                      <div>
                                          <Typography className={classes1.alinhamento}>
                                            Utilize um imóvel ou Auto como<br/>
                                            garantia no seu empréstimo.<br/>
                                          </Typography> 
                                      </div>
                                      <div style={{float: "left", marginTop: "5px"}}>
                                          <Button className={classes1.buttonEmprestimosGeral} color="success" round>
                                            ACESSAR
                                          </Button>
                                      </div>
                                  </GridItem>
                            </GridContainer>
                          </Card>
                          <Card 
                            background
                            className={classes1.buttonCardMenu2}
                            style={{
                            backgroundImage: `url(${MenuFinImob})`
                           }}>
                            <GridContainer  /*background*/  style={{width:"inherit"}}>
                                  <GridItem style={{alignText:"left", padding: "70px 40px 5px", zIndex: "2"}}>
                                      <div>
                                          <Typography className={classes1.textoPrincipalEmprestimos} >
                                          Financiamento Imobiliário<br/>
                                          </Typography>
                                      </div>
                                  </GridItem>
                                  <GridItem style={{display:"block", justifyContent:"left", textAlign: "left", padding: "10px 40px 20px", zIndex: "2"}}>
                                      <div>
                                          <Typography className={classes1.alinhamento}>
                                            Financie o imóvel comercial ou <br/>
                                            residencial dos seus sonhos.<br/>
                                          </Typography> 
                                      </div>
                                      <div style={{float: "left", marginTop: "5px"}}>
                                          <Button className={classes1.buttonEmprestimosGeral} color="success" round>
                                            ACESSAR
                                          </Button>
                                      </div>
                                  </GridItem>
                            </GridContainer>
                          </Card>
                 </div>
                 <div style={{display:"flex", justifyContent:"flex-start", height:"280px", width:"100%", overflow:"hidden", marginTop:"-35px"}}>
                 <Card 
                   background
                   className={classes1.buttonCardMenu2}
                   style={{
                   backgroundImage: `url(${MenuEmpPessoal})`
                   }}>
                   <GridContainer  /*background*/  style={{width:"inherit"}}>
                         <GridItem style={{alignText:"left", padding: "70px 40px 5px", zIndex: "2"}}>
                             <div>
                                 <Typography className={classes1.textoPrincipalEmprestimos}>
                                   Empréstimo Pessoal (CDC)<br/>
                                   
                                 </Typography>
                             </div>
                         </GridItem>
                         <GridItem style={{display:"block", justifyContent:"left",  textAlign: "left", padding: "10px 40px 20px", zIndex: "2"}}>
                             <div>
                                 <Typography className={classes1.alinhamento}>
                                 Seu empréstimo pessoal<br/>
                                 rápido e fácil.<br/>
                                 </Typography> 
                             </div>
                             <div style={{float: "left", marginTop: "5px"}}>
                                 <Button className={classes1.buttonEmprestimosGeral} color="success" round>
                                   ACESSAR
                                 </Button>
                             </div>
                         </GridItem>
                   </GridContainer>
                 </Card>
                 <Card 
                   background
                   className={classes1.buttonCardMenu2}
                   style={{
                   backgroundImage: `url(${MenuEmpConsg})` 
                   }}>
                   <GridContainer  /*background*/  style={{width:"inherit"}}>
                         <GridItem style={{alignText:"left", padding: "70px 40px 5px", zIndex: "2"}}>
                             <div>
                                 <Typography className={classes1.textoPrincipalEmprestimos}>
                                 Empréstimo Consignado<br/> 
                                 </Typography>
                             </div>
                         </GridItem>
                         <GridItem style={{display:"block", justifyContent:"left", textAlign: "left", padding: "10px 40px 20px", zIndex: "2"}}>
                             <div>
                                 <Typography className={classes1.alinhamento}>
                                 Empréstimo para servidores,<br/>
                                 aposentados e pensionistas.<br/>
                                 </Typography> 
                             </div>
                             <div style={{float: "left", marginTop: "5px"}}>
                                 <Button className={classes1.buttonEmprestimosGeral} color="success" round>
                                   ACESSAR
                                 </Button>
                             </div>
                         </GridItem>
                   </GridContainer>
                 </Card>
              </div>
          </div>
      </div>
                    ),
                  },
                  {
                    tabButton: <span style={{width:"100%", display:"inline-flex", justifyContent:"space-between" }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><BeachAccessIcon style={{marginRight:"10px", verticalAlign:"middle",  }}></BeachAccessIcon>Seguros</span><ArrowForwardIosIcon fontSize="small" style={{fontSize: 12, marginLeft:"10px", verticalAlign:"middle", height: "auto",}}></ArrowForwardIosIcon></span>,
                    tabContent: (
                      <div style={{height:"512px", width:"98%", overflow:"hidden", display:"-webkit-box", marginTop: "-30px"}}>
                      <Card 
                        background
                        className={classes1.buttonCardMenuBox}
                        style={{
                          backgroundImage: `url(${MenuSeguros})` 
                       }}>
                        <GridContainer  /*background*/  style={{width:"inherit"}}>
                              <GridItem style={{alignText:"left", padding: "330px 40px 5px", zIndex: "2"}}>
                                  <div>
                                      <Typography style={{float:"left", fontWeight:"bold", fontSize:"22px"}} >
                                        Seguros
                                      </Typography>
                                  </div>
                              </GridItem>
                              <GridItem style={{display:"flex", justifyContent:"space-between", alignText:"left", padding: "10px 40px 20px", zIndex: "2"}}>
                                  <div>
                                      <Typography style={{fontSize:"14px", textAlign: "left", lineHeight: "1.2", color:"#fff!Important"}}>
                                        Contrate para você, sua família<br/>
                                        ou seu pet diversas modalidades de <br/>
                                        seguros, de maneira muito rápida e fácil.<br/>
                                        Fique protegido(a) de riscos e imprevistos.
                                      </Typography> 
                                  </div>
                                  <div>
                                      <Button className={classes1.buttonContaDigitalPF} color="success" round>
                                        ACESSAR
                                      </Button>
                                  </div>
                              </GridItem>
                        </GridContainer>
                      </Card>
                </div>
                    ),
                  },
                  {
                    tabButton: <span style={{width:"100%", display:"inline-flex", justifyContent:"space-between" }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><AttachMoney  style={{marginRight:"10px", verticalAlign:"middle",  }}></AttachMoney> Câmbio</span><span style={{marginInlineStart: "auto"}}  className={classes.badgesome}><Badge color="gray">Em breve</Badge></span><ArrowForwardIosIcon fontSize="small" style={{fontSize: 12, marginLeft:"10px", verticalAlign:"middle", height: "auto",}}></ArrowForwardIosIcon></span>,
                    tabContent: (
                      <div style={{height:"512px", width:"98%", overflow:"hidden", display:"-webkit-box", marginTop: "-30px"}}>
                      <Card 
                        background
                        className={classes1.buttonCardMenuBox}
                        style={{
                          backgroundImage: `url(${MenuCambio})` 
                        }}>
                        <GridContainer  /*background*/  style={{width:"inherit"}}>
                              <GridItem style={{alignText:"left", padding: "330px 40px 5px", zIndex: "2"}}>
                                  <div>
                                      <Typography style={{float:"left", fontWeight:"bold", fontSize:"22px"}} >
                                        Câmbio
                                      </Typography>
                                  </div>
                              </GridItem>
                              <GridItem style={{display:"flex", justifyContent:"space-between", alignText:"left", padding: "10px 40px 20px", zIndex: "2"}}>
                                  <div>
                                      <Typography style={{fontSize:"14px", textAlign: "left", lineHeight: "1.2", color:"#fff!Important"}}>
                                        Compre mais de 20 moedas<br/>
                                        estrangeiras em espécie e retire em <br/>
                                        pontos comerciais de parceiros em todo o <br/>
                                        Brasil ou solicite nosso delivery em sua residência*.
                                      </Typography> 
                                  </div>
                                  <div>
                                      <Button className={classes1.buttonContaDigitalPF} color="success" round>
                                        ACESSAR
                                      </Button>
                                  </div>
                              </GridItem>
                        </GridContainer>
                      </Card>
                </div>
                    ),
                  },
                  {
                    tabButton: <span style={{width:"100%", display:"inline-flex", justifyContent:"space-between" }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><BeenhereIcon  style={{marginRight:"10px", verticalAlign:"middle",  }}></BeenhereIcon> Consórcios</span><span style={{marginInlineStart: "auto"}}   className={classes.badgesome}><Badge color="gray">Em breve</Badge></span><ArrowForwardIosIcon fontSize="small" style={{fontSize: 12, marginLeft:"10px", verticalAlign:"middle", height: "auto",}}></ArrowForwardIosIcon></span>,
                    tabContent: (
                      <div style={{display:"inline-flex"}}>
                      <div style={{height:"500px", width:"45%", overflow:"hidden", marginTop: "-30px", marginRight:"15px", marginBottom: "-14px"}}>
                      <Link style={{textDecoration: "none"}} to ="/consorcios">
                          <Card 
                            background
                            className={classes1.buttonCardMenuFittiEmprestimos}
                            style={{
                            backgroundImage: `url(${MenuConsorcio})` 
                            }}>
                            <GridContainer  /*background*/  style={{width:"inherit"}}>
                                  <GridItem className={classes1.buttonCardMenuFittiConsQuadrinho}>
                                      <div>
                                          <Typography style={{fontWeight:"bold", fontSize:"18px"}} >
                                            Consórcios
                                          </Typography>
                                      </div>
                                  </GridItem>
                                  <GridItem style={{display:"flex", justifyContent:"center", alignText:"center", padding: "5px 40px 180px", zIndex: "2", marginLeft:"15px"}}>
                        
                                      <div>
                                          <Button style={{width:"100px", height:"30px"}} color="success" round>
                                            ACESSAR
                                          </Button>
                                      </div>
                                  </GridItem>
                            </GridContainer>
                          </Card>
                      </Link>
                      </div>
                      <div style={{display:"Grid", justifyItems: "center"}}> 
                      <div style={{display:"flex", justifyContent:"flex-start", height:"280px", width:"100%", overflow:"hidden", marginTop: "-30px", marginBottom: "-18px"}}>
                          <Card 
                            background
                            className={classes1.buttonCardMenu2}
                            style={{
                             backgroundImage: `url(${MenuConsorcioAUTO})` 
                            }}>
                            <GridContainer  /*background*/  style={{width:"inherit"}}>
                                  <GridItem style={{alignText:"left", padding: "70px 40px 5px", zIndex: "2"}}>
                                      <div>
                                          <Typography className={classes1.textoPrincipalEmprestimos}>
                                            Consórcio Auto<br/>
                                          </Typography>
                                      </div>
                                  </GridItem>
                                  <GridItem style={{display:"block", justifyContent:"left",  textAlign:"left", padding: "10px 40px 20px", zIndex: "2"}}>
                                      <div>
                                          <Typography className={classes1.alinhamento}>
                                            A maneira mais acessível<br/>
                                            para adquirir o seu automóvel.<br/>
                                          </Typography> 
                                      </div>
                                      <div style={{float: "left", marginTop: "5px"}}>
                                          <Button className={classes1.buttonEmprestimosGeral} color="success" round>
                                            ACESSAR
                                          </Button>
                                      </div>
                                  </GridItem>
                            </GridContainer>
                          </Card>
                          <Card 
                            background
                            className={classes1.buttonCardMenu2}
                            style={{
                            backgroundImage: `url(${MenuConsorcioMOTO})` 
                            }}>
                            <GridContainer  /*background*/  style={{width:"inherit"}}>
                                  <GridItem style={{alignText:"left", padding: "70px 40px 5px", zIndex: "2"}}>
                                      <div>
                                          <Typography className={classes1.textoPrincipalEmprestimos} >
                                         Consórcio Moto
                                          </Typography>
                                      </div>
                                  </GridItem>
                                  <GridItem style={{display:"block", justifyContent:"left", textAlign: "left", padding: "10px 40px 20px", zIndex: "2"}}>
                                      <div>
                                          <Typography className={classes1.alinhamento}>
                                            O jeito mais acessível <br/>
                                            para adquirir a sua moto.<br/>
                                          </Typography> 
                                      </div>
                                      <div style={{float: "left", marginTop: "5px"}}>
                                          <Button className={classes1.buttonEmprestimosGeral} color="success" round>
                                            ACESSAR
                                          </Button>
                                      </div>
                                  </GridItem>
                            </GridContainer>
                          </Card>
                 </div>
                 <div style={{display:"flex", justifyContent:"flex-start", height:"280px", width:"100%", overflow:"hidden", marginTop:"-30px"}}>
                 <Card 
                   background
                   className={classes1.buttonCardMenu2}
                   style={{
                    backgroundImage: `url(${MenuConsorcioImobiliario})` 
                   }}>
                   <GridContainer  /*background*/  style={{width:"inherit"}}>
                         <GridItem style={{alignText:"left", padding: "70px 40px 5px", zIndex: "2"}}>
                             <div>
                                 <Typography className={classes1.textoPrincipalEmprestimos}>
                                   Consórcio Imobiliário
                                 </Typography>
                             </div>
                         </GridItem>
                         <GridItem style={{display:"block", justifyContent:"left",  textAlign: "left", padding: "10px 40px 20px", zIndex: "2"}}>
                             <div>
                                 <Typography className={classes1.alinhamento}>
                                 Seu sonho de ter um imóvel<br/>
                                 de maneira acessível.<br/>
                                 </Typography> 
                             </div>
                             <div style={{float: "left", marginTop: "5px"}}>
                                 <Button className={classes1.buttonEmprestimosGeral} color="success" round>
                                   ACESSAR
                                 </Button>
                             </div>
                         </GridItem>
                   </GridContainer>
                 </Card>
                 <Card 
                   background
                   className={classes1.buttonCardMenu2}
                   style={{
                    backgroundImage: `url(${MenuConsorcioServico})` 
                    }}>
                   <GridContainer  /*background*/  style={{width:"inherit"}}>
                         <GridItem style={{alignText:"left", padding: "70px 40px 5px", zIndex: "2"}}>
                             <div>
                                 <Typography className={classes1.textoPrincipalEmprestimos}>
                                 Consórcio de serviços
                                 </Typography>
                             </div>
                         </GridItem>
                         <GridItem style={{display:"block", justifyContent:"left", textAlign: "left", padding: "10px 40px 20px", zIndex: "2"}}>
                             <div>
                                 <Typography className={classes1.alinhamento}>
                                 Use p/ procedimentos estéticos,<br/>
                                 reformas, educação e viagens.<br/>
                                 </Typography> 
                             </div>
                             <div style={{float: "left", marginTop: "5px"}}>
                                 <Button className={classes1.buttonEmprestimosGeral} color="success" round>
                                   ACESSAR
                                 </Button>
                             </div>
                         </GridItem>
                   </GridContainer>
                 </Card>
              </div>
          </div>
      </div>
                    ),
                  },
                  {
                    tabButton: <span style={{width:"100%", display:"inline-flex", justifyContent:"space-between" }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><ShoppingCart  style={{marginRight:"10px", verticalAlign:"middle",  }}></ShoppingCart> Shopping</span><span style={{marginInlineStart: "auto"}}   className={classes.badgesome}><Badge color="gray">Em breve</Badge></span><ArrowForwardIosIcon fontSize="small" style={{fontSize: 12, marginLeft:"10px", verticalAlign:"middle", height: "auto",}}></ArrowForwardIosIcon></span>,
                    tabContent: (
                      <div style={{height:"512px", width:"98%", overflow:"hidden", display:"-webkit-box", marginTop: "-30px"}}>
                      <Card 
                        background
                        className={classes1.buttonCardMenuBox}
                        style={{
                          backgroundImage: `url(${MenuShopping})` 
                        }}>
                        <GridContainer  /*background*/  style={{width:"inherit"}}>
                              <GridItem style={{alignText:"left", padding: "330px 40px 5px", zIndex: "2"}}>
                                  <div>
                                      <Typography style={{float:"left", fontWeight:"bold", fontSize:"22px"}} >
                                        Shopping
                                      </Typography>
                                  </div>
                              </GridItem>
                              <GridItem style={{display:"flex", justifyContent:"space-between", alignText:"left", padding: "10px 40px 20px", zIndex: "2"}}>
                                  <div>
                                      <Typography style={{fontSize:"14px", textAlign: "left", lineHeight: "1.2", color:"#fff!Important"}}>
                                        Encontre diversos produtos com<br/>
                                        descontos e cashback em um só lugar.<br/>
                                        Receba descontos e dinheiro de volta em<br/>
                                        cada compra, assinatura ou contratação de serviços.<br/>
                                      </Typography> 
                                  </div>
                                  <div>
                                      <Button className={classes1.buttonContaDigitalPF} color="success" round>
                                        ACESSAR
                                      </Button>
                                  </div>
                              </GridItem>
                        </GridContainer>
                      </Card>
                </div>
                    ),
                  },
                  {
                    tabButton: <span style={{width:"100%", display:"inline-flex", justifyContent:"space-between" }} target="" className={classes.dropdownLink}><span className={classes1.meiosDePagMenuIpod}><PaymentIcon  style={{marginRight:"10px", verticalAlign:"middle",  }}></PaymentIcon> Meios de pagamentos</span><span style={{marginInlineStart: "auto"}} className={classes.badgesome}><Badge color="gray">Em breve</Badge></span><ArrowForwardIosIcon fontSize="small" style={{fontSize: 12, marginLeft:"10px", verticalAlign:"middle", height: "auto",}}></ArrowForwardIosIcon></span>,
                    tabContent: (
                      <div style={{height:"512px", width:"98%", overflow:"hidden", display:"-webkit-box", marginTop: "-30px"}}>
                      <Card 
                        background
                        className={classes1.buttonCardMenuBox}
                        style={{
                          backgroundImage: `url(${MenuMeiosPagamentos})` 
                        }}>
                        <GridContainer  /*background*/  style={{width:"inherit"}}>
                              <GridItem style={{alignText:"left", padding: "330px 40px 5px", zIndex: "2"}}>
                                  <div>
                                      <Typography style={{float:"left", fontWeight:"bold", fontSize:"22px"}}>
                                        Meios de Pagamentos
                                      </Typography>
                                  </div>
                              </GridItem>
                              <GridItem style={{display:"flex", justifyContent:"space-between", alignText:"left", padding: "10px 40px 20px", zIndex: "2"}}>
                                  <div>
                                      <Typography style={{fontSize:"14px", textAlign: "left", lineHeight: "1.2", color:"#fff!Important"}}>
                                        Utilize nossas ferramentas<br/>
                                        de meios de pagamentos para realizar<br/>
                                        vendas de produtos e serviços, receber,<br/>
                                        processar pagamentos, recorrências e transações.
                                      </Typography> 
                                  </div>
                                  <div>
                                      <Button className={classes1.buttonContaDigitalPF} color="success" round>
                                        ACESSAR
                                      </Button>
                                  </div>
                              </GridItem>
                        </GridContainer>
                      </Card>
                </div>
                    ),
                  },
                  {
                    tabButton: <span style={{width:"100%", display:"inline-flex", justifyContent:"space-between" }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><BarChartIcon  style={{marginRight:"10px", verticalAlign:"middle",  }}></BarChartIcon> Investimentos</span><span style={{marginInlineStart: "auto"}}   className={classes.badgesome}><Badge color="gray">Em breve</Badge></span><ArrowForwardIosIcon fontSize="small" style={{fontSize: 12, marginLeft:"10px", verticalAlign:"middle", height: "auto",}}></ArrowForwardIosIcon></span>,
                    tabContent: (
                      <div style={{height:"512px", width:"98%", overflow:"hidden", display:"-webkit-box", marginTop: "-30px"}}>
                      <Card 
                        background
                        className={classes1.buttonCardMenuBox}
                        style={{
                          backgroundImage: `url(${MenuInvestimentos})` 
                        }}>
                        <GridContainer  /*background*/  style={{width:"inherit"}}>
                              <GridItem style={{alignText:"left", padding: "330px 40px 5px", zIndex: "2"}}>
                                  <div>
                                      <Typography style={{float:"left", fontWeight:"bold", fontSize:"22px"}} >
                                        Investimentos
                                      </Typography>
                                  </div>
                              </GridItem>
                              <GridItem style={{display:"flex", justifyContent:"space-between", alignText:"left", padding: "10px 40px 20px", zIndex: "2"}}>
                                  <div>
                                      <Typography style={{fontSize:"14px", textAlign: "left", lineHeight: "1.2", color:"#fff!Important"}}>
                                        Através da nossa plataforma<br/>
                                        de investimentos, obtenha acesso <br/>
                                        á diversos produtos de renda fixa e<br/>
                                        renda variável com corretagem zero e cashback.
                                      </Typography> 
                                  </div>
                                  <div>
                                      <Button className={classes1.buttonContaDigitalPF} color="success" round>
                                        ACESSAR
                                      </Button>
                                  </div>
                              </GridItem>
                        </GridContainer>
                      </Card>
                </div>
                    ),
                  },
                  {
                    tabButton:  <span style={{width:"100%", display:"inline-flex", justifyContent:"space-between" }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><LocalHospitalIcon  style={{marginRight:"10px", verticalAlign:"middle",  }}></LocalHospitalIcon > Fitti Saúde</span><span style={{marginInlineStart: "auto"}}   className={classes.badgesome}><Badge color="gray">Em breve</Badge></span><ArrowForwardIosIcon fontSize="small" style={{fontSize: 12, marginLeft:"10px", verticalAlign:"middle", height: "auto",}}></ArrowForwardIosIcon></span>,
                    tabContent: (
                      <div style={{display:"inline-flex"}}>
                      <div style={{height:"500px", width:"45%", overflow:"hidden", marginTop: "-30px", marginRight:"15px"}}>
                      <Card 
                        background
                        className={classes1.buttonCardMenuFittiEmprestimos}
                        style={{
                          backgroundImage: `url(${MenuSaudePrincipal})`  
                        }}>
                        <GridContainer  /*background*/  style={{width:"inherit"}}>
                              <GridItem className={classes1.buttonCardMenuFittiConsQuadrinho}>
                                  <div>
                                      <Typography style={{fontWeight:"bold", fontSize:"18px"}} >
                                        Fitti Saúde
                                      </Typography>
                                  </div>
                              </GridItem>
                              <GridItem style={{display:"flex", justifyContent:"center", alignText:"center", padding: "5px 40px 180px", zIndex: "2", marginLeft:"15px"}}>
                    
                                  <div>
                                      <Button style={{width:"100px", height:"32px"}} color="success" round>
                                        ACESSAR
                                      </Button>
                                  </div>
                              </GridItem>
                        </GridContainer>
                      </Card>
                      </div>
                      <div style={{display:"Grid", justifyItems: "center"}}> 
                      <div style={{display:"flex", justifyContent:"flex-start", height:"280px", width:"100%", overflow:"hidden", marginTop: "-30px", marginBottom: "-16px"}}>
                          <Card 
                            background
                            className={classes1.buttonCardMenu2}
                            style={{
                            backgroundImage: `url(${MenuSaudeConsultasTele})` 
                            }}>
                            <GridContainer  /*background*/  style={{width:"inherit"}}>
                                  <GridItem style={{alignText:"left", padding: "70px 40px 5px", zIndex: "2"}}>
                                      <div>
                                          <Typography className={classes1.textoPrincipalEmprestimos}>
                                            Consultas por telemedicina
                                          </Typography>
                                      </div>
                                  </GridItem>
                                  <GridItem style={{display:"block", justifyContent:"left",  textAlign:"left", padding: "10px 40px 20px", zIndex: "2"}}>
                                      <div>
                                          <Typography className={classes1.alinhamento}>
                                            Consultas por telemedicina com<br/>
                                            diversos especialistas.
                                          
                                          </Typography> 
                                      </div>
                                      <div style={{float: "left", marginTop: "5px"}}>
                                          <Button className={classes1.buttonEmprestimosGeral} color="success" round>
                                            ACESSAR
                                          </Button>
                                      </div>
                                  </GridItem>
                            </GridContainer>
                          </Card>
                          <Card 
                            background
                            className={classes1.buttonCardMenu2}
                            style={{
                              backgroundImage: `url(${MenuSaudeAgendamentoExames})` 
                            }}>
                            <GridContainer  /*background*/  style={{width:"inherit"}}>
                                  <GridItem style={{alignText:"left", padding: "70px 40px 5px", zIndex: "2"}}>
                                      <div>
                                          <Typography className={classes1.textoPrincipalEmprestimos} >
                                         Agendamento de Exames
                                          </Typography>
                                      </div>
                                  </GridItem>
                                  <GridItem style={{display:"block", justifyContent:"left", textAlign: "left", padding: "10px 40px 20px", zIndex: "2"}}>
                                      <div>
                                          <Typography className={classes1.alinhamento}>
                                            Agende exames laboratoriais <br/>
                                            e exames de imagem.
                                          </Typography> 
                                      </div>
                                      <div style={{float: "left", marginTop: "5px"}}>
                                          <Button className={classes1.buttonEmprestimosGeral} color="success" round>
                                            ACESSAR
                                          </Button>
                                      </div>
                                  </GridItem>
                            </GridContainer>
                          </Card>
                 </div>
                 <div style={{display:"flex", justifyContent:"flex-start", height:"280px", width:"100%", overflow:"hidden", marginTop:"-30px"}}>
                 <Card 
                   background
                   className={classes1.buttonCardMenu2}
                   style={{
                   backgroundImage: `url(${MenuSaudePlanos})`
                   }}>
                   <GridContainer  /*background*/  style={{width:"inherit"}}>
                         <GridItem   style={{alignText:"left", padding: "70px 40px 5px", zIndex: "2"}}>
                             <div>
                                 <Typography className={classes1.textoPrincipalEmprestimos}>
                                   Planos de Saúde
                                 </Typography>
                             </div>
                         </GridItem>
                         <GridItem style={{display:"block", justifyContent:"left",  textAlign: "left", padding: "10px 40px 20px", zIndex: "2"}}>
                             <div>
                                 <Typography className={classes1.alinhamento}>
                                 Contrate o melhor plano de saúde<br/>
                                 entre as operadoras parceiras.
                                 </Typography> 
                             </div>
                             <div style={{float: "left", marginTop: "5px"}}>
                                 <Button className={classes1.buttonEmprestimosGeral} color="success" round>
                                   ACESSAR
                                 </Button>
                             </div>
                         </GridItem>
                   </GridContainer>
                 </Card>
                 <Card 
                   background
                   className={classes1.buttonCardMenu2}
                   style={{
                   backgroundImage: `url(${MenuSaudeRemediosEFarm})` 
                  }}>
                   <GridContainer  /*background*/  style={{width:"inherit"}}>
                         <GridItem  style={{alignText:"left", padding: "70px 40px 5px", zIndex: "2"}}>
                             <div>
                                 <Typography className={classes1.textoPrincipalEmprestimos}>
                                 Remédios e Farmácia
                                 </Typography>
                             </div>
                         </GridItem>
                         <GridItem style={{display:"block", justifyContent:"left", textAlign: "left", padding: "10px 40px 20px", zIndex: "2"}}>
                             <div>
                                 <Typography className={classes1.alinhamento}>
                                 Remédios e itens de farmácia.<br/>
                                 Compre e receba em casa.
                                 </Typography> 
                             </div>
                             <div style={{float: "left", marginTop: "5px"}}>
                                 <Button className={classes1.buttonEmprestimosGeral} color="success" round>
                                   ACESSAR
                                 </Button>
                             </div>
                         </GridItem>
                   </GridContainer>
                 </Card>
              </div>
          </div>
      </div>
                    ),
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  </div>
  );
}
