import React from "react";
import { Link } from 'react-router-dom';
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
//import LocationOn from "@material-ui/icons/LocationOn";
// core components
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
//import Card from "components/Card/Card.js";
import styles from "assets/jss/material-kit-react/views/components.js";
//CSS Slick Carousel
import "slick-carousel/slick/slick.css"; 
//import "slick-carousel/slick/slick-theme.css"; 

import Button from "components/CustomButtons/Button.js";

//JQuery
//import $ from "jquery"

//Typography
import Typography from '@material-ui/core/Typography';
//Styled
import styled, {keyframes} from 'styled-components';
import CardsButtonsEmprestimosHomeCarEquity from './CardsButtonsEmprestimosHomeCarEquity'
import CardsButtonsEmprestimosPessoalCDC from './CardsButtonsEmprestimosPessoalCDC'
import CardsButtonsEmprestimosConsignado from './CardsButtonsEmprestimosConsignado'
import CardsButtonsEmprestimosFinImobiliario from './CardsButtonsEmprestimosFinImobiliario'
import CardsButtonsEmprestimosCrediarioCDC from './CardsButtonsEmprestimosCrediarioCDC'
import CardsButtonsEmprestimosFinAuto from './CardsButtonsEmprestimosFinAuto'




//import GridItem from "components/Grid/GridItem";


//import carouselStyle from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";
//import image1 from "assets/img/bg.jpg";
//import image2 from "assets/img/bg2.jpg";
//import image3 from "assets/img/bg3.jpg";

//import Slide from '@material-ui/core/Slide';


const useStyles = makeStyles(styles);
//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
  animation-delay: 1.5s;
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
  transform: translatey(0px);
  animation: ${float} 6s ease-in-out infinite;
  `;


//functions da arrow

const Prev = styled.button`
    color: "black";
    min-width: 60px;
    height: 60px;
    border-radius: 20px;
    background: #fff;
    border: 0px;
    outline: none;
    cursor: pointer;
    z-index: 200;
    margin: 10px;
    box-shadow: 5px 2px 8px 5px rgba(0,0,0,.10);
    transition: all .25s ease;
    font-Size: 1.6rem;  
    position: absolute;
    top: 50%;
    display: block;
    left:  -4.0vw;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    @media (max-width:780px) {  
        //min-Width: 0.5vw;
        border-radius: 8px;
        //margin: 1vw;
        //left: -1.0vw;
        };

    &:hover {
  
    transform: translate(0, -10px);
    box-shadow: 0px 17px 35px 0px rgba(0,0,0,.07);
    }

 `;
 const Next = styled.button`
    color: "black";
    min-width: 60px;
    height: 60px;
    border-radius: 20px;
    background: #fff;
    border: 0px;
    outline: none;
    cursor: pointer;
    z-index: 200;
    margin: 10px;
    box-shadow: 5px 2px 8px 5px rgba(0,0,0,.10);
    transition: all .25s ease;
    font-Size: 1.6rem;  
    display: block; 
    position: absolute;
    top: 50%;
    right: -1.8vw;
    display: block;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    @media (max-width:780px) {  
        //min-Width: 0.5vw;
        border-radius: 8px;
        margin: 0.02vw;
        };
    @media (max-width:375px) {  
    right: -1.5vw;
    };

    @media (max-width:361px) {  
    //right: -5.5vw;
    right: 2vw;
    };

    @media (max-width:321px) {  
    //right: -5.5vw;
    right: -.9vw;
    };

    &:hover {
  
    transform: translate(0, -10px);
    box-shadow: 0px 17px 35px 0px rgba(0,0,0,.07);
    }
 `;


export default function SectionCarousel() {
  const classes = useStyles();
  

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    nextArrow: <Next><i className="material-icons">keyboard_arrow_right</i></Next>,
    prevArrow: <Prev><i className="material-icons">keyboard_arrow_left</i></Prev>,
 
    responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 765,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },

        {
          breakpoint: 360,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },

        {
          breakpoint: 300,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ],
  
    appendDots: dots => (
        <div
          style={{ backgroundColor:"rgb(242, 243, 248"}}>
          <ul className={classes.customPagingDots}> {dots} </ul>
        </div>
      ),
    customPaging: i => (
      <Button size="sm" type="button" color="white" round>
          {i + 1}
      </Button>
      )

     

    };

  return (
   <div  className={classes.section} id="carousel">
      <div className={classes.container2}>
        <div>
      <Animation1>
                <Typography className={classes.titleanimatedbuttons}>
                    Escolha o seu Empréstimo
                </Typography> 
     </Animation1>
     </div>
     <div>
          <Carousel  {...settings}>
                <div className={classes.outline1}>
                  <Link style={{textDecoration: "none"}} to ="/emprestimos/homecarequity">
                      <CardsButtonsEmprestimosHomeCarEquity></CardsButtonsEmprestimosHomeCarEquity>
                  </Link>   
                </div >
                <div className={classes.outline1}>
                  <Link style={{textDecoration: "none"}} to ="/emprestimos/pessoalcdc"> 
                      <CardsButtonsEmprestimosPessoalCDC></CardsButtonsEmprestimosPessoalCDC>
                  </Link>
                </div>
                <div className={classes.outline1}>
                  <Link style={{textDecoration: "none"}} to ="/emprestimos/crediariocdc">
                      <CardsButtonsEmprestimosCrediarioCDC></CardsButtonsEmprestimosCrediarioCDC>
                  </Link>
                </div>
                <div className={classes.outline1}>
                  <Link style={{textDecoration: "none"}} to ="/emprestimos/consignado">
                      <CardsButtonsEmprestimosConsignado></CardsButtonsEmprestimosConsignado>
                  </Link>
                </div>
                <div className={classes.outline1}>
                  <Link style={{textDecoration: "none"}} to ="/emprestimos/finimobiliario">
                      <CardsButtonsEmprestimosFinImobiliario></CardsButtonsEmprestimosFinImobiliario>
                  </Link>
                </div>
                   <div className={classes.outline1}>
                  <Link style={{textDecoration: "none"}} to ="/emprestimos/finimobiliario">
                      <CardsButtonsEmprestimosFinAuto></CardsButtonsEmprestimosFinAuto>
                  </Link>
                </div>


                
            </Carousel>
         </div>
      </div>
    </div>
  );
}