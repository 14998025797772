import React from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// react components for routing our app without refresh
//import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
//import Header from "components/Header/Header.js";
import Footer2 from "components/Footer/Footer2";
//import ButtonsArea from "components/ButtonsArea/ButtonsArea";
//import ButtonsAreaAnime from "components/ButtonsArea/ButtonsAreaAnime.jsx";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
//import Parallax from "components/Parallax/Parallax.js";
// sections for this page
//import HeaderLinks from "components/Header/HeaderLinks.js";
//import SectionNavbarsNOVO from "./Sections/SectionNavbarsNOVO.js";
//Scrool Smooth site
//import { SmoothProvider } from 'react-smooth-scrolling'

import styles from "assets/jss/material-kit-react/views/components.js";
import ButtonsAreaAnime from "components/ButtonsArea/ButtonsAreaAnime";

//Efeitos
import {Typewriter} from 'react-simple-typewriter';
//import 'react-simple-typewriter/dist/index.css';

//Typography
import Typography from '@material-ui/core/Typography';

//Imagens
//import Logo from 'images/logo-fitticontadigital.svg';


//import Cardeapp from 'images/cardeapp.png';

//Container
//import Container from '@material-ui/core/Container';
//import Grid from '@material-ui/core/Grid';

//Animations
import AnimationUpDown from '../../../views/Components/Sections/AnimationUpDown';


//Css degrade Fitticolor
//import Fittidegrade from './Sections/Fittidegrade.js';

//Css
//Animations
import ArrowAnimatedButton from '../../../views/Components/Sections/ArrowAnimatedButton';
//import buttonStyle from "assets/jss/material-kit-react/components/buttonStyle.js";
//import "components/Appcss/App.css";

//JQuery
//import $ from "jquery"
//scroll
//import {animateScroll as scroll} from 'react-scroll';
//import { Fade } from "@material-ui/core";
//import anime from "animejs/lib/anime.es.js";

//Animação do cartão
//import Slide from '@material-ui/core/Slide';
import Servicos from 'components/Servicos/Servicos.jsx';
import Accordion from  'components/Accordion/Accordion.jsx';
import NavPillsPro from  'components/NavPills/NavPillsPro.jsx';
import VideoArea from  'components/VideoArea/VideoArea.jsx';
import CartoesFitti from 'components/CartoesFitti/CartoesFitti.jsx';


//import {BrowserRouter as Router} from 'react-router-dom';
//import Menu from '../../components/MenuViews/Menu';
//import Content from '../../components/MenuViews/Content';

const useStyles = makeStyles(styles);

export default function MenuContaDigital(props) {
  const classes = useStyles();
  //const { ...rest } = props;
  

  return (     
       <section  className={classes.appcss}>
            <div name="topo" id="topo" className={classes.sectionTop} >
                    <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={6}>
                        <div >
                            <Typography  className={classes.title}  color="initial">
                                A Conta Digital
                            </Typography>
                            <Typography className={classes.title}  color="initial">
                                    que dá Fitti com 
                                    <br></br><span style={{ color: "#3f3f3f", fontWeight: 'bold' }}> <Typewriter
                                        loop
                                        cursor
                                        cursorStyle='|'
                                        typeSpeed={70}
                                        deleteSpeed={50}
                                        delaySpeed={1000}
                                        words={['você!', 'a sua empresa!', 'seus sonhos!', 'o seu negócio!']}>
                                    </Typewriter></span>
                            </Typography> 
                            <Typography className={classes.subtitulo}>
                                    Em breve você poderá contar com a Conta Digital Fitti para Pessoas Físicas, MEI´s e PJ´s, além de uma série de produtos digitais como Empréstimos, Seguros, Câmbio, Meios de Pagamentos, entre outros.
                            </Typography>
                        </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <AnimationUpDown>
                            </AnimationUpDown>
                        </GridItem>
                    </GridContainer>
                    <div className={classes.arrowanimatedbuttons}>
                        <ArrowAnimatedButton>
                        </ArrowAnimatedButton>
                    </div>
            </div>
            <div name="section2" id="section2" style={{background: "rgb(242, 243, 248)", minHeight:"700px"}}>
                <ButtonsAreaAnime>
                </ButtonsAreaAnime>
            </div>
            <div name="section2" id="section2" style={{background: "#fff", minHeight:"700px"}}>
                <Servicos>
                </Servicos>
            </div>
            <div name="NavPills" id="NavPills" style={{background: "rgb(242, 243, 248)"}}>
                <NavPillsPro>
                </NavPillsPro>
            </div>
            <div name="section3" id="section3" style={{background: "#fff"}}>
                <CartoesFitti>
                </CartoesFitti>
            </div>

            <div name="ViddeoArea" id="VideoArea" style={{background: "rgb(242, 243, 248)"}}>
                <VideoArea>
                </VideoArea>
            </div>
            <div>
                <GridContainer className={classes.container} style={{minHeight:"517px"}}>
                    <Accordion>
                    </Accordion>
                </GridContainer>
            </div>
            <div name="rodape" id="rodape" >
                <Footer2>
                </Footer2>
            </div>
        </section>

  );
}