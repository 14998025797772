
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import HorizontalScroll from "react-scroll-horizontal";
//import Button from "components/CustomButtons/Button.js";
//import Checkbox from "@material-ui/core/Checkbox";
// material-ui icons
//import Check from "@material-ui/icons/Check";
// core components
//import Table from "components/Tables/Table";

//import CardFooter from "components/Card/CardFooterPro.js";


import style from "assets/jss/material-kit-react/views/componentsSections/contentAreas.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import style1 from "assets/jss/material-kit-react/views/components.js";
import Typography from '@material-ui/core/Typography';
import styled, { keyframes } from 'styled-components';
//import Car from "@material-ui/icons/DirectionsCarOutlined";
//import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
//import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
//import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
//import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
//import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
//import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
//import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
//import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
//import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';

//import styles from "assets/jss/material-kit-react/views/components.js";
//import { makeStyles } from "@material-ui/core/styles";
//import Slide from '@material-ui/core/Slide';
//import EmprestimoCarHomeEquity from 'images/emprestimohomeequity.png'
import EmprestimoHomeEquitySolo from 'images/homeEquitySolo.png'

import { Fade } from '@material-ui/core';
import SmoothImage from 'react-smooth-image';
//import videoImac from 'videos/clipeImac.mp4';
//import ReactPlayer from 'react-player/lazy';

import Check from '@material-ui/icons/Check';
//import Badge from "components/Badge/Badge.js";


//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;

const useStyles = makeStyles(style);
const useStyles1 = makeStyles(style1);


export default function Tables() {



  const classes = useStyles();
  const classes1 = useStyles1(style1);



  return (
    <GridContainer id="container-tabs" className={classes1.container} style={{display: "flex", justifyContent: "center"}}>
    <div className={classes1.container2}>
        <div className={classes1.espacoslistas}>
                    <Animation1>
                        <Typography className={classes1.titleanimatedbuttons2}>
                        Saiba quais são os requisitos do imóvel dado como garantia
                        </Typography>
                    </Animation1>
                  
                <GridContainer className={classes.container} style={{display: "flex", justifyContent: "center", marginTop:"40px"}}>
                    
                    <GridItem xs={12} sm={12} md={6} className={classes1.tableHomeCarEequity2}>
                         <Typography className={classes1.tableHomeCarEequityTitleSolo1}>
                                Como ofereço meu imóvel ou terreno como garantia?
                         </Typography>
                         <List>
                            <Typography  className={classes.letteraccordion}>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Você poderá ofertar em garantia o seu apartamento, casa em condomínio, casa fora de condomínio, ponto comercial, sala comercial, laje corporativa, terreno em condomínio e outros, desde que o imóvel seja de titularidade do proponente (ou em município limítrofe).</span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Você poderá continuar morando no imóvel, mesmo após receber o dinheiro do empréstimo.</span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Serão aceitos imóveis em construção, porém será considerado o valor do terreno para fins de garantia.</span>   
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Imóveis com avaliação de risco ambiental, risco de finalidade social, risco de eventual execução judicial, risco de qualquer tipo de ônus, risco de caracterização de vício de construção grave, imóvel rural com qualquer finalidade agrícola, imóvel com risco de execução de credores, não serão aceitos como garantia.</span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Serão aceitos imóveis com parte da área de construção não averbada, porém o LTV poderá sofrer atualização durante a análise.</span>
                              </ListItem>
                            </Typography>
                          </List> 
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} className={classes1.tableHomeCarEequity1}>           
                            <Fade  direction="up" in={true} timeout={{ appear: 3000, enter: 3000, exit: 3000,}} mountOnEnter unmountOnExit>
                                <div className={classes.imgAnimation}>
                                        <SmoothImage containerStyles={{height:"180px", marginBottom:"75px", overflow:"visible", paddingBottom:"0", display: "grid", justifyContent: "center"}} imageStyles={{position:"relative", height: "auto", overflow:"visible",  marginTop:"5%", marginBottom:"10%",  paddingBottom:"0" }}
                                            src={EmprestimoHomeEquitySolo} alt='Home Equity Fitti' transitionTime={1.0}  transitionTimeFunction={"ease-in"}>
                                        </SmoothImage>
                                </div>
                            </Fade>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
  </GridContainer >
  );
}