import React from "react";
import * as Scroll from 'react-scroll'
//import { Link, animateScroll as scroll } from 'react-scroll';
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/components/headerLinksStylePro.js";


const useStyles = makeStyles(styles);

export default function Ofitti(props) {
  let LinkScroll = Scroll.Link;
  const classes = useStyles();
  
  return (
  <LinkScroll to="rodape"  smooth={true} duration={1000}>
    <Button
      href="#rodape"
      color="transparent"
      target="" 
      className={classes.navLink}>
        O Fitti 
    </Button>
  </LinkScroll> 

  )
}
