import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
//import LocationOn from "@material-ui/icons/LocationOn";
// core components
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
//import Card from "components/Card/Card.js";
import styles from "assets/jss/material-kit-react/views/components.js";
//Badges dos cards
//import Badge from "components/Badge/Badge.js";
//Typography
//import Typography from '@material-ui/core/Typography';
//Styled
//import styled from 'styled-components';
//import Button from "components/CustomButtons/Button.js";

//import Cambio from 'images/cambio.png';
import CartaoMei from 'images/cartaoMei.png';
import SmoothImage from 'react-smooth-image';

const useStyles = makeStyles(styles);

export default function CardsButtons(props) { 
  const classes = useStyles(styles);

  

  return( 
  <div style={{marginBottom:"50px", marginTop:"90px", outline: "none"}}>
      <div style={{outline: "none", display: "flex", justifyContent:"center"}}>
            <SmoothImage className={classes.cardImg} containerStyles={{width:"250px", marginBottom:"75px", overflow:"visible", paddingBottom:"0" }} imageStyles={{position:"relative", width: "100%", height: "auto", overflow:"visible",  marginTop:"5%", marginBottom:"10%",  paddingBottom:"0" }}
                src={CartaoMei} alt='Cartão Mei - Conta Digital' transitionTime={1.0}  transitionTimeFunction={"ease-in"}>
            </SmoothImage>
           

                   
      </div>
  </div>
         
  );
}
