import React, {useState, useEffect} from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
//import Header from "components/Header/Header.js";
import Footer2 from "components/Footer/Footer2";
//import ButtonsArea from "components/ButtonsArea/ButtonsArea";
//import ButtonsAreaAnime from "components/ButtonsArea/ButtonsAreaAnime.jsx";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
//import Parallax from "components/Parallax/Parallax.js";
// sections for this page
//import HeaderLinks from "components/Header/HeaderLinks.js";
//import SectionNavbarsNOVO from "./Sections/SectionNavbarsNOVO.js";
//Scrool Smooth site
//import { SmoothProvider } from 'react-smooth-scrolling'

import styles from "assets/jss/material-kit-react/views/components.js";
//import ButtonsAreaAnimeConsorcio from "components/ButtonsAreaConsorcios/ButtonsAreaAnimeConsorcio";

//Efeitos
import {Typewriter} from 'react-simple-typewriter';
//import 'react-simple-typewriter/dist/index.css';

//Typography
import Typography from '@material-ui/core/Typography';

//Imagens
//import Logo from 'images/logo-fitticontadigital.svg';


//import Cardeapp from 'images/cardeapp.png';

//Container
//import Container from '@material-ui/core/Container';
//import Grid from '@material-ui/core/Grid';

//Animations
//import AnimationUpDown from '../../../views/Components/Sections/AnimationUpDown';
import AnimationUpDownInvestomentosRetail from  '../../../../../views/Components/Sections/AnimationUpDownInvestomentosRetail.js';


//Css degrade Fitticolor
//import Fittidegrade from './Sections/Fittidegrade.js';

//Css
//Animations
import ArrowAnimatedButton from '../../../../../views/Components/Sections/ArrowAnimatedButton';
//import buttonStyle from "assets/jss/material-kit-react/components/buttonStyle.js";
//import "components/Appcss/App.css";

//JQuery
//import $ from "jquery"
//scroll
//import {animateScroll as scroll} from 'react-scroll';
//import { Fade } from "@material-ui/core";
//import anime from "animejs/lib/anime.es.js";

//Animação do cartão
//import Slide from '@material-ui/core/Slide';
//import Servicos from 'components/Servicos/Servicos.jsx';
import AccordionEmprestimosHomeCarEquity from  'components/Accordion/AccordionEmprestimosHomeCarEquity.jsx';
//import NavPillsPro from  'components/NavPills/NavPillsPro.jsx';
import VideoAreaEmprestimosHomeEquity from  'components/VideoArea/VideoAreaEmprestimosHomeEquity.jsx';
//import CartoesFitti from 'components/CartoesFitti/CartoesFitti.jsx';
//import CardConsorcios from 'components/CardConsorcios/CardConsorcios.jsx';
import CardEmprestimosHomeCarRquity from 'components/CardEmprestimos/CardEmprestimosCarHomeEquity.jsx';
import ServicosEmprestimosHomeCarEquity from 'components/Servicos/ServicosEmprestimosHomeCarEquity.jsx';
import ChartEmprestimosHomeEquity from 'components/Charts/ChartEmprestimosHomeEquity.jsx';
import TableHomeEquity from 'components/Tables/TableHomeEquity.jsx';
import TableHomeEquitySolo from 'components/Tables/TableHomeEquitySolo.jsx';
import TableCarEquitySolo from 'components/Tables/TableCarEquitySolo.jsx';
//import NavPillsProEmpreCarHomeEquity from 'components/NavPills/NavPillsProEmpreCarHomeEquity.jsx';
//import { useLocation } from 'react-router-dom';


//import {BrowserRouter as Router} from 'react-router-dom';
//import Menu from '../../components/MenuViews/Menu';
//import Content from '../../components/MenuViews/Content';
// plugin that creates slider
//import Slider from "nouislider";
//import Nouislider from "nouislider-react";
//import noUiSlider from 'nouislider';
//import "nouislider/distribute/nouislider1.css";
//import wNumb from "wnumb";

// @material-ui/core components
//import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui icons
//import People from '@material-ui/icons/People';
// core components
//import CustomInput from 'components/CustomInput/CustomInput.js';
//import MKInput from "components/Inputs/MKInput.js";
//import InputAdornment from '@mui/material/InputAdornment';


import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import NumberFormat from 'react-number-format';
//import Input from '@mui/material/Input';
//import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
//import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
//import FormControl from '@mui/material/FormControl';
//import FormLabel from '@mui/material/FormLabel';
//Badges dos cards
import Badge from "components/Badge/Badge.js";
import NavPills from "components/NavPills/NavPillsInvestimentos.js";
//import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
//import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import Slider2 from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
//import SliderUnstyled from '@mui/base/SliderUnstyled';
import PaidIcon from '@mui/icons-material/Paid';
import RendaFixIcon from '@mui/icons-material/SignalCellularAlt';
import RendaVarIcon from '@mui/icons-material/Equalizer';
import AporteMensalIcon from '@mui/icons-material/Savings';

//import ModalButtonEmprestimos from './ModalButtonEmprestimos.jsx';
//import SliderHomeEquity from './SliderHomeEquity.jsx';
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components

import modalStyle from "assets/jss/material-kit-react/modalStylePro.js";
//import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
//import AnimatedNumber from 'react-animated-number';

//import Card from "components/Card/Card.js";
//import CardBody from "components/Card/CardBody.js";
//import SliderUnstyled from '@mui/base/SliderUnstyled';
import styled1, { keyframes } from 'styled-components';
import RepeatIcon from '@mui/icons-material/Repeat';
import DoneIcon from '@mui/icons-material/Done';
import CountUp from 'react-countup';
//import Tables from './../../../../Tables/TableConsorcioServicos';
import {animateScroll as scroll} from 'react-scroll';
import * as Scroll from 'react-scroll'
import { QueryStatsRounded } from "@mui/icons-material";
import ChartInvestimentosSimulacaoRendaFixa from 'components/Charts/ChartInvestimentosSimulacaoRendaFixa';

//Itens da renda variável
import  AutoComplete from "@mui/material/Autocomplete";
//import { format } from "date-fns";
import axios from "axios";
import TickerIcon from '@mui/icons-material/Addchart'
import Avatar from '@mui/material/Avatar';
import { CircularProgress } from "@material-ui/core";


const style = theme => ({
  ...modalStyle(theme),
  modalRootExample: {
    "& > div:first-child": {
      display: "none"
    },
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  }
});

const useStyles = makeStyles(styles); //tudo que tiver 2 no modal tirar
const useStyles3 = makeStyles(style); //itens do modal..ligadas ao const classes sem numeração


//Itens do modal
//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled1.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});



//const ariaLabel = { 'aria-label': 'description' };


const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });
  
  TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  
  const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        /* onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }} */
        type="tel"
        allowNegative={false}
        autoComplete="off"
        thousandSeparator="."
        isNumericString
        prefix="R$"
        /* suffix=",00" */
        decimalScale={2}
        fixedDecimalScale={true}
        decimalSeparator=","
        allowLeadingZeros={false}
       
        onValueChange={values => {
          onChange({  target: {
            value: values.value
          },
        });
      } 
    }
        
      />
    );
  });
  
  NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  

 //APIS da parte de Renda Variável:
  const ALPHA_VANTAGE_API_KEY = 'RXD6KESNQNFT6D5Q'; // Substitua com sua chave de API válida
  const NINJAS_API_KEY = 'k5dcXBZHTsursY2NAkm6SQ==S0NntmEWfynpg8ZG'; // Substitua com sua chave de API válida para API Ninjas 



  //Inicio do default function 
  export default function MenuInvestimentosRetail(props) {
  const classes = useStyles();
  //const { ...rest } = props;
  let LinkScroll = Scroll.Link;

//INPUT do Valor do Investimento Inicial da renda fixa
  var timeout20;
  const [value20, setValue20] = useState(0)
  const handleChange20 = (event20) => {
    timeout20 && clearTimeout(timeout20);
    timeout20 = setTimeout(()=>{
      console.log('change');
      const inputvalue20 = event20.target.value
       if (inputvalue20 === '') {
        setValue20(0) 
     } else {
        const parsedValue20 = parseFloat(inputvalue20)
        setValue20(isNaN(parsedValue20) ? 0 : parsedValue20)
     }
    }, 1000); 
  };


  //INPUT do Valor do Investimento Inicial da renva variável
  var timeout201;
  const [value201, setValue201] = useState(0)
  const handleChange201 = (event201) => {
    timeout201 && clearTimeout(timeout201);
    timeout201= setTimeout(()=>{
      console.log('change');
      const inputvalue201 = event201.target.value
       if (inputvalue201 === '') {
        setValue201(0) 
     } else {
        const parsedValue201 = parseFloat(inputvalue201)
        setValue201(isNaN(parsedValue201) ? 0 : parsedValue201)
     }
    }, 1000); 
  };

  
    
 

    //Hooks dos states da renda variável
    var timeout202;
    const [ticker, setTicker] = useState(null)
    const handleChange202 = (event202, newValue) => {
      timeout202 && clearTimeout(timeout202);
      timeout202= setTimeout(()=>{
        console.log('change');
        setTicker(newValue)
      }, 100); 
    };

    //Variáveis do Input Autocomplete Renda Vaiável Inputs
    //const tickersList = ['AAPL', 'MSFT','GOOG', 'AMZN','TSLA']

    //const [result, setResult] = useState(null);
    const [tickerOptions, setTickerOptions] = useState([
      { symbol: 'AAPL', name: 'Apple Inc.', logoUrl: 'https://logo.com/aapl.png' },
      { symbol: 'MSFT', name: 'Microsoft Corp.', logoUrl: 'https://logo.com/msft.png' },

    ]); // Para armazenar as sugestões de tickers
   
    var timeout203;
        const [searchQuery, setSearchQuery] = useState('');   // Armazena o que o usuário está digitando
        const handleChange203 = (event203, newInputValue) => {
          timeout203 && clearTimeout(timeout203);
          timeout203 = setTimeout(()=>{
            console.log('change');
            setSearchQuery(newInputValue)
            if (newInputValue === '') {
              setTickerOptions([
                { symbol: 'AAPL', name: 'Apple Inc.', logoUrl: 'https://logo.com/aapl.png' },
                { symbol: 'MSFT', name: 'Microsoft Corp.', logoUrl: 'https://logo.com/msft.png' },
                // Outras opções padrão
              ]); // Resetar para opções padrão quando vazio
            }
          }, 100); 
        };


    const [loading, setLoading] = useState(false); // Adiciona estado para carregar o loader


     // Função para buscar tickers da API Alpha Vantage
  const fetchTickers = async (query) => {
    try {
      setLoading(true); // Ativa o loader
      const response = await axios.get(
        `https://www.alphavantage.co/query?function=SYMBOL_SEARCH&keywords=${query}&apikey=${ALPHA_VANTAGE_API_KEY}`
      );
      const data = response.data.bestMatches;

      const options = await Promise.all(
        data.map(async (item) => {
          const symbol = item['1. symbol'];
          const name = item['2. name'];

          // Busca logo da empresa na API Ninjas
          const logoResponse = await axios.get(
            `https://api.api-ninjas.com/v1/logo?ticker=${symbol}`,
            { headers: { 'X-Api-Key': NINJAS_API_KEY } }
          );
          const logoUrl = logoResponse.data[0]?.image || '';

          return {
            symbol,
            name,
            logoUrl, // Adiciona logo ao objeto
          };
        })
      );
      setTickerOptions(options); // Atualiza o estado
    } catch (error) {
      console.error('Erro ao buscar tickers e logos:', error);
    } finally {
      setLoading(false); //Desativa o loader após carregar dados
    }
  };

  // useEffect para lidar com busca de tickers e abortar em caso de desmontagem
  useEffect(() => {
    let isMounted = true; // Variável para rastrear se o componente está montado

    if (searchQuery.length > 1) {
      const delayDebounceFn = setTimeout(() => {
        fetchTickers(searchQuery).then(() => {
          if (!isMounted) return; // Não atualiza o estado se o componente foi desmontado
        });
      }, 300);

      return () => {
        clearTimeout(delayDebounceFn);
        isMounted = false; // Define como desmontado quando o componente for desmontado
      };
    }
  }, [searchQuery]);

  /*
//HyperText do Input do Crédito
const [valorMinimoEmprestimo, setValorMinimoEmprestimo] = useState(<span>Valor mínimo de R$40.000</span>)
useEffect( () => {
  var timeMinimoEmprestimo
  timeMinimoEmprestimo && clearTimeout(timeMinimoEmprestimo);
  timeMinimoEmprestimo = setTimeout(()=>{
    console.log('change');
    setValorMinimoEmprestimo(value20 > 0 && value20 < 40000 ?  <span style={{color:"red"}}>Valor mínimo de R$40.000</span> : <span>Valor mínimo de R$40.000</span>)
  },500)

}, [value20])

*/
  //INPUT do Valor dos aportes mensais da renda fixa
  var timeout22;
  const [value22, setValue22] = useState(0)
  const handleChange22 = (event22) => {
    timeout22 && clearTimeout(timeout22);
    timeout22 = setTimeout(()=>{
      console.log('change');
     const inputvalue22 = event22.target.value
     if (inputvalue22 === '') {
        setValue22(0) 
     } else {
        const parsedValue22 = parseFloat(inputvalue22)
        setValue22(isNaN(parsedValue22) ? 0 : parsedValue22)
     }
    }, 1000); 
  };

/*
  //HyperText do Input do Imovel

  const valorCondicao = value20 * 2.5
  const [valorMinimoImovel, setValorMinimoImovel] = useState(<span>Valor mínimo de R$40.000</span>)
useEffect( () => {
  function minimoimovel(){ 
    if(value22 > 0 && value22 < valorCondicao){ 
      return(
      <span style={{color:"red"}}>Valor mínimo de R$ {new Intl.NumberFormat('pt-BR').format(valorCondicao)} </span>
      ) 
    } else { 
      return(
      <span>Valor mínimo de R$100.000</span>
      ) 
    }
  };
  var timeMinimoImovel
  timeMinimoImovel && clearTimeout(timeMinimoImovel);
  timeMinimoImovel = setTimeout(()=>{
    console.log('change');
    setValorMinimoImovel(minimoimovel())
  },500)

}, [value22, valorCondicao])

*/

 

  //Itens do radio
  const [selectedValue, setSelectedValue] = React.useState('prefixado');

  const handleChange3 = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange3,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

 
//INPUT do Radio Tesouro prefixado, selic, IPCA
var timeoutRadioTesouro;
const [valueRadioTesouro, setValueRadioTesouro] = useState('prefixado')
const handleChangeRadioTesouro = (eventRadioTesouro) => {
  timeoutRadioTesouro && clearTimeout(timeoutRadioTesouro);
  timeoutRadioTesouro = setTimeout(()=>{
    console.log('change');
    setValueRadioTesouro(eventRadioTesouro.target.value);
  }, 0); 
};


/*
//Item sobre o taxa de juros que aparece no modal
const [tipoTxjuros, setTipoTxJuros] = useState(0.0124)
useEffect( () => {
  var timeoutTipoTxJuros
  timeoutTipoTxJuros && clearTimeout(timeoutTipoTxJuros);
  timeoutTipoTxJuros = setTimeout(()=>{
    console.log('change');
    const proporcaoInputCredito1 = value20 * 3.3334
    setTipoTxJuros(value22 >= proporcaoInputCredito1 ? 0.0124 : 0.0129 )
  },200)

}, [value22, value20]) */

        //Função Titop label
        //Função do Slider da renda fixa com todas as variáveis necessárias
        function valueLabelFormat(value5) {
          const units = ['Meses'];

          return `${value5} ${units[0]}`;
        }
        var timeout;
        const [value5, setValue5] = useState(12)
        const handleChange10 = (event, newValue) => {
          timeout && clearTimeout(timeout);
          timeout = setTimeout(()=>{
            console.log('change');
            setValue5(newValue);
          }, 1000); 
        };

        //Função do Slider da renda variável com todas as variáveis necessárias
        function valueLabelFormat2(value51) {
          const units = ['Meses'];

          return `${value51} ${units[0]}`;
        }
        var timeout;
        const [value51, setValue51] = useState(6)
        const handleChange101 = (event, newValue) => {
          timeout && clearTimeout(timeout);
          timeout = setTimeout(()=>{
            console.log('change');
            setValue51(newValue);
          }, 1000); 
        };
        
        

        const PrettoSlider = styled(Slider2)({
          color: '#52af77',
          height: 8,
          '& .MuiSlider-track': {
            border: 'none',
          },
          '& .MuiSlider-thumb': {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: 'inherit',
            },
            '&:before': {
              display: 'none',
            },
          },
          '& .MuiSlider-valueLabel': {
            lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 90,
            height: 30,
            //borderRadius: '50% 50% 50% 0',
            backgroundColor: '#52af77',
          // transformOrigin: 'bottom left',
            //transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            //'&:before': { display: 'none' },
            //'&.MuiSlider-valueLabelOpen': {
              //transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
            //},
            //'& > *': {
              //transform: 'rotate(45deg)',
            //},
          },
          '& .MuiSlider-markLabel': {
            fontSize: "0.55rem",
            },
        });

        //Marks da renda fixa
        const marks = [
          {
            value:12,
            label: '12',
          },
          {
            value: 24,
            label: '24',
          },
          {
            value: 36,
            label: '36',
          },
          {
            value: 48,
            label: '48',
          },
          {
            value: 60,
            label: '60',
          },
          {
            value: 72,
            label: '72',
          },
            {
            value: 84,
            label: '84',
          },
            {
            value: 96,
            label: '96',
          },
          {
            value: 108,
            label: '108',
          },
            {
            value: 120,
            label: '120',
          },
          {
            value: 132,
            label: '132',
          },
          {
            value: 144,
            label: '144',
          },
          {
            value: 156,
            label: '156',
          },
            {
            value: 168,
            label: '168',
          },
            {
            value: 180,
            label: '180',
          },
            {
            value: 192,
            label: '192',
          },
            {
            value: 204,
            label: '204',
          },
              {
            value: 216,
            label: '216',
          },
        ];

        //Marks da renda variável
        const marks2 = [
          {
            value:6,
            label: '6',
          },
          {
            value: 12,
            label: '12',
          },
          {
            value: 18,
            label: '18',
          },
          {
            value: 24,
            label: '24',
          },
          {
            value: 30,
            label: '30',
          },
          {
            value: 36,
            label: '36',
          },
            {
            value: 42,
            label: '42',
          },
            {
            value: 48,
            label: '48',
          },
          {
            value: 54,
            label: '54',
          },
            {
            value: 60,
            label: '60',
          },
         
        ];
        //Itens do modal de simulação
        const [scrollingModal, setScrollingModal] = React.useState(false);
        const [scrollingModal2, setScrollingModal2] = React.useState(false);
        
        //Itens do useStyles()
        //const classes = useStyles();
        const classes3 = useStyles3();


        //Hooks dos states dos resultados
        const [price, setPrice] = useState(0)
        const [price2, setPrice2] = useState(0)
        const [price3, setPrice3] = useState(0)
        const [price4, setPrice4] = useState(0)
        const [price5, setPrice5] = useState(0)
        const [investTotal, setInvestTotal] = useState(0)
        const [rendTotalCdb, setRendTotalCdb] = useState(0)
        const [rendTotalPrefixado, setRendTotalPrefixado] = useState(0)
        const [rendTotalSelic, setRendTotalSelic] = useState(0)
        const [rendTotalIpca, setRendTotalIpca] = useState(0)
        const [rendTotalPoupanca, setRendTotalPoupanca] = useState(0)

      
        //Variáveis Input Renda Fixa
        const vp = parseFloat(value20)
        const pmt = parseFloat(value22)
        //const pmt2 = parseFloat(value22/21)
        //const pmt3 = parseFloat(value22/30)
        const n = parseFloat(value5)
        const n2 = parseFloat(value5*21)
        const n3 = parseFloat(value5*30)

        const calculadorIR = value5 === 12 ? parseFloat(20/100) : 
                            (value5 > 12 && value5 <= 24) ? parseFloat(17.5/100) :
                            (value5 > 24) ? parseFloat(15/100) :
                            parseFloat(0)
             
        //Variáveis Taxas CDI
        const Cdi =  parseFloat(11.5)
        const iCdiAno = Cdi/100
        const iCdiDiario = (Math.pow((1 + iCdiAno), 1/252))-1
        const iCdiMes = (Math.pow((1 + iCdiDiario), 22))-1
        
         //Variáveis InvestimentoTotal
        const investimentoTotal = parseFloat(value20) + (parseFloat(value22) * parseFloat(value5))
      
        //Variáveis Taxas CDB
        const rentCdi = parseFloat(150/100)
        const iCdbDiario = iCdiDiario * rentCdi
        const iCdbMes = (Math.pow((1 + iCdbDiario), 22))-1
        
        //Variáveis resultado CDB
        const resultadoCdb = ((vp*(Math.pow((1 + iCdbDiario), n2))) + pmt*((((Math.pow((1 + iCdbMes), n))-1)/iCdbMes)))
        const rendimentoTotalCdb = resultadoCdb-investimentoTotal
        const irCdb = rendimentoTotalCdb * calculadorIR
        const rendimentoLiquidoCdb = rendimentoTotalCdb - irCdb
        const resultadoLiquidoCdb =  rendimentoLiquidoCdb + investimentoTotal
        const taxaRetornoCdb = ((resultadoLiquidoCdb/investimentoTotal)-1)*100
       
        //Variáveis Taxas LC
        const rentLC = parseFloat(90/100)
        //const iLciMes = iCdiMes * rentLC
        const iLciDiario = iCdiDiario * rentLC
        const iLciMes =  (Math.pow((1 + iLciDiario), 22))-1
        //Variáveis resultado LC
        const resultadoLC = ((vp*(Math.pow((1 + iLciDiario), n2))) + pmt*((((Math.pow((1 + iLciMes), n))-1)/iLciMes)))
        const rendimentoTotalLC = resultadoLC-investimentoTotal
        const taxaRetornoLC = ((resultadoLC/investimentoTotal)-1)*100
      


        //Variáveis Taxas Tesouro Direto Prefixado
        //const rentPre = 100/100
        const iPre = parseFloat(11.6/100)
        const iPreDiaria = (Math.pow((1 + iPre), 1/252))-1
        const iPreMes = (Math.pow((1 + iPreDiaria), 22))-1
        
        //Variáveis resultado Selic
        const resultadoTesouroPrefixado = ((vp*(Math.pow((1 + iPreDiaria), n2))) + pmt*((((Math.pow((1 + iPreMes), n))-1)/iPreMes)))
        const rendimentoTotalTesouroPrefixado = resultadoTesouroPrefixado-investimentoTotal
        const irTesouroPrefixado = rendimentoTotalTesouroPrefixado * calculadorIR
        const rendimentoLiquidoTesouroPrefixado = rendimentoTotalTesouroPrefixado - irTesouroPrefixado
        const resultadoLiquidoTesouroPrefixado =  rendimentoLiquidoTesouroPrefixado + investimentoTotal
        const taxaRetornoTesouroPrefixado = ((resultadoLiquidoTesouroPrefixado/investimentoTotal)-1)*100

        //Variáveis Taxas Tesouro Direto Selic
        const rentSelic = parseFloat(100/100)
        const selic = parseFloat(10.5/100)
        const selicDia = (Math.pow((1 + selic), 1/252))-1
        const selicMes = (Math.pow((1 + selicDia), 22))-1
        
        //Variáveis resultado Selic
        const resultadoTesouroSelic = ((vp*(Math.pow((1 + selicDia), n2))) + pmt*((((Math.pow((1 + selicMes), n))-1)/selicMes)))
        const rendimentoTotalTesouroSelic = resultadoTesouroSelic-investimentoTotal
        const irTesouroSelic = rendimentoTotalTesouroSelic * calculadorIR
        const rendimentoLiquidoTesouroSelic = rendimentoTotalTesouroSelic - irTesouroSelic
        const resultadoLiquidoTesouroSelic =  rendimentoLiquidoTesouroSelic + investimentoTotal
        const taxaRetornoTesouroSelic = ((resultadoLiquidoTesouroSelic/investimentoTotal)-1)*100

        //Variáveis Taxas Tesouro Direto IPCA+
        const iPlus = parseFloat(6.15/100)
        const IPCA = parseFloat(4.5/100)
        const IPCADiario = (Math.pow((1 + IPCA), 1/360))-1
        const IPCAMes = (Math.pow((1 + IPCADiario), 30))-1
        const iPlusDiario = (Math.pow((1 + iPlus), 1/252))-1
        const iPlusMes = (Math.pow((1 + iPlusDiario), 22))-1
        const IPCAiPLUSMes = IPCAMes+iPlusMes
        //const IPCAiPLUSDiario = IPCADiario+iPlusDiario
        const IPCAiPLUS = IPCA + iPlus 

        //Variáveis resultado Selic
        const captizalicaoTesouroIPCAIPlus = ((vp*(Math.pow((1 + iPlusDiario), n2))) + pmt*((((Math.pow((1 + iPlusMes), n))-1)/iPlusMes)))
        const capitalizacaoTesouroIPCAIPCA = ((vp*(Math.pow((1 + IPCADiario ), n3))) + pmt*((((Math.pow((1 + IPCAMes  ), n))-1)/IPCAMes)))
        const resultadoTesouroIPCATotal = (captizalicaoTesouroIPCAIPlus + capitalizacaoTesouroIPCAIPCA)-investimentoTotal 
        const rendimentoTotalTesouroIPCA =  resultadoTesouroIPCATotal-investimentoTotal
        const irTesouroIPCA = rendimentoTotalTesouroIPCA * calculadorIR
        const rendimentoLiquidoTesouroIPCA = rendimentoTotalTesouroIPCA - irTesouroIPCA
        const resultadoLiquidoTesouroIPCA = rendimentoLiquidoTesouroIPCA + investimentoTotal
        const taxaRetornoTesouroIPCA = ((resultadoLiquidoTesouroIPCA/investimentoTotal)-1)*100
     

        //Poupanca = Taxa selic igual ou inferior que 8.5% = 70% da selic
        //           Taxa selic maior que 8.5%, fica 0.5% + TR
        const iPoupanca = parseFloat(0.5/100)
        const tr =  parseFloat(6.74/100)
        const iPoupancaValida = selic >= (8.5/100) ? (iPoupanca+tr) : (selic*parseFloat(0.7))
        const iPoupancaValidaMes = (Math.pow((1 + iPoupancaValida), 1/12))-1
        //Variáveis resultado Poupanca
        const resultadoPoupanca = ((vp*(Math.pow((1 + iPoupancaValidaMes ), n))) + pmt*((((Math.pow((1 + iPoupancaValidaMes ), n))-1)/iPoupancaValidaMes )))
        const rendimentoTotalPoupanca = resultadoPoupanca-investimentoTotal
        
        

        const simulacaoRendaFixa = () => {
          //setPrice(price + ((10000)/((((1+0.125)^5)-1)/(0.125*((1+0.125)^5)))) )
          //const variacaoTxCreditoBruto = ((((value20 - 40000)/1000)*(-0.0005))+0.1560235)
          //const txCreditoBruto = value20 ===  40000  ?  0.1560235 : variacaoTxCreditoBruto
          if(value20 ==="" && value22=== "") {
            setScrollingModal(false) 
            setScrollingModal2(true) 
          } else if(value20 <= 0 && value22 <= 0) {
            setScrollingModal(false) 
            setScrollingModal2(true)
          } else { 
            setPrice(price + resultadoLiquidoCdb)
            setPrice2(price2 + resultadoLC)
            setPrice3(price3 + resultadoLiquidoTesouroPrefixado )
            setPrice4(price4 + resultadoLiquidoTesouroSelic)
            setPrice5(price5 + resultadoLiquidoTesouroIPCA)

            setInvestTotal(investTotal + investimentoTotal)
            
            setRendTotalCdb(rendTotalCdb + rendimentoTotalCdb)
            setRendTotalPrefixado(rendTotalPrefixado + rendimentoLiquidoTesouroPrefixado)
            setRendTotalSelic(rendTotalSelic + rendimentoLiquidoTesouroSelic)
            setRendTotalIpca(rendTotalIpca + rendimentoLiquidoTesouroIPCA )
            setRendTotalPoupanca(rendTotalPoupanca + rendimentoTotalPoupanca)

            setCountUptipoPrice(mudaPriceTesouro)  
            setScrollingModal(true)
            }
          
        }

        //Para desligar o modal e zerar o calculo
          const simulacaoRendaFixaRestart = () => {
          //setPrice(price + ((10000)/((((1+0.125)^5)-1)/(0.125*((1+0.125)^5)))) )
          setPrice(price - price)
          setPrice2(price2 - price2)
          setPrice3(price3 - price3)
          setPrice4(price4 - price4)
          setPrice5(price5 - price5)
          setInvestTotal(investTotal - investTotal)
          setRendTotalCdb(rendTotalCdb - rendTotalCdb)
          setRendTotalPrefixado(rendTotalPrefixado - rendTotalPrefixado)
          setRendTotalSelic(rendTotalSelic - rendTotalSelic)
          setRendTotalIpca(rendTotalIpca - rendTotalIpca)
          setRendTotalPoupanca(rendTotalPoupanca - rendTotalPoupanca)
          setScrollingModal(false)
        }

            const simulacaoRendaFixaClose = () => {
          //setPrice(price + ((10000)/((((1+0.125)^5)-1)/(0.125*((1+0.125)^5)))) )
          setPrice(price - price)
          setPrice2(price2 - price2)
          setPrice3(price3 - price3)
          setPrice4(price4 - price4)
          setPrice5(price5 - price5)
          setInvestTotal(investTotal - investTotal)
          setRendTotalCdb(rendTotalCdb - rendTotalCdb)
          setRendTotalPrefixado(rendTotalPrefixado - rendTotalPrefixado)
          setRendTotalSelic(rendTotalSelic - rendTotalSelic)
          setRendTotalIpca(rendTotalIpca - rendTotalIpca)
          setRendTotalPoupanca(rendTotalPoupanca - rendTotalPoupanca)
          setScrollingModal2(false)
        }


    //Item sobre tipo de Tesouro Calculo - Montante principal que aparece no modal com mudança do radio
        const mudaPriceTesouro = ()=>{
          if(valueRadioTesouro === 'prefixado'){ 
            return(
              <CountUp end={price3}
                    className={classes.titlePriceRendaFixa}
                    formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                    frameStyle={perc => ( {opacity: perc/100} )}
                    decimals={2}
                    decimal=","
                ></CountUp>
            ) 
          } else if (valueRadioTesouro === 'selic') { 
            return(
              <CountUp end={price4}
                    className={classes.titlePriceRendaFixa}
                    formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                    frameStyle={perc => ( {opacity: perc/100} )}
                    decimals={2}
                    decimal=","
                ></CountUp>
            ) 
          } else { 
             return(
                <CountUp end={price5}
                    className={classes.titlePriceRendaFixa}
                    formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                    frameStyle={perc => ( {opacity: perc/100} )}
                    decimals={2}
                    decimal=","
                ></CountUp> 
            ) 
          }
         }
        

      //Item sobre tipo de Tesouro - Calculo Montante principal que aparece no modal com mudança do radio
       const [countUptipoPrice, setCountUptipoPrice] = useState(null)

        useEffect( () => {
          setCountUptipoPrice(mudaPriceTesouro())                          
        }, [])

        useEffect( () => {
            setCountUptipoPrice(mudaPriceTesouro())                          
        }, [valueRadioTesouro, price3, price4, price5])



      //Item sobre tipo de Tesouro - Calculo Rentabilidade principal que aparece no modal com mudança do radio
        const mudaRentTesouro = ()=>{
          if(valueRadioTesouro === 'prefixado'){ 
            return(
              
                <CountUp end={rendTotalPrefixado}
                  style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                  formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                  frameStyle={perc => ( {opacity: perc/100} )}
                  decimals={2}
                  decimal=","
                ></CountUp>
             
            ) 
          } else if (valueRadioTesouro === 'selic') { 
            return(
              
              <CountUp end={rendTotalSelic}
                    style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                    formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                    frameStyle={perc => ( {opacity: perc/100} )}
                    decimals={2}
                    decimal=","
                ></CountUp>
           
            ) 
          } else { 
            return(
              
                <CountUp end={rendTotalIpca}
                    style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                    formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                    frameStyle={perc => ( {opacity: perc/100} )}
                    decimals={2}
                    decimal=","
                ></CountUp>
             
            ) 
          }
         }

        //Item sobre tipo de Tesouro - Calculo Rentabilidade que aparece no modal com mudança do radio
        const [countUptipoRent, setCountUptipoRent] = useState(null)

        useEffect( () => {
          setCountUptipoRent(mudaRentTesouro())                          
        }, [])

        useEffect( () => {
            setCountUptipoRent(mudaRentTesouro())                          
        }, [valueRadioTesouro, rendTotalPrefixado, rendTotalSelic, rendTotalIpca])



        //Item sobre tipo de Tesouro - Calculo Taxa principal que aparece no modal com mudança do radio
        const mudaTaxaTesouro = ()=>{
          if(valueRadioTesouro === 'prefixado'){ 
            return(
              <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", textAlign:"center", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>Rentabilidade prefixada de {(iPre*100).toFixed(0).replace(".", ",")}% a.a ou de {(iPreMes*100).toFixed(2).replace(".", ",")}% a.m, c/ retorno líquido de {taxaRetornoTesouroPrefixado.toFixed(2).replace(".", ",")}% a.p.</div>
            ) 
          } else if (valueRadioTesouro === 'selic') { 
            return(
              <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", textAlign:"center", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>{(rentSelic*100).toFixed(0).replace(".", ",")}% da selic de {(selic*100).toFixed(1).replace(".", ",")}% a.a ou de {(selicMes*100).toFixed(2).replace(".", ",")}% a.m, c/ retorno líquido de {taxaRetornoTesouroSelic.toFixed(2).replace(".", ",")}% a.p.</div>
            ) 
          } else { 
            return(
              <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", textAlign:"center", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>IPCA de {(IPCA*100).toFixed(1).replace(".", ",")}% a.a + juros prefixados de {(iPlus*100).toFixed(2).replace(".", ",")}% a.a, totalizando {(IPCAiPLUS*100).toFixed(2).replace(".", ",")}% a.a ou {(IPCAiPLUSMes*100).toFixed(2).replace(".", ",")}% a.m , c/ retorno líquido de {taxaRetornoTesouroIPCA.toFixed(2).replace(".", ",")}% a.p.</div>
            ) 
          }
         }

        //Item sobre tipo de Tesouro - Calculo Taxa principal que aparece no modal com mudança do radio
        const [countUptipoTaxa, setCountUptipoTaxa] = useState(null)

        useEffect( () => {
          setCountUptipoTaxa(mudaTaxaTesouro())                          
        }, [])

        useEffect( () => {
            setCountUptipoTaxa(mudaTaxaTesouro())                          
        }, [valueRadioTesouro, price3, price4, price5])


  return (     
       <section  className={classes.appcss}>
            <div className={classes.sectionTopConsorcio} >
                <GridContainer className={classes.container3}>
                    <GridItem xs={12} sm={12} md={12} lg={6} className={classes.ipadSimulationCenter}>
                        <div>
                            <Typography  className={classes.titleEmprestimosPrincipal}  color="initial">
                               <span>Investimentos </span><span style={{ color: "#3f3f3f", fontWeight: 'bold'}}> Retail</span>
                            </Typography>
                            <Typography className={classes.titleEmprestimosPrincipal}  color="initial">
                                    que dão Fitti c/ <br className={classes.titleEmprestimosPrincipal2BR}/>
                                    <span className={classes.titleEmprestimosPrincipal2} style={{ color: "#3f3f3f", fontWeight: 'bold'}}> <Typewriter
                                        loop
                                        cursor
                                        cursorStyle='|'
                                        typeSpeed={70}
                                        deleteSpeed={50}
                                        delaySpeed={1000}
                                        words={['você!', 'seu jeito!']}>
                                    </Typewriter></span>
                            </Typography> 
                            <div className={classes.displayTitlesub3}>
                                <GridItem xs={12} sm={12} md={12}>
                                    <div className={classes.displayFundoCinzaEmprestimos3}>
                                                                                  
 <GridContainer style={{marginTop: "-25px"}}> 
      <div style={{maxWidth: "500px"}}>
          <NavPills style={{overflow:"hidden", height:"40px"}}
            color="success"
            
            tabs={[
              {
                tabButton: (<span><RendaFixIcon style={{marginRight: "5px", verticalAlign: "middle"}}></RendaFixIcon>Renda Fixa</span> ),
                tabContent: (
                  <div className={classes.empreParamsInputTotal}>
                
                                <div className={classes.empreParamsInput}>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Typography className={classes.title2subEmpreParamsInput}>
                                          <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Investimento inicial</span>
                                        </Typography>
                                        <TextField 
                                            id="outlined-basic" 
                                            //label="Valor do empréstimo" 
                                            variant="outlined"
                                            //required
                                            placeholder="R$100.000,00"
                                            //helperText={valorMinimoEmprestimo}
                                            color="success"
                                            size="small"
                                            value={value20.numberformat}
                                            onChange={handleChange20}
                                            name="numberformat"
                                            //id="formatted-numberformat-input"
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                              
                                            
                                            //InputProps={{startAdornment: <InputAdornment position="start">R$</InputAdornment>, ariaLabel}}
                                            >
                                        </TextField>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Typography className={classes.title2subEmpreParamsInput}>
                                            <span><AporteMensalIcon  style={{marginRight: "5px", verticalAlign: "middle"}}></AporteMensalIcon>Aportes mensais</span>
                                        </Typography>
                                        <TextField 
                                            id="outlined-basic" 
                                            //label="Valor do imóvel" 
                                            variant="outlined"
                                            //required
                                            placeholder="R$100.000,00"
                                            //helperText={valorMinimoImovel}
                                            color="success"
                                            size="small"
                                            value={value22.numberformat}
                                            onChange={handleChange22}
                                            name="numberformat"
                                            //id="formatted-numberformat-input"
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                    
                                            //InputProps={{startAdornment: <InputAdornment position="start">R$</InputAdornment>, ariaLabel}}
                                            >
                                        </TextField>
                                    </GridItem>
                                </div>
                                <GridItem style={{paddingLeft: "46px", paddingRight: "46px"}}> 
                                        <Typography className={classes.title2subEmpreParamsInput2}>
                                            Quanto tempo deixaria seu dinheiro investido?
                                        </Typography>
                                        <PrettoSlider
                                        aria-label="Temperature"
                                        //defaultValue={30}
                                        //getAriaValueText={valuetext}
                                      
                                        valueLabelDisplay="on"
                                        valueLabelColor="success"
                                        getAriaValueText={valueLabelFormat}
                                        valueLabelFormat={valueLabelFormat}
                                        step={12}
                                        marks={marks}
                                        min={12}
                                        max={216}
                                        color="success"
                                        defaultValue={value5} 
                                        onChange={handleChange10}
                                              >
                                        </PrettoSlider>
                                </GridItem>                                                
                  </div>
                )
              },


              {
                tabButton: (<span><RendaVarIcon  style={{marginRight: "5px", verticalAlign: "middle"}}></RendaVarIcon>Renda Variável</span> ),
                tabContent: (
                   <div className={classes.empreParamsInputTotal}>
                
                                <div className={classes.empreParamsInput}>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Typography className={classes.title2subEmpreParamsInput}>
                                            <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Investimento inicial</span>
                                        </Typography>
                                        <TextField 
                                            id="outlined-basic" 
                                            //label="Valor do empréstimo" 
                                            variant="outlined"
                                            //required
                                            placeholder="R$100.000,00"
                                            //helperText={valorMinimoEmprestimo}
                                            color="success"
                                            size="small"
                                            value={value201.numberformat}
                                            onChange={handleChange201} // criar um handleChange cpm if no botão principal com useEffect = {setInvestment(Number(e.target.value))}
                                            name="numberformat"
                                            //id="formatted-numberformat-input"
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            //InputProps={{startAdornment: <InputAdornment position="start">R$</InputAdornment>, ariaLabel}}
                                            >
                                        </TextField>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Typography className={classes.title2subEmpreParamsInput}>
                                             <span><TickerIcon  style={{marginRight: "5px", verticalAlign: "middle"}}></TickerIcon>Ativo selecionado</span>
                                        </Typography>
                                        <AutoComplete
                                            options={tickerOptions}
                                            getOptionLabel={(option) => `${option.symbol} - ${option.name}`} // Exibe o rótulo da opção
                                            onInputChange={handleChange203} //Monitora o input do usuário
                                            value={ticker}
                                            onChange={handleChange202} //Armazena apenas o ticker (Symbol)
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                  <Avatar src={option.logoUrl} alt={option.name} sx={{ width: 24, height: 24, marginRight: '10px' }} />
                                                  {`${option.symbol} - ${option.name}`}
                                                </li>
                                            )}
                                            
                                            renderInput={(params) => (<TextField {...params} 
                                                                      label="Selecione o Ticker" 
                                                                      color="success"
                                                                      size="small"
                                                                      id="outlined-basic" 
                                                                      variant="outlined"
                                                                      InputProps={{
                                                                          ...params.InputProps,
                                                                          endAdornment: (
                                                                            <>
                                                                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                              {params.InputProps.endAdornment}
                                                                            </>
                                                                          ),
                                                                        }}
                                                                    ></TextField>)}
                                                                    
                                            isOptionEqualToValue={(option, value) => option.symbol === value?.symbol} // Comparação correta
                                            //noOptionsText="Nenhuma opção" // Mensagem quando não há opções
                                            loading={loading} // Ativa o estado de loading no Autocomplete
                                          ></AutoComplete>
                                    </GridItem>
                                </div>
                                <GridItem style={{paddingLeft: "46px", paddingRight: "46px"}}> 
                                      <Typography className={classes.title2subEmpreParamsInput2}>
                                          Selecione o número de meses:
                                      </Typography>
                                      <PrettoSlider
                                      aria-label="Temperature"
                                      //defaultValue={30}
                                      //getAriaValueText={valuetext}
                                    
                                      valueLabelDisplay="on"
                                      valueLabelColor="success"
                                      getAriaValueText={valueLabelFormat2} //Resolver 
                                      valueLabelFormat={valueLabelFormat2} //Resolver 
                                      step={12}
                                      marks={marks2}
                                      min={6}
                                      max={60}
                                      color="success"
                                      defaultValue={value51} 
                                      onChange={handleChange101} //Resolver 
                                            >
                                      </PrettoSlider>
                                </GridItem>  
                        
                              </div>
                            )
                          },

                        ]}>
                      </NavPills>
                    </div>
                </GridContainer>
                        
                                    </div>
                                </GridItem>
                            </div>

                           <div style={{display: "flex", justifyContent: "center", marginTop: "-30px"}}>
                           
                             <div>
                               <LinkScroll to="topo"  smooth={true} duration={1000} offset={-100} onClick={() => scroll.scrollToTop()}>
                                  <Button color="success" round onClick={simulacaoRendaFixa}  className={classes.botaoSimularConsorcio}>
                                      Simule seu investimento
                                  </Button>
                               </LinkScroll>
                                  <Dialog 
                                    //onClick={() => setScrollingModal(false)}
                                    classes={{
                                      root: `${classes3.modalRoot} ${classes3.modalRootExample}`,
                                      paper: classes3.modal
                                    }}
                                    open={scrollingModal}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={simulacaoRendaFixaRestart}
                                    aria-labelledby="classic-modal-slide-title"
                                    aria-describedby="classic-modal-slide-description">
                                    <DialogTitle
                                      id="classic-modal-slide-title"
                                      disableTypography
                                      className={classes3.modalHeader}>
                                      <Button
                                        simple
                                        className={classes3.modalCloseButton}
                                        key="close"
                                        aria-label="Close"
                                        onClick={simulacaoRendaFixaRestart}>
                                        {" "}
                                        <Close className={classes3.modalClose} />
                                      </Button>
                                      <div className={classes3.container2}>
                                            <Animation1>
                                                  <Typography className={classes.titleanimatedbuttons2}>
                                                    Resultado da simulação
                                                  </Typography>
                                            </Animation1>
                                        </div>

                                    </DialogTitle>
                                    <DialogContent
                                      id="classic-modal-slide-description"
                                      className={classes3.modalBody}>

                                    
                                  <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <div className={classes.displayFundoCinzaEmprestimos2}>
                                    <NavPills style={{overflow:"hidden", height:"40px"}}
                                            color="success"
                                            tabs={[
                                              {
                                                tabButton: (<span><RendaFixIcon style={{marginRight: "5px", verticalAlign: "middle"}}></RendaFixIcon>CDB e LC</span> ),
                                                tabContent: (
                                                  <div>
                                                      <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Montante líquido final:</span>
                                                          </Typography>

                                                                <CountUp end={price}
                                                                className={classes.titlePriceRendaFixa}
                                                                formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                frameStyle={perc => ( {opacity: perc/100} )}
                                                                decimals={2}
                                                                decimal=","
                                                                ></CountUp>
                                                      </div>
                                                      <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span>Nº de Meses</span>
                                                          </Typography>
                                                        
                                                          <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>{value5}</div>
                                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span>Investimento total + (Rendimento - IR)</span>
                                                          </Typography>
                                                          <div className={classes.calcInvesRend}>
                                                            <CountUp end={investTotal}
                                                                style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                frameStyle={perc => ( {opacity: perc/100} )}
                                                                decimals={2}
                                                                decimal=","
                                                            ></CountUp>
                                                            <Typography style={{padding:"0 10px"}}>+</Typography>
                                                            <Badge color="success">
                                                              <CountUp end={rendimentoLiquidoCdb}
                                                                  style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                  formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                  frameStyle={perc => ( {opacity: perc/100} )}
                                                                  decimals={2}
                                                                  decimal=","
                                                              ></CountUp>
                                                            </Badge>
                                                  
                                                            
                                                          </div>
                                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                            <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                                <span>Taxa de juros</span>
                                                            </Typography>
                                                            <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", textAlign:"center", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>{(rentCdi*100).toFixed(0).replace(".", ",")}% do CDI de {(Cdi*1).toFixed(1).replace(".", ",")}% a.a ou de {(iCdiMes*100).toFixed(2).replace(".", ",")}% a.m, c/ retorno líquido de {taxaRetornoCdb.toFixed(2).replace(".", ",")}% a.p.</div>
                                                            <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                            <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                                <span>Rendimento na poupança:</span>
                                                            </Typography>
                                                            <div className={classes.calcInvesRend}>
                                                              <Badge color="success">
                                                                <CountUp end={rendimentoTotalPoupanca}
                                                                    style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                    formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                    frameStyle={perc => ( {opacity: perc/100} )}
                                                                    decimals={2}
                                                                    decimal=","
                                                                ></CountUp>
                                                              </Badge>
                                                            </div>
                                                            <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                       
                                                </div>
                                                )
                                              },
                                              {
                                                tabButton: (<span><RendaFixIcon  style={{marginRight: "5px", verticalAlign: "middle"}}></RendaFixIcon>LCI e LCA</span> ),
                                                tabContent: (
                                                  <div>
                                                      <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Montante líquido final:</span>
                                                          </Typography>

                                                                <CountUp end={price2}
                                                                className={classes.titlePriceRendaFixa}
                                                                formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                frameStyle={perc => ( {opacity: perc/100} )}
                                                                decimals={2}
                                                                decimal=","
                                                                ></CountUp>
                                                      </div>
                                                      <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span>Nº de Meses</span>
                                                          </Typography>
                                                        
                                                          <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>{value5}</div>
                                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span>Investimento total + Rendimento</span>
                                                          </Typography>
                                                          <div className={classes.calcInvesRend} >
                                                            <CountUp end={investimentoTotal}
                                                                style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                frameStyle={perc => ( {opacity: perc/100} )}
                                                                decimals={2}
                                                                decimal=","
                                                            ></CountUp>
                                                            <Typography style={{padding:"0 10px"}} >+</Typography>
                                                            <Badge color="success">
                                                              <CountUp end={rendimentoTotalLC}
                                                                  style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                  formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                  frameStyle={perc => ( {opacity: perc/100} )}
                                                                  decimals={2}
                                                                  decimal=","
                                                              ></CountUp>
                                                            </Badge>
                                                          </div>
                                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                            <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                                <span>Taxa de juros</span>
                                                            </Typography>
                                                            <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", textAlign:"center", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>{(rentLC*100).toFixed(0).replace(".", ",")}% do CDI de {(Cdi*1).toFixed(1).replace(".", ",")}% a.a ou de {(iCdiMes*100).toFixed(2).replace(".", ",")}% a.m, c/ retorno líquido de {taxaRetornoLC.toFixed(2).replace(".", ",")}% a.p.</div>
                                                            <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                            <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                                <span>Rendimento na poupança:</span>
                                                            </Typography>
                                                            <div className={classes.calcInvesRend}>
                                                              <Badge color="success">
                                                                <CountUp end={rendimentoTotalPoupanca}
                                                                    style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                    formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                    frameStyle={perc => ( {opacity: perc/100} )}
                                                                    decimals={2}
                                                                    decimal=","
                                                                ></CountUp>
                                                              </Badge>
                                                            </div>
                                                            <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                </div>
                                                )
                                              },
                                              {
                                                tabButton: (<span><RendaFixIcon  style={{marginRight: "5px", verticalAlign: "middle"}}></RendaFixIcon>Tesouro</span> ),
                                                tabContent: (
                                                  <div>
                                                      <div>                                            
                                                        <GridItem style={{display:"block", justifyContent:"center", marginTop:"5px"}}>
                                                            <Typography className={classes.title2subEmpreParamsInput}><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>
                                                                Tipos de Tesouro Direto:
                                                            </Typography>
                                                            <RadioGroup value={valueRadioTesouro} onChange={handleChangeRadioTesouro} defaultValue='prefixado' style={{display:"flex", justifyContent:"center"}} row aria-label="Capitalização" name="row-radio-buttons-group">
                                                                <FormControlLabel value="prefixado" {...controlProps('prefixado')} control={<Radio color="success" size="small" />}  label="Prefixado"></FormControlLabel>
                                                                <FormControlLabel value="selic"   {...controlProps('selic')} control={<Radio color="success" size="small" />}  label="Selic"></FormControlLabel>
                                                                <FormControlLabel value="ipca"   {...controlProps('ipca')} control={<Radio color="success" size="small" />}  label="IPCA+"></FormControlLabel>
                                                            </RadioGroup>
                                                        </GridItem>
                                                      </div>
                                                      <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Montante líquido final:</span>
                                                          </Typography>
                                                          {countUptipoPrice}
                                                      </div>
                                                      <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span>Nº de Meses</span>
                                                          </Typography>
                                                        
                                                          <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>{value5}</div>
                                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span>Investimento total + (Rendimento - IR)</span>
                                                          </Typography>
                                                          <div className={classes.calcInvesRend}>
                                                            <CountUp end={investimentoTotal}
                                                                style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                frameStyle={perc => ( {opacity: perc/100} )}
                                                                decimals={2}
                                                                decimal=","
                                                            ></CountUp>
                                                            <Typography style={{padding:"0 10px"}} >+</Typography>
                                                            <Badge color="success">
                                                             {countUptipoRent}
                                                            </Badge>
                                                          </div>
                                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                            <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                                <span>Taxa de juros</span>
                                                            </Typography>
                                                            {countUptipoTaxa}
                                                            <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                       
                                                </div>
                                                )
                                              },
                                              {
                                                tabButton: (<span><QueryStatsRounded  style={{marginRight: "5px", verticalAlign: "middle"}}></QueryStatsRounded>Gráfico</span> ),
                                                tabContent: (
                                                  <div>
                                                     
                                                          <div>
                                                              <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                                  <span><QueryStatsRounded  style={{marginRight: "5px", verticalAlign: "middle"}}></QueryStatsRounded>Gráfico da simulação:</span>
                                                              </Typography>
                                                              <ChartInvestimentosSimulacaoRendaFixa value5={value5} value20={value20} value22={value22}></ChartInvestimentosSimulacaoRendaFixa>
                                                          </div>
                                                </div>
                                                )
                                              },


                                        ]}>
                                      </NavPills>
                                        
                                      
                                
                                      </div>
                                    </GridItem>
                                    </DialogContent>
                                    <DialogActions style={{display: "flex", justifyContent: "space-evenly"}}>
                                        <GridItem className={classes.modalFooterEmpre}>
                                          <Link style={{textDecoration:"none"}} to="/emprestimos/homecarequity/chathomeequity">
                                              <Button onClick={simulacaoRendaFixaRestart}  color="success" round>
                                                  <span><DoneIcon fontSize="large"  style={{marginRight: "5px", verticalAlign: "middle"}}></DoneIcon> Gostei! Começar a investir!</span>
                                              </Button>
                                          </Link>
                                          <Button onClick={simulacaoRendaFixaRestart} color="secondary" round>
                                              <span><RepeatIcon fontSize="large" style={{marginRight: "5px", verticalAlign: "middle"}}></RepeatIcon> Simular novamente</span>
                                          </Button>
                                        
                                        </GridItem>
                                    </DialogActions>
                                  </Dialog>


                                  <Dialog 
                                    //onClick={() => setScrollingModal(false)}
                                    classes={{
                                      root: `${classes3.modalRoot} ${classes3.modalRootExample}`,
                                      paper: classes3.modal
                                    }}
                                    open={scrollingModal2}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={simulacaoRendaFixaClose}
                                    aria-labelledby="classic-modal-slide-title"
                                    aria-describedby="classic-modal-slide-description">
                                    <DialogTitle
                                      id="classic-modal-slide-title"
                                      disableTypography
                                      className={classes3.modalHeader}>
                                      <Button
                                        simple
                                        className={classes3.modalCloseButton}
                                        key="close"
                                        aria-label="Close"
                                        onClick={simulacaoRendaFixaClose}>
                                        {" "}
                                        <Close className={classes3.modalClose} />
                                      </Button>
                                      <div className={classes3.container2}>
                                            <Animation1>
                                                  <Typography className={classes.titleanimatedbuttons2}>
                                                    Resultado da simulação
                                                  </Typography>
                                            </Animation1>
                                        </div>

                                    </DialogTitle>
                                    <DialogContent
                                      id="classic-modal-slide-description"
                                      className={classes3.modalBody}>

                                    
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div className={classes.displayFundoCinzaEmprestimos2}>
                                          <div>
                                            <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Preencha todos os campos!</span>
                                            </Typography>
                                          </div>
                                        </div>
                                      </GridItem>
                                    </DialogContent>
                              
                                    <DialogActions style={{display: "flex", justifyContent: "space-evenly"}}>
                                        <GridItem className={classes.modalFooterEmpre}>
                                      
                                          <Button onClick={simulacaoRendaFixaClose} color="success" round>
                                              <span><DoneIcon style={{marginRight: "5px", verticalAlign: "middle"}}></DoneIcon>OK!</span>
                                          </Button>
                                        </GridItem>
                                    </DialogActions>
                                  </Dialog>
                      
                                 

                                  <Dialog 
                                    //onClick={() => setScrollingModal(false)}
                                    classes={{
                                      root: `${classes3.modalRoot} ${classes3.modalRootExample}`,
                                      paper: classes3.modal
                                    }}
                                    //open={scrollingModal3}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={simulacaoRendaFixaRestart}
                                    aria-labelledby="classic-modal-slide-title"
                                    aria-describedby="classic-modal-slide-description">
                                    <DialogTitle
                                      id="classic-modal-slide-title"
                                      disableTypography
                                      className={classes3.modalHeader}>
                                      <Button
                                        simple
                                        className={classes3.modalCloseButton}
                                        key="close"
                                        aria-label="Close"
                                        onClick={simulacaoRendaFixaRestart}>
                                        {" "}
                                        <Close className={classes3.modalClose} />
                                      </Button>
                                      <div className={classes3.container2}>
                                            <Animation1>
                                                  <Typography className={classes.titleanimatedbuttons2}>
                                                    Resultado da simulação
                                                  </Typography>
                                            </Animation1>
                                        </div>

                                    </DialogTitle>
                                    <DialogContent
                                      id="classic-modal-slide-description"
                                      className={classes3.modalBody}>

                                    
                                  <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <div className={classes.displayFundoCinzaEmprestimos2}>
                                    <NavPills style={{overflow:"hidden", height:"40px"}}
                                            color="success"
                                            tabs={[
                                              {
                                                tabButton: (<span><RendaFixIcon style={{marginRight: "5px", verticalAlign: "middle"}}></RendaFixIcon>Ativo selecionado</span> ),
                                                tabContent: (
                                                  <div>
                                                      <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Montante líquido final:</span>
                                                          </Typography>

                                                                <CountUp end={price}
                                                                className={classes.titlePriceRendaFixa}
                                                                formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                frameStyle={perc => ( {opacity: perc/100} )}
                                                                decimals={2}
                                                                decimal=","
                                                                ></CountUp>
                                                      </div>
                                                      <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span>Nº de Meses</span>
                                                          </Typography>
                                                        
                                                          <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>{value5}</div>
                                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span>Investimento total + (Rendimento - IR)</span>
                                                          </Typography>
                                                          <div className={classes.calcInvesRend}>
                                                            <CountUp end={investTotal}
                                                                style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                frameStyle={perc => ( {opacity: perc/100} )}
                                                                decimals={2}
                                                                decimal=","
                                                            ></CountUp>
                                                            <Typography style={{padding:"0 10px"}}>+</Typography>
                                                            <Badge color="success">
                                                              <CountUp end={rendimentoLiquidoCdb}
                                                                  style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                  formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                  frameStyle={perc => ( {opacity: perc/100} )}
                                                                  decimals={2}
                                                                  decimal=","
                                                              ></CountUp>
                                                            </Badge>
                                                  
                                                            
                                                          </div>
                                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                            <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                                <span>Taxa de retorno</span>
                                                            </Typography>
                                                            <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", textAlign:"center", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>{(rentCdi*100).toFixed(0).replace(".", ",")}% do CDI de {(Cdi*1).toFixed(1).replace(".", ",")}% a.a ou de {(iCdiMes*100).toFixed(2).replace(".", ",")}% a.m, c/ retorno líquido de {taxaRetornoCdb.toFixed(2).replace(".", ",")}% a.p.</div>
                                                            <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                            <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                                <span>Rendimento no CDI:</span>
                                                            </Typography>
                                                            <div className={classes.calcInvesRend}>
                                                              <Badge color="success">
                                                                <CountUp end={rendimentoTotalPoupanca}
                                                                    style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                    formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                    frameStyle={perc => ( {opacity: perc/100} )}
                                                                    decimals={2}
                                                                    decimal=","
                                                                ></CountUp>
                                                              </Badge>
                                                            </div>
                                                            <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                       
                                                </div>
                                                )
                                              },
                                              
                                              {
                                                tabButton: (<span><QueryStatsRounded  style={{marginRight: "5px", verticalAlign: "middle"}}></QueryStatsRounded>Gráfico</span> ),
                                                tabContent: (
                                                  <div>
                                                     
                                                          <div>
                                                              <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                                  <span><QueryStatsRounded  style={{marginRight: "5px", verticalAlign: "middle"}}></QueryStatsRounded>Gráfico da simulação:</span>
                                                              </Typography>
                                                              <ChartInvestimentosSimulacaoRendaFixa value5={value5} value20={value20} value22={value22}></ChartInvestimentosSimulacaoRendaFixa>
                                                          </div>
                                                </div>
                                                )
                                              },


                                        ]}>
                                      </NavPills>
                                        
                                      
                                
                                      </div>
                                    </GridItem>
                                    </DialogContent>
                                    <DialogActions style={{display: "flex", justifyContent: "space-evenly"}}>
                                        <GridItem className={classes.modalFooterEmpre}>
                                          <Link style={{textDecoration:"none"}} to="/emprestimos/homecarequity/chathomeequity">
                                              <Button onClick={simulacaoRendaFixaRestart}  color="success" round>
                                                  <span><DoneIcon fontSize="large"  style={{marginRight: "5px", verticalAlign: "middle"}}></DoneIcon> Gostei! Começar a investir!</span>
                                              </Button>
                                          </Link>
                                          <Button onClick={simulacaoRendaFixaRestart} color="secondary" round>
                                              <span><RepeatIcon fontSize="large" style={{marginRight: "5px", verticalAlign: "middle"}}></RepeatIcon> Simular novamente</span>
                                          </Button>
                                        
                                        </GridItem>
                                    </DialogActions>
                                  </Dialog>



                     


                                </div>
                            </div>

                        </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={6} className={classes.animationHomecarEquity}>
                            <AnimationUpDownInvestomentosRetail>
                            </AnimationUpDownInvestomentosRetail>
                        </GridItem>
                    </GridContainer>
                    <div className={classes.arrowanimatedbuttonsHomeCarEquity}>
                        <ArrowAnimatedButton>
                        </ArrowAnimatedButton>
                    </div>
            </div>
             <div name="" id="section2" style={{background: "rgb(242, 243, 248)", minHeight:"700px", marginTop:"-70px"}}>
                <TableHomeEquity>
                </TableHomeEquity>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"30px", marginBottom:"80px", display: "flex", justifyContent: "center"}}>
                    <LinkScroll to="topo"  smooth={true} duration={1000} offset={-100} onClick={() => scroll.scrollToTop()}>
                            <Button  className={classes.botaoSimularConsorcio} color="success" round>
                            SIMULE SEU CRÉDITO
                            </Button>
                    </LinkScroll>
                    </GridItem>
                </GridContainer>
            </div>
            <div name="" id="section2" style={{background: "#fff", minHeight:"700px", marginTop:"-50px"}}>
                <TableHomeEquitySolo>
                </TableHomeEquitySolo>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"30px", marginBottom:"80px", display: "flex", justifyContent: "center"}}>
                    <LinkScroll to="topo"  smooth={true} duration={1000} offset={-100} onClick={() => scroll.scrollToTop()}>
                            <Button  className={classes.botaoSimularConsorcio} color="success" round>
                            SIMULE SEU CRÉDITO
                            </Button>
                    </LinkScroll>
                    </GridItem>
                </GridContainer>
            </div>
            <div name="" id="section2" style={{background: "rgb(242, 243, 248)", minHeight:"700px", marginTop:"-50px", marginBotton:"50px"}}>
            <TableCarEquitySolo>
            </TableCarEquitySolo>
            <GridContainer className={classes.container}>
                <GridItem xs={12} sm={12} md={12} style={{marginTop:"30px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                  <LinkScroll to="topo"  smooth={true} duration={1000} offset={-100} onClick={() => scroll.scrollToTop()}>
                          <Button  className={classes.botaoSimularConsorcio} color="success" round>
                          SIMULE SEU CRÉDITO
                          </Button>
                  </LinkScroll>
                </GridItem>
            </GridContainer>
        </div>

           
           
             <div name="" id="section2" style={{background: "#fff", minHeight:"700px"}}>
                <CardEmprestimosHomeCarRquity>
                </CardEmprestimosHomeCarRquity>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"-5px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                    <LinkScroll to="topo"  smooth={true} duration={1000} offset={-100} onClick={() => scroll.scrollToTop()}>
                            <Button  className={classes.botaoSimularConsorcio} color="success" round>
                            SIMULE SEU CRÉDITO
                            </Button>
                      </LinkScroll>
                    </GridItem>
                </GridContainer>
            </div>
           
            <div name="" id="section2" style={{background: "rgb(242, 243, 248)", minHeight:"700px"}}>
                <ServicosEmprestimosHomeCarEquity>
                </ServicosEmprestimosHomeCarEquity>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"5px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                    <LinkScroll to="topo"  smooth={true} duration={1000} offset={-100} onClick={() => scroll.scrollToTop()}>
                            <Button  className={classes.botaoSimularConsorcio} color="success" round>
                            SIMULE SEU CRÉDITO
                            </Button>
                      </LinkScroll>
                    </GridItem>
                </GridContainer>
            </div>
            <div name="" id="section2" style={{background: "#fff", minHeight:"550px"}}>
                <ChartEmprestimosHomeEquity>
                </ChartEmprestimosHomeEquity>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"-18px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                    <LinkScroll to="topo"  smooth={true} duration={1000} offset={-100} onClick={() => scroll.scrollToTop()}>
                            <Button  className={classes.botaoSimularConsorcio} color="success" round>
                            SIMULE SEU CRÉDITO
                            </Button>
                     </LinkScroll>
                    </GridItem>
                </GridContainer>
            </div>
            <div name="ViddeoArea" id="VideoArea" style={{background: "rgb(242, 243, 248)"}}>
                <VideoAreaEmprestimosHomeEquity>
                </VideoAreaEmprestimosHomeEquity>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"-49px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                    <LinkScroll to="topo"  smooth={true} duration={1000} offset={-100} onClick={() => scroll.scrollToTop()}>
                            <Button  className={classes.botaoSimularConsorcio} color="success" round>
                            SIMULE SEU CRÉDITO
                            </Button>
                        </LinkScroll>
                    </GridItem>
                </GridContainer>
            </div>
            <div>
                <GridContainer className={classes.container} style={{minHeight:"517px"}}>
                    <AccordionEmprestimosHomeCarEquity>
                    </AccordionEmprestimosHomeCarEquity>
                </GridContainer>
            </div>
            <div name="rodape" id="rodape" >
                <Footer2>
                </Footer2>
            </div>
        </section>

  );
}