import {
  container,
  coloredShadow,
  title,
  cardTitle,
  description,
  mlAuto,
  infoColor,
  whiteColor,
  roseColor,
  grayColor,
  hexToRgb,
} from "assets/jss/material-kit-react-pro.js";

import imageStyles from "assets/jss/material-kit-react/imagesStyles.js";

import rotatingCards from "assets/jss/material-kit-react/rotatingCardsPro.js";

const styles = {
  container,
  coloredShadow,
  title,
  mlAuto,
  cardTitle,
  ...imageStyles,
  ...rotatingCards,
  sectionGray: {
    background: grayColor[14],
  },
  sectionWhite: {
    background: whiteColor,
  },
  cardTitleAbsolute: {
    ...cardTitle,
    position: "absolute !important",
    bottom: "15px !important",
    left: "15px !important",
    color: whiteColor + " !important",
    fontSize: "1.125rem !important",
    textShadow:
      "0 2px 5px rgba(" + hexToRgb(grayColor[9]) + ", 0.5) !important",
  },
  cardTitleWhite: {
    "&, & a": {
      ...title,
      marginTop: "12.625rem",
      marginBottom: "0",
      minHeight: "auto",
      color: whiteColor + " !important",
    },
  },
  cardCategory: {
    marginTop: "10px",
    "& svg": {
      position: "relative",
      top: "8px",
    },
  },
  cardCategorySocial: {
    marginTop: "10px",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: "22px",
      position: "relative",
      marginTop: "-4px",
      top: "2px",
      marginRight: "5px",
    },
    "& svg": {
      position: "relative",
      top: "5px",
    },
  },
  cardCategorySocialWhite: {
    marginTop: "10px",
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: "22px",
      position: "relative",
      marginTop: "-4px",
      top: "2px",
      marginRight: "5px",
    },
    "& svg": {
      position: "relative",
      top: "5px",
    },
  },
  cardCategoryWhite: {
    marginTop: "10px",
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.7)",
  },
  cardCategoryFullWhite: {
    marginTop: "10px",
    color: whiteColor,
  },
  cardDescription: {
    ...description,
  },
  cardDescriptionWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)",
    float: "left",
    padding:"0"
  },
  author: {
    display: "inline-flex",
    "& a": {
      color: grayColor[1],
    },
  },
  authorWhite: {
    display: "inline-flex",
    "& a": {
      color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)",
    },
  },
  avatar: {
    width: "30px",
    height: "30px",
    overflow: "hidden",
    borderRadius: "50%",
    marginRight: "5px",
  },
  statsWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)",
    display: "inline-flex",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "3px",
      marginRight: "3px",
      marginLeft: "3px",
      fontSize: "18px",
      lineHeight: "18px",
    },
    "& svg": {
      position: "relative",
      top: "3px",
      marginRight: "3px",
      marginLeft: "3px",
      width: "18px",
      height: "18px",
    },
  },
  stats: {
    color: grayColor[0],
    display: "flex",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "3px",
      marginRight: "3px",
      marginLeft: "3px",
      fontSize: "18px",
      lineHeight: "18px",
    },
    "& svg": {
      position: "relative",
      top: "3px",
      marginRight: "3px",
      marginLeft: "3px",
      width: "18px",
      height: "18px",
    },
  },
  justifyContentCenter: {
    WebkitBoxPack: "center !important",
    MsFlexPack: "center !important",
    justifyContent: "center !important",
  },
  iconWrapper: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.76)",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid " + grayColor[14],
    borderRadius: "50%",
    lineHeight: "174px",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: "55px",
      lineHeight: "55px",
    },
    "& svg": {
      width: "55px",
      height: "55px",
    },
  },
  iconWrapperColor: {
    borderColor: "rgba(" + hexToRgb(whiteColor) + ", 0.25)",
  },
  iconWhite: {
    color: whiteColor,
  },
  iconRose: {
    color: roseColor[0],
  },
  iconInfo: {
    color: infoColor[0],
  },
  marginTop30: {
    marginTop: "30px",
  },
  textCenter: {
    textAlign: "center",
  },
  marginBottom20: {
    marginBottom: "20px",
  },

buttonCardMenu: {
  cursor:"pointer",
  backgroundSize: "100%", 
  marginBottom: "-14px",
  transition: "all .5s ease-in-out",
  "&:hover,&:focus": {
    backgroundSize: "110%", 
  },
    "&, & p": {
      color: whiteColor + " !important",
    },
    '@media (max-width:1199px)':  {
      backgroundSize: "120%",
      "&:hover,&:focus": {
        backgroundSize: "155%", 
      },
    },

  },

  buttonCardMenuBox: {
    cursor:"pointer",
    backgroundSize: "120%", 
    transition: "all .5s ease-in-out",
    "&:hover,&:focus": {
      backgroundSize: "130%", 
    },
      "&, & p": {
        color: whiteColor + " !important",
      },
    '@media (max-width:1199px)':  {
      backgroundSize: "145%",
      "&:hover,&:focus": {
        backgroundSize: "160%", 
      },
    },
  },

buttonCardMenu2: {
  cursor:"pointer",
  backgroundSize: "160%", 
  transition: "all .5s ease-in-out",
  marginRight:"15px",
  "&:hover,&:focus": {
    backgroundSize: "175%", 
  },
    "&, & p": {
      color: whiteColor + " !important",
    },
  '@media (max-width:1199px)':  {
    backgroundSize: "185%",
    "&:hover,&:focus": {
      backgroundSize: "205%", 
    },
  },
  '@media (max-width:991px)':  {
    backgroundSize: "185%",
    "&:hover,&:focus": {
      backgroundSize: "205%", 
    },
  },  
},

buttonCardMenu2EmpresEmpresas: {
  cursor:"pointer",
  backgroundSize: "100%", 
  transition: "all .5s ease-in-out",
  marginRight:"15px",
  "&:hover,&:focus": {
    backgroundSize: "115%", 
  },
    "&, & p": {
      color: whiteColor + " !important",
    },
  '@media (max-width:1199px)':  {
    backgroundSize: "100%",
    "&:hover,&:focus": {
      backgroundSize: "115%", 
    },
  },
  '@media (max-width:991px)':  {
    backgroundSize: "185%",
    "&:hover,&:focus": {
      backgroundSize: "205%", 
    },
  },  
},

buttonCardMenuFittiEmprestimos: {
  cursor:"pointer",
  backgroundSize: "580%", 
  transition: "all .5s ease-in-out",
  backgroundRepeatY: "no-repeat",
  "&:hover,&:focus": {
    backgroundSize: "600%", 
  },
    "&, & p": {
      color: whiteColor + " !important",
    },
    '@media (max-width:1200px)':  {
      backgroundSize: "680%", 
      "&:hover,&:focus": {
        backgroundSize: "720%", 
      },
    },
},

buttonCardMenuFittiEmprestimosEmp: {
  cursor:"pointer",
  backgroundSize: "680%", 
  transition: "all .5s ease-in-out",
  backgroundRepeatY: "no-repeat",
  "&:hover,&:focus": {
    backgroundSize: "720%", 
  },
    "&, & p": {
      color: whiteColor + " !important",
    },
    '@media (max-width:1200px)':  {
      backgroundSize: "740%", 
      "&:hover,&:focus": {
        backgroundSize: "780%", 
      },
    },
},

buttonContaDigitalPF: {
   width:"200px", 
   height:"30px", 
   marginLeft:"110px",
  '@media (max-width:1200px)':  {
    Width:"185px", 
    marginLeft:"20px",
  },
},

buttonContaDigitalEstudante: {
  width:"200px", 
  height:"30px", 
  marginLeft:"50px",
 '@media (max-width:1200px)':  {
   Width:"185px", 
   marginLeft:"-40px",
 },
},

buttonEmprestimosGeralEmp: {
  width:"200px", 
  height:"30px", 
  marginLeft:"50px",
  marginRight: "37px",
 '@media (max-width:1200px)':  {
   Width:"185px", 
   marginLeft:"-40px",
 },
},

buttonEmprestimosGeral: {
  width:"158px", 
  height:"30px",
  '@media (max-width:991px)':  {
    width:"108px", 
  },
},

textoPrincipalEmprestimos: {
  float:"left", 
  textAlign:"left", 
  fontWeight:"bold", 
  fontSize:"16px",
  marginTop: "40px",
  marginBottom: "-15px",
  '@media (max-width:1199px)':  {
    fontSize:"14px",
  },
  '@media (max-width:991px)':  {
    fontSize:"10.5px",
  },
},

alinhamento: {
  fontSize:"14px", 
  textAlign: "left", 
  lineHeight: "1.2",
  '@media (max-width:1199px)':  {
    fontSize:"12px",
  },
  '@media (max-width:991px)':  {
    fontSize:"8.5px",
  },
},

buttonCardMenuFittiEmpreQuadrinho: {
  textAlign:"left", 
  padding: "200px 28px 0px", 
  zIndex: "2", 
  marginLeft: "17px",
  '@media (max-width:1199px)':  {
    padding: "200px 15px 0px", 
  },
},

buttonCardMenuFittiEmpreQuadro2Empresas: {
  textAlign:"left", 
  padding: "200px 28px 0px", 
  zIndex: "2", 
  marginLeft: "17px",
  '@media (max-width:1199px)':  {
    padding: "200px 15px 0px", 
  },
},

buttonCardMenuFittiConsQuadrinho: {
  textAlign:"center", 
  padding: "200px 20px 0px", 
  zIndex: "2", 
  marginLeft: "30px",
  '@media (max-width:1199px)':  {
    padding: "200px 0px 0px", 
  },
},

meiosDePagMenuIpod: {
  marginRight:"10px",
  '@media (max-width:1199px)':  {
    fontSize:"10px",
  },
},

};

export default styles;