import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
//import Weekend from "@material-ui/icons/Weekend";
//import Home from "@material-ui/icons/Home";
//import Business from "@material-ui/icons/Business";
//import AccountBalance from "@material-ui/icons/AccountBalance";
import Check from "@material-ui/icons/Check";
//import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
//import CardFooter from "components/Card/CardFooter.js";
//import Button from "components/CustomButtons/Button.js";
//import NavPills from "components/NavPills/NavPills.js";
import Success from "components/Typography/Success.js";
//import Danger from "components/Typography/Danger.js";

import pricingStyle from "assets/jss/material-kit-react/views/sectionsSections/pricingStyle.js";
import styles1 from "assets/jss/material-kit-react/views/components.js";
import styles2 from "assets/jss/material-kit-react/views/componentsSections/sectionCards.js";
import Typography from '@material-ui/core/Typography';
import styled, { keyframes } from 'styled-components';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Badge from "components/Badge/Badge.js";
//import Table from "components/Table/Table.js";

//import bg11 from "assets/img/bg11.jpg";
//import city from "assets/img/examples/city.jpg";
//import cardBlog3 from "assets/img/examples/card-blog3.jpg";

const useStyles = makeStyles(pricingStyle);
const useStyles1 = makeStyles(styles1);
const useStyles2 = makeStyles(styles2);


//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;


export default function SectionPricing({ ...rest }) {
  const classes = useStyles(pricingStyle);
  const classes1 = useStyles1(styles1)
  const classes2 = useStyles2(styles2)
  return (
          <GridContainer className={classes2.container} >
            <div className={classes1.container2} style={{marginBottom:"35px", marginTop:"20px"}}>
                <Animation1>
                      <Typography className={classes1.titleanimatedbuttons2}>
                        Porque escolher o consórcio Fitti?
                        
                      </Typography>
                </Animation1>
            </div>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <Card pricing >
                <CardBody pricing >
                 <Typography style={{fontWeight:"bold", fontSize:"1.5rem", color: "#3f3f3f", textAlign: "center"}}>Como funciona o consórcio?</Typography>
                 <List>
                      <ListItem className={classes.servicosListas}> 
                          <Success>
                            <Check style={{marginRight: "15px"}} />
                          </Success>{" "}
                          <Typography className={classes.subtitulo}>
                                        <Badge color="gray">COTAS:</Badge><span className={classes.textoCardsServicos}> Todos os participantes (PF ou PJ) têm uma cota e contribuem mensalmente com parcelas sem juros.</span>
                          </Typography>
                    </ListItem> 
                    <ListItem className={classes.servicosListas}> 
                          <Success>
                            <Check style={{marginRight: "15px"}} />
                          </Success>{" "}
                          <Typography className={classes.subtitulo}>
                                        <Badge color="gray">RESGATE:</Badge><span className={classes.textoCardsServicos}> O contemplado (definido por lance ou sorteio) pode escolher entre retirar sua carta de crédito e comprar seu veículo, ou deixar o dinheiro aplicado na administradora.</span>
                          </Typography>
                    </ListItem> 
                    <ListItem className={classes.servicosListasBottom}> 
                          <Success>
                              <Check style={{marginRight: "15px"}} />
                          </Success>{" "}
                            <Typography className={classes.subtitulo}>
                                         <Badge color="gray">PRAZO:</Badge><span className={classes.textoCardsServicos}>  Duração máxima: 120 meses.</span>
                            </Typography>
                    </ListItem> 
                  </List>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <Card pricing color="success">
                <CardBody pricing >
                <Typography style={{fontWeight:"bold", fontSize:"1.5rem", color: "#3f3f3f", textAlign: "center"}}>Formas de ser contemplado</Typography>
                 <List>
                      <ListItem  className={classes.servicosListas}> 
                          <Success>
                            <Check style={{marginRight: "15px"}} />
                          </Success>{" "}
                          <Typography className={classes.subtitulo}>
                                        <Badge color="gray">SORTEIO:</Badge><span className={classes.textoCardsServicos}>  Uma bolinha numerada é retirada de um globo e o número sorteado é contemplado.</span>
                          </Typography>
                    </ListItem> 
                    <ListItem className={classes.servicosListas}> 
                          <Success>
                            <Check style={{marginRight: "15px"}} />
                          </Success>{" "}
                          <Typography className={classes.subtitulo}>
                                         <Badge color="gray">LANCE:</Badge><span className={classes.textoCardsServicos}>  O participante oferece uma porcentagem, proporcional ao valor em dinheiro, para receber sua carta de crédito. </span>
                          </Typography>
                    </ListItem> 
                    <ListItem className={classes.servicosListas}> 
                          <Success>
                              <Check style={{marginRight: "15px"}} />
                          </Success>{" "}
                          <Typography className={classes.subtitulo}>  
                                      <Badge color="gray">LANCE EMBUTIDO:</Badge><span className={classes.textoCardsServicos}>  Até 20% do valor da carta de crédito é usado para compor o lance.</span>
                          </Typography>
                    </ListItem>
                    <ListItem className={classes.servicosListasBottom}> 
                          <Success>
                              <Check style={{marginRight: "15px"}} />
                          </Success>{" "}
                          <Typography className={classes.subtitulo}>          
                                      <Badge color="gray">LANCE FIXO:</Badge><span className={classes.textoCardsServicos}>  Participantes oferecem valor fixo de lance (Ex: 30%) e entram em um sorteio à parte, aumentando a chance de serem contemplados.</span>
                          </Typography>
                    </ListItem> 
                  </List>
                </CardBody>
              </Card>
            </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
              <Card pricing >
                <CardBody pricing >
                 <Typography style={{fontWeight:"bold", fontSize:"1.5rem", color: "#3f3f3f", textAlign: "center"}}>Porque investir no consórcio!</Typography>
                 <List>
                      <ListItem className={classes.servicosListas}> 
                          <Success>
                            <Check style={{marginRight: "15px"}} />
                          </Success>{" "}
                          <Typography className={classes.subtitulo}>
                                        <Badge color="gray">CUSTO BAIXO:</Badge><span className={classes.textoCardsServicos}>  Você não paga juros e não tem valor de entrada.</span>
                          </Typography>
                    </ListItem> 
                    <ListItem className={classes.servicosListas}> 
                          <Success>
                            <Check style={{marginRight: "15px"}} />
                          </Success>{" "}
                          <Typography className={classes.subtitulo}>
                                         <Badge color="gray">AUTONOMIA:</Badge><span className={classes.textoCardsServicos}> Com a carta de crédito você escolhe qualquer veículo fabricado nos últimos 5 anos.</span>
                          </Typography>
                    </ListItem> 
                    <ListItem className={classes.servicosListasBottom}> 
                          <Success>
                              <Check style={{marginRight: "15px"}}/>
                          </Success>{" "}
                            <Typography className={classes.subtitulo}>  
                                        <Badge color="gray">ORGANIZAÇÃO:</Badge><span className={classes.textoCardsServicos}> A melhor maneira de criar seu planejamento financeiro!</span>
                            </Typography>
                    </ListItem> 
                  </List>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
   
  );
}
