import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui icons
// core components
import Card from "components/Card/CardPro.js";
import CardBody from "components/Card/CardBodyPro.js";
import CardHeader from "components/Card/CardHeaderPro.js";
//import Info from "components/Typography/Info.js";

import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";
import cardsStyle from "assets/jss/material-kit-react/views/componentsSections/sectionCards.js";

import { cardTitle } from "assets/jss/material-kit-react-pro.js";
//import Typography from '@material-ui/core/Typography';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ReactPlayer from 'react-player/lazy';
import Typography from '@material-ui/core/Typography';
import styled, { keyframes } from 'styled-components'
import styles from "assets/jss/material-kit-react/views/components.js";
//import "slick-carousel/slick/video1.css"; 



const style = {
  ...imagesStyles,
  ...cardsStyle,
  cardTitle
};

const useStyles = makeStyles(style);
const useStyles1 = makeStyles(styles);

//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;

export default function CardExampleCardBlog() {
  const classes = useStyles();
  const classes1 = useStyles1();
  return (
  <GridContainer className={classes.container} style={{display:"flex", justifyContent:"center"}} > 
      <div className={classes1.container2} style={{marginBottom:"35px", marginTop:"20px"}} >
            <Animation1>
                  <Typography className={classes1.titleanimatedbuttons2}>
                    Fitti Consórcios no seu dia a dia
                  </Typography>
            </Animation1>
      </div>
    <GridItem style={{width:"800px", height:"auto", marginBottom:"50px"}}>
      <Card blog>
        <CardHeader image>
        
        <div className={classes.imgCard}>
          <ReactPlayer className="videofitti"
            url='https://www.youtube.com/watch?v=6gRPEOk_R5Q'
            width='100%'
            height='30rem'
            webkit-playsinline
            playsInline
            controls >
            </ReactPlayer>
        </div>
        
        </CardHeader>
        <CardBody>
            <Typography className={classes1.titulovideo}>
                Seja Fitti Consórcios você também!
            </Typography> 
            <Typography className={classes1.textovideo}>
                Uma série de produtos relacionados á Consórcios para você contratar via web e via app.
                Produtos e serviços entregues em poucos minutos com taxas mais atrativas e simplificadas.
                Aquele "Fitti" com as necessidades do seu dia a dia.
            </Typography> 
        </CardBody>
      </Card>
      </GridItem>
  </GridContainer>
  );
}