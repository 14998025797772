
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import HorizontalScroll from "react-scroll-horizontal";
//import Button from "components/CustomButtons/Button.js";
//import Checkbox from "@material-ui/core/Checkbox";
// material-ui icons
//import Check from "@material-ui/icons/Check";
// core components
import Table from "components/Tables/Table";

//import CardFooter from "components/Card/CardFooterPro.js";


import style from "assets/jss/material-kit-react/views/componentsSections/contentAreas.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import style1 from "assets/jss/material-kit-react/views/components.js";
import Typography from '@material-ui/core/Typography';
import styled, { keyframes } from 'styled-components';
//import Car from "@material-ui/icons/DirectionsCarOutlined";
//import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import DirectionsBikeOutlinedIcon from '@material-ui/icons/DirectionsBikeOutlined';




//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;

const useStyles = makeStyles(style);
const useStyles1 = makeStyles(style1);


export default function Tables() {



  const classes = useStyles();
  const classes1 = useStyles1(style1);



  return (
    <GridContainer id="container-tabs" className={classes1.container} style={{display: "flex", justifyContent: "center"}}>
    <div className={classes1.container2} style={{marginBottom:"35px", marginTop:"10px"}}>
        
                    <Animation1>
                        <Typography className={classes1.titleanimatedbuttons2}>
                            Adquira a sua moto, nacional ou importada!

                        </Typography>
                    </Animation1>
                    <GridItem style={{marginTop:"50px", marginBottom:"50px"}}>
                          <div style={{boxShadow: "0px 2px 23px -16px", borderRadius: "20px", marginBottom:"60px", marginTop:"50px", paddingTop: "20px", paddingBottom: "20px", paddingLeft:"25px", paddingRight:"25px", display:"flex"}}>
                            <LocalAtmOutlinedIcon style={{color: "#555", fontSize:"5.0rem", alignSelf: "center", marginRight:"20px"}}></LocalAtmOutlinedIcon>
                            <Typography className={classes1.textoCardsServicos} style={{alignSelf: "center"}}>
                                    Ao adquirir um consórcio de motos, você planeja a compra da sua moto sem pagar juros e sem entrada. Você pode comprar qualquer marca ou modelo de moto, nacional ou importada, nova ou usada, de acordo com o valor do crédito contratado.
                            </Typography>
                         </div>
                    </GridItem>
                    <Animation1>
                        <Typography className={classes1.titleanimatedbuttons2}>
                            Algumas opções de prazos e parcelas
                        </Typography>
                    </Animation1>
                </div> 
    <GridItem xs={12} sm={12} md={12} >

    <Table id="container-tabs"
      striped
      tableHead={[ 
                          <DirectionsBikeOutlinedIcon name="esquerda" id="esquerda" style={{fontSize:"5.0rem"}}></DirectionsBikeOutlinedIcon>,
                              
                           <Typography className={classes1.title2subTaxas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                            80x
                           </Typography> , 
                          
                           <Typography className={classes1.title2subTaxas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                            66x
                           </Typography>, 
                           <Typography className={classes1.title2subTaxas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                            60x
                           </Typography>, 
                           <Typography className={classes1.title2subTaxas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                            54x
                           </Typography>, 
                           <Typography className={classes1.title2subTaxas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                            48x
                           </Typography>,  
                             <Typography name="direita" id="direita" className={classes1.title2subTaxas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                            46x
                           </Typography>, ]}
      tableData={[
        [
            <Typography className={classes1.subTableWords} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                            Créditos
            </Typography>,
            <Typography className={classes1.subTableWords} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                            Parcelas
            </Typography>,
            <Typography className={classes1.subTableWords} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                            Parcelas
            </Typography>,
            <Typography className={classes1.subTableWords} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                            Parcelas
            </Typography>,
            <Typography className={classes1.subTableWords} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                            Parcelas
            </Typography>,
            <Typography className={classes1.subTableWords} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                            Parcelas
            </Typography>,
            <Typography className={classes1.subTableWords} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                            Parcelas
            </Typography>,
        ],
        [
             <Typography className={classes1.subTableWordsCred} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                            25 Mil
            </Typography>,
            <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
             <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
        ],
        [
             <Typography className={classes1.subTableWordsCred} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                            50 Mil
            </Typography>,
            <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
             <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
        ],
        [
             <Typography className={classes1.subTableWordsCred} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                            70 Mil
            </Typography>,
            <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
             <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
        ],
        [
             <Typography className={classes1.subTableWordsCred} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                            90 Mil
            </Typography>,
            <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
             <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
        ],
        [
             <Typography className={classes1.subTableWordsCred} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                           100 Mil
            </Typography>,
            <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
             <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
        ],
         [
             <Typography className={classes1.subTableWordsCred} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                           120 Mil
            </Typography>,
            <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
             <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
              <Typography className={classes1.subTableWordsParcelas} style={{ color: "#3f3f3f", marginTop:"10px", textAlign: "center"}}>
                                              327,36
            </Typography>,
        ],
      ]}
      customCellClasses={[
        classes.tablefixo,
        classes.textCenter,
        classes.padding0,
        classes.textRight,
        classes.textRight
      ]}
      customClassesForCells={[0, 1, 5, 6]}
      customHeadCellClasses={[
        classes.tablefixo,
        classes.textCenter,
        classes.textRight,
        classes.textRight
      ]}
      customHeadClassesForCells={[0, 5, 6]}
    />

    <GridItem style={{marginTop:"50px", marginBottom:"50px"}}>
      <Animation1>
          <Typography className={classes1.titleanimatedbuttons2}>
              O sorteio e a assembleia dos grupos ocorrem todo mês!
          </Typography>
      </Animation1>
      <div style={{boxShadow: "0px 2px 23px -16px", borderRadius: "20px", marginBottom:"50px", marginTop:"50px", paddingTop: "20px", paddingBottom: "20px", paddingLeft:"25px", paddingRight:"25px", display:"flex"}}>
        <EmojiEventsOutlinedIcon style={{color: "#555", fontSize:"5.0rem", alignSelf: "center", marginRight:"20px"}}></EmojiEventsOutlinedIcon>
        <Typography className={classes1.textoCardsServicos} style={{alignSelf: "center"}}>
              Comprando a sua cota agora, você garante a sua vaga, já participa do próximo sorteio, poderá realizar lances além da possibilidade de pode ser contemplado.
        </Typography>
      </div>
    </GridItem>
  </GridItem>
  </GridContainer >
  );
}