import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
//import Icon from "@material-ui/core/Icon";
import PhoneIcon  from "@material-ui/icons/PhoneOutlined";
import FingerPrint  from "@material-ui/icons/FingerprintOutlined";
import EmailOutlined  from "@material-ui/icons/EmailOutlined";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
//import Assignment from "@material-ui/icons/Assignment";
import Face from "@material-ui/icons/Face";
import Phone from "@material-ui/icons/PhoneIphone";
//import Code from "@material-ui/icons/Code";
//import Group from "@material-ui/icons/Group";
//import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CardHeader from "components/Card/CardHeader.js";

import style from "assets/jss/material-kit-react/views/componentsSections/javascriptStylesPro.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(style);

export default function ExampleSingup() {
  const [signupModal, setSignupModal] = React.useState(false);
  const classes = useStyles();
  return (
    <div>
      <Button color="success" round onClick={() => setSignupModal(true)}>
         Abra sua conta
      </Button>
      <Dialog 
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalLogin2 
        }}
        open={signupModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSignupModal(false)}
        aria-labelledby="signup-modal-slide-title"
        aria-describedby="signup-modal-slide-description"
      >
        <Card plain className={classes.modalLoginCard}>
          <DialogTitle
            id="signup-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <CardHeader
              plain
              color="success"
              className={`${classes.textCenter} ${classes.cardLoginHeader}`}
            >
              <Button
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={() => setSignupModal(false)}
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <div>
              <h2 className={classes.cardTitleWhite} style={{justifyContent:"center", align:"center", margin: "10px", display:"flex",
paddingLeft:"20px", fontFamily: "Roboto!Important"
}} >Abra agora a sua Conta Digital Fitti</h2>
              </div>
            </CardHeader>
          </DialogTitle>
          <DialogContent 
            id="signup-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
                <InfoArea
                  className={classes.infoArea}
                  title="Conta Digital Pessoa Física"
                  description={
                    <p>
                      Conta digital "gratuita" para você chamar de sua e usufruir de uma série de ferramentas e benefícios.
                    </p>
                  }
                  icon={Phone}
                  iconColor="success"
                />
                <InfoArea
                  className={classes.infoArea}
                  title="Conta Digital Mei "
                  description={
                    <p>
                     Conta Digital "gratuita" para você que está empreendendo e precisa de boas ferramentas para ajudar a tocar o seu negócio.
                    </p>
                  }
                  icon={Phone}
                  iconColor="success"
                />
                <InfoArea
                  className={classes.infoArea}
                  title="Conta Digital PJ"
                  description={
                    <p>
                      Conta Digital "gratuita" para atender as necessidades do dia a dia da sua empresa.
                    </p>
                  }
                  icon={Phone}
                  iconColor="success"
                />
              </GridItem>
              <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>

                <form className={classes.form}>
                  <CustomInput
                   success
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Face className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      placeholder: "Nome",
                    }}
                  />
                  <CustomInput
                   success
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <EmailOutlined  className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      placeholder: "Email",
                    }}
                  />
                  <CustomInput
                   success
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}>
                            <PhoneIcon className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      placeholder: "Telefone",
                    }}
                  />

              <CustomInput  
               success
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}>
                            <FingerPrint className={classes.inputAdornmentIcon}/>
                        </InputAdornment>
                      ),
                      placeholder: "CPF",
                    }}
                  />
           <div style={{marginBottom: "1px"}}></div>
                  <FormControlLabel sytle={{display:"flex", justifyContent:"center"}}
                    classes={{
                      label: classes.label,
                    }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        //onClick={() => this.handleToggle(1)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={
                      <span style={{color: "#3f3f3f", textAlign:"center"}}>
                        Eu concordo com a {" "} {" "} <a href="#pablo">Política de Privacidade</a>.
                  
                      </span>
                    }
                  />
                  <div className={classes.textCenter}  style={{marginBottom: "20px"}}>
                    <Button round style={{width:"300px"}} color="success">
                      Continuar
                    </Button>
                  </div>
              </form>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Card>
      </Dialog>
    </div>
  );
}
