import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
//import Dashboard from "@material-ui/icons/Dashboard";
//import Schedule from "@material-ui/icons/Schedule";
//import List from "@material-ui/icons/List";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPillsMenu from "components/NavPills/NavPillsMenu.js";
import pillsStyle from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";
import Badge from "components/Badge/Badge.js";

//import ShoppingCart from "@material-ui/icons/ShoppingCart";
import AttachMoney from "@material-ui/icons/AttachMoney";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import BarChartIcon from '@material-ui/icons/BarChart';
//import PaymentIcon from '@material-ui/icons/Payment';
import BeenhereIcon from '@material-ui/icons/Beenhere';
//import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
//import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
//import FastfoodIcon from '@material-ui/icons/Fastfood';



// @material-ui icons
//import Subject from "@material-ui/icons/Subject";
//import WatchLater from "@material-ui/icons/WatchLater";
// core components
import Card from "components/Card/CardPro.js";
//import CardBody from "components/Card/CardBodyPro.js";
import Button from "components/CustomButtons/Button.js";

import cardsStyle from "assets/jss/material-kit-react/views/componentsSections/sectionCards.js";
//Typography
import Typography from '@material-ui/core/Typography';

//Imagens
//import MenuContaDigitalMei from 'images/MenuContaDigitalMei.jpg';
//import MenuContaDigitalPJ from 'images/MenuContaDigitalPJ.jpg';
import MenuEmprestimos from 'images/MenuEmprestimos.jpg';
import MenuFinImobEmp from 'images/MenuFinImobEmp.jpg';
//import MenuEmpPessoal from 'images/MenuEmpPessoal.jpg';
//import MenuEmpConsg from 'images/MenuEmpConsg.jpg';
import MenuHomeEquityEmp from 'images/MenuHomeEquityEmp.jpg';
import MenuSegurosEmp from 'images/MenuSegurosEmp.jpg';
import MenuCambioEmp from 'images/MenuCambioEmp.jpg';
//import MenuShopping from 'images/MenuShopping.jpg';
//import MenuMeiosPagamentosEmp from 'images/MenuMeiosPagamentosEmp.jpg';
import MenuInvestimentosEmp from 'images/MenuInvestimentosEmp.jpg';
import MenuConsorcio from 'images/MenuConsorcio.jpg';
import MenuConsorcioServico from 'images/MenuConsorcioServico.jpg';
import MenuConsorcioImobiliario from 'images/MenuConsorcioImobiliario.jpg';
import MenuConsorcioAUTO from 'images/MenuConsorcioAUTO.jpg';
import MenuConsorcioMOTO from 'images/MenuConsorcioMOTO.jpg';
//import MenuSaudePrincipal from 'images/MenuSaudePrincipal.jpg';
//import MenuSaudeConsultasTele from 'images/MenuSaudeConsultasTele.jpg';
//import MenuSaudeAgendamentoExames from 'images/MenuSaudeAgendamentoExames.jpg';
//import MenuSaudePlanos from 'images/MenuSaudePlanos.jpg';
//import MenuSaudeRemediosEFarm from 'images/MenuSaudeRemediosEFarm.jpg';
//import MenuBeneficiosEmp from 'images/MenuBeneficiosEmp.jpg';


const style1 = {
  ...cardsStyle,
};

const useStyles1 = makeStyles(style1);
const useStyles = makeStyles(pillsStyle);

export default function SectionPills() {

const classes1 = useStyles1();
const classes = useStyles();

  
  return (
    <div className={classes.MenuConteudoPills}>
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="navigation-pills">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <NavPillsMenu
                horizontal={{
                  tabsGrid: { xs: 12, sm: 4, md: 4 },
                  contentGrid: { xs: 12, sm: 8, md: 8 },
                }}
                color="success"
                tabs={[
                  {
                    tabButton: <span style={{width:"100%", display:"inline-flex", justifyContent:"space-between" }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><BarChartIcon  style={{marginRight:"10px", verticalAlign:"middle",  }}></BarChartIcon> Investimentos</span><span style={{marginInlineStart: "auto"}}   className={classes.badgesome}><Badge color="gray">Em breve</Badge></span><ArrowForwardIosIcon fontSize="small" style={{fontSize: 12, marginLeft:"10px", verticalAlign:"middle", height: "auto",}}></ArrowForwardIosIcon></span>,
                    tabContent: (
                      <div style={{height:"512px", width:"98%", overflow:"hidden", display:"-webkit-box", marginTop: "-30px"}}>
                      <Card 
                        background
                        className={classes1.buttonCardMenuBox}
                        style={{
                          backgroundImage: `url(${MenuInvestimentosEmp})` 
                        }}>
                        <GridContainer  /*background*/  style={{width:"inherit"}}>
                              <GridItem style={{alignText:"left", padding: "330px 40px 5px", zIndex: "2"}}>
                                  <div>
                                      <Typography style={{float:"left", fontWeight:"bold", fontSize:"22px"}} >
                                        Investimentos
                                      </Typography>
                                  </div>
                              </GridItem>
                              <GridItem style={{display:"flex", justifyContent:"space-between", alignText:"left", padding: "10px 40px 20px", zIndex: "2"}}>
                                  <div>
                                      <Typography style={{fontSize:"14px", textAlign: "left", lineHeight: "1.2", color:"#fff!Important"}}>
                                        Através da nossa plataforma<br/>
                                        de investimentos, obtenha acesso <br/>
                                        á diversos produtos de renda fixa e<br/>
                                        renda variável com corretagem zero e cashback.
                                      </Typography> 
                                  </div>
                                  <div>
                                      <Button className={classes1.buttonContaDigitalPF} color="success" round>
                                        ACESSAR
                                      </Button>
                                  </div>
                              </GridItem>
                        </GridContainer>
                      </Card>
                </div>
                    ),
                  },
                  {
                    tabButton:  <span style={{width:"100%", display:"inline-flex", justifyContent:"space-between", }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><MonetizationOnIcon style={{marginRight:"10px", verticalAlign:"middle",  }}></MonetizationOnIcon>Empréstimos</span><ArrowForwardIosIcon fontSize="small" style={{fontSize: 12, float:"right", verticalAlign:"middle", height: "auto", }}></ArrowForwardIosIcon></span>,
                    tabContent: (
                      <div style={{display:"inline-flex"}}>
                      <div style={{height:"500px", width:"32%", overflow:"hidden", marginTop: "-30px", marginRight:"15px"}}>
                      <Card 
                        background
                        className={classes1.buttonCardMenuFittiEmprestimos}
                        style={{
                        backgroundImage: `url(${MenuEmprestimos})`
                        }}>
                        <GridContainer  /*background*/  style={{width:"inherit"}}>
                              <GridItem className={classes1.buttonCardMenuFittiEmpreQuadro2Empresas}>
                                  <div>
                                      <Typography style={{fontWeight:"bold", fontSize:"16px"}} >
                                        Fitti<br/>
                                        Empréstimos
                                      </Typography>
                                  </div>
                              </GridItem>
                              <GridItem style={{display:"flex", justifyContent:"center", alignText:"center", padding: "5px 40px 158px", zIndex: "2", marginLeft:"15px"}}>
                    
                                  <div>
                                      <Button style={{width:"100px", height:"30px"}} color="success" round>
                                        ACESSAR
                                      </Button>
                                  </div>
                              </GridItem>
                        </GridContainer>
                      </Card>
                      </div>
                      <div style={{display:"Grid", justifyItems: "center"}}> 
                      <div style={{display:"flex", justifyContent:"flex-start", height:"280px", width:"100%", overflow:"hidden", marginTop: "-30px", marginBottom: "-14px"}}>
                          <Card 
                            background
                            className={classes1.buttonCardMenu2EmpresEmpresas}
                            style={{
                              backgroundImage: `url(${MenuHomeEquityEmp})`
                                             }}>
                            <GridContainer  /*background*/  style={{width:"inherit"}}>
                                  <GridItem style={{alignText:"left", padding:"125px 40px 5px", zIndex: "2"}}>
                                      <div>
                                          <Typography style={{float:"left", fontWeight:"bold", fontSize:"18px"}}>
                                            Home Equity/Car Equity<br/>
                                          </Typography>
                                      </div>
                                  </GridItem>
                                  <GridItem style={{display:"flex", justifyContent:"space-between",  textAlign:"left", padding: "0px 0px 20px 40px", zIndex: "2"}}>
                                      <div style={{marginRight: "-50px"}}>
                                          <Typography className={classes1.alinhamento}>
                                            Utilize um imóvel ou Auto como<br/>
                                            garantia no empréstimo<br/>
                                            para a sua empresa.
                                          </Typography> 
                                      </div>
                                      <div>
                                          <Button className={classes1.buttonEmprestimosGeralEmp} color="success" round>
                                            ACESSAR
                                          </Button>
                                      </div>
                                  </GridItem>
                            </GridContainer>
                          </Card>
                          
                 </div>
                 <div style={{display:"flex", justifyContent:"flex-start", height:"280px", width:"100%", overflow:"hidden", marginTop:"-35px"}}>
                 <Card 
                            background
                            className={classes1.buttonCardMenu2EmpresEmpresas}
                            style={{
                            backgroundImage: `url(${MenuFinImobEmp})`
                           }}>
                            <GridContainer  /*background*/  style={{width:"inherit"}}>
                                  <GridItem style={{alignText:"left", padding: "125px 40px 5px", zIndex: "2"}}>
                                      <div>
                                          <Typography style={{float:"left", fontWeight:"bold", fontSize:"18px"}}>
                                          Financiamento Imobiliário<br/>
                                          </Typography>
                                      </div>
                                  </GridItem>
                                  <GridItem style={{display:"flex", justifyContent:"space-between",  textAlign:"left", padding: "0px 0px 20px 40px", zIndex: "2"}}>
                                      <div>
                                          <Typography className={classes1.alinhamento}>
                                            Financie um imóvel comercial ou<br/>
                                            industrial para a sua empresa<br/>
                                            ou seu negócio.
                                          </Typography> 
                                      </div>
                                      <div>
                                          <Button className={classes1.buttonEmprestimosGeralEmp} color="success" round>
                                            ACESSAR
                                          </Button>
                                      </div>
                                  </GridItem>
                            </GridContainer>
                          </Card>
                        </div>
                    </div>
                </div>
                    ),
                  },
                  {
                    tabButton: <span style={{width:"100%", display:"inline-flex", justifyContent:"space-between" }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><BeachAccessIcon style={{marginRight:"10px", verticalAlign:"middle",  }}></BeachAccessIcon>Seguros</span><ArrowForwardIosIcon fontSize="small" style={{fontSize: 12, marginLeft:"10px", verticalAlign:"middle", height: "auto",}}></ArrowForwardIosIcon></span>,
                    tabContent: (
                      <div style={{height:"512px", width:"98%", overflow:"hidden", display:"-webkit-box", marginTop: "-30px"}}>
                      <Card 
                        background
                        className={classes1.buttonCardMenuBox}
                        style={{
                          backgroundImage: `url(${MenuSegurosEmp})` 
                       }}>
                        <GridContainer  /*background*/  style={{width:"inherit"}}>
                              <GridItem style={{alignText:"left", padding: "330px 40px 5px", zIndex: "2"}}>
                                  <div>
                                      <Typography style={{float:"left", fontWeight:"bold", fontSize:"22px"}} >
                                        Seguros
                                      </Typography>
                                  </div>
                              </GridItem>
                              <GridItem style={{display:"flex", justifyContent:"space-between", alignText:"left", padding: "10px 40px 20px", zIndex: "2"}}>
                                  <div>
                                      <Typography style={{fontSize:"14px", textAlign: "left", lineHeight: "1.2", color:"#fff!Important"}}>
                                        Contrate para sua empresa<br/>
                                        ou seu negócio diversas modalidades de <br/>
                                        seguros, de maneira muito rápida e fácil.<br/>
                                        Proteja sua empresa de riscos e imprevistos.
                                      </Typography> 
                                  </div>
                                  <div>
                                      <Button className={classes1.buttonContaDigitalPF} color="success" round>
                                        ACESSAR
                                      </Button>
                                  </div>
                              </GridItem>
                        </GridContainer>
                      </Card>
                </div>
                    ),
                  },
                  {
                    tabButton: <span style={{width:"100%", display:"inline-flex", justifyContent:"space-between" }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><AttachMoney  style={{marginRight:"10px", verticalAlign:"middle",  }}></AttachMoney> Câmbio</span><span style={{marginInlineStart: "auto"}}  className={classes.badgesome}><Badge color="gray">Em breve</Badge></span><ArrowForwardIosIcon fontSize="small" style={{fontSize: 12, marginLeft:"10px", verticalAlign:"middle", height: "auto",}}></ArrowForwardIosIcon></span>,
                    tabContent: (
                      <div style={{height:"512px", width:"98%", overflow:"hidden", display:"-webkit-box", marginTop: "-30px"}}>
                      <Card 
                        background
                        className={classes1.buttonCardMenuBox}
                        style={{
                          backgroundImage: `url(${MenuCambioEmp})` 
                        }}>
                        <GridContainer  /*background*/  style={{width:"inherit"}}>
                              <GridItem style={{alignText:"left", padding: "330px 40px 5px", zIndex: "2"}}>
                                  <div>
                                      <Typography style={{float:"left", fontWeight:"bold", fontSize:"22px"}} >
                                        Câmbio
                                      </Typography>
                                  </div>
                              </GridItem>
                              <GridItem style={{display:"flex", justifyContent:"space-between", alignText:"left", padding: "10px 40px 20px", zIndex: "2"}}>
                                  <div>
                                      <Typography style={{fontSize:"14px", textAlign: "left", lineHeight: "1.2", color:"#fff!Important"}}>
                                        Negocie mais de 20 moedas<br/>
                                        estrangeiras em espécie e retire em <br/>
                                        pontos comerciais de parceiros em todo o <br/>
                                        Brasil ou solicite nosso delivery em sua empresa*.
                                      </Typography> 
                                  </div>
                                  <div>
                                      <Button className={classes1.buttonContaDigitalPF} color="success" round>
                                        ACESSAR
                                      </Button>
                                  </div>
                              </GridItem>
                        </GridContainer>
                      </Card>
                </div>
                    ),
                  },
                  {
                    tabButton: <span style={{width:"100%", display:"inline-flex", justifyContent:"space-between" }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><BeenhereIcon  style={{marginRight:"10px", verticalAlign:"middle",  }}></BeenhereIcon> Consórcios</span><span style={{marginInlineStart: "auto"}}   className={classes.badgesome}><Badge color="gray">Em breve</Badge></span><ArrowForwardIosIcon fontSize="small" style={{fontSize: 12, marginLeft:"10px", verticalAlign:"middle", height: "auto",}}></ArrowForwardIosIcon></span>,
                    tabContent: (
                      <div style={{display:"inline-flex"}}>
                      <div style={{height:"500px", width:"45%", overflow:"hidden", marginTop: "-30px", marginRight:"15px", marginBottom: "-14px"}}>
                      <Card 
                        background
                        className={classes1.buttonCardMenuFittiEmprestimos}
                        style={{
                        backgroundImage: `url(${MenuConsorcio})` 
                        }}>
                        <GridContainer  /*background*/  style={{width:"inherit"}}>
                              <GridItem className={classes1.buttonCardMenuFittiConsQuadrinho}>
                                  <div>
                                      <Typography style={{fontWeight:"bold", fontSize:"18px"}} >
                                        Consórcios
                                      </Typography>
                                  </div>
                              </GridItem>
                              <GridItem style={{display:"flex", justifyContent:"center", alignText:"center", padding: "5px 40px 180px", zIndex: "2", marginLeft:"15px"}}>
                    
                                  <div>
                                      <Button style={{width:"100px", height:"30px"}} color="success" round>
                                        ACESSAR
                                      </Button>
                                  </div>
                              </GridItem>
                        </GridContainer>
                      </Card>
                      </div>
                      <div style={{display:"Grid", justifyItems: "center"}}> 
                      <div style={{display:"flex", justifyContent:"flex-start", height:"280px", width:"100%", overflow:"hidden", marginTop: "-30px", marginBottom: "-18px"}}>
                          <Card 
                            background
                            className={classes1.buttonCardMenu2}
                            style={{
                             backgroundImage: `url(${MenuConsorcioAUTO})` 
                            }}>
                            <GridContainer  /*background*/  style={{width:"inherit"}}>
                                  <GridItem style={{alignText:"left", padding: "70px 40px 5px", zIndex: "2"}}>
                                      <div>
                                          <Typography className={classes1.textoPrincipalEmprestimos}>
                                            Consórcio Auto<br/>
                                          </Typography>
                                      </div>
                                  </GridItem>
                                  <GridItem style={{display:"block", justifyContent:"left",  textAlign:"left", padding: "10px 40px 20px", zIndex: "2"}}>
                                      <div>
                                          <Typography className={classes1.alinhamento}>
                                            A maneira mais acessível<br/>
                                            para adquirir o seu automóvel.<br/>
                                          </Typography> 
                                      </div>
                                      <div style={{float: "left", marginTop: "5px"}}>
                                          <Button className={classes1.buttonEmprestimosGeral} color="success" round>
                                            ACESSAR
                                          </Button>
                                      </div>
                                  </GridItem>
                            </GridContainer>
                          </Card>
                          <Card 
                            background
                            className={classes1.buttonCardMenu2}
                            style={{
                            backgroundImage: `url(${MenuConsorcioMOTO})` 
                            }}>
                            <GridContainer  /*background*/  style={{width:"inherit"}}>
                                  <GridItem style={{alignText:"left", padding: "70px 40px 5px", zIndex: "2"}}>
                                      <div>
                                          <Typography className={classes1.textoPrincipalEmprestimos} >
                                         Consórcio Moto
                                          </Typography>
                                      </div>
                                  </GridItem>
                                  <GridItem style={{display:"block", justifyContent:"left", textAlign: "left", padding: "10px 40px 20px", zIndex: "2"}}>
                                      <div>
                                          <Typography className={classes1.alinhamento}>
                                            O jeito mais acessível <br/>
                                            para adquirir a sua moto.<br/>
                                          </Typography> 
                                      </div>
                                      <div style={{float: "left", marginTop: "5px"}}>
                                          <Button className={classes1.buttonEmprestimosGeral} color="success" round>
                                            ACESSAR
                                          </Button>
                                      </div>
                                  </GridItem>
                            </GridContainer>
                          </Card>
                 </div>
                 <div style={{display:"flex", justifyContent:"flex-start", height:"280px", width:"100%", overflow:"hidden", marginTop:"-30px"}}>
                 <Card 
                   background
                   className={classes1.buttonCardMenu2}
                   style={{
                    backgroundImage: `url(${MenuConsorcioImobiliario})` 
                   }}>
                   <GridContainer  /*background*/  style={{width:"inherit"}}>
                         <GridItem style={{alignText:"left", padding: "70px 40px 5px", zIndex: "2"}}>
                             <div>
                                 <Typography className={classes1.textoPrincipalEmprestimos}>
                                   Consórcio Imobiliário
                                 </Typography>
                             </div>
                         </GridItem>
                         <GridItem style={{display:"block", justifyContent:"left",  textAlign: "left", padding: "10px 40px 20px", zIndex: "2"}}>
                             <div>
                                 <Typography className={classes1.alinhamento}>
                                 Seu sonho de ter um imóvel<br/>
                                 de maneira acessível.<br/>
                                 </Typography> 
                             </div>
                             <div style={{float: "left", marginTop: "5px"}}>
                                 <Button className={classes1.buttonEmprestimosGeral} color="success" round>
                                   ACESSAR
                                 </Button>
                             </div>
                         </GridItem>
                   </GridContainer>
                 </Card>
                 <Card 
                   background
                   className={classes1.buttonCardMenu2}
                   style={{
                    backgroundImage: `url(${MenuConsorcioServico})` 
                    }}>
                   <GridContainer  /*background*/  style={{width:"inherit"}}>
                         <GridItem style={{alignText:"left", padding: "70px 40px 5px", zIndex: "2"}}>
                             <div>
                                 <Typography className={classes1.textoPrincipalEmprestimos}>
                                 Consórcio de serviços
                                 </Typography>
                             </div>
                         </GridItem>
                         <GridItem style={{display:"block", justifyContent:"left", textAlign: "left", padding: "10px 40px 20px", zIndex: "2"}}>
                             <div>
                                 <Typography className={classes1.alinhamento}>
                                 Use p/ procedimentos estéticos,<br/>
                                 reformas, educação e viagens.<br/>
                                 </Typography> 
                             </div>
                             <div style={{float: "left", marginTop: "5px"}}>
                                 <Button className={classes1.buttonEmprestimosGeral} color="success" round>
                                   ACESSAR
                                 </Button>
                             </div>
                         </GridItem>
                   </GridContainer>
                 </Card>
              </div>
          </div>
      </div>
                    ),
                  },

                 
                  {
                    tabButton: <span style={{width:"100%", display:"inline-flex", justifyContent:"space-between" }} target="" className={classes.dropdownLink}><span style={{marginRight:"10px", }}><BarChartIcon  style={{marginRight:"10px", verticalAlign:"middle",  }}></BarChartIcon> Investimentos</span><span style={{marginInlineStart: "auto"}}   className={classes.badgesome}><Badge color="gray">Em breve</Badge></span><ArrowForwardIosIcon fontSize="small" style={{fontSize: 12, marginLeft:"10px", verticalAlign:"middle", height: "auto",}}></ArrowForwardIosIcon></span>,
                    tabContent: (
                      <div style={{height:"512px", width:"98%", overflow:"hidden", display:"-webkit-box", marginTop: "-30px"}}>
                      <Card 
                        background
                        className={classes1.buttonCardMenuBox}
                        style={{
                          backgroundImage: `url(${MenuInvestimentosEmp})` 
                        }}>
                        <GridContainer  /*background*/  style={{width:"inherit"}}>
                              <GridItem style={{alignText:"left", padding: "330px 40px 5px", zIndex: "2"}}>
                                  <div>
                                      <Typography style={{float:"left", fontWeight:"bold", fontSize:"22px"}} >
                                        Investimentos
                                      </Typography>
                                  </div>
                              </GridItem>
                              <GridItem style={{display:"flex", justifyContent:"space-between", alignText:"left", padding: "10px 40px 20px", zIndex: "2"}}>
                                  <div>
                                      <Typography style={{fontSize:"14px", textAlign: "left", lineHeight: "1.2", color:"#fff!Important"}}>
                                        Através da nossa plataforma<br/>
                                        de investimentos, obtenha acesso <br/>
                                        á diversos produtos de renda fixa e<br/>
                                        renda variável com corretagem zero e cashback.
                                      </Typography> 
                                  </div>
                                  <div>
                                      <Button className={classes1.buttonContaDigitalPF} color="success" round>
                                        ACESSAR
                                      </Button>
                                  </div>
                              </GridItem>
                        </GridContainer>
                      </Card>
                </div>
                    ),
                  },
                 


                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  </div>
  );
}
