import React from "react";
import { Doughnut } from 'react-chartjs-2';
import  'chartjs-plugin-datalabels';
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/components.js";
import Typography from '@material-ui/core/Typography';
import styled, { keyframes } from 'styled-components';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
//import replaceAllInserter from 'string.prototype.replaceall'
//import taxasconsorcio from 'images/taxasconsorcio.png';
//import SmoothImage from 'react-smooth-image';
//import Check from '@material-ui/icons/Check';
//import Close from '@material-ui/icons/Close';
//import Success from "components/Typography/Success.js";
//import CardBody from "components/Card/CardBody.js";
//import Card from "components/Card/Card.js";
//import pricingStyle from "assets/jss/material-kit-react/views/sectionsSections/pricingStyle.js";
//import ListItem from "@material-ui/core/ListItem";
//import List from "@material-ui/core/List";


//import CardFooter from "components/Card/CardFooter.js";
//import Button from "components/CustomButtons/Button.js";
//import NavPills from "components/NavPills/NavPills.js";
//import Danger from "components/Typography/Danger.js";



// @material-ui/icons
//import Favorite from "@material-ui/icons/Favorite";
// core components



// sections for this page






Chart.register(ChartDataLabels);

const useStyles = makeStyles(styles);
//const useStyles2 = makeStyles(pricingStyle);

//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;


export default function ChartConsorcios(){
    //const text1 = "Tesouro Direto, CDB, LCI e LCA <br /> CRI e CRA, Debêntures Incentivadas, Letra de Câmbio, Letra Financeira "
    //const text2 = "vamos testar" 


     const classes = useStyles(styles)
     //const classes2 = useStyles2(pricingStyle)
     let chartfinal = {
          
            chartData: {
                labels: [ ['Renda Fixa (Prefixado)'],
                          ['Renda Fixa (Pós-fixado)'],
                          ['Renda Fixa (Inflação)'],   
                          ['Renda Variável (Brasil)'],
                          ['Renda Variável (Internacional)'],  
                          ['Fundos Multimercados'],
                          ['Fundos Imobiliários'],
                          ['Derivativos'],
                        ],
                labels1: [ 'CDB Pré, Tesouro Prefixado, LC´s','CDB Pós, Tesouro Selic, LC´s', 'CDB IPCA+, Tesouro IPCA, LC´s', 'Ações, BDR´s, ETF´s e Fundos', 'Ações, ADR´s, ETF´s e Fundos','FIM´s','FII´s', 'Opções, SWAPS, COE´s e Futuros'],
                            
                datasets: [
                            {   label: 'Fiti x Concorrentes',
                                data: [60, 12, 8, 5, 5, 5, 5, 0],
                                backgroundColor: [  'rgba(76, 175, 80, 0.6)',
                                                    'rgba(76, 175, 80, 0.4)',
                                                    'rgba(76, 175, 80, 0.2)', 
                                                    'rgba(0, 172, 193, 1)',
                                                    'rgba(0, 172, 193, 0.5)',
                                                    'rgba(153, 153, 153, 0.6)',
                                                    'rgba(210, 210, 210, 0.6)',
                                                    'rgba(210, 210, 120, 1)', ],

                                    borderColor: [  'rgba(76, 175, 80, 1)',
                                                    'rgba(153, 153, 153, 1)',
                                                    'rgba(210, 210, 210, 1)' ], 
                                    borderWidth: 0,
                                    borderRadius: 0,
                                    barPercentage:0.8,
                                    hoverOffset: 8,
                                    
                            }
                        
                        
                        ]
            }   
    }
    //const plugins = { pluginsFinal: [ChartDataLabels] }
   
    const options = {
            
            config: {
                
                plugins: {legend: {display: true}, datalabels:{font:{size:'20px'}, formatter: function(value, context){ return value + '%'}},
                tooltip: {
                    backgroundColor:'rgba(153, 153, 153, 1)',
                    borderColor:'rgba(153, 153, 153, 1)',
                    borderWidth:'2',
                    callbacks: {
                        label: function (context, data) {
                           
                            var label = chartfinal.chartData.labels1[context.dataIndex]
                            var value = chartfinal.chartData.datasets[context.datasetIndex].data[context.dataIndex]; 
                            
                            return label +': '+ value + '%' 
                        },

                         title: function(context) {
                            if (context && context[0] && context[0].label) {
                                console.log(context[0].label);
                                const labelString = String(context[0].label);  // Convert label to string
                                return labelString.replaceAll(',', ' ');
                            } else {
                                console.log('Label is undefined');
                                return '';
                            }
                            
                        },
                          //footer: footer,
                        },
                        labelColor: function(context) {
                            return (context.dataset.data.backgroundColor)
                        },
                       
                },

            },
                responsive: true,
                maintainAspectRatio: false,
                animation: { animateScroll: true, duration:2000, easing:'easeInQuart', delay:50},
                animationDelay: true,
               
              /*  scales: {  x: { grid: {display: false,} },
                           y: { ticks: { beginAtZero: true, display: false, callback: (label) => `${label}%` ,  gridLines: { display: true, drawBorder: true } }} }  */ 
                
            }
    }


    let chartfinal2 = {
          
        chartData: {
            labels: [ ['Renda Fixa (Prefixado)'],
                      ['Renda Fixa (Pós-fixado)'],
                      ['Renda Fixa (Inflação)'],   
                      ['Renda Variável (Brasil)'],
                      ['Renda Variável (Internacional)'],  
                      ['Fundos Multimercados'],
                      ['Fundos Imobiliários'],
                      ['Derivativos'],
                    ],
            labels1: [ 'CDB Pré, Tesouro Prefixado, LC´s','CDB Pós, Tesouro Selic, LC´s', 'CDB IPCA+, Tesouro IPCA, LC´s', 'Ações, BDR´s, ETF´s e Fundos', 'Ações, ADR´s, ETF´s e Fundos','FIM´s','FII´s', 'Opções, SWAPS, COE´s e Futuros'],
                        
            datasets: [
                        {   label: 'Fiti x Concorrentes',
                            data: [40, 12, 8, 15, 15, 5, 5, 0],
                            backgroundColor: [  'rgba(76, 175, 80, 0.6)',
                                                'rgba(76, 175, 80, 0.4)',
                                                'rgba(76, 175, 80, 0.2)', 
                                                'rgba(0, 172, 193, 1)',
                                                'rgba(0, 172, 193, 0.5)',
                                                'rgba(153, 153, 153, 0.6)',
                                                'rgba(210, 210, 210, 0.6)',
                                                'rgba(210, 210, 120, 1)', ],

                                borderColor: [  'rgba(76, 175, 80, 1)',
                                                'rgba(153, 153, 153, 1)',
                                                'rgba(210, 210, 210, 1)' ], 
                                borderWidth: 0,
                                borderRadius: 0,
                                barPercentage:0.8,
                                hoverOffset: 8,
                                
                        }
                    
                    
                    ]
        }   
}
//const plugins = { pluginsFinal: [ChartDataLabels] }

const options2 = {
        
        config: {
            
            plugins: {legend: {display: true}, datalabels:{font:{size:'20px'}, formatter: function(value, context){ return value + '%'}},
            tooltip: {
                backgroundColor:'rgba(153, 153, 153, 1)',
                borderColor:'rgba(153, 153, 153, 1)',
                borderWidth:'2',
                callbacks: {
                    label: function (context, data) {
                       
                        var label = chartfinal2.chartData.labels1[context.dataIndex]
                        var value = chartfinal2.chartData.datasets[context.datasetIndex].data[context.dataIndex]; 
                        
                        return label +': '+ value + '%' 
                    },

                     title: function(context) {
                        if (context && context[0] && context[0].label) {
                            console.log(context[0].label);
                            const labelString = String(context[0].label);  // Convert label to string
                            return labelString.replaceAll(',', ' ');
                        } else {
                            console.log('Label is undefined');
                            return '';
                        }
                        
                    },
                      //footer: footer,
                    },
                    labelColor: function(context) {
                        return (context.dataset.data.backgroundColor)
                    },
                   
            },

        },
            responsive: true,
            maintainAspectRatio: false,
            animation: { animateScroll: true, duration:2000, easing:'easeInQuart', delay:50},
            animationDelay: true,
           
          /*  scales: {  x: { grid: {display: false,} },
                       y: { ticks: { beginAtZero: true, display: false, callback: (label) => `${label}%` ,  gridLines: { display: true, drawBorder: true } }} }  */ 
            
        }
}



let chartfinal3 = {
          
    chartData: {
        labels: [ ['Renda Fixa (Prefixado)'],
                  ['Renda Fixa (Pós-fixado)'],
                  ['Renda Fixa (Inflação)'],   
                  ['Renda Variável (Brasil)'],
                  ['Renda Variável (Internacional)'],  
                  ['Fundos Multimercados'],
                  ['Fundos Imobiliários'],
                  ['Derivativos'],
                ],
        labels1: [ 'CDB Pré, Tesouro Prefixado, LC´s','CDB Pós, Tesouro Selic, LC´s', 'CDB IPCA+, Tesouro IPCA, LC´s', 'Ações, BDR´s, ETF´s e Fundos', 'Ações, ADR´s, ETF´s e Fundos','FIM´s','FII´s', 'Opções, SWAPS, COE´s e Futuros'],
                    
        datasets: [
                    {   label: 'Fiti x Concorrentes',
                        data: [20, 10, 5, 20, 20, 15, 5, 5],
                        backgroundColor: [  'rgba(76, 175, 80, 0.6)',
                                            'rgba(76, 175, 80, 0.4)',
                                            'rgba(76, 175, 80, 0.2)', 
                                            'rgba(0, 172, 193, 1)',
                                            'rgba(0, 172, 193, 0.5)',
                                            'rgba(153, 153, 153, 0.6)',
                                            'rgba(210, 210, 210, 0.6)',
                                            'rgba(210, 210, 120, 1)', ],

                            borderColor: [  'rgba(76, 175, 80, 1)',
                                            'rgba(153, 153, 153, 1)',
                                            'rgba(210, 210, 210, 1)' ], 
                            borderWidth: 0,
                            borderRadius: 0,
                            barPercentage:0.8,
                            hoverOffset: 8,
                            
                    }
                
                
                ]
    }   
}
//const plugins = { pluginsFinal: [ChartDataLabels] }

const options3 = {
    
    config: {
        
        plugins: {legend: {display: true}, datalabels:{font:{size:'20px'}, formatter: function(value, context){ return value + '%'}},
        tooltip: {
            backgroundColor:'rgba(153, 153, 153, 1)',
            borderColor:'rgba(153, 153, 153, 1)',
            borderWidth:'2',
            callbacks: {
                label: function (context, data) {
                   
                    var label = chartfinal3.chartData.labels1[context.dataIndex]
                    var value = chartfinal3.chartData.datasets[context.datasetIndex].data[context.dataIndex]; 
                    
                    return label +': '+ value + '%' 
                },

                 title: function(context) {
                    if (context && context[0] && context[0].label) {
                        console.log(context[0].label);
                        const labelString = String(context[0].label);  // Convert label to string
                        return labelString.replaceAll(',', ' ');
                    } else {
                        console.log('Label is undefined');
                        return '';
                    }
                    
                },
                  //footer: footer,
                },
                labelColor: function(context) {
                    return (context.dataset.data.backgroundColor)
                },
               
        },

    },
        responsive: true,
        maintainAspectRatio: false,
        animation: { animateScroll: true, duration:2000, easing:'easeInQuart', delay:50},
        animationDelay: true,
       
      /*  scales: {  x: { grid: {display: false,} },
                   y: { ticks: { beginAtZero: true, display: false, callback: (label) => `${label}%` ,  gridLines: { display: true, drawBorder: true } }} }  */ 
        
    }
}


    return (   
       
<GridContainer className={classes.container} style={{display: "flex", justifyContent: "center"}}>
                <div className={classes.container2} style={{marginBottom:"35px", marginTop:"10px"}}>
                    <Animation1>
                        <Typography className={classes.titleanimatedbuttons2}>
                            Produtos para todos os perfis de investidor
                        </Typography>
                    </Animation1>
                </div> 
          <GridItem className={classes.displayCardsInvest}> 
          <GridItem xs={12} sm={4} md={4} >
                          <Typography className={classes.titleInvestimentosChart}>
                                Conservador:
                          </Typography>
                          <div style={{display: "flex", justifyContent: "center"}}>
                              <div className={classes.chartConsorcios} style={{boxShadow: "0px 2px 23px -16px", borderRadius: "20px", marginBottom:"50px", marginTop:"20px", paddingTop: "20px", paddingBottom: "20px", paddingLeft:"25px", paddingRight:"25px"}}>
                                  <Doughnut data={chartfinal.chartData} options={options.config} ></Doughnut>
                              </div>
                          </div>
          </GridItem> 
          <GridItem xs={12} sm={4} md={4} >              
                          <Typography className={classes.titleInvestimentosChart}>
                                Moderado:
                          </Typography>
                          <div style={{display: "flex", justifyContent: "center"}}>
                              <div className={classes.chartConsorcios} style={{boxShadow: "0px 2px 23px -16px", borderRadius: "20px", marginBottom:"50px", marginTop:"20px", paddingTop: "20px", paddingBottom: "20px", paddingLeft:"25px", paddingRight:"25px"}}>
                                  <Doughnut data={chartfinal2.chartData} options={options2.config}></Doughnut>
                              </div>
                          </div>
          </GridItem>  
          <GridItem xs={12} sm={4} md={4} >
                          <Typography className={classes.titleInvestimentosChart}>
                                Sofisticado:
                          </Typography>
                          <div style={{display: "flex", justifyContent: "center"}}>
                              <div className={classes.chartConsorcios} style={{boxShadow: "0px 2px 23px -16px", borderRadius: "20px", marginBottom:"50px", marginTop:"20px", paddingTop: "20px", paddingBottom: "20px", paddingLeft:"25px", paddingRight:"25px"}}>
                                  <Doughnut data={chartfinal3.chartData} options={options3.config} ></Doughnut>
                              </div>
                          </div>
          </GridItem>
        </GridItem>
      </GridContainer> 
      

    );
}

